
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { SalesOrder, SalesRefund } from '@/models/salestypes';
    import { Customer, ManagtUser } from '@/models/managttypes';
    import AppDeclarations from '@/tools/declarations';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    const greaterThanZero = (val: number) => val > 0;
    const fNewDate = new Date(),
        year = fNewDate.getFullYear()

    const emptySalesRefund: SalesRefund = {
        IdRefund: 0,
        OrderKey: '',
        RefundNbr: '',
        ClientKey: '',
        OperDate: fNewDate,
        UserEmail: '',
        RefundStatus: 0,
        PaytStatus: 0,
        DeviseISO: '',
        OfficeCode: '',
        RefundNote: '',
        RefundBarcode: '',
        RefundQRCode: '',
        RefundCreatedBy: '',
        FiscalYear: 0,
        TempoKey: '',
        EntryStockStatus: 0,
        RefundKey: '',        
    };


    export default defineComponent({        
        name: 'SalesRefundModal',
        emits: ['closeForm'],
        props: [
            "visible",
            "initialValues", 
            "recordStatus",
            "listOfUsers",
            "listOfCustomers",
            "selectedMonth"
        ],
        data(){
            return  {
                v$: useVuelidate(),
                appFxs,
                language,
                dataRecord: {...emptySalesRefund} as SalesRefund,
                isSavingRecord: false,
                currCode: 'RWF',

                theListStaff: [] as ManagtUser[],
                theCustomers: [] as Customer[],

                firstDate: fNewDate,
                lastDate: fNewDate,
                selectedMonthData: this.selectedMonth as number,
                selectedYear: year,

                userEmail: '',
            }
        },
        computed: {
            windowTitle() {
                return this.recordStatus == 0 ? language.add_refund_oper :
                    language.edit_refund_oper;
            },
            
            isDateCorrect(): boolean{
                return this.isBetweenTwoDates(this.dataRecord.OperDate);
            }
        },
        methods: {
            isBetweenTwoDates(val: Date): boolean{
                return appFxs.isBetweenTwoDates(val, this.firstDate, this.lastDate);
            },
            generateOrderNbr(){
                const otime = new Date();
                const oSec = otime.getSeconds();
                const oMins = otime.getMinutes();

                const oRandom = appFxs.getRandomDigits(2);
                const fSecs = oSec.toString().length == 2 ? 
                    oSec.toString() : '0' + oSec.toString();
                const fMins = oMins.toString().length == 2 ? 
                    oMins.toString() : '0' + oMins.toString();

                const oNbr = 'RFD' + appFxs.generateTextNbr(5) + `${oRandom}${fSecs}${fMins}`;
                this.dataRecord.RefundNbr =  oNbr.toUpperCase();
            },
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.$emit('closeForm', {returnType: 1, returnData: this.dataRecord});
            },
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: SalesRefund = {...oValues, OperDate: new Date(oValues.OperDate)} as SalesRefund;

                        this.selectedMonthData = this.selectedMonth as number

                        this.selectedYear = Number(appFxs.getLocalStorageByKey(AppDeclarations.currentYearbook, year.toString()));
                        const userEmail = appFxs.getLocalStorageByKey(AppDeclarations.userEmail, '');
                        this.userEmail = userEmail;

                        this.firstDate = appFxs.getFirstDate(this.selectedYear, this.selectedMonthData);
                        this.lastDate = appFxs.getLastDate(this.selectedYear, this.selectedMonthData);

                        this.theCustomers = appFxs.getProxyData(this.listOfCustomers) as Customer[];
                        this.theListStaff = appFxs.getProxyData(this.listOfUsers) as ManagtUser[];
                        this.v$.$reset();
                        this.dataRecord = fValues;
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {
                    RefundNbr: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    OrderKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    ClientKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    UserEmail: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    RefundBarcode: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    RefundQRCode: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    RefundCreatedBy: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    TempoKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                }
            }
        }
    })
