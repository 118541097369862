import { Company, TaxSett } from '@/models/managttypes';
import { DiscountWithRules } from '@/models/notdb';
import { ProdProduct } from '@/models/producttypes';
import { SalesOrder, SalesOrderDetail, SalesQuote, SalesQuoteDetail, SalesRefund, SalesRefundDetail } from '@/models/salestypes';
import getLanguage from '@/tools/language';
import appDeclarations from "./declarations";
import GeneralFx from '@/tools/general_fx';

const language = getLanguage(),
        appFxs = GeneralFx();
function dataFunctions(){
    return {
        getDefaultCompany(): Company {
            return {
                Active: true,
                AlreadySet: true,
                CompanyId: 0,
                CompanyKey: '',
                CompanyName: '',
                Country: 'RW',
                DateCreated: new Date(),
                DateExpiryLicense: new Date(),
                Email: '',
                Licensed: true,
                ShortName: '',
                AcctCurrency: 'RWF',
                Adress: '',
                City: '',
                Fax: '',
                LogoPath: '',
                MobilePhone: '',
                OfficePhone: '',
                POBox: '',
                VATNumber: '',
                Website: '',
            }
        },
        getTaxValue(
            theListTaxes: TaxSett[],
            selectedProduct: ProdProduct,
            taxableAmt: number
        ):number{
            const taxKey = selectedProduct.DefaultTax;
            const oTax = theListTaxes.find(o => o.TaxKey == taxKey);
            if(oTax==undefined) return 0;
            const oValue = taxableAmt * (oTax.TaxRate / 100);
            return oValue;
        },
        getDiscountValue(
            lstDisc: DiscountWithRules[],
            orderRecord: SalesOrder,
            dataRecord: SalesOrderDetail
        ): {keys:string, value: number} {

            let oValue = 0;
            const dOperDate = orderRecord.OperDate;
            const oQty = dataRecord.Quantity;
            const oUnitPr = dataRecord.UnitPrice;
            const subTotal = oQty * oUnitPr;
            const customerKey = orderRecord.ClientKey;
            const productSku = dataRecord.ProductSKU;
            const appliedDiscKeys: string[] = [];

            lstDisc.forEach(oDisc => {
                const oneDisc = oDisc.discount;

                const bHasEndDate = oneDisc.HasEndDate;
                const dStartDate = oneDisc.StartDate;
                const dEndDate = oneDisc.EndDate;
                const bHasRules = oneDisc.HasRules;
                const bIsStillActive = oneDisc.IsStillActive;

                const bAcceptCust = oneDisc.ApplytoAllClients || oneDisc.Clients.includes(customerKey);
                const bAcceptProd = oneDisc.ApplytoAllProducts || oneDisc.Products.includes(productSku);

                const isValidDate = !bHasEndDate || appFxs.isBetweenTwoDates(dOperDate, dStartDate, dEndDate);


                const bToApply = bIsStillActive && isValidDate && bAcceptCust && bAcceptProd;
                if(bToApply){
                    if (bHasRules) {
                        const rules = oDisc.listOfRules;
                        const oQty = dataRecord.Quantity;
                        const ruleToApply = rules.find(fx => oQty >= fx.MinQuant && oQty <= fx.MaxQuant);
                        if(ruleToApply != undefined){
                            oValue += (subTotal * (ruleToApply.DiscValue / 100));
                        }
                    }else{
                        oValue += (subTotal * (oDisc.discount.DiscValue / 100));
                    }

                    appliedDiscKeys.push(oneDisc.DiscountCode);
                }
                
            });
            const keys = appliedDiscKeys.length > 0 ?
            appliedDiscKeys.join(';'):
            '';

            return {
                keys,
                value: oValue
            };
        },
        getDiscountValueForQuote(
            lstDisc: DiscountWithRules[],
            orderRecord: SalesQuote,
            dataRecord: SalesQuoteDetail
        ): {keys:string, value: number} {

            let oValue = 0;
            const dOperDate = orderRecord.OperDate;
            const oQty = dataRecord.Quantity;
            const oUnitPr = dataRecord.UnitPrice;
            const subTotal = oQty * oUnitPr;
            const customerKey = orderRecord.ClientKey;
            const productSku = dataRecord.ProductSKU;
            const appliedDiscKeys: string[] = [];

            lstDisc.forEach(oDisc => {
                const oneDisc = oDisc.discount;

                const bHasEndDate = oneDisc.HasEndDate;
                const dStartDate = oneDisc.StartDate;
                const dEndDate = oneDisc.EndDate;
                const bHasRules = oneDisc.HasRules;
                const bIsStillActive = oneDisc.IsStillActive;

                const bAcceptCust = oneDisc.ApplytoAllClients || oneDisc.Clients.includes(customerKey);
                const bAcceptProd = oneDisc.ApplytoAllProducts || oneDisc.Products.includes(productSku);

                const isValidDate = !bHasEndDate || appFxs.isBetweenTwoDates(dOperDate, dStartDate, dEndDate);


                const bToApply = bIsStillActive && isValidDate && bAcceptCust && bAcceptProd;
                if(bToApply){
                    if (bHasRules) {
                        const rules = oDisc.listOfRules;
                        const oQty = dataRecord.Quantity;
                        const ruleToApply = rules.find(fx => oQty >= fx.MinQuant && oQty <= fx.MaxQuant);
                        if(ruleToApply != undefined){
                            oValue += (subTotal * (ruleToApply.DiscValue / 100));
                        }
                    }else{
                        oValue += (subTotal * (oDisc.discount.DiscValue / 100));
                    }

                    appliedDiscKeys.push(oneDisc.DiscountCode);
                }
                
            });
            const keys = appliedDiscKeys.length > 0 ?
            appliedDiscKeys.join(';'):
            '';

            return {
                keys,
                value: oValue
            };
        },
        getDiscountValueForRefund(
            lstDisc: DiscountWithRules[],
            refundRecord: SalesRefund,
            dataRecord: SalesRefundDetail
        ): {keys:string, value: number} {

            let oValue = 0;
            const dOperDate = refundRecord.OperDate;
            const oQty = dataRecord.Quantity;
            const oUnitPr = dataRecord.UnitPrice;
            const subTotal = oQty * oUnitPr;
            const customerKey = refundRecord.ClientKey;
            const productSku = dataRecord.ProductSKU;
            const appliedDiscKeys: string[] = [];

            lstDisc.forEach(oDisc => {
                const oneDisc = oDisc.discount;

                const bHasEndDate = oneDisc.HasEndDate;
                const dStartDate = oneDisc.StartDate;
                const dEndDate = oneDisc.EndDate;
                const bHasRules = oneDisc.HasRules;
                const bIsStillActive = oneDisc.IsStillActive;

                const bAcceptCust = oneDisc.ApplytoAllClients || oneDisc.Clients.includes(customerKey);
                const bAcceptProd = oneDisc.ApplytoAllProducts || oneDisc.Products.includes(productSku);

                const isValidDate = !bHasEndDate || appFxs.isBetweenTwoDates(dOperDate, dStartDate, dEndDate);


                const bToApply = bIsStillActive && isValidDate && bAcceptCust && bAcceptProd;
                if(bToApply){
                    if (bHasRules) {
                        const rules = oDisc.listOfRules;
                        const oQty = dataRecord.Quantity;
                        const ruleToApply = rules.find(fx => oQty >= fx.MinQuant && oQty <= fx.MaxQuant);
                        if(ruleToApply != undefined){
                            oValue += (subTotal * (ruleToApply.DiscValue / 100));
                        }
                    }else{
                        oValue += (subTotal * (oDisc.discount.DiscValue / 100));
                    }

                    appliedDiscKeys.push(oneDisc.DiscountCode);
                }
                
            });
            const keys = appliedDiscKeys.length > 0 ?
            appliedDiscKeys.join(';'):
            '';

            return {
                keys,
                value: oValue
            };
        },
        getNewSalesDetailOper(
            lstDisc: DiscountWithRules[],
            listTaxes: TaxSett[],
            selectedProduct: ProdProduct,
            salesOper: SalesOrder,
            formerRecord: SalesOrderDetail,
            newRecord: SalesOrderDetail,
        ): SalesOrderDetail {
            const newQty = formerRecord.Quantity + 
                            newRecord.Quantity;
            const unitPrice = formerRecord.UnitPrice;
            const subTotal = newQty * unitPrice;

            const finalData = {
                ...formerRecord, 
                Quantity: newQty,
                SubTotal: subTotal,
            } as SalesOrderDetail;
            const discs = this.getDiscountValue(lstDisc,salesOper,finalData);

            const taxableAmt = subTotal - discs.value;

            const taxValue = !selectedProduct.IsTaxable ?0:
             this.getTaxValue(listTaxes, selectedProduct, taxableAmt);
            const totalAmt = taxableAmt + taxValue;

            return {
                IdOrderDet: 0,
                OrderKey: salesOper.OrderKey,
                OrderDetailKey: formerRecord.OrderDetailKey,
                ProductSKU: formerRecord.ProductSKU,
                ProductOrient: formerRecord.ProductOrient,
                VariantValues: formerRecord.VariantValues,
                Quantity: newQty,
                UnitPrice: unitPrice,
                SubTotal : subTotal,

                DiscountKeys: discs.keys,
                DiscountAmount: discs.value,
                TaxableAmount: taxableAmt,
                TaxKey: selectedProduct.DefaultTax,
                TaxRate: formerRecord.TaxRate,
                TaxValue: taxValue,
                TotalAmt: totalAmt,
                FiscalYear: formerRecord.FiscalYear,
                TempoKey: formerRecord.TempoKey,
            };
        },
        getNewQuoteDetailOper(
            lstDisc: DiscountWithRules[],
            listTaxes: TaxSett[],
            selectedProduct: ProdProduct,
            salesOper: SalesQuote,
            formerRecord: SalesQuoteDetail,
            newRecord: SalesQuoteDetail,
        ): SalesQuoteDetail {
            const newQty = formerRecord.Quantity + 
                            newRecord.Quantity;
            const unitPrice = formerRecord.UnitPrice;
            const subTotal = newQty * unitPrice;

            const finalData = {
                ...formerRecord, 
                Quantity: newQty,
                SubTotal: subTotal,
            } as SalesQuoteDetail;
            const discs = this.getDiscountValueForQuote(lstDisc,salesOper,finalData);

            const taxableAmt = subTotal - discs.value;

            const taxValue = !selectedProduct.IsTaxable ?0:
             this.getTaxValue(listTaxes, selectedProduct, taxableAmt);
            const totalAmt = taxableAmt + taxValue;

            return {
                IdQuoteDet: 0,
                QuoteKey: salesOper.QuoteKey,
                QuoteDetailKey: formerRecord.QuoteDetailKey,
                ProductSKU: formerRecord.ProductSKU,
                ProductOrient: formerRecord.ProductOrient,
                VariantValues: formerRecord.VariantValues,
                Quantity: newQty,
                UnitPrice: unitPrice,
                SubTotal : subTotal,

                DiscountKeys: discs.keys,
                DiscountAmount: discs.value,
                TaxableAmount: taxableAmt,
                TaxKey: selectedProduct.DefaultTax,
                TaxRate: formerRecord.TaxRate,
                TaxValue: taxValue,
                TotalAmt: totalAmt,
                FiscalYear: formerRecord.FiscalYear,
                TempoKey: formerRecord.TempoKey,
            };
        },
        getNewRefundDetailOper(
            lstDisc: DiscountWithRules[],
            listTaxes: TaxSett[],
            selectedProduct: ProdProduct,
            refundOper: SalesRefund,
            formerRecord: SalesRefundDetail,
            newRecord: SalesRefundDetail,
        ): SalesRefundDetail {
            const newQty = formerRecord.Quantity + 
                            newRecord.Quantity;
            const unitPrice = formerRecord.UnitPrice;
            const subTotal = newQty * unitPrice;

            const finalData = {
                ...formerRecord, 
                Quantity: newQty,
                SubTotal: subTotal,
            } as SalesRefundDetail;
            const discs = this.getDiscountValueForRefund(lstDisc, refundOper, finalData);

            const taxableAmt = subTotal - discs.value;

            const taxValue = !selectedProduct.IsTaxable ?0:
             this.getTaxValue(listTaxes, selectedProduct, taxableAmt);
            const totalAmt = taxableAmt + taxValue;

            return {
                IdRefundDet: 0,
                RefundKey: refundOper.RefundKey,
                RefundDetailKey: formerRecord.RefundDetailKey,
                ProductSKU: formerRecord.ProductSKU,
                ProductOrient: formerRecord.ProductOrient,
                VariantValues: formerRecord.VariantValues,
                Quantity: newQty,
                UnitPrice: unitPrice,
                SubTotal : subTotal,

                DiscountKeys: discs.keys,
                DiscountAmount: discs.value,
                TaxableAmount: taxableAmt,
                TaxKey: selectedProduct.DefaultTax,
                TaxRate: formerRecord.TaxRate,
                TaxValue: taxValue,
                TotalAmt: totalAmt,
                FiscalYear: formerRecord.FiscalYear,
                TempoKey: formerRecord.TempoKey,
                OrderDetKey: formerRecord.OrderDetKey,
                OrderKey: formerRecord.OrderKey
            };
        }
    }
}

export default dataFunctions; 