
    import { defineComponent } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { ProdSubCategory } from '@/models/producttypes';
    import NetServices from '@/tools/netservices';

    const language =GetLanguage(),
        appFxs = GeneralFx();

    
    const emptySubCategory: ProdSubCategory = {
        ProdCategoryId: 0,
        NameEn: '',
        Code: '',
        Description: '',
        NameFr: '',
        NameRw: '',
        SubCode: '',
    };
    export default defineComponent({
        name: 'SubCategoryModal',
        emits: ['closeForm'],
        props: ["visible", "initialValues", "recordStatus"],
        data(){
            return {
                language,
                dataRecord: {...emptySubCategory},
                isSaving: false,
            }
        },
        computed: {            
            windowTitle():string {
                return this.recordStatus === 0 ? language.add_sub_categ:
                language.edit_sub_categ;
            },
            validationRules(){
                const codeError = !this.dataRecord.Code;
                const codeErrors = [] as string[];
                if(codeError) codeErrors.push(language.fldRequired);
                
                const subCodeError = !this.dataRecord.SubCode;
                const subCodeErrors = [] as string[];
                if(subCodeError) subCodeErrors.push(language.fldRequired);
                
                const nameEnError = !this.dataRecord.NameEn;
                const nameEnErrors = [] as string[];
                if(nameEnError) nameEnErrors.push(language.fldRequired);
                
                const nameFrError = !this.dataRecord.NameFr;
                const nameFrErrors = [] as string[];
                if(nameFrError) nameFrErrors.push(language.fldRequired);
                
                const nameRwError = !this.dataRecord.NameRw;
                const nameRwErrors = [] as string[];
                if(nameRwError) nameRwErrors.push(language.fldRequired);

                return {
                    $invalid: codeError || subCodeError || nameEnError || nameFrError || nameRwError,
                    fields: {
                        Code: {
                            $error: codeError,
                            $errors: codeErrors
                        },
                        SubCode: {
                            $error: subCodeError,
                            $errors: subCodeErrors
                        },
                        NameEn: {
                            $error: nameEnError,
                            $errors: nameEnErrors
                        },
                        NameFr: {
                            $error: nameFrError,
                            $errors: nameFrErrors
                        },
                        NameRw: {
                            $error: nameRwError,
                            $errors: nameRwErrors
                        },
                    }
                }
            }
        },
        methods: {
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.isSaving = true;
                const finalRec = {...this.dataRecord, SubCode: this.dataRecord.SubCode.toUpperCase()} as ProdSubCategory;
                NetServices.requestPost('products/categs/savesubcategoryrecord',{
                    prodSubCategoryRecord: finalRec,
                    toDelete: false,
                })
                .then(oRes => {
                    if(!oRes.bReturn){
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        this.isSaving = false;
                        return;
                    }

                    this.isSaving = false;
                    this.$emit('closeForm', {returnType: 1, returnData: oRes.listOfProdSubCategories});
                });
            },
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: ProdSubCategory = {...oValues};                       

                        

                        this.dataRecord = fValues;
                    }
                }
            }
        },        
        mounted(){
            appFxs.updateLoadedScripts();
        }
    })
