
import { defineComponent } from 'vue';
import GeneralFx from '@/tools/general_fx';
import GetLanguage from '@/tools/language';
import { PasswordStyle, WidthCssStyle } from '@/tools/smarttypes';

const appFxs = GeneralFx();

export default defineComponent({
    props: {
        password: {
            type: String,
            required: true
        }
    },
    data(){
        return {
            language: GetLanguage(),
        }
    },
    computed: {
        passwClass(): PasswordStyle {
            const pw = this.$props.password;
            const pctg = appFxs.passwordStrengthCheck(pw);
            const lng = (!pw)?0:pw.length;

            let clsBg = (pctg < 20) ? 'pw-meter-no-bg' :
                (pctg < 40) ? 'pw-meter-vweak-bg' :
                    (pctg < 60) ? 'pw-meter-soso-bg' :
                        (pctg < 80) ? 'pw-meter-good-bg' :
                            (pctg <= 100) ? 'pw-meter-great-bg' :
                                'pw-meter-no-bg';

            let clsInfo = (pctg < 20) ? '&nbsp;' :
                (pctg < 40) ? this.language.weak :
                    (pctg < 60) ? this.language.good :
                        (pctg < 80) ? this.language.strong :
                            (pctg <= 100) ? this.language.very_strong :
                                '&nbsp;';

            return {
                width: (lng) ? pctg.toString() + '%' : '0px',
                bgMeterClass: clsBg,
                infoClass: clsInfo
            }
        },
        getWidth(): WidthCssStyle {
            return {width: this.passwClass.width}
        }
    }
})
