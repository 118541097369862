import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal fade show",
  style: {"display":"block"}
}
const _hoisted_2 = { class: "modal-dialog wise-modal" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { for: "Code" }
const _hoisted_9 = ["placeholder", "disabled"]
const _hoisted_10 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_11 = { for: "NameEn" }
const _hoisted_12 = ["placeholder", "disabled"]
const _hoisted_13 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_14 = { for: "NameFr" }
const _hoisted_15 = ["placeholder", "disabled"]
const _hoisted_16 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_17 = { for: "NameRw" }
const _hoisted_18 = ["placeholder", "disabled"]
const _hoisted_19 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_20 = { class: "col-md-12 form-group" }
const _hoisted_21 = { for: "Description" }
const _hoisted_22 = ["placeholder", "disabled"]
const _hoisted_23 = { class: "modal-footer" }
const _hoisted_24 = { class: "d-flex justify-content-end" }
const _hoisted_25 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.windowTitle), 1),
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args))),
                type: "button",
                class: "btn-close"
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.validationRules.fields.Code.$error}])
                }, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.language.code), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "Code",
                    class: "capletter form-control",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataRecord.Code) = $event)),
                    placeholder: '(' + _ctx.language.code.toLowerCase() + ')',
                    maxlength: "10",
                    autocomplete: "off",
                    onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectOnFocus($event))),
                    disabled: _ctx.isSavingRecord
                  }, null, 40, _hoisted_9), [
                    [_vModelText, _ctx.dataRecord.Code]
                  ]),
                  (_ctx.validationRules.fields.Code.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.validationRules.fields.Code.$errors[0]), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.validationRules.fields.NameEn.$error}])
                }, [
                  _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.language.name + ' (en)'), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "NameEn",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataRecord.NameEn) = $event)),
                    placeholder: '(' + _ctx.language.name.toLowerCase() + ' (en))',
                    maxlength: "35",
                    autocomplete: "off",
                    onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectOnFocus($event))),
                    disabled: _ctx.isSavingRecord
                  }, null, 40, _hoisted_12), [
                    [_vModelText, _ctx.dataRecord.NameEn]
                  ]),
                  (_ctx.validationRules.fields.NameEn.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.validationRules.fields.NameEn.$errors[0]), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.validationRules.fields.NameFr.$error}])
                }, [
                  _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.language.name + ' (fr)'), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "NameFr",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dataRecord.NameFr) = $event)),
                    placeholder: '(' + _ctx.language.name.toLowerCase() + ' (fr))',
                    maxlength: "35",
                    autocomplete: "off",
                    onFocus: _cache[6] || (_cache[6] = ($event: any) => (_ctx.selectOnFocus($event))),
                    disabled: _ctx.isSavingRecord
                  }, null, 40, _hoisted_15), [
                    [_vModelText, _ctx.dataRecord.NameFr]
                  ]),
                  (_ctx.validationRules.fields.NameFr.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.validationRules.fields.NameFr.$errors[0]), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.validationRules.fields.NameRw.$error}])
                }, [
                  _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.language.name + ' (rw)'), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "NameRw",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dataRecord.NameRw) = $event)),
                    placeholder: '(' + _ctx.language.name.toLowerCase() + ' (rw))',
                    maxlength: "35",
                    autocomplete: "off",
                    onFocus: _cache[8] || (_cache[8] = ($event: any) => (_ctx.selectOnFocus($event))),
                    disabled: _ctx.isSavingRecord
                  }, null, 40, _hoisted_18), [
                    [_vModelText, _ctx.dataRecord.NameRw]
                  ]),
                  (_ctx.validationRules.fields.NameRw.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.validationRules.fields.NameRw.$errors[0]), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.language.description), 1),
                  _withDirectives(_createElementVNode("textarea", {
                    id: "Description",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dataRecord.Description) = $event)),
                    placeholder: '(' + _ctx.language.description.toLowerCase() + ')',
                    maxlength: "500",
                    autocomplete: "off",
                    onFocus: _cache[10] || (_cache[10] = ($event: any) => (_ctx.selectOnFocus($event))),
                    disabled: _ctx.isSavingRecord,
                    rows: "4"
                  }, null, 40, _hoisted_22), [
                    [_vModelText, _ctx.dataRecord.Description]
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-secondary me-2",
                  onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args)))
                }, _toDisplayString(_ctx.language.close), 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.saveNewCategory && _ctx.saveNewCategory(...args))),
                  disabled: _ctx.validationRules.$invalid || _ctx.isSavingRecord
                }, [
                  (_ctx.isSavingRecord)
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 0,
                        icon: ['fa', 'fa-spinner']
                      }))
                    : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 1,
                        icon: ['fa', 'fa-save']
                      })),
                  _createTextVNode(" " + _toDisplayString(_ctx.language.save), 1)
                ], 8, _hoisted_25)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}