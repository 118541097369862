import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal fade show",
  style: {"display":"block"}
}
const _hoisted_2 = { class: "modal-dialog wise-modal" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-md-6 form-group" }
const _hoisted_9 = { for: "Sex" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "col-md-6 form-group" }
const _hoisted_13 = { for: "AccountName" }
const _hoisted_14 = ["value", "placeholder"]
const _hoisted_15 = { for: "SmsAccount" }
const _hoisted_16 = ["placeholder", "disabled"]
const _hoisted_17 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_18 = { for: "Password" }
const _hoisted_19 = ["placeholder", "disabled"]
const _hoisted_20 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_21 = { for: "PhoneNumber" }
const _hoisted_22 = ["placeholder", "disabled"]
const _hoisted_23 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_24 = { class: "col-md-6 form-group" }
const _hoisted_25 = { for: "PhoneNumberFormat" }
const _hoisted_26 = ["value", "placeholder"]
const _hoisted_27 = { class: "modal-footer" }
const _hoisted_28 = { class: "d-flex justify-content-end" }
const _hoisted_29 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.windowTitle), 1),
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args))),
                type: "button",
                class: "btn-close"
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.language.country), 1),
                  _withDirectives(_createElementVNode("select", {
                    id: "Sex",
                    name: "Sex",
                    class: "form-control-sel",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataRecord.Country) = $event)),
                    onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCountryChanged && _ctx.onCountryChanged(...args))),
                    disabled: _ctx.dataRecord.SMSAcctId != 0,
                    autofocus: ""
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (oCtry) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: oCtry.code,
                        value: oCtry.code
                      }, _toDisplayString(oCtry.name), 9, _hoisted_11))
                    }), 128))
                  ], 40, _hoisted_10), [
                    [_vModelSelect, _ctx.dataRecord.Country]
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.language.telephone_code), 1),
                  _createElementVNode("input", {
                    type: "text",
                    id: "AccountName",
                    class: "form-control",
                    value: _ctx.dataRecord.CallCode,
                    placeholder: '(' + _ctx.language.telephone_code.toLowerCase() + ')',
                    disabled: true,
                    maxlength: "5",
                    autocomplete: "off"
                  }, null, 8, _hoisted_14)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.SmsAccount.$error}])
                }, [
                  _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.language.account_name), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "SmsAccount",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataRecord.SmsAccount) = $event)),
                    placeholder: '(' + _ctx.language.account_name.toLowerCase() + ')',
                    disabled: _ctx.dataRecord.SMSAcctId != 0,
                    maxlength: "50",
                    autocomplete: "off",
                    onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_16), [
                    [_vModelText, _ctx.dataRecord.SmsAccount]
                  ]),
                  (_ctx.v$.dataRecord.SmsAccount.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.v$.dataRecord.SmsAccount.$errors[0].$message), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.Password.$error}])
                }, [
                  _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.language.password), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "password",
                    id: "Password",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dataRecord.Password) = $event)),
                    placeholder: '(' + _ctx.language.password.toLowerCase() + ')',
                    disabled: _ctx.dataRecord.SMSAcctId != 0,
                    maxlength: "50",
                    autocomplete: "off",
                    onFocus: _cache[6] || (_cache[6] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_19), [
                    [_vModelText, _ctx.dataRecord.Password]
                  ]),
                  (_ctx.v$.dataRecord.Password.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.v$.dataRecord.Password.$errors[0].$message), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.PhoneNumber.$error}])
                }, [
                  _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.language.phone_nbr), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "PhoneNumber",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dataRecord.PhoneNumber) = $event)),
                    placeholder: '(' + _ctx.language.phone_nbr.toLowerCase() + ')',
                    disabled: _ctx.dataRecord.SMSAcctId != 0,
                    maxlength: "10",
                    autocomplete: "off",
                    onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onOtherFieldChanged && _ctx.onOtherFieldChanged(...args))),
                    onFocus: _cache[9] || (_cache[9] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_22), [
                    [_vModelText, _ctx.dataRecord.PhoneNumber]
                  ]),
                  (_ctx.v$.dataRecord.PhoneNumber.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_ctx.v$.dataRecord.PhoneNumber.$errors[0].$message), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.language.formated_phone_nbr), 1),
                  _createElementVNode("input", {
                    type: "text",
                    id: "PhoneNumberFormat",
                    class: "form-control",
                    value: _ctx.dataRecord.PhoneNumberFormat,
                    placeholder: '(' + _ctx.language.formated_phone_nbr.toLowerCase() + ')',
                    disabled: true,
                    maxlength: "12",
                    autocomplete: "off"
                  }, null, 8, _hoisted_26)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-secondary me-2",
                  onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args)))
                }, _toDisplayString(_ctx.language.close), 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.saveRecordData && _ctx.saveRecordData(...args))),
                  disabled: _ctx.v$.$invalid || (_ctx.dataRecord.SMSAcctId != 0)
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fa', 'fa-save'] }),
                  _createTextVNode(" " + _toDisplayString(_ctx.language.save), 1)
                ], 8, _hoisted_29)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}