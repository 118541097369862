
    import { useVuelidate } from '@vuelidate/core'
    import { required, helpers } from '@vuelidate/validators'

    import { defineComponent } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { SalesOrderPayment } from '@/models/salestypes';
    import AppDeclarations from '@/tools/declarations';
    import NetServices from '@/tools/netservices';
    import Swal from 'sweetalert2';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    const greaterThanZero = (val: number) => val > 0;
    const fNewDate = new Date(),
        year = fNewDate.getFullYear();

    const emptySalesPayt: SalesOrderPayment = {
      IdOrderPayt: 0,
      OrderKey : '',
      OrderPaytKey: '',
      ReceiptNbr: '',
      AmtPaid: 0,
      CashierEmail: '',
      PaytType: 0,
      PaytDate: new Date(),
      PaytBarcode: '',
      PaytQRCode: '',
  };
    
    export default defineComponent({
        name: 'SalesOrderPaytModal',
        emits: ['closeForm'],
        props: [
            "visible",
            "initialValues", 
            "recordStatus",
            "remainingAmt",
            "populateData"
        ],
        data(){
            return {
                v$: useVuelidate(),
                appFxs,
                language,
                dataRecord: {...emptySalesPayt} as SalesOrderPayment,
                isSavingRecord: false,
                currCode: 'RWF',
                userEmail: '',

                theRemainingAmt: 0,
                thePopulateData: false,
            }
        },
        computed: {
            windowTitle() {
                return this.recordStatus == 0 ? language.add_payment :
                    language.edit_payment;
            },
            isAmtPaidValid(): boolean{
                return this.dataRecord.AmtPaid <= this.theRemainingAmt;
            }
        },
        methods: {
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            generateReceiptNbr(){
                const otime = new Date();
                const oSec = otime.getSeconds();
                const oMins = otime.getMinutes();

                const oRandom = appFxs.getRandomDigits(2);
                const fSecs = oSec.toString().length == 2 ? 
                    oSec.toString() : '0' + oSec.toString();
                const fMins = oMins.toString().length == 2 ? 
                    oMins.toString() : '0' + oMins.toString();

                const oNbr = 'RCPT' + appFxs.generateTextNbr(3) + `${oRandom}${fSecs}${fMins}`;
                this.dataRecord.ReceiptNbr =  oNbr.toUpperCase();
            },
            saveRecordData(){
                appFxs.showConfirm(language.payment, language.payt_confirm_qst)
                    .then(vRes => {
                        if(!vRes.isConfirmed) return;

                        this.saveRecordDataFinal();
                    });
            },
            saveRecordDataFinal(){
                appFxs.showAjaxLoader();
                NetServices.requestPost('salesorders/payts/savedirectsalespayt', {
                    salesPaytOper: this.dataRecord,
                    populateData: this.thePopulateData,
                })
                .then(oRes => {
                    this.isSavingRecord = false;
                    if(!oRes.bReturn){
                        Swal.close();
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                        .then(vRes => {
                            this.$emit('closeForm', {returnType: 1, returnData: null});
                        });
                        return;
                    }

                    Swal.close();
                    if(oRes.typeReturn == 0){
                        this.$emit('closeForm', {returnType: 1, returnData: null});
                    }else{
                        this.$emit('closeForm', {returnType: 1, returnData: {
                            salesOrderInfo: oRes.salesOrderInfo!,
                            lstSalesPayments: oRes.lstSalesPayments!,
                        }});
                    }
                });


                
            },
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: SalesOrderPayment = {...oValues} as SalesOrderPayment;


                        const userEmail = appFxs.getLocalStorageByKey(AppDeclarations.userEmail, '');
                        this.userEmail = userEmail;
                        this.theRemainingAmt = this.remainingAmt;

                        this.thePopulateData = this.populateData as boolean;

                        if(this.recordStatus == 0){
                            this.v$.$reset();
                        }else{
                            this.v$.$reset();
                        }
                        this.dataRecord = fValues;
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {
                    OrderKey: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    ReceiptNbr: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    CashierEmail: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    PaytBarcode: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    PaytQRCode: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    PaytType: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    AmtPaid: {
                        required: helpers.withMessage(language.fldRequired, required),
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),
                        $autoDirty: true,
                    },
                }
            }
        },
        mounted(){
            appFxs.updateLoadedScripts();
        }
    });
