    
  import { useVuelidate } from '@vuelidate/core'
  import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import ListSubCategs from '@/components/products/ListOfSubCategories.vue';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';
  import { Customer } from "@/models/managttypes";

  import Swal from "sweetalert2";

  import { ProdCategory } from '@/models/producttypes';
  const language =GetLanguage(),
      appFxs = GeneralFx();

  const emptyCategory: ProdCategory = {
      ProdCategoryId: 0,
      Code: '',
      NameEn: '',
      NameFr: '',
      NameRw: '',
      Description: '',
  };

  export default defineComponent({
        components: {
            ListSubCategs,
        },
      data() {
          return {
              v$: useVuelidate(),
              language,
              appFxs,
              dataRecord: { ...emptyCategory } as ProdCategory,
              isSavingRecord: false,
              isLoading: true,
              categoryKey: '',
              nbrSubCategories: 0,
          }
      },
      computed: {
          windowTitle(): string {
              return this.isLoading ? `${language.loading}...` : `${this.getCategoryName(this.dataRecord)}`;
          },
          subCategoriesLink(): string{
            return '/app/products/categories/' + this.categoryKey + '/subcategories/list';
          }
      },
      methods: {
          getCategoryName(oCateg: ProdCategory) {
              const oLang = appFxs.getLocalLanguage();
              return oLang == 'en' ? oCateg.NameEn :
                  oLang == 'fr' ? oCateg.NameFr :
                      oLang == 'rw' ? oCateg.NameRw :
                          oCateg.NameEn;
          },
          selectOnFocus(evt: Event) {
              const oInput = evt.target as HTMLInputElement;
              oInput.select();
          },
          saveNewCateg() {
              this.isSavingRecord = true;
              const oRecord = appFxs.getProxyData(this.dataRecord) as ProdCategory;
              const fRecord = { ...oRecord, Code: oRecord.Code.toUpperCase()  };

              NetServices.requestPost('products/categs/saveprodcategory', {
                prodCategoryRecord: fRecord,
                  toDelete: false,
              }).then(oRes => {
                  if (!oRes.bReturn) {
                      appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                      this.isSavingRecord = false;
                      return;
                  }

                  const fList: ProdCategory[] = [];
                  oRes.listOfProdCategories!.forEach(oEmail => {
                      const fEmail = { ...oEmail }
                      fList.push(fEmail);
                  });

                  const oPath = `/app/products/categories/list`;
                  router.replace({ path: oPath, replace: true });
              });
          }
      },
      mounted() {
          document.title = language.loading + '... - ' + this.language.appName;
          const categoryKeyVal = this.$route.params.categoryKey as string;
          if (!categoryKeyVal) {
              appFxs.showAlert(language.missing_settings, language.missing_settings_msg)
                  .then(oRes => {
                      router.replace({ path: '/app/products/categories/list', replace: true })
                  });
              return;
          }

          this.categoryKey = categoryKeyVal;

          NetServices.requestGet(`products/categs/getoneprodcateg/${categoryKeyVal}`)
              .then(oRes => {
                  if (!oRes.bReturn) {
                      appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                          .then(vRes => {
                              router.replace({ path: '/app/products/categories/list', replace: true });
                          });
                      return;
                  }

                  this.nbrSubCategories = oRes.nbrOfSubCategories!;
                  const tempCateg = oRes.oneProductCateg!;
                  const oFinalCust = { ...tempCateg };

                  this.dataRecord = oFinalCust;
                  this.isLoading = false;
                  document.title = this.windowTitle + ' - ' + this.language.appName;
              });
      },
      validations() {
          return {
              dataRecord: {
                  Code: {
                      required: helpers.withMessage(language.fldRequired, required),
                      $autoDirty: true,
                  },
                  NameEn: {
                      required: helpers.withMessage(language.fldRequired, required),
                      $autoDirty: true,
                  },
                  NameFr: {
                      required: helpers.withMessage(language.fldRequired, required),
                      $autoDirty: true,
                  },
                  NameRw: {
                      required: helpers.withMessage(language.fldRequired, required),
                      $autoDirty: true,
                  },
              }
          }
      }
  })
