
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent, toRaw } from 'vue';
    import InputDataArea from '@/components/inputs/InputDataArea.vue';
    import { EmailSenderSett, ManagtUser } from '@/models/managttypes';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';

    import NetServices from '@/tools/netservices';
    import { DateOperation, TelCode } from '@/tools/smarttypes';
    import appDeclarations from '@/tools/declarations';

    const language =GetLanguage(),
        appFxs = GeneralFx(),
        emptyCardVal = {
            NameOnCard: '',
            CardNbr: '',
            CardExpirMonth: '1',
            CardExpirYear: (new Date).getFullYear().toString(),
            SecCode: '',
        };

export default defineComponent({
    name: 'UserSettModal',
    emits: ['closeForm'],
    props: ["visible", "initialValues", "recordStatus"],
    data(){
        return {
            v$: useVuelidate(),
            language,
            windowTitle: language.cred_card_info,
            dataRecord: emptyCardVal,
            vYears: appFxs.getYearFromNow(10),
            vMonths: appFxs.getMonthsDates(),
        }
    },
    methods: {       
        closeFormRet(){
            this.$emit('closeForm', {returnType: 0, returnData: null});
        },
        selectOnFocus(evt: Event){
            const oInput = evt.target as HTMLInputElement;
            oInput.select();
        },
        saveRecordData(){
            appFxs.showAlert(language.credit_card_error, language.credit_card_error_msg);
        }

    },
    mounted(){

    },
    validations(){
        return {
            dataRecord: {
                NameOnCard: {
                    required: helpers.withMessage(language.fldRequired, required),                  
                    $autoDirty: true,
                },
                CardNbr: {
                    required: helpers.withMessage(language.fldRequired, required),                  
                    $autoDirty: true,
                },
                SecCode: {
                    required: helpers.withMessage(language.fldRequired, required),                  
                    $autoDirty: true,
                },
            }
        }
    }
})
