
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { ProdProduct, PurchaseOperation } from '@/models/producttypes';
    import { Customer, Supplier } from '@/models/managttypes';
    import AppDeclarations from '@/tools/declarations';
    import NetServices from '@/tools/netservices';
    import { DiscountRec } from '@/models/salestypes';


    const language =GetLanguage(),
        appFxs = GeneralFx();
    const newDate = new Date(),
        year = newDate.getFullYear();
    const greaterThanZero = (val: number) => val > 0;

    const emptyDiscountRect: DiscountRec = {
        IdDiscount: 0,
        DiscountCode: '',
        DiscountName: '',
        StartDate: newDate,
        HasEndDate: false,
        EndDate: newDate,
        ApplytoAllClients: true,
        Clients: '',
        ApplytoAllProducts: true,
        Products: '',
        HasRules: false,
        DiscValue: 0,
        IsStillActive: true,
        CreatedDate: newDate,
    };
    export default defineComponent({        
        name: 'DiscRecModal',
        emits: ['closeForm'],
        props: ["visible", "initialValues", "recordStatus", "listOfProducts", "listOfCustomers"],
        data(){
            return {
                v$: useVuelidate(),
                appFxs,
                language,
                dataRecord: {...emptyDiscountRect} as DiscountRec,


                products: this.listOfProducts as ProdProduct[],
                customers: this.listOfCustomers as Customer[],
                isSavingRecord: false,

                firstDate: newDate,
                lastDate: newDate,
                selectedYear: year,
            }
        },
        computed: {
            selectedCustomers(): Customer[]{
                const sels = this.dataRecord.Clients;
                const arr = sels.split(';');
                return this.customers.filter(o => arr.includes(o.ClientKey));
            },
            selectedProducts(): ProdProduct[]{
                const sels = this.dataRecord.Products;
                const arr = sels.split(';');
                return this.products.filter(o => arr.includes(o.SKU));
            },
            windowTitle(){
                return this.recordStatus == 0 ? language.new_discount:
                language.edit_discount;
            },
            isEndDateCorrect(): boolean{
                const bHasEndDate = this.dataRecord.HasEndDate;
                if(!bHasEndDate) return true;

                return this.isLessThanfirstDate();
            }
        },
        methods: {
            onApplyClientsChange(evt: Event){
                if(!this.dataRecord.ApplytoAllClients) this.dataRecord.Clients = '';
            },
            deleteClientFromSelected(oElt: Customer){
                const eltClt = appFxs.getProxyData(oElt) as Customer;

                const alreadySel = this.dataRecord.Clients;
                const arr = alreadySel.split(';');
                const selectArr = this.customers.filter(o => arr.includes(o.ClientKey));
                const final = selectArr.filter(o => o.ClientKey != eltClt.ClientKey);
                const fReturn = final.map(o => o.ClientKey).join(';');
                this.dataRecord.Clients = fReturn;
            },
            updateApplyCustomer(evt: Event){
                const oTarget = (evt.target as HTMLSelectElement);
                if(oTarget.value == 'GLOBALCUST'){
                    oTarget.value = "";
                    return;
                }

                const alreadySel = this.dataRecord.Clients;
                const arr = alreadySel.split(';')
                if(!arr.includes(oTarget.value)){
                    arr.push(oTarget.value);
                    const final = arr.join(';');
                    this.dataRecord.Clients = final;
                }

                oTarget.value = "";
            },
            onApplyProdsChange(evt: Event){
                if(!this.dataRecord.ApplytoAllProducts) this.dataRecord.Products = '';
            },
            deleteProductFromSelected(oElt: ProdProduct){
                const eltClt = appFxs.getProxyData(oElt) as ProdProduct;

                const alreadySel = this.dataRecord.Products;
                const arr = alreadySel.split(';');
                const selectArr = this.products.filter(o => arr.includes(o.SKU));
                const final = selectArr.filter(o => o.SKU != eltClt.SKU);
                const fReturn = final.map(o => o.SKU).join(';');
                this.dataRecord.Products = fReturn;
            },
            updateApplyProduct(evt: Event){
                const oTarget = (evt.target as HTMLSelectElement);

                const alreadySel = this.dataRecord.Products;
                const arr = alreadySel.split(';')
                if(!arr.includes(oTarget.value)){
                    arr.push(oTarget.value);
                    const final = arr.join(';');
                    this.dataRecord.Products = final;
                }

                oTarget.value = "";
            },



            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.isSavingRecord = true;
                const opsData = {...this.dataRecord, DiscountCode: this.dataRecord.DiscountCode.toUpperCase() } as DiscountRec;
                NetServices.requestPost('salesorders/discountrecs/savediscountrec', {
                    discountRecModel: opsData,
                    toDelete: false,
                }).then(oRes => {
                    if (!oRes.bReturn) {
                        this.isSavingRecord = false;
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }
                    this.isSavingRecord = false;
                    this.$emit('closeForm', {returnType: 1, returnData: oRes.listOfDiscountWithRules!});
                });
            },
            isBetweenTwoDates(val: Date): boolean{
                return appFxs.isBetweenTwoDates(val, this.firstDate, this.lastDate);
            },
            isLessThanfirstDate(): boolean{
                const fDate = this.dataRecord.StartDate.getTime();
                const lDate = this.dataRecord.EndDate.getTime();
                
                return lDate > fDate;
            }
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: DiscountRec = {
                            ...oValues, 
                            StartDate: new Date(oValues.StartDate),
                            EndDate: new Date(oValues.EndDate),
                            CreatedDate: new Date(oValues.CreatedDate),
                        };

                        const custs = this.listOfCustomers as Customer[];
                        this.customers = custs;
                        const prods = this.listOfProducts as ProdProduct[];
                        this.products = prods;

                        this.selectedYear = Number(appFxs.getLocalStorageByKey(AppDeclarations.currentYearbook, year.toString()));

                        if(this.recordStatus == 0){
                            this.v$.$reset();
                        }

                        this.dataRecord = fValues;                        
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {
                    DiscountCode: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    DiscountName: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    DiscValue: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),                 
                        $autoDirty: true,
                    },
                }
            }
        },
        mounted(){
            appFxs.updateLoadedScripts();
        }
    })
