import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { 
    faUserSecret, 
    faTimesCircle, 
    faSpinner,
    faUser,
    faCog,
    faCogs,
    faBank,
    faEnvelope,
    faUsers,
    faFileInvoice,
    faSms,
    faCube,
    faCalendar,
    faDollar,
    faWrench,
    faLock,
    faRefresh,
    faSave,
    faPercentage,
    faCheck,
    faTrash,
    faEdit,
    faPrint,
    faPlus,
    faPallet,
    faDollyFlatbed,
    faSeedling,
} from '@fortawesome/free-solid-svg-icons';

import JWPager from '@/components/general/base/JWPager.vue';
import JWTagInput from '@/components/general/base/JWTagInput.vue';
import JWTagInputV2 from '@/components/general/base/JWTagInputV2.vue';
import PrinterDialog from '@/components/general/base/PrintingSystemMdal.vue';

import SmsSenderModal from '@/components/modals/SmsSenderWindowModal.vue';
import EmailSenderModal from '@/components/modals/EmailSenderWindowModal.vue';
import NumericFieldInput from '@/components/inputs/NumericFieldInput.vue';

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';


import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import '@/assets/styles/byihuse.styles.css';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import VueBarcode from '@chenfengyuan/vue-barcode';

library.add(
    faUserSecret,
    faTimesCircle,
    faSpinner,
    faUser,
    faCog,
    faCogs,
    faBank,
    faEnvelope,
    faUsers,
    faFileInvoice,
    faSms,
    faCube,
    faCalendar,
    faDollar,
    faWrench,
    faLock,
    faSave,
    faRefresh,
    faPercentage,
    faCheck,
    faTrash,
    faEdit,
    faPrint,
    faPlus,
    faPallet,
    faDollyFlatbed,
    faSeedling,
    );



const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('Datepicker', Datepicker)
app.component('jw-pager', JWPager)
app.component('jw-tag-input', JWTagInput)
app.component('jw-tag-input-v2', JWTagInputV2)
app.component('printer-dialog', PrinterDialog)
app.component('sms-sender-modal', SmsSenderModal)
app.component('email-sender-modal', EmailSenderModal)
app.component('QuillEditor', QuillEditor)
app.component('barcode', VueBarcode)
app.component('numeric-fld-input', NumericFieldInput)
app.use(router)

app.config.globalProperties.filters = {
    formatNumber(value: number){
        return new Intl.NumberFormat("en-US").format(value);
    }
}

app.mount('#app');

declare global {
    interface Window {
        scan?: any;
    }
}