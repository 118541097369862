
    import { defineComponent } from 'vue';
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';

    const language = GetLanguage(),
        appFxs = GeneralFx();

    export default defineComponent({
        name: 'JWTagInputV2',
        props: {
            initialTags:{
                type: Array,
                default: () => [],
            },
            placeholder:{
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            emailActivated: {
                type: Boolean,
                default: false,
                required: false,
            }
        },
        emits: ['afterUpdate'],
        data(){
            return {
                language,
                tags: [] as string[],
                id: Math.random().toString(36).substring(7),
                newTag: '',
                fPlaceHolder: !this.placeholder ? language.new_tag : this.placeholder
            }
        },
        methods: {
            onTagsChanged(){
                this.$emit('afterUpdate', this.tags);
            },
            setNewTags(arr: string[]){
                this.tags = arr;
                this.$emit('afterUpdate', this.tags);
            },
            addTag(tag: string){
                if (!tag) return;
                if (this.tags.includes(tag)) {
                    this.handleDuplicate(tag);
                    return;
                }
                if(this.emailActivated && !appFxs.isEmailValid(tag)){
                    return;
                }
                this.tags.push(tag);
                this.newTag = "";
                this.onTagsChanged();
            },
            removeTag(index: number){
                this.tags.splice(index, 1);
                this.onTagsChanged();
            },
            handleDuplicate(tag: string){
                this.newTag = "";
            },
        },
        mounted(){
            this.tags = this.initialTags as string[];
        },
        watch: {
            initialTags: {
                handler(newV, oldV){
                    const newArr = newV as any[];
                    const oldArr = oldV as any[];

                    if(!appFxs.arrayEquals(newArr, oldArr)){
                        this.setNewTags(this.initialTags as string[]);
                    }
                }
            }
        }
    });
