
    import { defineComponent, Teleport } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { TaxSett } from "@/models/managttypes";

    import TaxSettModal from "@/components/modals/TaxSettModal.vue";
    import Swal from "sweetalert2";

    const language =GetLanguage(),
    appFxs = GeneralFx();
    const emptyTaxSett: TaxSett = {
        TaxSettId: 0,
        TaxKey: '',
        TaxName: '',
        TaxRate: 0,
        IsActive: true
    }

    
    export default defineComponent({
        components: {
            TaxSettModal,
        },
        data(){
            return {
                appFxs,
                language,
                isStillLoading: true,
                listOfTaxes: [] as TaxSett[],
                isDialogOpen: false,
                dialogRecordStatus: 0,
                dialogRecordData: (emptyTaxSett as TaxSett),
                taxSearchKey: '',

                

                isPrintvisible: false,
                printInitialValues: {...appFxs.getReportingData()} as any,
                printExtraData: {}
            }
        },
        computed: {
            filteredTaxes(): TaxSett[] {
                let searchKey = this.taxSearchKey;

                if(searchKey.length === 0) return this.listOfTaxes;
                searchKey = searchKey.toLowerCase();
                
                const filterd = this.listOfTaxes.filter(oDev => {
                    return oDev.TaxName.toLowerCase().includes(searchKey) || 
                        oDev.TaxRate.toString().toLowerCase().includes(searchKey);
                });
                return filterd;
            },

        },
        methods: {
            refreshData(){
                appFxs.showAjaxLoader();

                NetServices.requestGet('managt/settings/getlisttaxsetts')
                    .then(oRes => {
                        if(!oRes.bReturn){
                                appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                                .then(xRes => {

                                    Swal.close();
                                });
                                return;
                            }

                        const fList: TaxSett[] = [];
                        oRes.listOfTaxSettings!.forEach(oEmail => {
                            const fEmail = {...oEmail}
                            fList.push(fEmail);
                        });

                        this.listOfTaxes = fList;
                        
                        Swal.close();
                    });
            },
            openOneTaxSettWind(type: number, data: TaxSett|any){
                if(type===0){
                    const fNew = {...emptyTaxSett};
                    this.dialogRecordData = (fNew as TaxSett);
                    this.dialogRecordStatus = 0;
                }else{
                    this.dialogRecordData = data!;
                    this.dialogRecordStatus = 1;
                }
                
                this.isDialogOpen = true;
            },
            deleteOneTaxSett(data: TaxSett){
                appFxs.showConfirm(language.delete, language.delete_email_actt_qst)
                .then(oResp => {
                    if(!oResp.isConfirmed)return;
                    this.finalDeleteTaxSett(data);
                });
            },
            finalDeleteTaxSett(data: TaxSett){
                appFxs.showAjaxLoader();

                NetServices.requestPost('managt/settings/saveonetaxsetting', {
                    taxSettModel: data,
                    toDelete: true,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: TaxSett[] = [];
                    oRes.listOfTaxSettings!.forEach(oEmail => {
                        const fEmail = {...oEmail}
                        fList.push(fEmail);
                    });

                    this.listOfTaxes = fList;
                    
                    Swal.close();
                });
            },
            onCloseForm(evt: any){
                this.isDialogOpen = false;

                if(evt.returnType===0)return;

                const returnData = appFxs.getProxyData(evt.returnData);
                appFxs.showAjaxLoader();

                NetServices.requestPost('managt/settings/saveonetaxsetting', {
                    taxSettModel: returnData,
                    toDelete: false,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: TaxSett[] = [];
                    oRes.listOfTaxSettings!.forEach(oEmail => {
                        const fEmail = {...oEmail}
                        fList.push(fEmail);
                    });

                    this.listOfTaxes = fList;
                    
                    Swal.close();
                });
            },

            printListItems(){
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = language.list_of_taxes;
                dPrint.ReportToPrint = 2;

                this.printInitialValues = dPrint;


                this.isPrintvisible = true;
            },
            onPrintClose(evt: any){                
                this.isPrintvisible = false;

                if(evt.returnType===0)return;
            }
        },
        mounted(){
            document.title = `${this.language.settings}/${this.language.taxSettings} - ${this.language.appName}`;
            const fNew = {...emptyTaxSett};
            this.dialogRecordData = (fNew as TaxSett);

            NetServices.requestGet('managt/settings/getlisttaxsetts')
                .then(oRes => {
                    if(!oRes.bReturn){
                            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                            .then(xRes => {
                                router.replace({path: '/app/myprofile', replace: true});
                            });
                            return;
                        }

                    const fList: TaxSett[] = [];
                    oRes.listOfTaxSettings!.forEach(oEmail => {
                        const fEmail = {...oEmail}
                        fList.push(fEmail);
                    });

                    this.listOfTaxes = fList;
                    
                    this.isStillLoading = false;
                });
        }
    })
