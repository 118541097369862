
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { ProdProduct, ProdStockExit, ProductVariantDetail } from '@/models/producttypes';
    import NetServices from '@/tools/netservices';
    import appDeclarations from '@/tools/declarations';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    const newDate = new Date();
    const emptyExitRecord: ProdStockExit = {
        IdStockExit: 0,
        ExitKey: '',
        ProductSku: '',
        ProductVariantKey: '',
        ProductType: 0,
        ExitType: 1,
        OrderKey: '',
        UserEmail: '',
        CustomerKey: '',
        Quantity: 0,
        Price: 0,
        ExitDate: new Date(),
        Description: '',
        FiscalYear: newDate.getFullYear(),
        TotalPrice: 0,
    };
    const greaterThanZero = (val: number) => val > 0;

    export default defineComponent({     
        name: 'PurchDetailModal',
        emits: ['closeForm'],
        props: [
            "visible",
            "initialValues", 
            "recordStatus", 
            "listProducts", 
            "listVariants"
        ],
        data(){
            return {
                v$: useVuelidate(),
                appFxs,
                language,
                dataRecord: {...emptyExitRecord} as ProdStockExit,
                isSavingRecord: false,
                currCode: 'RWF',

                products: this.listProducts as ProdProduct[],
                variantDets: this.listVariants as ProductVariantDetail[],
                specificVariants: [] as ProductVariantDetail[],
            }
        },
        computed: {
            windowTitle(){
                return this.recordStatus == 0 ? language.add_exit_oper:
                language.edit_exit_oper;
            },
            isVariantSet(): boolean{
                return this.dataRecord.ProductType == 0 ? true:
                (this.dataRecord.ProductVariantKey.length > 0);
            },
        },
        methods: {
            onProductChange(){
                const prodSku = this.dataRecord.ProductSku;

                const finalAllProds = appFxs.getProxyData(this.listProducts) as ProdProduct[];
                const finalAllVar = appFxs.getProxyData(this.listVariants) as ProductVariantDetail[];

                if(!prodSku){
                    this.dataRecord.ProductType = 0;
                    this.dataRecord.ProductVariantKey = '';
                    this.dataRecord.Quantity = 0;
                    this.dataRecord.Price = 0;
                    return;
                }
                const realProdchecker = finalAllProds.find(o => o.SKU == prodSku);

                if(realProdchecker == null){
                    this.dataRecord.ProductType = 0;
                    this.dataRecord.ProductVariantKey = '';
                    this.dataRecord.Quantity = 0;
                    this.dataRecord.Price = 0;
                    return;
                }

                const specVariants = finalAllVar.filter(o => o.ProductSku == prodSku);                
                const hasVariants = specVariants.length > 0;

                this.dataRecord.ProductVariantKey = '';
                this.specificVariants = specVariants;
                
                this.dataRecord.ProductType = hasVariants ? 1 : 0;

                if(!hasVariants){
                    this.dataRecord.Price = realProdchecker.SellingPrice;
                }else{
                    this.dataRecord.Price = 0;
                }
            },
            onVariantChange(){
                const varSku = this.dataRecord.ProductVariantKey;

                if(!varSku){
                    this.dataRecord.Price = 0;
                    return;
                }
                const foundVars = this.specificVariants.find(o => o.VariantValues == varSku);
                if(!foundVars){
                    this.dataRecord.Price = 0;
                    return;
                }

                this.dataRecord.Price = foundVars!.PurchasePrice;
            },
            changeSousTotalRecs(){
                const oRec = this.dataRecord.Quantity * this.dataRecord.Price;
                this.dataRecord.TotalPrice = oRec;
            },


            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.isSavingRecord = true;
                NetServices.requestPost('stockops/stockexits/savestockexitmanualoper', {
                    stockExitRecord: this.dataRecord,
                    toDelete: false,
                }).then(oRes => {
                    if (!oRes.bReturn) {
                        this.isSavingRecord = false;
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    this.isSavingRecord = false;
                    this.v$.$reset();

                    this.$emit('closeForm', {returnType: 1, returnData: {
                        bReturn: true,
                        listItems: oRes.listOfStockExits!
                    }});
                });
            },
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: ProdStockExit = {...oValues} as ProdStockExit;

                        this.products = this.listProducts as ProdProduct[];
                        this.variantDets = this.listVariants as ProductVariantDetail[];

                        if(this.recordStatus == 0){
                            this.v$.$reset();
                        }else{
                            const isVariant = fValues.ProductType == 1;
                            const specific = isVariant ? this.variantDets.filter(o => o.ProductSku == fValues.ProductSku): [];

                            this.specificVariants = specific;
                        }

                        this.dataRecord = fValues;
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {
                    ProductSku: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    Quantity: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),                 
                        $autoDirty: true,
                    },
                    Price: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),                 
                        $autoDirty: true,
                    },
                    TotalPrice: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),                 
                        $autoDirty: true,
                    },
                    Description: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                }
            }
        },
        mounted(){
            this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
        }
    })
