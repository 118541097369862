import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal fade show",
  style: {"display":"block"}
}
const _hoisted_2 = { class: "modal-dialog wise-modal" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { for: "FirstName" }
const _hoisted_9 = ["placeholder", "disabled"]
const _hoisted_10 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_11 = { for: "LastName" }
const _hoisted_12 = ["placeholder", "disabled"]
const _hoisted_13 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_14 = { class: "col-md-6 form-group" }
const _hoisted_15 = { for: "DisplayName" }
const _hoisted_16 = ["placeholder", "disabled"]
const _hoisted_17 = { for: "Email" }
const _hoisted_18 = ["placeholder", "disabled"]
const _hoisted_19 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_20 = { for: "JobTitle" }
const _hoisted_21 = ["placeholder", "disabled"]
const _hoisted_22 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_23 = { class: "col-md-6 form-group" }
const _hoisted_24 = { for: "MobilePhone" }
const _hoisted_25 = ["placeholder", "disabled"]
const _hoisted_26 = { class: "col-md-6 form-group" }
const _hoisted_27 = { for: "TelephoneImei" }
const _hoisted_28 = ["placeholder"]
const _hoisted_29 = { class: "col-md-6 form-group" }
const _hoisted_30 = { for: "SerialNumber" }
const _hoisted_31 = ["placeholder"]
const _hoisted_32 = { class: "col-md-6 form-group" }
const _hoisted_33 = { for: "DOB" }
const _hoisted_34 = ["value", "placeholder"]
const _hoisted_35 = { class: "col-md-6 form-group" }
const _hoisted_36 = { for: "Sex" }
const _hoisted_37 = ["value"]
const _hoisted_38 = { class: "col-md-6 form-group" }
const _hoisted_39 = { for: "Country" }
const _hoisted_40 = ["value"]
const _hoisted_41 = { class: "col-md-6 form-group" }
const _hoisted_42 = { for: "City" }
const _hoisted_43 = ["placeholder"]
const _hoisted_44 = { class: "col-md-6 form-group" }
const _hoisted_45 = { for: "TwitterAccount" }
const _hoisted_46 = ["placeholder"]
const _hoisted_47 = { class: "col-md-6 form-group" }
const _hoisted_48 = { for: "LinkedInAccount" }
const _hoisted_49 = ["placeholder"]
const _hoisted_50 = { class: "col-md-6 form-group" }
const _hoisted_51 = { for: "FacebookAccount" }
const _hoisted_52 = ["placeholder"]
const _hoisted_53 = { class: "col-md-6 d-flex align-items-center" }
const _hoisted_54 = { class: "form-check" }
const _hoisted_55 = {
  class: "form-check-label",
  for: "UserActive"
}
const _hoisted_56 = { class: "col-md-6 d-flex align-items-center" }
const _hoisted_57 = { class: "form-check" }
const _hoisted_58 = {
  class: "form-check-label",
  for: "ReceiveWiseEmail"
}
const _hoisted_59 = { class: "col-md-6 d-flex align-items-center" }
const _hoisted_60 = { class: "form-check" }
const _hoisted_61 = {
  class: "form-check-label",
  for: "IsAdmin"
}
const _hoisted_62 = { class: "modal-footer" }
const _hoisted_63 = { class: "d-flex justify-content-end" }
const _hoisted_64 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.windowTitle), 1),
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args))),
                type: "button",
                class: "btn-close"
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.FirstName.$error}])
                }, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.language.firstName), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "FirstName",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataRecord.FirstName) = $event)),
                    placeholder: '(' + _ctx.language.firstName.toLowerCase() + ')',
                    maxlength: "50",
                    autocomplete: "off",
                    autofocus: "",
                    disabled: _ctx.dataRecord.UserId != 0,
                    onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_9), [
                    [_vModelText, _ctx.dataRecord.FirstName]
                  ]),
                  (_ctx.v$.dataRecord.FirstName.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.v$.dataRecord.FirstName.$errors[0].$message), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.LastName.$error}])
                }, [
                  _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.language.lastName), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "LastName",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataRecord.LastName) = $event)),
                    placeholder: '(' + _ctx.language.lastName.toLowerCase() + ')',
                    maxlength: "50",
                    autocomplete: "off",
                    disabled: _ctx.dataRecord.UserId != 0,
                    onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_12), [
                    [_vModelText, _ctx.dataRecord.LastName]
                  ]),
                  (_ctx.v$.dataRecord.LastName.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.v$.dataRecord.LastName.$errors[0].$message), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.language.displayName), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "DisplayName",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dataRecord.DisplayName) = $event)),
                    placeholder: '(' + _ctx.language.displayName.toLowerCase() + ')',
                    maxlength: "50",
                    autocomplete: "off",
                    disabled: _ctx.dataRecord.UserId != 0,
                    onFocus: _cache[6] || (_cache[6] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_16), [
                    [_vModelText, _ctx.dataRecord.DisplayName]
                  ])
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.Email.$error}])
                }, [
                  _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.language.email), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "email",
                    id: "Email",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dataRecord.Email) = $event)),
                    placeholder: '(' + _ctx.language.email.toLowerCase() + ')',
                    disabled: _ctx.dataRecord.UserId != 0,
                    maxlength: "50",
                    autocomplete: "off",
                    onFocus: _cache[8] || (_cache[8] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_18), [
                    [_vModelText, _ctx.dataRecord.Email]
                  ]),
                  (_ctx.v$.dataRecord.Email.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.v$.dataRecord.Email.$errors[0].$message), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.JobTitle.$error}])
                }, [
                  _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.language.jobTitle), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "JobTitle",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dataRecord.JobTitle) = $event)),
                    placeholder: '(' + _ctx.language.jobTitle.toLowerCase() + ')',
                    disabled: _ctx.dataRecord.UserId != 0,
                    maxlength: "50",
                    autocomplete: "off",
                    onFocus: _cache[10] || (_cache[10] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_21), [
                    [_vModelText, _ctx.dataRecord.JobTitle]
                  ]),
                  (_ctx.v$.dataRecord.JobTitle.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.v$.dataRecord.JobTitle.$errors[0].$message), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.language.mobilePhone), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "MobilePhone",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.dataRecord.MobilePhone) = $event)),
                    placeholder: '(' + _ctx.language.mobilePhone.toLowerCase() + ')',
                    maxlength: "50",
                    autocomplete: "off",
                    disabled: _ctx.dataRecord.UserId != 0,
                    onkeypress: "return event.charCode >= 48 && event.charCode <= 57",
                    onFocus: _cache[12] || (_cache[12] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_25), [
                    [_vModelText, _ctx.dataRecord.MobilePhone]
                  ])
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.language.imei), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "TelephoneImei",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.dataRecord.TelephoneImei) = $event)),
                    placeholder: '(' + _ctx.language.imei.toLowerCase() + ')',
                    maxlength: "50",
                    autocomplete: "off",
                    onkeypress: "return event.charCode >= 48 && event.charCode <= 57",
                    onFocus: _cache[14] || (_cache[14] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_28), [
                    [_vModelText, _ctx.dataRecord.TelephoneImei]
                  ])
                ]),
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.language.ser_nbr), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "SerialNumber",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.dataRecord.SerialNumber) = $event)),
                    placeholder: '(' + _ctx.language.ser_nbr.toLowerCase() + ')',
                    maxlength: "50",
                    autocomplete: "off",
                    onkeypress: "return event.charCode >= 48 && event.charCode <= 57",
                    onFocus: _cache[16] || (_cache[16] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_31), [
                    [_vModelText, _ctx.dataRecord.SerialNumber]
                  ])
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.language.dateOfBirth), 1),
                  _createElementVNode("input", {
                    type: "date",
                    id: "DOB",
                    class: "form-control",
                    value: _ctx.dateToYYYYMMDD(_ctx.dataRecord.DOB),
                    onInput: _cache[17] || (_cache[17] = ($event: any) => (_ctx.setDobChange($event))),
                    placeholder: '(' + _ctx.language.dateOfBirth.toLowerCase() + ')',
                    autocomplete: "off",
                    onkeypress: "return event.charCode >= 48 && event.charCode <= 57",
                    onFocus: _cache[18] || (_cache[18] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_34)
                ]),
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.language.sex), 1),
                  _withDirectives(_createElementVNode("select", {
                    id: "Sex",
                    name: "Sex",
                    class: "form-control-sel",
                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.dataRecord.Sex) = $event))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sexes, (sex) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: sex.id,
                        value: sex.id
                      }, _toDisplayString(sex.label), 9, _hoisted_37))
                    }), 128))
                  ], 512), [
                    [_vModelSelect, _ctx.dataRecord.Sex]
                  ])
                ]),
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("label", _hoisted_39, _toDisplayString(_ctx.language.country), 1),
                  _withDirectives(_createElementVNode("select", {
                    id: "Country",
                    name: "Country",
                    class: "form-control-sel",
                    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.dataRecord.Country) = $event))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (ctry) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: ctry.code,
                        value: ctry.code
                      }, _toDisplayString(ctry.name), 9, _hoisted_40))
                    }), 128))
                  ], 512), [
                    [_vModelSelect, _ctx.dataRecord.Country]
                  ])
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _createElementVNode("label", _hoisted_42, _toDisplayString(_ctx.language.city), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "City",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.dataRecord.City) = $event)),
                    placeholder: '(' + _ctx.language.city.toLowerCase() + ')',
                    maxlength: "50",
                    autocomplete: "off",
                    onFocus: _cache[22] || (_cache[22] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_43), [
                    [_vModelText, _ctx.dataRecord.City]
                  ])
                ]),
                _createElementVNode("div", _hoisted_44, [
                  _createElementVNode("label", _hoisted_45, _toDisplayString(_ctx.language.twitter), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "TwitterAccount",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.dataRecord.TwitterAccount) = $event)),
                    placeholder: '(' + _ctx.language.twitter.toLowerCase() + ')',
                    maxlength: "50",
                    autocomplete: "off",
                    onFocus: _cache[24] || (_cache[24] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_46), [
                    [_vModelText, _ctx.dataRecord.TwitterAccount]
                  ])
                ]),
                _createElementVNode("div", _hoisted_47, [
                  _createElementVNode("label", _hoisted_48, _toDisplayString(_ctx.language.linkedin), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "LinkedInAccount",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.dataRecord.LinkedInAccount) = $event)),
                    placeholder: '(' + _ctx.language.linkedin.toLowerCase() + ')',
                    maxlength: "50",
                    autocomplete: "off",
                    onFocus: _cache[26] || (_cache[26] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_49), [
                    [_vModelText, _ctx.dataRecord.LinkedInAccount]
                  ])
                ]),
                _createElementVNode("div", _hoisted_50, [
                  _createElementVNode("label", _hoisted_51, _toDisplayString(_ctx.language.facebook), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "FacebookAccount",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.dataRecord.FacebookAccount) = $event)),
                    placeholder: '(' + _ctx.language.facebook.toLowerCase() + ')',
                    maxlength: "50",
                    autocomplete: "off",
                    onFocus: _cache[28] || (_cache[28] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_52), [
                    [_vModelText, _ctx.dataRecord.FacebookAccount]
                  ])
                ]),
                _createElementVNode("div", _hoisted_53, [
                  _createElementVNode("div", _hoisted_54, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.dataRecord.UserActive) = $event)),
                      id: "UserActive"
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.dataRecord.UserActive]
                    ]),
                    _createElementVNode("label", _hoisted_55, _toDisplayString(_ctx.language.is_active), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_56, [
                  _createElementVNode("div", _hoisted_57, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.dataRecord.ReceiveWiseEmail) = $event)),
                      id: "ReceiveWiseEmail"
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.dataRecord.ReceiveWiseEmail]
                    ]),
                    _createElementVNode("label", _hoisted_58, _toDisplayString(_ctx.language.rec_mark_email), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_59, [
                  _createElementVNode("div", _hoisted_60, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.dataRecord.IsAdmin) = $event)),
                      id: "IsAdmin"
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.dataRecord.IsAdmin]
                    ]),
                    _createElementVNode("label", _hoisted_61, _toDisplayString(_ctx.language.is_admin), 1)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_62, [
              _createElementVNode("div", _hoisted_63, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-secondary me-2",
                  onClick: _cache[32] || (_cache[32] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args)))
                }, _toDisplayString(_ctx.language.close), 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[33] || (_cache[33] = 
//@ts-ignore
(...args) => (_ctx.saveRecordData && _ctx.saveRecordData(...args))),
                  disabled: _ctx.v$.$invalid
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fa', 'fa-save'] }),
                  _createTextVNode(" " + _toDisplayString(_ctx.language.save), 1)
                ], 8, _hoisted_64)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}