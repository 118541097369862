
    import { defineComponent } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';
    import GetLanguage from '@/tools/language';
    
  
    import AccountImageArea from '@/components/account/AccountImageArea.vue';
    import ResetWaitForm from '@/components/account/ResetWaitForm.vue';
  
    export default defineComponent({
      components: {
        AccountImageArea,
        ResetWaitForm,
      },
      data(){
        return {
          language: GetLanguage(),
        }
      },
      mounted() {
        document.title = this.language.passwordReqEmailSent + ' - ' + this.language.appName;
      }
    })
  