
    import { defineComponent } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';  

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';

    import AccountImageArea from '@/components/account/AccountImageArea.vue';
    import JoinCompanyForm from '@/components/account/JoinCompanyForm.vue';
    import FullPageLoading from '@/components/general/FullPageLoading.vue';

    import NetServices from '@/tools/netservices';
import { JoinRecord } from "@/tools/smarttypes";

    const language =GetLanguage(),
    appFxs = GeneralFx();

    export default defineComponent({
        components: {
            AccountImageArea,
            JoinCompanyForm,
            FullPageLoading,
        },
        data(){
            return {
                language,
                isFormInvalid: true,
                isNetLoading: false,
                isServerError: false,
                errorMessage: '',
                isLoadStarted: true,
                joinCompanyKey: '',
                emailToJoinWith: ''
            }
        },
        methods: {
            closeErrorPanel(){
                this.isServerError = false;
            },
            sendFormToServer(oData: any){
                this.isNetLoading = true;
                this.isServerError = false;

                const joinRecord:JoinRecord = {
                    JoinKey: window.btoa(this.joinCompanyKey),
                    Password: window.btoa(oData.password),
                    ConfirmPassword: window.btoa(oData.confPassword),
                };

                NetServices.requestPost('managt/account/joincompany/saveoperation', {
                    joinRecordData: joinRecord,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        this.isServerError = true;
                        this.errorMessage = oRes.msgBody;
                        this.isNetLoading = false;
                        return;
                    }

                    router.replace({path: '/account/login', replace: true})
                }); 
            }
        },
        mounted() {
            document.title = this.language.joinByihuseCompany + ' - ' + this.language.appName;
            this.isLoadStarted = true;

            const joinCompanyKey = this.$route.params.joinCompanyKey as string;
            if(!joinCompanyKey){
                appFxs.showAlert(language.missing_settings, language.missing_settings_msg)
                    .then(oRes => {
                        router.replace({path: '/account/login', replace: true})
                    });
                return;
            }

            this.joinCompanyKey = joinCompanyKey;

            const encodedJoinKey = window.btoa(joinCompanyKey);

            NetServices.requestGet(`managt/account/joincompany/checkjoinops/${encodedJoinKey}`)
                .then(oRes => {
                    if(!oRes.bReturn){
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                            .then(vRes => {
                                router.replace({path: '/account/login', replace: true})
                            });
                        return;
                    }

                    this.emailToJoinWith = oRes.joiningEmail!;
                    this.isLoadStarted = false;
                });
        },
    })
