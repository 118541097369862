
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { SalesQuote } from '@/models/salestypes';
    import { Customer, ManagtUser } from '@/models/managttypes';
    import AppDeclarations from '@/tools/declarations';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    const greaterThanZero = (val: number) => val > 0;
    const fNewDate = new Date(),
        year = fNewDate.getFullYear()

    const emptySalesOrder: SalesQuote = {
        IdQuote: 0,
        QuoteKey: '',
        QuoteNbr: '',
        ClientKey: '',
        OperDate: fNewDate,
        UserEmail: '',
        QuoteStatus: 0,
        DeviseISO: '',
        OfficeCode: '',
        QuoteNote: '',
        QuoteBarcode: '',
        QuoteQRCode: '',
        QuoteCreatedBy: '',
        FiscalYear: 0,
        TempoKey: '',
        GeneratedOrderKey: '',
    }


    export default defineComponent({        
        name: 'SalesQuoteModal',
        emits: ['closeForm'],
        props: [
            "visible",
            "initialValues", 
            "recordStatus",
            "listOfUsers",
            "listOfCustomers",
            "selectedMonth"
        ],
        data(){
            return  {
                v$: useVuelidate(),
                appFxs,
                language,
                dataRecord: {...emptySalesOrder} as SalesQuote,
                isSavingRecord: false,
                currCode: 'RWF',

                theListStaff: [] as ManagtUser[],
                theCustomers: [] as Customer[],

                firstDate: fNewDate,
                lastDate: fNewDate,
                selectedMonthData: this.selectedMonth as number,
                selectedYear: year,

                userEmail: '',
            }
        },
        computed: {
            windowTitle() {
                return this.recordStatus == 0 ? language.add_sales_quote :
                    language.edit_sales_quote;
            },
            
            isDateCorrect(): boolean{
                return this.isBetweenTwoDates(this.dataRecord.OperDate);
            }
        },
        methods: {
            isBetweenTwoDates(val: Date): boolean{
                return appFxs.isBetweenTwoDates(val, this.firstDate, this.lastDate);
            },
            generateOrderNbr(){                
                this.dataRecord.QuoteNbr =  appFxs.generatedSalesQuoteNbr();
            },
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.$emit('closeForm', {returnType: 1, returnData: this.dataRecord});
            },
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: SalesQuote = {...oValues, OperDate: new Date(oValues.OperDate)} as SalesQuote;

                        this.selectedMonthData = this.selectedMonth as number

                        this.selectedYear = Number(appFxs.getLocalStorageByKey(AppDeclarations.currentYearbook, year.toString()));
                        const userEmail = appFxs.getLocalStorageByKey(AppDeclarations.userEmail, '');
                        this.userEmail = userEmail;

                        this.firstDate = appFxs.getFirstDate(this.selectedYear, this.selectedMonthData);
                        this.lastDate = appFxs.getLastDate(this.selectedYear, this.selectedMonthData);

                        this.theCustomers = appFxs.getProxyData(this.listOfCustomers) as Customer[];
                        this.theListStaff = appFxs.getProxyData(this.listOfUsers) as ManagtUser[];
                        this.v$.$reset();
                        this.dataRecord = fValues;
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {
                    QuoteNbr: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    ClientKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    UserEmail: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    QuoteBarcode: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    QuoteQRCode: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    QuoteCreatedBy: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    TempoKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                }
            }
        }
    })
