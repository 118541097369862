
  import { defineComponent } from "vue";
  import {RouterLink } from 'vue-router';
  import router from '@/router/index';  

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';

  import AccountImageArea from '@/components/account/AccountImageArea.vue';
  import ResetForm from '@/components/account/ResetForm.vue';
  import FullPageLoading from '@/components/general/FullPageLoading.vue';

  import NetServices from '@/tools/netservices';

  const language =GetLanguage(),
    appFxs = GeneralFx();

export default defineComponent({
    components: {
      AccountImageArea,
      ResetForm,
      FullPageLoading,
    },
    data(){
      return {
        language,
        isFormInvalid: true,
        isNetLoading: false,
        isServerError: false,
        errorMessage: '',
        isLoadStarted: true,
        resetKey: this.$route.params.resetKey as string,
      }
    },
    mounted() {
      document.title = this.language.resetPassword + ' - ' + this.language.appName;
      NetServices.requestPost('managt/account/checkpasswordrequestkey', {
        passwordResetKey: window.btoa(this.resetKey),
      }).then(oRes => {
        if(oRes.bReturn){
          this.isLoadStarted = false;
        }else{
          router.replace({path: '/account/login', replace: true});
        }
      });
    },
    methods: {
      closeErrorPanel(){
        this.isServerError = false;
      },
      sendFormToServer(oData: any){
        this.isNetLoading = true;
        this.isServerError = false;

        NetServices.requestPost('managt/account/finalresetpassword', {
            passwordResetKey: window.btoa(this.resetKey),
            password: window.btoa(oData.password),
            confPassword: window.btoa(oData.confPassword),
        }).then(oRes => {
          if(!oRes.bReturn){
            this.isServerError = true;
            this.errorMessage = oRes.msgBody;
            this.isNetLoading = false;
            return;
          }

          router.replace({path: '/account/login', replace: true})
        }); 
      }
    }
})
