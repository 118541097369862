import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "d-none d-md-block col-md-3" }
const _hoisted_3 = { class: "list-group" }
const _hoisted_4 = { class: "col-sm-12 col-md-9" }
const _hoisted_5 = { class: "d-sm-flex d-md-none justify-content-end align-items-center d-block" }
const _hoisted_6 = { "aria-label": "breadcrumb" }
const _hoisted_7 = { class: "breadcrumb" }
const _hoisted_8 = { class: "breadcrumb-item" }
const _hoisted_9 = { class: "breadcrumb-item" }
const _hoisted_10 = { class: "breadcrumb-item" }
const _hoisted_11 = { class: "breadcrumb-item" }
const _hoisted_12 = { class: "breadcrumb-item" }
const _hoisted_13 = { class: "breadcrumb-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_RouterLink, {
          to: "/app/products/brands",
          class: "list-group-item list-group-item-action"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.language.brands), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_RouterLink, {
          to: "/app/products/categories",
          class: "list-group-item list-group-item-action"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.language.categories), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_RouterLink, {
          to: "/app/products/products",
          class: "list-group-item list-group-item-action"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.language.products), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_RouterLink, {
          to: "/app/products/purchases",
          class: "list-group-item list-group-item-action"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.language.purchases), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_RouterLink, {
          to: "/app/products/stockstatus",
          class: "list-group-item list-group-item-action"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.language.stock_status), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_RouterLink, {
          to: "/app/products/stockexits",
          class: "list-group-item list-group-item-action"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.language.stock_exit), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("nav", _hoisted_6, [
          _createElementVNode("ol", _hoisted_7, [
            _createElementVNode("li", _hoisted_8, [
              _createVNode(_component_RouterLink, { to: "/app/products/brands" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.language.brands), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_9, [
              _createVNode(_component_RouterLink, { to: "/app/products/categories" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.language.categories), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_10, [
              _createVNode(_component_RouterLink, { to: "/app/products/products" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.language.products), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_11, [
              _createVNode(_component_RouterLink, { to: "/app/products/purchases" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.language.purchases), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_12, [
              _createVNode(_component_RouterLink, { to: "/app/products/stockstatus" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.language.stock_status), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_13, [
              _createVNode(_component_RouterLink, { to: "/app/products/stockexits" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.language.stock_exit), 1)
                ]),
                _: 1
              })
            ])
          ])
        ])
      ]),
      _createVNode(_component_router_view)
    ])
  ]))
}