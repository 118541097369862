
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { Customer } from "@/models/managttypes";

    import Swal from "sweetalert2";

    import { TelCode } from '@/tools/smarttypes';
    import { ProdCategory } from '@/models/producttypes';

    const language =GetLanguage(),
    appFxs = GeneralFx();

    const emptyCategory: ProdCategory = {
        ProdCategoryId: 0,
        Code: '',
        NameEn: '',
        NameFr: '',
        NameRw: '',
        Description: '',
    };
    export default defineComponent({
        data() {
            return {
                v$: useVuelidate(),
                language,
                appFxs,
                dataRecord: { ...emptyCategory } as ProdCategory,
                isSavingRecord: false,
            }
        },
        methods: {
            selectOnFocus(evt: Event) {
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveNewCategory() {
                this.isSavingRecord = true;
                const initRec = appFxs.getProxyData(this.dataRecord) as ProdCategory;
                const fRecord = { ...initRec, Code: initRec.Code.toUpperCase()  };
                
                NetServices.requestPost('products/categs/saveprodcategory', {
                    prodCategoryRecord: fRecord,
                    toDelete: false,
                }).then(oRes => {
                    if (!oRes.bReturn) {
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        this.isSavingRecord = false;
                        return;
                    }

                    const fList: ProdCategory[] = [];
                    oRes.listOfProdCategories!.forEach(oEmail => {
                        const fEmail = { ...oEmail }
                        fList.push(fEmail);
                    });

                    const oPath = `/app/products/categories/list`;
                    router.replace({ path: oPath, replace: true });
                });
            }
        },
        mounted() {
            document.title = language.new_categ + ' - ' + this.language.appName;
            appFxs.updateLoadedScripts();
        },
        validations() {
            return {
                dataRecord: {
                    Code: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    NameEn: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    NameFr: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    NameRw: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                }
            }
        }
    })
