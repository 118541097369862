import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "modal-dialog wise-modal" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "modal-footer" }
const _hoisted_7 = { class: "d-flex justify-content-end" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeliveryInput = _resolveComponent("DeliveryInput")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'d-none': !_ctx.visible}, "modal fade show"]),
    style: {"display":"block"}
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.windowTitle), 1),
          (!_ctx.isSavingRecord)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args))),
                type: "button",
                class: "btn-close"
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.theListOfdetails, (oRec) => {
            return (_openBlock(), _createBlock(_component_DeliveryInput, {
              key: oRec.OrderDetailKey,
              "remaining-data-record": oRec,
              onOnUpdate: _ctx.onEltUpdate
            }, null, 8, ["remaining-data-record", "onOnUpdate"]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary me-2",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args))),
              disabled: _ctx.isSavingRecord
            }, _toDisplayString(_ctx.language.close), 9, _hoisted_8),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.saveRecordData && _ctx.saveRecordData(...args))),
              disabled: _ctx.isSavingRecord || !_ctx.isAnySelected
            }, [
              (_ctx.isSavingRecord)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    icon: ['fa', 'fa-spinner']
                  }))
                : _createCommentVNode("", true),
              (!_ctx.isSavingRecord)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 1,
                    icon: ['fa', 'fa-save']
                  }))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.language.save), 1)
            ], 8, _hoisted_9)
          ])
        ])
      ])
    ])
  ], 2))
}