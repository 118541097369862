import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, vModelRadio as _vModelRadio, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal fade show",
  style: {"display":"block"}
}
const _hoisted_2 = { class: "modal-dialog wise-modal" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = {
  class: "p-4",
  name: "frmInvoice"
}
const _hoisted_8 = { class: "d-flex mb-3" }
const _hoisted_9 = { class: "dlg-strong-title" }
const _hoisted_10 = { class: "flex-fill" }
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "d-flex mb-3" }
const _hoisted_13 = { class: "dlg-strong-title" }
const _hoisted_14 = { class: "flex-fill" }
const _hoisted_15 = { class: "d-flex mb-3" }
const _hoisted_16 = { class: "dlg-strong-title" }
const _hoisted_17 = { class: "flex-fill pt-2" }
const _hoisted_18 = { class: "form-check" }
const _hoisted_19 = {
  class: "form-check-label",
  for: "SendReminder0"
}
const _hoisted_20 = { class: "form-check" }
const _hoisted_21 = {
  class: "form-check-label",
  for: "SendReminder1"
}
const _hoisted_22 = { class: "modal-footer" }
const _hoisted_23 = { class: "d-flex justify-content-end" }
const _hoisted_24 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.windowTitle), 1),
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args))),
                type: "button",
                class: "btn-close"
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("form", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("strong", _hoisted_9, _toDisplayString(_ctx.language.currency), 1),
                  _createElementVNode("div", _hoisted_10, [
                    _withDirectives(_createElementVNode("select", {
                      class: "input-select-data",
                      "ng-required": "true",
                      id: "Currency",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataRecord.Currency) = $event))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currencies, (oCurr) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: oCurr.code,
                          value: oCurr.code
                        }, _toDisplayString(oCurr.name), 9, _hoisted_11))
                      }), 128))
                    ], 512), [
                      [_vModelSelect, _ctx.dataRecord.Currency]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("strong", _hoisted_13, _toDisplayString(_ctx.language.invoice_footer), 1),
                  _createElementVNode("div", _hoisted_14, [
                    _withDirectives(_createElementVNode("textarea", {
                      id: "InvoiceFooter",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dataRecord.InvoiceFooter) = $event)),
                      name: "InvoiceFooter",
                      maxlength: "500",
                      class: "input-text-data selectallfocus",
                      rows: "3",
                      style: {"height":"150px","margin-top":"0px","margin-bottom":"-10px"}
                    }, null, 512), [
                      [_vModelText, _ctx.dataRecord.InvoiceFooter]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("strong", _hoisted_16, _toDisplayString(_ctx.language.reminders), 1),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "flexRadioDefault",
                        id: "SendReminder0",
                        value: "0",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataRecord.SendReminder) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.dataRecord.SendReminder]
                      ]),
                      _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.language.send_inv_reminder), 1)
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "radio",
                        name: "flexRadioDefault",
                        id: "SendReminder1",
                        value: "1",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dataRecord.SendReminder) = $event))
                      }, null, 512), [
                        [_vModelRadio, _ctx.dataRecord.SendReminder]
                      ]),
                      _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.language.dt_send_inv_reminder), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-secondary me-2",
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args)))
                }, _toDisplayString(_ctx.language.close), 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.saveRecordData && _ctx.saveRecordData(...args))),
                  disabled: _ctx.v$.$invalid
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fa', 'fa-save'] }),
                  _createTextVNode(" " + _toDisplayString(_ctx.language.save), 1)
                ], 8, _hoisted_24)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}