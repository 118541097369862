
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'
  
    import { defineComponent, Teleport } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';
  
    import NetServices from '@/tools/netservices';
  
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { Supplier } from "@/models/managttypes";
  
    import Swal from "sweetalert2";
    
    import { TelCode } from '@/tools/smarttypes';
  
    const language =GetLanguage(),
    appFxs = GeneralFx();
    const emptySupplier:Supplier = {
        IdSupplier: 0,
        SupplierKey: '',
        SupplierOrigin: 1,
      SupplierType: 0,
      SupplierName: '',
      Phone: '',
      Email: '',
      AdressLine1: '',
      AdressLine2: '',
      City: '',
      Country: 'RW',
      Region: '',
      AcceptMarketing: true,
      TaxExempted: false,
      Note: '',
      Tags: '',
      DateCreated: new Date(),
      SupplierLang: 0,
      Sex: 0,
    };
    export default defineComponent({
      data(){
        return {
          v$: useVuelidate(),
          language,
          appFxs,
          dataRecord: {...emptySupplier} as Supplier,
          countries: [] as TelCode[],
          languages: appFxs.listOfLangs(),
          sexes: appFxs.listOfSexes(),
          clientTypes: appFxs.clientTypes(),
          isSavingRecord: false,
          tags: [] as string[],
          options: []
        }
      },
      methods: {      
        selectOnFocus(evt: Event) {
          const oInput = evt.target as HTMLInputElement;
          oInput.select();
        },
        updateTags(evt: any){
          const fFinal = evt as string[];
          const ccF = fFinal.length>0 ? fFinal.join(';'): '';
          this.dataRecord.Tags = ccF;
        },
        saveNewCustomer(){
          this.isSavingRecord = true;
          const fRecord = {...appFxs.getProxyData(this.dataRecord)} as Supplier;
  
          NetServices.requestPost('managt/suppliers/saveonesupplier',{
            supplierRecord: fRecord,
            toDelete: false,
          }).then(oRes => {
            if (!oRes.bReturn) {
              appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
              this.isSavingRecord = false;
              return;
            }
  
            const fList: Supplier[] = [];
            oRes.listOfSuppliers!.forEach(oEmail => {
              const fEmail = { ...oEmail, DateCreated: new Date(oEmail.DateCreated) }
              fList.push(fEmail);
            });
  
            const oPath = `/app/suppliers/list`;
            router.replace({path: oPath, replace: true});
          });
        }
      },
      mounted(){
        document.title = language.new_supplier + ' - ' + this.language.appName;
        const oCtries = appFxs.getLocalStorageByKey(appDeclarations.telephonesCodes, '[]');
        const countries = JSON.parse(oCtries);
        this.countries = countries;
      },
      validations(){
        return {
          dataRecord: {
            SupplierName: {
                required: helpers.withMessage(language.fldRequired, required),                 
                $autoDirty: true,
            },
            Phone: {
                required: helpers.withMessage(language.fldRequired, required),
                minLength: helpers.withMessage(language.minLengthReqMsg, minLength(10)),
                maxLength: helpers.withMessage(language.maxLengthReqMsg, maxLength(10)),
                $autoDirty: true,
            },
            Email: {
                required: helpers.withMessage(language.fldRequired, required),
                email: helpers.withMessage(language.emailFormat, email),                         
                $autoDirty: true,
            },
            AdressLine1: {
                required: helpers.withMessage(language.fldRequired, required),                 
                $autoDirty: true,
            },
            City: {
                required: helpers.withMessage(language.fldRequired, required),                 
                $autoDirty: true,
            },
            Region: {
                required: helpers.withMessage(language.fldRequired, required),                 
                $autoDirty: true,
            },
          }
        }
      }
    })
  