
    import { defineComponent } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { Company, EmailSenderSett, ManagtUser } from "@/models/managttypes";
    import { DateOperation, UserRow } from "@/tools/smarttypes";

    import BillingInfoModal from "@/components/modals/BillingInfoModal.vue";
    import Swal from "sweetalert2";

    const language =GetLanguage(),
        appFxs = GeneralFx();
    const emptyCardVal = {
        NameOnCard: '',
        CardNbr: '',
        CardExpirMonth: '',
        CardExpirYear: (new Date).getFullYear().toString(),
        SecCode: '',
    };

    export default defineComponent({
        components: {
            BillingInfoModal
        },
        data(){
            return {
                language,
                connectedUser: null as ManagtUser | any,
                isStillLoading: true,
                isDialogOpen: false,
                dialogRecordData: {...emptyCardVal},
                dialogRecordStatus: 0,
            }
        },
        methods: {
            showBillingPlanInfo(){
                appFxs.showAlert(language.free_account, language.free_account_msg);
            },
            showCreditCardInfo(){
                const fNew = {...emptyCardVal};
                fNew.NameOnCard = `${this.connectedUser.FirstName} ${this.connectedUser.LastName}`;
                

                this.dialogRecordData = fNew;
                this.dialogRecordStatus = 0;
                this.isDialogOpen = true;
            },
            cancelAccount(){
                appFxs.showAlert(language.cred_card_info, language.cred_card_deb_msg);
            },
            onCloseForm(evt: any){
                this.isDialogOpen = false;

                if(evt.returnType===0)return;
            }
        },
        mounted(){
            document.title = `${this.language.settings}/${this.language.billingInfo} - ${this.language.appName}`;

            const oCurrentUser = appFxs.getLocalStorageByKey(appDeclarations.connecteUserRecord, '{}');
            const finalConnectedUser = JSON.parse(oCurrentUser, (k,v)=> {
                if(k==='DOB'){
                    return new Date(v);
                }
                return v;
            });
            this.connectedUser = finalConnectedUser as ManagtUser;
            this.isStillLoading = false;
        }
    })
