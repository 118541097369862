
  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';
  import DataFxs from '@/tools/data_functions';

  import Swal from "sweetalert2";
  import { SalesOrderPayment } from "@/models/salestypes";
  import { SalesOrderInfo } from "@/models/notdb";

  import SalesOrderDetailModal from '@/components/modals/SalesOrderDetailModal.vue';
  import SalesOrderPaytModal from '@/components/modals/SalesOrderPaytModal.vue';


  const language =GetLanguage(),
    appFxs = GeneralFx(),
    dataFxs = DataFxs();
  const fNewDate = new Date(),
    year = fNewDate.getFullYear();

  const emptySalesPayt: SalesOrderPayment = {
    IdOrderPayt: 0,
    OrderKey : '',
    OrderPaytKey: '',
    ReceiptNbr: '',
    AmtPaid: 0,
    CashierEmail: '',
    PaytType: 0,
    PaytDate: new Date(),
    PaytBarcode: '',
    PaytQRCode: '',
  }

  export default defineComponent({
    name: 'SalesPaysByOder',
    components: {
      SalesOrderDetailModal,
      SalesOrderPaytModal,
    },
    data(){
      return {
        appFxs,
        language,        
        isStillLoading: true,
        itemSearchKey: '',
        itemsPerPage: 5,
        selectedYear: year,
        currCode: 'RWF',
        userEmail: '',

        selectedOrderKey: '',

        lstSalesPayments: [] as SalesOrderPayment[],
        itemsPageElts: [] as SalesOrderPayment[],
        salesOrderInfo: null as SalesOrderInfo|any,
        paytTypes: appFxs.getPaytTypes(),

        savedSalesOperKey: '',

        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 8,
          ReportTitle: language.list_of_brands
        } as any,
        printExtraData: {},

        isSalesPaytVis: false,
        salesPaytInitVals: {...emptySalesPayt} as SalesOrderPayment,
        salesPaytRecStatus: 0,
        salesRemAmt: 0,
      }
    },
    computed: {
      filteredRecordItems(): SalesOrderPayment[] {
        let searchKey = this.itemSearchKey;

        if (searchKey.length === 0) return this.lstSalesPayments;
        searchKey = searchKey.toLowerCase();

        const filterd = this.lstSalesPayments.filter(oDev => {
          return oDev.ReceiptNbr.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
      getOrderInfo():{
        nOrder:string;
        cltName: string;
        orderDate: Date;
        orderAmt: number;
        paidAmt: number;
        balanace: number;
      }{

        if(this.salesOrderInfo==null){
          return {
            nOrder: '',
            cltName: '',
            orderDate: new Date(),
            orderAmt: 0,
            paidAmt: 0,
            balanace: 0,
          }
        }

        const fFinal = this.salesOrderInfo as SalesOrderInfo;

        return {
            nOrder: fFinal.SalesOrderRecord.OrderNbr,
            cltName: fFinal.CustomerRecord.ClientName,
            orderDate: fFinal.SalesOrderRecord.OperDate,
            orderAmt: fFinal.TotalAmt,
            paidAmt: fFinal.PaidAmount,
            balanace: fFinal.RemainingToPay,
          }
      }
    },
    methods: {
      deletePaytRecord(oRecord: SalesOrderPayment){
        appFxs.showConfirm(language.delete, language.delete_quest_rec)
          .then(vRes => {
            if(!vRes.isConfirmed)return;
            this.deletePaytRecordFinal(oRecord);
          });
      },
      deletePaytRecordFinal(oRecord: SalesOrderPayment){
        appFxs.showAjaxLoader();

        NetServices.requestPost('salesorders/payts/deletesalesoperrecord',{
          populateData: true,
          salesPaytOperKey: oRecord.OrderPaytKey,
        })
        .then(oRes => {
          if(!oRes.bReturn){
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }

          const finalList: SalesOrderPayment[] = [];
          const fList = oRes.lstSalesPayments!;
          fList.forEach(cList => {
            const final = { ...cList, PaytDate: new Date(cList.PaytDate) } as SalesOrderPayment;
            finalList.push(final);
          });

          this.lstSalesPayments = finalList;
          const fD = oRes.salesOrderInfo!;
          const finalFd = {
            ...fD,
            SalesOrderRecord: { ...fD.SalesOrderRecord, OperDate: new Date(fD.SalesOrderRecord.OperDate) }
          } as SalesOrderInfo;

          this.salesOrderInfo = finalFd;

          Swal.close();
        });
      },
      addPaytRecord(iStatus: number, oRecord: SalesOrderPayment | any){
          const oSalesOrder = this.salesOrderInfo as SalesOrderInfo;

        if(iStatus==0){
          const fValues = {
            ...emptySalesPayt,
            OrderKey: oSalesOrder.SalesOrderRecord.OrderKey,
            ReceiptNbr: ('RCPT' + appFxs.generateTextNbr(7)).toUpperCase(),
            AmtPaid: oSalesOrder.RemainingToPay,
            CashierEmail: this.userEmail,
            PaytType: 0,
            PaytDate: oSalesOrder.SalesOrderRecord.OperDate,
            PaytBarcode: appFxs.getRandomDigits(12),
            PaytQRCode: appFxs.getRandomDigits(15),
          } as SalesOrderPayment;
          this.salesPaytInitVals = fValues;
          this.salesPaytRecStatus = 0;
          this.salesRemAmt = oSalesOrder.RemainingToPay;
        }else{
          const finalVal = {...oRecord} as SalesOrderPayment;
          this.salesPaytInitVals = finalVal;
          this.salesPaytRecStatus = 0;
          this.salesRemAmt = oSalesOrder.RemainingToPay;
        }
        this.isSalesPaytVis = true;
      },
      refreshList(){
        appFxs.showAjaxLoader();
        NetServices.requestGet(`salesorders/payts/getlistpaymentsbyorder/${this.selectedOrderKey}`)
          .then(oRes => {
            if (!oRes.bReturn) {
              Swal.close();
              appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
              return;
            }

            const finalList: SalesOrderPayment[] = [];
            const fList = oRes.lstSalesPayments!;
            fList.forEach(cList => {
              const final = { ...cList, PaytDate: new Date(cList.PaytDate) } as SalesOrderPayment;
              finalList.push(final);
            });

            this.lstSalesPayments = finalList;
            const fD = oRes.salesOrderInfo!;
            const finalFd = {
              ...fD,
              SalesOrderRecord: { ...fD.SalesOrderRecord, OperDate: new Date(fD.SalesOrderRecord.OperDate) }
            } as SalesOrderInfo;

            this.salesOrderInfo = finalFd;

            Swal.close();
          });
      },
      onPageChange(evt: any){
            this.itemsPageElts = appFxs.getProxyData(evt);
      },
      onClosePaytModal(evt: any){
        if (evt.returnType == 0) {
          this.isSalesPaytVis = false;
          return;
        }

        const oData = evt.returnData;
        const salesOrderInfo = oData.salesOrderInfo as SalesOrderInfo;
        const lstSalesPayments = oData.lstSalesPayments as SalesOrderPayment[];

        const finalList: SalesOrderPayment[] = [];
          const fList = lstSalesPayments;
          fList.forEach(cList => {
            const final = {...cList, PaytDate: new Date(cList.PaytDate)} as SalesOrderPayment;
            finalList.push(final);
          });

          this.lstSalesPayments = finalList;
          const fD = salesOrderInfo;
          const finalFd = {
            ...fD,
            SalesOrderRecord: {...fD.SalesOrderRecord, OperDate: new Date(fD.SalesOrderRecord.OperDate)}
          } as SalesOrderInfo;

          this.salesOrderInfo = finalFd;
          this.isSalesPaytVis = false;
      },
      printListItems(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.payts_by_order;
        dPrint.ReportToPrint = 24;

        this.printInitialValues = dPrint;
        this.printExtraData = {
          SalesOrderKey: this.selectedOrderKey
        }

        this.isPrintvisible = true;
      },      
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },
      printReceipt(oPayt: SalesOrderPayment){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = `${language.sales_recipt} - ${oPayt.ReceiptNbr}`;
        dPrint.ReportToPrint = 25;

        this.printInitialValues = dPrint;
        
        this.printExtraData = {
          ReceiptOperKey: oPayt.OrderPaytKey
        }

        this.isPrintvisible = true;
      }
    },
    mounted(){   
      document.title = this.language.list_of_payt_by_order + ' - ' + this.language.appName;
      this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
      this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
      this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
      this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));

      const orderKey = this.$route.params.orderKey;
      if(orderKey == null){
        router.push({
          name: 'appsalessaleslist',
        });
        return;
      }
      const _key = orderKey as string;
      this.selectedOrderKey = _key;
      this.paytTypes = appFxs.getPaytTypes();

      NetServices.requestGet(`salesorders/payts/getlistpaymentsbyorder/${_key}`)
        .then(oRes => {
          if(!oRes.bReturn){
            router.push({
              name: 'appsalespayments',
            });
            return;
          }

          const finalList: SalesOrderPayment[] = [];
          const fList = oRes.lstSalesPayments!;
          fList.forEach(cList => {
            const final = {...cList, PaytDate: new Date(cList.PaytDate)} as SalesOrderPayment;
            finalList.push(final);
          });

          this.lstSalesPayments = finalList;
          const fD = oRes.salesOrderInfo!;
          const finalFd = {
            ...fD,
            SalesOrderRecord: {...fD.SalesOrderRecord, OperDate: new Date(fD.SalesOrderRecord.OperDate)}
          } as SalesOrderInfo;

          this.salesOrderInfo = finalFd;

          this.isStillLoading = false;
        });



      
      

    }
  })
