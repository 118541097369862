
    import { defineComponent } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { AppYearBook } from "@/models/managttypes";
    import Swal from "sweetalert2";

    import YearBookSettingsModal from "@/components/modals/YearBookSettingsModal.vue";


    const language =GetLanguage(),
        appFxs = GeneralFx();
    const emptyYearBook: AppYearBook = {
        YearBookId: 0,
        YearWork: new Date().getFullYear() + 1,
        StartMonth: 1,
        EndMonth: 12,
        MonthsLength: 12,
        Locked: false
    };
    export default defineComponent({
        components: {
            YearBookSettingsModal,
        },
        data(){
            return {
                language,
                isStillLoading: true,
                listOfFinYears: [] as AppYearBook[],

                isDialogOpen: false,
                dialogRecordStatus: 0,
                dialogRecordData: emptyYearBook,
                eltSearchKey: '',

                
                isPrintvisible: false,
                printInitialValues: {...appFxs.getReportingData()} as any,
                printExtraData: {}
            }
        },
        methods: {
            refreshData(){
                appFxs.showAjaxLoader();

                NetServices.requestGet('managt/settings/getliststyearbooks')
                    .then(oRes => {
                        if(!oRes.bReturn){
                                appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                                .then(xRes => {

                                    Swal.close();
                                });
                                return;
                            }

                        const fList: AppYearBook[] = [];
                        oRes.listOfFinancYears!.forEach(oEmail => {
                            const fEmail = {...oEmail}
                            fList.push(fEmail);
                        });

                        this.listOfFinYears = fList;
                        
                        Swal.close();
                    });
            
            },
            openMyYearBookWind(iStatus: number, oData: AppYearBook | any){
                if(iStatus===0){
                    const fNew = {...emptyYearBook};
                    this.dialogRecordData = (fNew as AppYearBook);
                    this.dialogRecordStatus = 0;
                }else{
                    this.dialogRecordData = oData!;
                    this.dialogRecordStatus = 1;
                }
                
                this.isDialogOpen = true;
            },
            deleteOneYearBook(data: AppYearBook){
                appFxs.showConfirm(language.delete, language.delete_fin_year_qst)
                    .then(oResp => {
                        if(!oResp.isConfirmed)return;
                        this.finalDeleteOneYearBook(data);
                    });
            },
            finalDeleteOneYearBook(data: AppYearBook){
                appFxs.showAjaxLoader();
                NetServices.requestPost('managt/settings/saveoneyearbook', {
                    yearSettModel: data,
                    toDelete: true,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: AppYearBook[] = [];
                    oRes.listOfFinancYears!.forEach(oSmsAcct => {
                        const fEmail = {...oSmsAcct}
                        fList.push(fEmail);
                    });

                    this.listOfFinYears = fList;
                    
                    Swal.close();
                });
            },
            onCloseForm(evt: any){
                this.isDialogOpen = false;

                if(evt.returnType===0)return;

                const returnData = appFxs.getProxyData(evt.returnData);
                appFxs.showAjaxLoader();

                NetServices.requestPost('managt/settings/saveoneyearbook', {
                    yearSettModel: returnData,
                    toDelete: false,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: AppYearBook[] = [];
                    oRes.listOfFinancYears!.forEach(oEmail => {
                        const fEmail = {...oEmail}
                        fList.push(fEmail);
                    });

                    this.listOfFinYears = fList;
                    
                    Swal.close();
                });
            },

            

            printListItems(){
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = language.list_of_fin_years;
                dPrint.ReportToPrint = 3;

                this.printInitialValues = dPrint;


                this.isPrintvisible = true;
            },
            onPrintClose(evt: any){                
                this.isPrintvisible = false;
                if(evt.returnType===0)return;
            }
        },
        mounted(){
            document.title = `${this.language.settings}/${this.language.financialYears} - ${this.language.appName}`;

            const fNew = {...emptyYearBook};
            this.dialogRecordData = (fNew as AppYearBook);
        
            NetServices.requestGet('managt/settings/getliststyearbooks')
                .then(oRes => {
                    if(!oRes.bReturn){
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                        .then(xRes => {
                            router.replace({path: '/app/myprofile', replace: true});
                        });
                        return;
                    }

                    const fList: AppYearBook[] = [];
                    oRes.listOfFinancYears!.forEach(oEmail => {
                        const fEmail = {...oEmail}
                        fList.push(fEmail);
                    });

                    this.listOfFinYears = fList;
                    
                    this.isStillLoading = false;
                });
        }
    })
