
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

import { defineComponent, toRaw } from 'vue';
import InputDataArea from '@/components/inputs/InputDataArea.vue';
import { EmailSenderSett, ManagtUser } from '@/models/managttypes';

import GetLanguage from '@/tools/language';
import GeneralFx from '@/tools/general_fx';

import NetServices from '@/tools/netservices';
import { DateOperation, TelCode } from '@/tools/smarttypes';
import appDeclarations from '@/tools/declarations';

const language =GetLanguage(),
    appFxs = GeneralFx();

const emptyUser: ManagtUser = 
    {
        UserId: 0,
        Email: '',
        Password: '77777',
        FirstName: '',
        LastName: '',
        DisplayName: '',
        JobTitle: '',
        UserBio: '',
        MobilePhone: '',
        Sex: 0,
        DOB: appFxs.addingDate(new Date(), DateOperation.Year, -20, true),
        FacebookAccount: '',
        TwitterAccount: '',
        LinkedInAccount: '',
        KeyRequestInvite: '',
        TelephoneImei: '',
        SerialNumber: '',
        PhotoPath: '',
        Country: 'RW',
        City: '',
        UserActive: true,
        WaitForResp: false,
        ReceiveWiseEmail: true,
        IsMainAdmin: false,
        IsAdmin: false,
    };

export default defineComponent({
    name: 'UserSettModal',
    emits: ['closeForm'],
    props: ["visible", "initialValues", "recordStatus"],
    data(){
        return {
            v$: useVuelidate(),
            language,
            dataRecord: emptyUser,
            sexes: [
                { id: '0', label: language.male },
                { id: '1', label: language.female },
            ],
            roles: [
                { id: '0', label: language.administrator },
                { id: '1', label: language.team_leader },
                { id: '1', label: language.team_member },
            ],
            countries: [] as TelCode[],
        }
    },    
    computed: {        
        windowTitle():string {
            return this.dataRecord.UserId === 0 ? language.add_user:
            language.edit_user;
        }
    },
    methods: {        
        closeFormRet(){
            this.$emit('closeForm', {returnType: 0, returnData: null});
        },
        selectOnFocus(evt: Event){
            const oInput = evt.target as HTMLInputElement;
            oInput.select();
        },
        saveRecordData(){
            this.$emit('closeForm', {returnType: 1, returnData: this.dataRecord});
        },
        dateToYYYYMMDD(d: Date) {
            return d && new Date(d.getTime()-(d.getTimezoneOffset()*60*1000)).toISOString().split('T')[0]
        },
        setDobChange(evt: Event){
            this.dataRecord.DOB = (evt.target as HTMLInputElement).valueAsDate!;
        },
    },
    validations(){
        return {
            dataRecord: {
                Email: {
                    required: helpers.withMessage(language.fldRequired, required),                 
                    email: helpers.withMessage(language.emailFormat, email),                         
                    $autoDirty: true,
                },
                FirstName: {
                    required: helpers.withMessage(language.fldRequired, required),                  
                    $autoDirty: true,
                },
                LastName: {
                    required: helpers.withMessage(language.fldRequired, required),                  
                    $autoDirty: true,
                },
                JobTitle: {
                    required: helpers.withMessage(language.fldRequired, required),                  
                    $autoDirty: true,
                },
            }
        };
    },
    watch:{
        visible: {
            handler(newV, oldV){
                if((newV) && newV !== oldV ){
                    const oValues = appFxs.getProxyData(this.initialValues);
                    const fValues: ManagtUser = {...oValues, DOB: new Date(oValues.DOB)};

                    if(this.recordStatus == 0){
                        this.v$.$reset();
                    }

                    this.dataRecord = fValues;
                }
            }
        }
    },
    mounted(){
        const oCtries = appFxs.getLocalStorageByKey(appDeclarations.telephonesCodes, '[]');
        const countries = JSON.parse(oCtries);

        this.countries = countries;
    }
})
