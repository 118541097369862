
  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';

  import Swal from "sweetalert2";
  import { ProdBrand, ProdProduct } from "@/models/producttypes";
  import { Supplier, TaxSett } from "@/models/managttypes";

  import ProdStockBalance from '@/components/smalls/ProdStockBalanceComp.vue';
  import ProdPriceTaxIncluded from '@/components/smalls/ProdTaxIncludedComp.vue';

  const language =GetLanguage(),
  appFxs = GeneralFx();


  export default defineComponent({
    components: {
      'prod-stock-bal': ProdStockBalance,
      'prod-tax-included': ProdPriceTaxIncluded,
    },
    data(){
      return {              
        appFxs,
        language,
        isStillLoading: true,
        productsSearchKey: '',
        listOfProducts: [] as ProdProduct[],
        productsPageElts: [] as ProdProduct[],
        itemsPerPage: 5,
        currentYear: 2000,

        
        listOfSuppliers: [] as Supplier[],          
        listOfTaxSettings: [] as TaxSett[],
        listOfProdBrands: [] as ProdBrand[],

        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 8,
          ReportTitle: language.list_of_products
        } as any,
        printExtraData: {},

        currCode: 'RWF',
      }
    },
    computed: {
      filteredProdBrands(): ProdProduct[] {
        let searchKey = this.productsSearchKey;

        if (searchKey.length === 0) return this.listOfProducts;
        searchKey = searchKey.toLowerCase();

        const filterd = this.listOfProducts.filter(oDev => {
          return oDev.Name.toLowerCase().includes(searchKey) ||
            oDev.SKU.toLowerCase().includes(searchKey) ||
            oDev.BarCode.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
    },
    methods: {
      showAddBrandAction(iStatus: number, oRecord: ProdProduct | any){
        if(iStatus==0){
            router.replace({path: '/app/products/products/new', replace: true});
          }else{
            const fSupplier = oRecord as ProdProduct;
            const oPath = `/app/products/products/editor/${fSupplier.SKU}`;
            router.replace({path: oPath, replace: true});
          }
      },
      onPageChange(evt: any){
            this.productsPageElts = appFxs.getProxyData(evt);
      },
      deleteProdBrandRecord(data: ProdProduct){
        appFxs.showConfirm(language.delete, language.delete_quest_rec)
          .then(oResp => {
            if (!oResp.isConfirmed) return;
            this.finalDeleteBrand(data);
          });
      },
      finalDeleteBrand(data: ProdProduct){
        appFxs.showAjaxLoader();

        NetServices.requestPost('products/products/saveproduct', {
          prodProductRecord: data,
          toDelete: true,
          populateData: true,
        }).then(oRes => {
            if(!oRes.bReturn){
                Swal.close();

                appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                return;
            }

            const fList: ProdProduct[] = [];
            oRes.listOfProducts!.forEach(oEmail => {
                const fEmail = {...oEmail}
                fList.push(fEmail);
            });

            this.listOfProducts = fList;
            
            Swal.close();
        });
      },
      refreshList(){
          appFxs.showAjaxLoader();

          NetServices.requestGet('products/products/listofproducts')
          .then(oRes => {
              if(!oRes.bReturn){
                      appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                      .then(xRes => {
                          //router.replace({path: '/app/myprofile', replace: true});
                          Swal.close();
                      });
                      return;
                  }

              const fList: ProdProduct[] = [];
              oRes.listOfProducts!.forEach(oEmail => {
                  const fEmail = {...oEmail}
                  fList.push(fEmail);
              });

              this.listOfSuppliers = oRes.listOfSuppliers!
              this.listOfTaxSettings = oRes.listOfTaxSettings!
              this.listOfProdBrands = oRes.listOfProdBrands!

              this.listOfProducts = fList;


              Swal.close();
          });
      },
      printListItems(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.list_of_products;
        dPrint.ReportToPrint = 11;

        this.printInitialValues = dPrint;

        const oPr = {
          CurrentYear: this.currentYear,
        };
        this.printExtraData = oPr;

        this.isPrintvisible = true;
      },      
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },
    },
    mounted(){
        document.title = this.language.list_of_products + ' - ' + this.language.appName;
        this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
        this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
        this.currentYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, '2000'));
  
        NetServices.requestGet('products/products/listofproducts')
          .then(oRes => {
              if(!oRes.bReturn){
                      appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                      .then(xRes => {
                          router.replace({path: '/app/myprofile', replace: true});
                      });
                      return;
                  }
  
              const fList: ProdProduct[] = [];
              oRes.listOfProducts!.forEach(oEmail => {
                const fEmail = {...oEmail}
                    fList.push(fEmail);
              });

              this.listOfSuppliers = oRes.listOfSuppliers!;
              this.listOfTaxSettings = oRes.listOfTaxSettings!;
              this.listOfProdBrands = oRes.listOfProdBrands!;
  
              this.listOfProducts = fList;
              
              this.isStillLoading = false;
          });
      }
  })
