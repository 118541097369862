
  import { useVuelidate } from '@vuelidate/core'
  import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

  import { defineComponent, Teleport } from "vue";
  import {RouterLink } from 'vue-router';
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';
  import { Customer } from "@/models/managttypes";

  import Swal from "sweetalert2";

  import { ProdBrand } from '@/models/producttypes';

  const language =GetLanguage(),
  appFxs = GeneralFx();

  const emptyBrand: ProdBrand = {
    ProdBrandId: 0,
    Code: '',
    BrandName: '',
    Description: '',
  };


  export default defineComponent({
    data(){
        return {
          v$: useVuelidate(),
          language,
          appFxs,
          dataRecord: {...emptyBrand} as ProdBrand,
          isSavingRecord: false,
          isLoading: true,
          brandKey: '',
        }
    },
    computed: {
      windowTitle(): string{
        return this.isLoading ? `${language.loading}...`: `${this.dataRecord.BrandName}`;
      },
      isDisabledByDef(): boolean{
        return this.dataRecord.Code === 'BR0001';
      }
    },
    methods: {
      selectOnFocus(evt: Event) {
        const oInput = evt.target as HTMLInputElement;
        oInput.select();
      },
      saveNewBrand(){
        this.isSavingRecord = true;
        const fRecord = {...appFxs.getProxyData(this.dataRecord)};

        NetServices.requestPost('products/brands/saveprodbrand',{
          prodBrandRecord: fRecord,
          toDelete: false,
        }).then(oRes => {
          if (!oRes.bReturn) {
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            this.isSavingRecord = false;
            return;
          }

          const fList: ProdBrand[] = [];
          oRes.listOfProdBrands!.forEach(oEmail => {
            const fEmail = { ...oEmail}
            fList.push(fEmail);
          });

          const oPath = `/app/products/brands/list`;
          router.replace({path: oPath, replace: true});
        });
      }
    },
    mounted(){
      document.title = this.windowTitle + ' - ' + this.language.appName;
      const brandKeyVal = this.$route.params.brandKey as string;
      if (!brandKeyVal) {
        appFxs.showAlert(language.missing_settings, language.missing_settings_msg)
          .then(oRes => {
            router.replace({ path: '/app/products/brands/list', replace: true })
          });
        return;
      }

      this.brandKey = brandKeyVal;

      NetServices.requestGet(`products/brands/getoneprodbrand/${brandKeyVal}`)
        .then(oRes => {
          if (!oRes.bReturn) {
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
              .then(vRes => {
                router.replace({ path: '/app/products/brands/list', replace: true });
              });
            return;
          }

          const tempBrand = oRes.oneProductBrand!;
          const oFinalCust = {...tempBrand };          

          this.dataRecord = oFinalCust;
          this.isLoading = false;
        });
    },
    validations(){
      return {
        dataRecord: {
          Code: {
              required: helpers.withMessage(language.fldRequired, required),                 
              $autoDirty: true,
          },
          BrandName: {
              required: helpers.withMessage(language.fldRequired, required),                 
              $autoDirty: true,
          },
        }
      }
    }
  })
