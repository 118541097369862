
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { SalesOrderDelivery } from '@/models/salestypes';
    import { ManagtUser } from '@/models/managttypes';
    import AppDeclarations from '@/tools/declarations';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    const greaterThanZero = (val: number) => val > 0;
    const fNewDate = new Date(),
        year = fNewDate.getFullYear()

    const emptyDeliverOper:SalesOrderDelivery = {
        IdDelivery: 0,
        DeliveryKey: '',
        DeliveryNbr: '',
        DeliveryStatus: 0,
        OrderNbr: '',
        OrderKey: '',
        DeliveryDate: fNewDate,
        UserEmail: '',
        Barcode: '',
        QrCode: '',
        ReceiverName: '',
        ReceiverTel: '',
        CreatedBy: '',
    }


    export default defineComponent({        
        name: 'DeliveryOrderModal',
        emits: ['closeForm'],
        props: [
            "visible",
            "initialValues", 
            "recordStatus",
            "listOfUsers",
        ],
        data(){
            return  {
                v$: useVuelidate(),
                appFxs,
                language,
                dataRecord: {...emptyDeliverOper} as SalesOrderDelivery,
                isSavingRecord: false,
                currCode: 'RWF',

                theListStaff: [] as ManagtUser[],

                userEmail: '',
            }
        },
        computed: {
            windowTitle() {
                return this.recordStatus == 0 ? language.add_delivery_oper :
                    language.edit_delivery_oper;
            },
        },
        methods: {
            generateOrderNbr(){
                const otime = new Date();
                const oSec = otime.getSeconds();
                const oMins = otime.getMinutes();

                const oRandom = appFxs.getRandomDigits(2);
                const fSecs = oSec.toString().length == 2 ? 
                    oSec.toString() : '0' + oSec.toString();
                const fMins = oMins.toString().length == 2 ? 
                    oMins.toString() : '0' + oMins.toString();

                const oNbr = 'DEL' + appFxs.generateTextNbr(5) + `${oRandom}${fSecs}${fMins}`;
                this.dataRecord.DeliveryNbr =  oNbr.toUpperCase();
            },
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.$emit('closeForm', {returnType: 1, returnData: this.dataRecord});
            },
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: SalesOrderDelivery = {
                            ...oValues, DeliveryDate: new Date(oValues.DeliveryDate)
                        } as SalesOrderDelivery;

                        const userEmail = appFxs.getLocalStorageByKey(AppDeclarations.userEmail, '');
                        this.userEmail = userEmail;

                        this.theListStaff = appFxs.getProxyData(this.listOfUsers) as ManagtUser[];
                        this.v$.$reset();
                        this.dataRecord = fValues;
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {
                    DeliveryKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    DeliveryNbr: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    OrderNbr: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    OrderKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    UserEmail: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    Barcode: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    QrCode: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    CreatedBy: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                }
            }
        },
        mounted(){
            appFxs.updateLoadedScripts();
        }
    })
