
  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';

  import Swal from "sweetalert2";
  import { DiscountRec, DiscountRule } from "@/models/salestypes";

  import DiscountRecModal from '@/components/modals/DiscountRecordModal.vue';
  import DiscountRuleModal from '@/components/modals/DiscountRuleModal.vue';


  import { Customer } from "@/models/managttypes";
  import { ProdProduct } from "@/models/producttypes";
  import { DiscountWithRules } from "@/models/notdb";

  const language =GetLanguage(),
  appFxs = GeneralFx();
  const newDate = new Date();
  
  const emptyDiscountRect: DiscountRec = {
        IdDiscount: 0,
        DiscountCode: '',
        DiscountName: '',
        StartDate: newDate,
        HasEndDate: false,
        EndDate: newDate,
        ApplytoAllClients: true,
        Clients: '',
        ApplytoAllProducts: true,
        Products: '',
        HasRules: false,
        DiscValue: 0,
        IsStillActive: true,
        CreatedDate: newDate,
    };
  const emptyDiscountRule: DiscountRule = {
      IdDiscRule: 0,
      DiscountCode: '',
      RuleKey: '',
      MinQuant: 0,
      MaxQuant: 0,
      DiscValue: 0,
  };


  export default defineComponent({
    components: {
      DiscountRecModal,
      DiscountRuleModal
    },
    data(){
      return {              
        appFxs,
        language,
        isStillLoading: true,
        itemSearchKey: '',
        listOfDiscountWithRules: [] as DiscountWithRules[],
        itemPageElts: [] as DiscountWithRules[],
        itemSerachKey: '',
        itemsPerPage: 5,

        listOfCustomers: [] as Customer[],
        listOfProducts: [] as ProdProduct[],

        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 8,
          ReportTitle: language.list_of_brands
        } as any,
        printExtraData: {},

        isDiscModalVis: false,
        discModalInitVal: {...emptyDiscountRect} as DiscountRec,
        discModalStatus: 0,

        isDiscRuleModalVis: false,
        discRuleModalInitVal: {...emptyDiscountRule} as DiscountRule,
        discRuleModalStatus: 0,
      }
    },
    computed: {
      filteredItems(): DiscountWithRules[] {
        let searchKey = this.itemSearchKey;

        if (searchKey.length === 0) return this.listOfDiscountWithRules;
        searchKey = searchKey.toLowerCase();

        const filterd = this.listOfDiscountWithRules.filter(oDev => {
          return oDev.discount.DiscountCode.toLowerCase().includes(searchKey) ||
            oDev.discount.DiscountName.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
    },
    methods: {
      deleteDiscRuleRec(oRecord: DiscountRule){
        appFxs.showConfirm(language.delete, language.delete_quest_rec)
        .then(vRes => {
          if(vRes.isConfirmed){
            this.deleteDiscRuleRecFinal(oRecord);
          }
        });
      },
      deleteDiscRuleRecFinal(oRecord: DiscountRule){
        appFxs.showAjaxLoader();
        const opsData = { ...oRecord } as DiscountRule;

        NetServices.requestPost('salesorders/discountrecs/rules/savediscountrule', {
          discountRuleModel: opsData,
          toDelete: true,
        }).then(oRes => {
          if (!oRes.bReturn) {
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }
          
          this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
          Swal.close();
        });
      },
      addEditDiscRule(iStatus: number, oDiscount: DiscountRec, oRecord: DiscountRule|any){
        if(iStatus==0){
          const oRec = {
            ...emptyDiscountRule,
            DiscountCode: oDiscount.DiscountCode,
            RuleKey: appFxs.generateUUID(),
            DiscValue: oDiscount.DiscValue
          } as DiscountRule;
          this.discRuleModalInitVal = oRec;
          this.discRuleModalStatus = 0;
        }else{
          this.discRuleModalInitVal = {...oRecord} as DiscountRule;
          this.discRuleModalStatus = 1;
        }

        this.isDiscRuleModalVis = true;
      },
      getProductInfo(oRecord: DiscountRec): string{
        return oRecord.ApplytoAllProducts ? language.all_products:
          language.specific_products;
      },
      getCustomerInfo(oRecord: DiscountRec): string{
        return oRecord.ApplytoAllClients ? language.all_clients:
          language.specific_clients;
      },
      getDiscEndDate(oRecord: DiscountRec): string{
        return oRecord.HasEndDate ? appFxs.formatDate(oRecord.EndDate):
          language.endless;
      },
      showAddEditDiscount(iStatus: number, oRecord: DiscountRec | any){
        if (iStatus == 0) {
          const oRec = {...emptyDiscountRect} as DiscountRec;
          this.discModalInitVal = oRec;
        } else {
          this.discModalInitVal = {...oRecord} as DiscountRec;
        }

        this.isDiscModalVis = true;
      },
      onPageChange(evt: any){
            this.itemPageElts = appFxs.getProxyData(evt);
      },
      deleteDiscountRec(data: DiscountRec){
        appFxs.showConfirm(language.delete, language.delete_quest_rec)
          .then(oResp => {
            if (!oResp.isConfirmed) return;
            this.finalDeleteBrand(data);
          });
      },
      finalDeleteBrand(data: DiscountRec){
        appFxs.showAjaxLoader();

        NetServices.requestPost('salesorders/discountrecs/savediscountrec', {
          discountRecModel: data,
            toDelete: true,
        }).then(oRes => {
            if(!oRes.bReturn){
                Swal.close();

                appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                return;
            }

            const fList: DiscountWithRules[] = [];
            oRes.listOfDiscountWithRules!.forEach(oEmail => {
                const fEmail = {...oEmail}
                fList.push(fEmail);
            });

            this.listOfDiscountWithRules = fList;
            
            Swal.close();
        });
      },
      refreshList(){
          appFxs.showAjaxLoader();

          NetServices.requestGet('salesorders/discountrecs/getlistofdiscountrecs')
          .then(oRes => {
              if(!oRes.bReturn){
                      appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                      .then(xRes => {
                          //router.replace({path: '/app/myprofile', replace: true});
                          Swal.close();
                      });
                      return;
                  }

              const fList: DiscountWithRules[] = [];
              oRes.listOfDiscountWithRules!.forEach(oEmail => {
                  const fEmail = {...oEmail}
                  fList.push(fEmail);
              });

              this.listOfDiscountWithRules = fList;
              this.listOfProducts = oRes.listOfProducts!;
              this.listOfCustomers = oRes.listOfCustomers!;
              Swal.close();
          });
      },
      printListItems(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.list_of_discounts;
        dPrint.ReportToPrint = 33;

        this.printInitialValues = dPrint;


        this.isPrintvisible = true;
      },      
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },



      onDiscModalClose(evt: any){
        if(evt.returnType==0){
          this.isDiscModalVis = false;
          return;
        }

        const lst = evt.returnData as DiscountWithRules[];
        this.listOfDiscountWithRules = lst;
        this.isDiscModalVis = false;
      },
      onDiscRuleModalClose(evt: any){
        if(evt.returnType==0){
          this.isDiscRuleModalVis = false;
          return;
        }

        const lst = evt.returnData as DiscountWithRules[];
        this.listOfDiscountWithRules = lst;
        this.isDiscRuleModalVis = false;
      }
    },
    mounted(){
        document.title = this.language.list_of_discounts + ' - ' + this.language.appName;
        this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
  
        NetServices.requestGet('salesorders/discountrecs/getlistofdiscountrecs')
          .then(oRes => {
              if(!oRes.bReturn){
                      appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                      .then(xRes => {
                          router.replace({path: '/app/myprofile', replace: true});
                      });
                      return;
                  }
  
              const fList: DiscountWithRules[] = [];
              oRes.listOfDiscountWithRules!.forEach(oEmail => {
                const fEmail = {...oEmail}
                    fList.push(fEmail);
              });
  
              this.listOfDiscountWithRules = fList;
              this.listOfProducts = oRes.listOfProducts!;
              this.listOfCustomers = oRes.listOfCustomers!;
              
              this.isStillLoading = false;
          });
      }
  })
