import AppDeclarations from "./declarations";
import { DateOperation, HeaderType, PrintRecType, ShortData, UserRow } from './smarttypes';
import GetLanguage from '@/tools/language';
import Swal from 'sweetalert2'
import moment from 'moment';
import jquery from 'jquery';





function generalFx()  {
    const $ = jquery;
    const language = GetLanguage();

    const wdMonthsLng = [
        language.jan_lng,
        language.feb_lng,
        language.mar_lng,
        language.apr_lng,
        language.may_lng,
        language.jun_lng,
        language.jul_lng,
        language.aug_lng,
        language.sept_lng,
        language.oct_lng,
        language.nov_lng,
        language.dec_lng
    ],
    wdMonthsShrt = [
        language.jan_shrt,
        language.feb_shrt,
        language.mar_shrt,
        language.apr_lng,
        language.may_lng,
        language.jun_lng,
        language.jul_shrt,
        language.aug_shrt,
        language.sept_shrt,
        language.oct_shrt,
        language.nov_shrt,
        language.dec_shrt
    ];

    return {
        //#region general
        getLocalStorageByKey: (key: string, defaultValue: string) => {
            const elt = localStorage.getItem(key);
            return elt ?? defaultValue;
        },
        setLocalStorage(key: string, valueData: string){
            localStorage.setItem(key, valueData);
        },
        cleanLocalStorageByKey(key: string){
            localStorage.setItem(key, '');
        },
        removeLocalStorageByKey(key: string){
            localStorage.removeItem(key);
        },
        getLocalLanguage(){
            return this.getLocalStorageByKey(AppDeclarations.currentLang, 'en');
        },
        toBoolFromStr(nbrStr: string): boolean {
            return nbrStr === '0' ? false:nbrStr === '1' ? true:false;
        },
        onlyCapitalLetters: (str: string):string => { 
            let newStr = "";
            
            for (let i = 0; i < str.length; i++) {
                if (str[i].match(/[A-Z]/)) {
                    newStr += str[i];
                }
                }
                return newStr;
        },
        onlySmallLetters: (str: string):string => { 
            let newStr = "";
            
            for (let i = 0; i < str.length; i++) {
                if (str[i].match(/[a-z]/)) {
                    newStr += str[i];
                }
                }
                return newStr;
        },
        onlySpecialChars: (strSource: string, specialChars: string): string => {
            const retArr:string[] = [];
            const arrSource = strSource.split('');
            arrSource.forEach(oSrc => {
                if(specialChars.includes(oSrc)){
                    retArr.push(oSrc);
                }
            });
            return retArr.join('');
        },
        passwordStrengthCheck(password: string): number {
            let retFinal = 0;
            const pLength = password.length;
            const regNbr = new RegExp('^[0-9]+$');
            if(/\d/.test(password)) {
                retFinal+=5;
                const tempoPw = password;
                const allNbrs = tempoPw.replace(/[^0-9]/g,'');
                const lenNbrs = allNbrs.length;
                if(lenNbrs! < 2){
                    retFinal+=5;
                }else if(lenNbrs! == 2){
                    retFinal+=10;
                }else{
                    retFinal += 15;
                }
            }        
            const regCapLetter = /[A-Z]/;
            if(regCapLetter.test(password)){
                retFinal+=5;

                const caps = this.onlyCapitalLetters(password).length;
                if(caps<2){
                    retFinal+=5;
                }else if(caps == 2){
                    retFinal+=10;
                }else{
                    retFinal += 15;
                }
            }
            const regSmallLetter = /[a-z]/;
            if(regSmallLetter.test(password)){
                retFinal+=5;

                const caps = this.onlySmallLetters(password).length;
                if(caps<2){
                    retFinal+=5;
                }else if(caps == 2){
                    retFinal+=10;
                }else{
                    retFinal += 15;
                }
            }
            const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            if(specialChars.test(password)){
                retFinal += 5;
                const caps = this.onlySpecialChars(password, "`!@#$%^&*()_+\-=\[\]{};':\"\\|,.<>\/?~").length;
                if(caps<2){
                    retFinal += 5;
                }else if(caps == 2){
                    retFinal += 10;
                }else{
                    retFinal += 15;
                }
            }
            if(pLength>=8){
                retFinal+=20;
            }
            return retFinal;
        },
        getManagtDefaultHeaders(): HeaderType {
            let newDate = new Date(),
                accesskey = this.getLocalStorageByKey(AppDeclarations.userAccessKey, ''),
                lang = this.getLocalLanguage(),
                companyCode = AppDeclarations.company_code,
                operationDate = this.dateToUtcStr(new Date()),

                year_app = this.getLocalStorageByKey(AppDeclarations.currentYearbook,
                    newDate.getFullYear().toString()),
                default_sms = this.getLocalStorageByKey(AppDeclarations.currentDefaultSmsAcct,
                    ''),
                current_devise = this.getLocalStorageByKey(AppDeclarations.currentDevise,
                    'USD'),
                current_branch = this.getLocalStorageByKey(AppDeclarations.currentBranch,
                    'HQ'),
                user_email_value = this.getLocalStorageByKey(AppDeclarations.userEmail,
                    ''),
                user_password_value = this.getLocalStorageByKey(AppDeclarations.userPassword,
                    '');

            const oDate = new Date();
            let bInt = !Number.isNaN(year_app);
            if (!bInt) {
                year_app = oDate.getFullYear().toString();
            }

            return {
                'user-access-key': (accesskey) ? btoa(accesskey) : '',
                'lang': (lang) ? btoa(lang) : "",
                'company-code': (companyCode) ? btoa(companyCode) : '',
                'client-operation-date': btoa(operationDate),
                'year-exercise-app': btoa(year_app),
                'default-sms-account': (default_sms) ? btoa(default_sms) : '',
                'current-devise-val': btoa(current_devise),
                'current-branch-val': btoa(current_branch),
                'user-email-value': (user_email_value) ? btoa(user_email_value) : '',
                'user-password-value': (user_password_value) ? btoa(user_password_value) : '',
            };
        },
        returnLang(englText:string, frText:string, rwText:string):string {
            const lng = this.getLocalLanguage();
            return lng == 'en' ? englText :
                lng == 'fr' ? frText :
                    lng == 'rw' ? rwText :
                        englText;
        },
        //#endregion

        //#region strings
        isEmailValid(email: string): boolean{
            const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            return reg.test(email);
        },
        replaceByCharacter(str: string, fChar: string, nChar: string): string{
            return str.split(fChar).join(nChar);
        },
        extractFileNameFromUrl(url: string): string {
            return url.substring(url.lastIndexOf('/') + 1);
        },
        downloadFromFileUrl(file_name:string, file_url:string) {
            const element = document.createElement('a');
            element.setAttribute('href', file_url);
            element.setAttribute('download', file_name);
            element.setAttribute('target', '_blank');

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },
        openForDowloadPrint(
            fileName: string, 
            userEmail: string,
            langOper: string){

            const downloadString = window.btoa(`${userEmail}|${fileName}|${langOper}`);

            const url = AppDeclarations.apiUrl + 'scripts/printdownloader.aspx?download_string=' + 
                downloadString;
            const printWindow = window.open(url, '_blank');
        },
        getCustomerOrigin(iOrigin: number):string{
            return iOrigin==0?language.automatic: language.manual;
        },
        getTextFromHtml(html: string): string{
            const span = $(`<span>${html}</span>`);
            return span.text();
        },
        generateUUID(): string {
            var d = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        generateTextNbr(length = 8): string {
            let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let str = '';
            for (let i = 0; i < length; i++) {
                str += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return str;
        },
        getRandomDigits(length: number): string{
            return Math.random().toString(2).substr(2, length);
        },
        getRandomInt(min: number, max: number): number{
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        //#endregion

        //#region generatenbrs
        generateRefEntrStockOperNbr(): string {
            const oDate = new Date(),
                oHrs = oDate.getHours().toString(),
                oMins = oDate.getMinutes().toString(),
                oSecs = oDate.getSeconds().toString();
            const sHrs = oHrs.length == 2 ? oHrs: `0${oHrs}`;
            const sMins = oMins.length == 2 ? oMins: `0${oMins}`;
            const sSecs = oSecs.length == 2 ? oSecs: `0${oSecs}`;
            const oGen = this.generateTextNbr(3);

            return `RFENTRST${sSecs}${sHrs}${sMins}${oGen}`.toUpperCase();
        },
        generatedSalesQuoteNbr(): string {
            const oDate = new Date(),
                oHrs = oDate.getHours().toString(),
                oMins = oDate.getMinutes().toString(),
                oSecs = oDate.getSeconds().toString();
            const sHrs = oHrs.length == 2 ? oHrs: `0${oHrs}`;
            const sMins = oMins.length == 2 ? oMins: `0${oMins}`;
            const sSecs = oSecs.length == 2 ? oSecs: `0${oSecs}`;
            const oGen = this.generateTextNbr(4);

            return `QT${sSecs}${sHrs}${sMins}${oGen}`.toUpperCase();
        },
        generatedSalesOrderNbr(): string {
            const oDate = new Date(),
                oHrs = oDate.getHours().toString(),
                oMins = oDate.getMinutes().toString(),
                oSecs = oDate.getSeconds().toString();
            const sHrs = oHrs.length == 2 ? oHrs: `0${oHrs}`;
            const sMins = oMins.length == 2 ? oMins: `0${oMins}`;
            const sSecs = oSecs.length == 2 ? oSecs: `0${oSecs}`;
            const oGen = this.generateTextNbr(4);

            return `ORD${sSecs}${sHrs}${sMins}${oGen}`.toUpperCase();
        },
        //#endregion

        //#region arrays
        getPaytTypes(): ShortData[]{
            return [
                {id: 0, label: language.cash_payt},
                {id: 1, label: language.mobile_money},
                {id: 2, label: language.other},
            ];
        },
        filterArrayByField(lst: any[], fld: string, val: any, defValue: any): any {
            const fElt = lst.filter(o => o[fld] == val);
            return fElt.length > 0 ? fElt[0]: defValue;
        },
        arrayEquals(a: any[], b: any[]){
            return Array.isArray(a) &&
                Array.isArray(b) &&
                a.length === b.length &&
                a.every((val, index) => val === b[index]);
        },
        listOfLangs(): ShortData[]{
            return [
                {id:0, label: language.english_m},
                {id:1, label: language.french_m},
                {id:2, label: language.rwanda_m},
                {id:3, label: language.swahili_m},
                {id:4, label: language.lingala_m},
                {id:5, label: language.other_m},
            ]
        },        
        listOfSexes(): ShortData[]{
            return [
                {id:0, label: language.male},
                {id:1, label: language.female},
            ]
        },       
        clientTypes(): ShortData[]{
            return [
                {id:0, label: language.individual},
                {id:1, label: language.corporate},
                {id:2, label: language.business},
                {id:3, label: language.government},
                {id:4, label: language.ngo},
                {id:5, label: language.religinst},
                {id:6, label: language.intern_organ},
            ]
        },
        getListMonthsByYear(iYear: number): ShortData[]{
            const lstRet = [] as ShortData[];
            for (let index = 0; index < 12; index++) {
                lstRet.push({
                    id: index + 1,
                    label: wdMonthsShrt[index] + ` - ${iYear}`,
                });
            }
            return lstRet;
        },
        //#endregion

        //#region date and time
        wdMonthsShrt,
        getYearFromNow(nYears: number): {id: number, label: string}[]{
            let fY = new Date().getFullYear(),
                lst = [] as {id: number, label: string}[];
            for (let i = 0; i < nYears; i++) {
                let _o = fY + i;
                lst.push({ id: _o, label: _o.toString() });
            }

            return lst;
        },
        getMonthsDates(): {id: number, label: string}[]{
            let fY = '',
                lst = [] as {id: number, label: string}[],
                months = wdMonthsShrt;

            for (let i = 0; i < months.length; i++) {
                let mInt = ((i + 1).toString().length > 1) ? (i + 1).toString() : '0' + (i + 1).toString();
                let _i = mInt + '-' + months[i];
                lst.push({ id: (i + 1), label: _i });
            }

            return lst;
        },
        dateToUtcStr(dDate: Date): string{
            return new Date(dDate).toJSON()
        },
        formatDateWithHrs(oDate: Date, format: string = 'DD/MM/YYYY HH:mm'):string{
            if(oDate){
                const oFinalDate = oDate;
                return moment(oFinalDate).format(format);
            }

            return '';
        },
        formatDate(oDate: Date, format: string = 'DD/MM/YYYY'):string{
            if(oDate){
                return moment(oDate).format(format);
            }

            return '';
        },
        formatDateFr(oDate: Date, bTime: boolean = false):string{
            const format = bTime ? 'DD/MM/YYYY hh:mm:ss':'DD/MM/YYYY';
            if(oDate){
                return moment(oDate).format(format);
            }

            return '';
        },
        formatDateEn(oDate: Date):string{
            if(oDate){
                return moment(oDate).format('MM/DD/YYYY');
            }

            return '';
        },
        addingDate(_date: Date, _oper: DateOperation, _add: number, bSub: boolean = false): Date{
            let _dateRet = new Date();

            try {
                _dateRet = new Date(_date.getTime());

                if (_oper == DateOperation.Day) {
                    _dateRet.setDate(_dateRet.getDate() + (1 * _add));
                } else if (_oper == DateOperation.Month) {
                    _dateRet.setMonth(_dateRet.getMonth() + (1 * _add));
                } else if (_oper == DateOperation.Year) {
                    _dateRet.setFullYear(_dateRet.getFullYear() + (1 * _add));
                }
            } catch (e) {

            }
            if (bSub == true) {
                _dateRet.setDate(_dateRet.getDate() + (1 * -1));
            }
            return _dateRet;
        },
        getFirstDate(year: number, month: number): Date{
            return new Date(year, month-1, 1);
        },
        getLastDate(year: number, month: number): Date{
            return new Date(year, month, 0);
        },
        getFirstDateByDate(referDate: Date): Date{
            const year = referDate.getFullYear();
            const month = referDate.getMonth() + 1;

            return new Date(year, month-1, 1);
        },
        getLastDateByDate(referDate: Date): Date{
            const year = referDate.getFullYear();
            const month = referDate.getMonth() + 1;

            return new Date(year, month, 0);
        },
        isBetweenTwoDates(_referDate: Date, _firstDate: Date, _lastDate: Date): boolean{
            const referDate = new Date(_referDate.getFullYear(), _referDate.getMonth(), _referDate.getDate(), 23,59,0);
            const firstDate = new Date(_firstDate.getFullYear(), _firstDate.getMonth(), _firstDate.getDate(), 23,59,0);
            const lastDate = new Date(_lastDate.getFullYear(), _lastDate.getMonth(), _lastDate.getDate(), 23,59,0);
            return referDate.getTime() >= firstDate.getTime() && 
                    referDate.getTime() <= lastDate.getTime();
        },
        isDateValidComparator(dateData: Date, dateRef: Date):boolean{
            const oDateData = new Date(dateData.getFullYear(), dateData.getMonth(), dateData.getDate(), 23,59,0);
            const oDateRef = new Date(dateRef.getFullYear(), dateRef.getMonth(), dateRef.getDate(), 23,59,0);

            return oDateData <= oDateRef;
        },
        //#endregion

        //#region jquery
        updateLoadedScripts(){
            //#region numeric
            const allComav2 = $('.commav');
            allComav2.on('keyup', (e) => {
                const input = (e.target as HTMLInputElement);
                var t = input.value;
                if(!t){
                    input.value = '0';
                    input.select();
                }
            });
            const allnumerics = $('.numeric');
            allnumerics.on('keyup', (e) => {
                const input = (e.target as HTMLInputElement);
                var t = input.value;
                if(!t){
                    input.value = '0';
                    input.select();
                }
            });
            allnumerics.on('keypress', (e)=> {
                return this.numberOnlyV2(e);
            });
            //#endregion
            /* const allCommaV = $('.commav');
            allCommaV.on('keypress') */
            //#region commav

            //#endregion

            //#region number
            const allnumbers = $('.number, .telephone-nbr');
            allnumbers.on('keypress', (e)=> {
                return this.numberOnlyV2(e);
            });
            //#endregion

            //#region capletter
            const allcapletters = $('.capletter');
            allcapletters.on('input', (e) => {
                $(e.target).val((_, val) => {
                    return val.toUpperCase();
                });
            });
            //#endregion

            //#region alpha-only
            const allAlphaOnly = $('.alpha-only');
            allAlphaOnly.on('keypress', (e) => {
                const charCode = e.which;
                const charStr = String.fromCharCode(charCode);

                if (/\d/.test(charStr)) {
                    return false;
                }

                return true;
            })
            //#endregion

            //#region tab-press
            const allTabPress = $('.tab-press');
            allTabPress.on('keypress', (e) => {
                if(e.keyCode === 13){
                    let nextItem = $(e.target).next('.tab-press');
                    console.log('nextItem', nextItem);
                    
                    if((nextItem as any).size() === 0){
                        nextItem = $('.tab-press').eq(0);
                    }
                    nextItem.focus();
                }
            })
            //#endregion

            //#region commav
            const allCommaV = $('.commav');
            allCommaV.on('keypress', (e) => {
                const vVal = (e.target as HTMLInputElement).value;
                return this.numberComma(e, vVal);
            });
            //#endregion
        },
        onGlobalKeyPressed(evt: KeyboardEvent){

        },
        //#endregion

        //#region alerts dialogs
        showInputAlert(msgTitle: string, confirmButtonText: string = 'OK'){
            return Swal.fire({
                title: msgTitle,
                input: 'text',
                confirmButtonText,
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
        },
        showAlert(msgTitle: string, msgBody: string, cancelButtonText: string = 'Cancel'){
            return Swal.fire({
                title: msgTitle,
                text: msgBody,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText,
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
        },
        showConfirm(
            msgTitle: string, 
            msgBody: string, 
            cancelButtonText: string = 'Cancel', 
            confirmButtonText: string = 'Yes'){
            return Swal.fire({
                title: msgTitle,
                text: msgBody,
                showCancelButton: true,
                showConfirmButton: true,
                cancelButtonText,
                confirmButtonText,
                allowEscapeKey: false,
                allowOutsideClick: false,
            });
        },
        showAjaxLoader(loadingMessage?: string){
            const langText = loadingMessage ?? GetLanguage().waitServerResp;
            const imageLoading = require('@/assets/images/loading.png');
            
            return Swal.fire({
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                html: `
                    <div class="py-2 d-flex flex-column justify-content-center align-items-center">
                        <span class="py-3">${langText}</span>
                        <img class="fa-spinner my-3" src="${imageLoading}" width="50" height="50" />
                    </div>
                `,
            });
        },
        getReportingData(): PrintRecType {
            const oLang = this.getLocalLanguage(),
                oUserEmail = this.getLocalStorageByKey(AppDeclarations.userEmail,'');
            
                return {
                    EmailMessage: '',
                    EmailReceiver: '',
                    EmailSubject: '',

                    EmailPrint: oUserEmail,
                    LangPrint: oLang,
                    ReportTitle: '',
                    ReportToPrint: 0,
                    StylePrint: 4,
                    TypeSoft: 0
                }
        },
        makeVoiceSinchaCall(phoneNbr: string){
            this.showAlert(language.error, `${language.sincha_call_error} (${phoneNbr})`);
        },
        //#endregion

        //#region number
        formatNumbers(val: number): string{
            return Intl.NumberFormat("en-US").format(val);
        },
        numberOnly(str: string): boolean {
            if (typeof str !== 'string') {
                return false;
              }
            
              if (str.trim() === '') {
                return false;
              }
            
              return !Number.isNaN(Number(str));
        },
        strictlyNbr(evt: any){
            const event = (evt) ? evt: window.event;
            var charCode = (event.which) ? event.which: event.keyCode;

            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        numberComma(evt: any, vValue: string): boolean{
            const isDot = evt.charCode == 46;
            const isNbr = (evt.charCode >= 48 && evt.charCode <= 57) || isDot;
            const hasDot = vValue.indexOf('.') !== -1;            
            if(isDot){
                if(hasDot) evt.preventDefault();
            }else{
                if(!isNbr) evt.preventDefault();
            }            

            return true;
        },
        numberOnlyV2(evt: any):boolean {
            return evt.charCode >= 48 && evt.charCode <= 57
        },
        floatNbr(event: any) {
            const input = (event.target as HTMLInputElement);
            if ((event.which !== 46 || input.value.indexOf('.') !== -1) && (event.which < 48 || event.which > 57)) {
                event.preventDefault();
            }
        },
        floatNbrT(event: any) {
            const input = (event.target as HTMLInputElement);
            if (event.which === 8 || event.keyCode === 37 || event.keyCode === 39 || event.keyCode === 46)
                return true;

            else if ((event.which !== 46 || input.value.indexOf('.') !== -1) && (event.which < 48 || event.which > 57))
                return false;
        },
        formatNbrShorten(value: number, bCap: boolean = true):string {
            let newValue = value.toString();
            if (value >= 1000) {
                let suffixes = ["", "k", "m", "b","t"];
                let suffixNum = Math.floor( (""+value).length/3 );
                let shortValue = 0;
                for (let precision = 2; precision >= 1; precision--) {
                    shortValue = parseFloat( (suffixNum !== 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
                    let dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
                    if (dotLessShortValue.length <= 2) { break; }
                }
                let shortNum = "";
                if (shortValue % 1 !== 0)  shortNum = shortValue.toFixed(1);
                newValue = shortValue+suffixes[suffixNum];
            }
            return (bCap) ? ((newValue) ? newValue.toString().toUpperCase() : newValue) : newValue;
        },
        //#endregion
        
        //#region objects
        getObjWithoutPerop(obj: any, propertyName: string){
            const { [propertyName]: unused, ...rest } = obj;
            return rest;
        },
        mergeObjects(firstObject: any, lastObject: any){
            const finalObject = {...firstObject, ...lastObject};

            return finalObject;
        },
        getFormData(xObject?: any):FormData {
            const form_data = new FormData();

            if(xObject){
                for (let key in xObject) {
                    form_data.append(key, xObject[key]);
                }
            }
            return form_data;
        },
        getProxyData(proxyData: any):any{
            return JSON.parse(JSON.stringify(proxyData));
        },
        //#endregion

        //#region different fxs
        getUserStatusName(row: UserRow): string{
            const bOwner = row.User.IsMainAdmin;
            const bAdm = row.User.IsAdmin;

            const sRet = (bOwner) ? GetLanguage().owner :
            (bAdm) ? GetLanguage().admin_sh :
            GetLanguage().team_member;

            return sRet;
        },
        showEditUserBtn(row: UserRow, userEmail: string){
            let bWait = row.User.WaitForResp,
                isUser = row.User.Email === userEmail;
            return !(bWait || isUser);
        },
        getListOfItemsPerPage(): ShortData[]{
            return [
                {id: 5, label: '5'},
                {id: 10, label: '10'},
                {id: 15, label: '15'},
                {id: 20, label: '20'},
                {id: 25, label: '25'},
                {id: 30, label: '30'},
                {id: 35, label: '35'},
                {id: 40, label: '40'},
                {id: 45, label: '45'},
                {id: 50, label: '50'},
            ]
        },
        setExpenderRow(row: UserRow){
            row.IsExpended = !row.IsExpended;
        },
        showUserExtra(row: UserRow, userEmail: string){
            let bOwner = row.User.IsMainAdmin,
                isUser = row.User.Email === userEmail;
            return !(bOwner || isUser);
        }
        //#endregion
    }
}

export default generalFx;