
import { defineComponent } from "vue";
import {RouterLink } from 'vue-router';
import router from '@/router/index';

import GetLanguage from '@/tools/language';
import GeneralFx from '@/tools/general_fx';
import appDeclarations from '@/tools/declarations'; 

  import AccountImageArea from '@/components/account/AccountImageArea.vue';
  import ForgotForm from '@/components/account/ForgotPwForm.vue';

  import NetServices from '@/tools/netservices';

  const language =GetLanguage(),
    appFxs = GeneralFx();

  export default defineComponent({
    components: {
      AccountImageArea,
      ForgotForm,
    },
    data(){
      return {
        language,
        isFormInvalid: true,
        isNetLoading: false,
        isServerError: false,
        errorMessage: '',
      }
    },
    mounted() {
      document.title = this.language.forgotPassWord + ' - ' + this.language.appName;
    },
    methods: {
      closeErrorPanel(){
        this.isServerError = false;
      },
      sendFormToServer(oData: any){
        this.isNetLoading = true;
        this.isServerError = false;

        NetServices.requestPost('managt/account/reqpasswordreset', {
          userEmail: window.btoa(oData.userEmail)
        }).then(oRes => {
          if(!oRes.bReturn){
            this.isServerError = true;
            this.errorMessage = oRes.msgBody;
            this.isNetLoading = false;
            return;
          }

          router.replace({path: '/account/resetwait', replace: true})
        }); 
      }
    }
  })
