
    import { defineComponent } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { Company, EmailSenderSett, ManagtUser } from "@/models/managttypes";
    import { DateOperation, UserRow } from "@/tools/smarttypes";

    import AlertDefault from "@/components/modals/AlertDefault.vue";
    import UserSettingsModal from "@/components/modals/UserSettingsModal.vue";
    import Swal from "sweetalert2";

    const language =GetLanguage(),
        appFxs = GeneralFx();
    const emptyUser: ManagtUser = 
    {
        UserId: 0,
        Email: '',
        Password: '77777',
        FirstName: '',
        LastName: '',
        DisplayName: '',
        JobTitle: '',
        UserBio: '',
        MobilePhone: '',
        Sex: 0,
        DOB: appFxs.addingDate(new Date(), DateOperation.Year, -20, true),
        FacebookAccount: '',
        TwitterAccount: '',
        LinkedInAccount: '',
        KeyRequestInvite: '',
        TelephoneImei: '',
        SerialNumber: '',
        PhotoPath: '',
        Country: 'RW',
        City: '',
        UserActive: true,
        WaitForResp: false,
        ReceiveWiseEmail: true,
        IsMainAdmin: false,
        IsAdmin: false,
    };
    const dPrintValues = appFxs.getReportingData();
    
    export default defineComponent({
        components: {
            UserSettingsModal
        },
        data(){
            return {
                language,
                appFxs,
                isStillLoading: true,
                connectedUser: null as ManagtUser | any,
                connectedDomain: null as Company | any,
                listOfAllUsers: [] as UserRow[],
                usersPageElts: [] as UserRow[],
                itemsPerPageLst: appFxs.getListOfItemsPerPage(),
                itemsPerPage: 5,
                searchValue: '',
                localLang: 'en',

                isDialogOpen: false,
                dialogRecordStatus: 0,
                dialogRecordData: emptyUser,

                isPrintvisible: false,
                printInitialValues: {...dPrintValues} as any,
                printExtraData: {}
            }
        },
        methods: {
            onPageCange(evt: any){
                this.usersPageElts = appFxs.getProxyData(evt);
            },
            refreshList(){
                appFxs.showAjaxLoader();

                NetServices.requestGet('managt/settings/getlistallusers')
                .then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: UserRow[] = [];
                    oRes.listOfUsers!.forEach(oUser => {
                        const fEmail = {...oUser, DOB: new Date(oUser.DOB)}
                        fList.push({IsExpended: false, User: fEmail});
                    });

                    this.listOfAllUsers = fList;
                    

                    Swal.close();
                });

            },
            deleteThisUserSett(row: UserRow){
                appFxs.showConfirm(language.delete, language.delete_quest_rec)
                .then(oResp => {
                    if(!oResp.isConfirmed)return;
                    this.finalDeleteUser(row.User);
                });
            },
            finalDeleteUser(user: ManagtUser){
                appFxs.showAjaxLoader();

                NetServices.requestPost('managt/settings/submituserforrequest', {
                    userSettRecord: user,
                    editType: 2,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: UserRow[] = [];
                    oRes.listOfUsers!.forEach(oUser => {
                        const fEmail = {...oUser, DOB: new Date(oUser.DOB)}
                        fList.push({IsExpended: false, User: fEmail});
                    });

                    this.listOfAllUsers = fList;
                    
                    Swal.close();
                });
            },
            resendEmailRequestJoin(row: UserRow){
                appFxs.showAjaxLoader();

                NetServices.requestPost('managt/settings/resendemailforjoin', {
                    receiverUserEmail: row.User.Email,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }
                   
                    
                    Swal.close();
                });
            },
            showAddUserAction(type: number, oRow: UserRow|any){
                if(type===0){
                    const fNew = {...emptyUser};
                    this.dialogRecordData = (fNew as ManagtUser);
                    this.dialogRecordStatus = 0;
                }else{
                    this.dialogRecordData = (oRow! as UserRow).User;
                    this.dialogRecordStatus = 1;
                }
                
                this.isDialogOpen = true;
            },
            onCloseForm(evt: any){
                this.isDialogOpen = false;

                if(evt.returnType===0)return;

                const returnData = appFxs.getProxyData(evt.returnData);

                appFxs.showAjaxLoader();

                const editType = (returnData.UserId === 0) ? 0 : 1;

                NetServices.requestPost('managt/settings/submituserforrequest', {
                    userSettRecord: returnData,
                    editType: editType
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: UserRow[] = [];
                    oRes.listOfUsers!.forEach(oUser => {
                        const fEmail = {...oUser, DOB: new Date(oUser.DOB)}
                        fList.push({IsExpended: false, User: fEmail});
                    });

                    this.listOfAllUsers = fList;
                    
                    Swal.close();
                });
            },

            
            printListItems(){
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = language.list_users;
                dPrint.ReportToPrint = 0;

                this.printInitialValues = dPrint;


                this.isPrintvisible = true;
            },
            onPrintClose(evt: any){                
                this.isPrintvisible = false;

                if(evt.returnType===0)return;
            }
        },
        computed: {
            userEmail():string{
                return (this.connectedUser) ? (this.connectedUser as ManagtUser).Email: '';
            },
            filteredItems(): UserRow[] {
                let searchKey = this.searchValue;

                if(searchKey.length === 0) return this.listOfAllUsers;
                searchKey = searchKey.toLowerCase();
                
                const filterd = this.listOfAllUsers.filter(oDev => {
                    return oDev.User.LastName.toLowerCase().includes(searchKey) || 
                        oDev.User.FirstName.toLowerCase().includes(searchKey) || 
                        oDev.User.Email.toLowerCase().includes(searchKey) || 
                        oDev.User.JobTitle.toLowerCase().includes(searchKey) || 
                        oDev.User.DisplayName.toLowerCase().includes(searchKey);
                });
                return filterd;
            },
        },
        mounted(){
            document.title = `${this.language.settings}/${this.language.users} - ${this.language.appName}`;
            this.localLang = appFxs.getLocalLanguage();

            const userRecord = appFxs.getLocalStorageByKey(appDeclarations.connecteUserRecord, '{}');
            const userJsonRecord = JSON.parse(userRecord, (k,v)=> {
                if(k === 'DOB'){
                    return new Date(v);
                }
                return v;
            });
            this.connectedUser = userJsonRecord as ManagtUser;
            const companyRecord = appFxs.getLocalStorageByKey(appDeclarations.connectedCompany, '{}');
            const companyJsonRecord = JSON.parse(companyRecord, (k,v)=> {
                if(k === 'DateCreated' || k === 'DateExpiryLicense'){
                    return new Date(v);
                }
                return v;
            });
            this.connectedDomain = companyJsonRecord as Company;

            NetServices.requestGet('managt/settings/getlistallusers')
                .then(oRes => {
                    if(!oRes.bReturn){

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: UserRow[] = [];
                    oRes.listOfUsers!.forEach(oUser => {
                        const fEmail = {...oUser, DOB: new Date(oUser.DOB)}
                        fList.push({IsExpended: false, User: fEmail});
                    });

                    this.listOfAllUsers = fList;
                    

                    this.isStillLoading = false;
                });

        }
    })
