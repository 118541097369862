import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal fade show",
  style: {"display":"block"}
}
const _hoisted_2 = { class: "modal-dialog wise-modal" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { for: "SubCode" }
const _hoisted_9 = ["placeholder", "disabled"]
const _hoisted_10 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_11 = { class: "modal-footer" }
const _hoisted_12 = { class: "d-flex justify-content-end" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.windowTitle), 1),
              (!_ctx.isSavingRecord)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args))),
                    type: "button",
                    class: "btn-close"
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-12 form-group", { 'has-error': _ctx.validationRules.fields.VariantCode.$error }])
                }, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.language.variant), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "SubCode",
                    class: "capletter form-control",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataRecord.VariantCode) = $event)),
                    placeholder: '(' + _ctx.language.variant.toLowerCase() + ')',
                    maxlength: "10",
                    autocomplete: "off",
                    autofocus: "",
                    onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectOnFocus($event))),
                    disabled: _ctx.isSavingRecord || _ctx.dataRecord.IdVariant != 0
                  }, null, 40, _hoisted_9), [
                    [_vModelText, _ctx.dataRecord.VariantCode]
                  ]),
                  (_ctx.validationRules.fields.VariantCode.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.validationRules.fields.VariantCode.$errors[0]), 1))
                    : _createCommentVNode("", true)
                ], 2)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-secondary me-2",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args))),
                  disabled: _ctx.isSavingRecord
                }, _toDisplayString(_ctx.language.close), 9, _hoisted_13),
                (_ctx.dataRecord.IdVariant == 0)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn btn-primary",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.saveRecordData && _ctx.saveRecordData(...args))),
                      disabled: _ctx.validationRules.$invalid || _ctx.isSavingRecord
                    }, [
                      (_ctx.isSavingRecord)
                        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                            key: 0,
                            icon: ['fa', 'fa-spinner']
                          }))
                        : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                            key: 1,
                            icon: ['fa', 'fa-save']
                          })),
                      _createTextVNode(" " + _toDisplayString(_ctx.language.save), 1)
                    ], 8, _hoisted_14))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}