
    import { defineComponent } from "vue";
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';

    import Swal from "sweetalert2";
    import { ProdProduct, ProductVariantDetail } from "@/models/producttypes";
    import { Customer, ManagtUser, TaxSett } from "@/models/managttypes";

    import { ShortData } from "@/tools/smarttypes";
    import { SalesOrder, SalesRefund } from "@/models/salestypes";
    import { DiscountWithRules, SalesRefundInfo } from "@/models/notdb";
    import RefundOperModal from '@/components/modals/RefundOperModal.vue';


    const language =GetLanguage(),
    appFxs = GeneralFx();
    const fNewDate = new Date();
    const newDate = new Date(),
        month = newDate.getMonth() +1,
        year = newDate.getFullYear();
    const emptySalesRefund:SalesRefund = {
        IdRefund: 0,
        RefundKey: '',
        OrderKey: '',
        RefundNbr: '',
        ClientKey: '',
        OperDate: fNewDate,
        UserEmail: '',
        RefundStatus: 0,
        PaytStatus: 0,
        EntryStockStatus: 0,
        DeviseISO: '',
        OfficeCode: '',
        RefundNote: '',
        RefundBarcode: '',
        RefundQRCode: '',
        RefundCreatedBy: '',
        FiscalYear: 0,
        TempoKey: '',
    }
    export default defineComponent({
        components: {RefundOperModal},
    data() {
        return {
            appFxs,
            language,
            isStillLoading: true,
            itemSearchKey: '',
            itemsPerPage: 5,
            
            currCode: 'RWF',
            userEmail: '',

            selectedMonth: month,
            selectedYear: year,
            listOfMonths: [] as ShortData[],

            lstOfSalesRefundsInfo: [] as SalesRefundInfo[],
            itemsPageElts: [] as SalesRefundInfo[],

            listOfDiscountWithRules: [] as DiscountWithRules[],
            listOfTaxSettings: [] as TaxSett[],

            listOfCustomers: [] as Customer[],
            listOfUsers: [] as ManagtUser[],

            listOfProducts: [] as ProdProduct[],
            lstOfProdVariantValues: [] as ProductVariantDetail[],
            clientRecord: null as Customer|any,


            isSalesRefundVis: false,
            salesRefundData: {...emptySalesRefund} as SalesRefund,
            salesRefundStatus: 0,

            oneTempoSalesOrder: null as any,




            isPrintvisible: false,
            printInitialValues: {
                ...appFxs.getReportingData(),
                ReportToPrint: 8,
                ReportTitle: language.list_of_brands
            } as any,
            printExtraData: {},
        }
    },
    computed: {
        selectedMonthLabel(): string {
            return this.listOfMonths[this.selectedMonth - 1].label;
        },
        filteredRecordItems(): SalesRefundInfo[] {
            let searchKey = this.itemSearchKey;

            if (searchKey.length === 0) return this.lstOfSalesRefundsInfo;
            searchKey = searchKey.toLowerCase();

            const filterd = this.lstOfSalesRefundsInfo.filter(oDev => {
                return oDev.SalesRefundRecord.RefundNbr.toLowerCase().includes(searchKey) ||
                    oDev.SalesOrderRecord.OrderNbr.toLowerCase().includes(searchKey) ||
                    oDev.CustomerRecord.ClientName.toLowerCase().includes(searchKey);
            });
            return filterd;
        },
    },
    methods: {
        completeOrder(oOrder: SalesRefund) {
            /* appFxs.showConfirm(language.lock_order, language.lock_order_msg_qst)
                .then(vRes => {
                    if (!vRes.isConfirmed) return;
                    this.completeOrderFinal(oOrder);
                }); */
        },
        completeOrderFinal(oOrder: SalesRefund) {
            /* appFxs.showAjaxLoader();
            NetServices.requestPost(`salesorders/sales/completesalesorder`, {
                salesOperKey: oOrder.OrderKey
            })
                .then(oRes => {
                    if (!oRes.bReturn) {
                        Swal.close();
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: SalesOrderInfo[] = [];
                    oRes.lstOfSalesOrdersInfo!.forEach(oSalesOrder => {
                        const fOrderTempo = { ...oSalesOrder } as SalesOrderInfo;
                        const fRealOrder = { ...fOrderTempo.SalesOrderRecord, OperDate: new Date(fOrderTempo.SalesOrderRecord.OperDate) } as SalesOrder;
                        fOrderTempo.SalesOrderRecord = fRealOrder;
                        fList.push(fOrderTempo);
                    });

                    this.listOfUsers = oRes.listOfUsers!;
                    this.listOfCustomers = oRes.listOfCustomers!;
                    this.listOfProducts = oRes.listOfProducts!;
                    this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;
                    this.listOfSalesOrdersInfo = fList;

                    this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
                    this.listOfTaxSettings = oRes.listOfTaxSettings!;

                    Swal.close();
                }); */
        },
        isCompleteReady(oOrder: SalesRefundInfo): boolean {
            const orderStatus = oOrder.SalesRefundRecord.RefundStatus;
            const paytStatus = oOrder.PaytStatus;
            const deliveryStatus = oOrder.DeliveryStatus;

            return orderStatus == 1 && paytStatus == 2 && deliveryStatus == 2;
        },
        deleteSalesRefund(oRecord: SalesRefund) {
            appFxs.showConfirm(language.delete, language.delete_quest_rec)
                .then(vRes => {
                    if (vRes.isConfirmed) this.finalDeleteSalesRefund(oRecord);
                });
        },
        finalDeleteSalesRefund(oRecord: SalesRefund) {
            appFxs.showAjaxLoader();
            NetServices.requestPost('salesorders/refund/deleterefundoperationrec', {
              salesRefundKey: oRecord.RefundKey
            })
                .then(oRes => {
                    if (!oRes.bReturn) {
                        Swal.close();
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }


                    const fList: SalesRefundInfo[] = [];
                    oRes.lstOfSalesRefundsInfo!.forEach(oSalesRefund => {
                      const fRefundTempo = { ...oSalesRefund } as SalesRefundInfo;
                      const fRealRefund = {
                        ...fRefundTempo.SalesRefundRecord,
                        OperDate: new Date(fRefundTempo.SalesRefundRecord.OperDate)
                      } as SalesRefund;
                      const fRealOrder = {
                        ...fRefundTempo.SalesOrderRecord,
                        OperDate: new Date(fRefundTempo.SalesOrderRecord.OperDate)
                      } as SalesOrder;

                      fRefundTempo.SalesRefundRecord = fRealRefund;
                      fRefundTempo.SalesOrderRecord = fRealOrder;

                      fList.push(fRefundTempo);
                    });

                    this.listOfUsers = oRes.listOfUsers!;
                    this.listOfCustomers = oRes.listOfCustomers!;
                    this.listOfProducts = oRes.listOfProducts!;
                    this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;
                    this.lstOfSalesRefundsInfo = fList;

                    this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
                    this.listOfTaxSettings = oRes.listOfTaxSettings!;

                    Swal.close();
                }); 
        },


        changeMonth(oMonth: number){
            this.selectedMonth = oMonth;
            this.refreshList();
        },
        onPageChange(evt: any){
                this.itemsPageElts = appFxs.getProxyData(evt);
        },
        refreshList(){
            appFxs.showAjaxLoader();

            NetServices.requestGet(`salesorders/refund/listofsalesrefundsbymonth/${this.selectedMonth}`)
              .then(oRes => {
                if (!oRes.bReturn) {
                  appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                    .then(xRes => {
                      Swal.close();
                      appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                    });
                  return;
                }

                const fList: SalesRefundInfo[] = [];
                oRes.lstOfSalesRefundsInfo!.forEach(oSalesRefund => {
                  const fRefundTempo = { ...oSalesRefund } as SalesRefundInfo;
                  const fRealRefund = {
                    ...fRefundTempo.SalesRefundRecord,
                    OperDate: new Date(fRefundTempo.SalesRefundRecord.OperDate)
                  } as SalesRefund;
                  const fRealOrder = {
                    ...fRefundTempo.SalesOrderRecord,
                    OperDate: new Date(fRefundTempo.SalesOrderRecord.OperDate)
                  } as SalesOrder;

                  fRefundTempo.SalesRefundRecord = fRealRefund;
                  fRefundTempo.SalesOrderRecord = fRealOrder;

                  fList.push(fRefundTempo);
                });

                this.listOfUsers = oRes.listOfUsers!;
                this.listOfCustomers = oRes.listOfCustomers!;
                this.listOfProducts = oRes.listOfProducts!;
                this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;
                this.lstOfSalesRefundsInfo = fList;

                this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
                this.listOfTaxSettings = oRes.listOfTaxSettings!;

                Swal.close();
              });

          },
        
        addSalesRefundRecord(iStatus: number, oRefund: any) {
            if(iStatus==0){
                appFxs.showInputAlert(language.type_order_nbr)
                .then(vRes => {
                    if(!vRes.isConfirmed) return;
                    if(!vRes.value)return;
                    this.checkIfSalesOrderExist(vRes.value);
                });
            }else{
              this.addSalesRefundRecordFinal(1, oRefund);
            }
        },
        checkIfSalesOrderExist(orderNbr: string){
            appFxs.showAjaxLoader();
            const codedNbr = window.btoa(orderNbr);
            NetServices.requestGet(`salesorders/refund/getorderstatusandrecordbynbr/${codedNbr}`)
                .then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    this.oneTempoSalesOrder = oRes.salesOrderElement!;
                    Swal.close();

                    this.addSalesRefundRecordFinal(0, null);
                });
        },
        addSalesRefundRecordFinal(iStatus: number, oRefund: any) {
            if (iStatus == 0) {
                const iTempo = this.oneTempoSalesOrder as SalesOrder;
                const fRecord = {
                    ...emptySalesRefund,
                    UserEmail: this.userEmail,
                    DeviseISO: this.currCode,
                    OfficeCode: 'HQ',
                    RefundBarcode: appFxs.getRandomDigits(12),
                    RefundQRCode: appFxs.getRandomDigits(15),
                    RefundCreatedBy: this.userEmail,
                    FiscalYear: this.selectedYear,
                    ClientKey: iTempo.ClientKey,
                    TempoKey: appFxs.getRandomDigits(35),
                    OrderKey: iTempo.OrderKey,
                } as SalesRefund;

                this.salesRefundData = fRecord;
            } else {
                this.salesRefundData = { ...oRefund } as SalesRefund;
            }

            this.isSalesRefundVis = true;
        },
        onDetailClose(evt: any) {
            if (evt.returnType == 0) {
                this.isSalesRefundVis = false;
                return;
            }

            this.isSalesRefundVis = false;

            const finalRet = appFxs.getProxyData(evt.returnData) as SalesRefund;
            finalRet.RefundKey = appFxs.generateUUID();
            const newClient = this.listOfCustomers.find(o => o.ClientKey == finalRet.ClientKey);

            if (!newClient) {
                appFxs.showAlert(language.miss_client, language.miss_client_msg)
                return;
            }


            appFxs.setLocalStorage(appDeclarations.tempoCustomerRecord, JSON.stringify(newClient));

            appFxs.setLocalStorage(appDeclarations.tempoSalesRefund, JSON.stringify(finalRet));
            appFxs.setLocalStorage(appDeclarations.tempoSalesRecord, JSON.stringify(this.oneTempoSalesOrder));

            appFxs.setLocalStorage(appDeclarations.tempoLstProducts, JSON.stringify(this.listOfProducts));
            appFxs.setLocalStorage(appDeclarations.tempoLstVariants, JSON.stringify(this.lstOfProdVariantValues));

            appFxs.setLocalStorage(appDeclarations.tempoLstTaxes, JSON.stringify(this.listOfTaxSettings));
            appFxs.setLocalStorage(appDeclarations.tempoLstDiscountsWRules, JSON.stringify(this.listOfDiscountWithRules));

            appFxs.setLocalStorage(appDeclarations.salesNewOperationState, '1');

            router.push({
                name: 'appsalesrefundnew',
            });
        },
        confirmSalesRefund(oRecord: SalesRefund) {
            appFxs.showConfirm(language.confirm, language.confirm_refund_msg)
                .then(vRes => {

                    if (vRes.isConfirmed) this.confirmSalesOrderFinal(oRecord);
                });
        },
        confirmSalesOrderFinal(oRecord: SalesRefund) {
            appFxs.showAjaxLoader();
            NetServices.requestPost('salesorders/refund/confirmsalesrefund',
                {
                  salesRefundKey: oRecord.RefundKey
                })
                .then(oRes => {
                    if (!oRes.bReturn) {
                        Swal.close();
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: SalesRefundInfo[] = [];
                    oRes.lstOfSalesRefundsInfo!.forEach(oSalesRefund => {
                      const fRefundTempo = { ...oSalesRefund } as SalesRefundInfo;
                      const fRealRefund = {
                        ...fRefundTempo.SalesRefundRecord,
                        OperDate: new Date(fRefundTempo.SalesRefundRecord.OperDate)
                      } as SalesRefund;
                      const fRealOrder = {
                        ...fRefundTempo.SalesOrderRecord,
                        OperDate: new Date(fRefundTempo.SalesOrderRecord.OperDate)
                      } as SalesOrder;

                      fRefundTempo.SalesRefundRecord = fRealRefund;
                      fRefundTempo.SalesOrderRecord = fRealOrder;

                      fList.push(fRefundTempo);
                    });

                    this.listOfUsers = oRes.listOfUsers!;
                    this.listOfCustomers = oRes.listOfCustomers!;
                    this.listOfProducts = oRes.listOfProducts!;
                    this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;
                    this.lstOfSalesRefundsInfo = fList;

                    this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
                    this.listOfTaxSettings = oRes.listOfTaxSettings!;

                    Swal.close();
                });
        },

        
        getOrderStatus(iStatus: number): string {
            return iStatus == 0 ? language.draft :
                iStatus == 1 ? language.confirmed :
                    language.completed;
        },
        getPaytStatus(iStatus: number): string {
            return iStatus == 0 ? language.not_paid :
                iStatus == 1 ? language.part_paid :
                    language.fully_paid;
        },
        getDeliveryStatus(iStatus: number): string {
            return iStatus == 0 ? language.not_transfered :
                iStatus == 1 ? language.part_transfered :
                    language.full_transfered;
        },






        printListItems() {
            const dPrint = appFxs.getReportingData();
            dPrint.ReportTitle = language.list_of_refund;
            dPrint.ReportToPrint = 27;

            this.printInitialValues = dPrint;

            this.printExtraData = {
              CurrentYear: this.selectedYear,
              CurrentMonth: this.selectedMonth,
            }

            this.isPrintvisible = true;
        },
        printOneRefundNote(oRecord: SalesRefundInfo){
          const dPrint = appFxs.getReportingData();
            dPrint.ReportTitle = `${language.refund_note} - ${oRecord.SalesRefundRecord.RefundNbr}`;
            dPrint.ReportToPrint = 28;

            this.printInitialValues = dPrint;

            this.printExtraData = {
              SalesRefundKey: oRecord.SalesRefundRecord.RefundKey,
            }

            this.isPrintvisible = true;
        },
        onPrintClose(evt: any) {
            this.isPrintvisible = false;
            if (evt.returnType === 0) return;
        },
    },
    mounted(){
        document.title = this.language.list_of_refund + ' - ' + this.language.appName;
        this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
        this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
        this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));
        this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');

        this.listOfMonths = appFxs.getListMonthsByYear(this.selectedYear);

        NetServices.requestGet(`salesorders/refund/listofsalesrefundsbymonth/${this.selectedMonth}`)
        .then(oRes => {
          if (!oRes.bReturn) {
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
              .then(xRes => {
                router.replace({ path: '/app/myprofile', replace: true });
              });
            return;
          }

          const fList: SalesRefundInfo[] = [];
          oRes.lstOfSalesRefundsInfo!.forEach(oSalesRefund => {            
            const fRefundTempo = { ...oSalesRefund } as SalesRefundInfo;
            const fRealRefund = {
                ...fRefundTempo.SalesRefundRecord, 
                OperDate: new Date(fRefundTempo.SalesRefundRecord.OperDate) 
            } as SalesRefund;
            const fRealOrder = {
                ...fRefundTempo.SalesOrderRecord,
                OperDate: new Date(fRefundTempo.SalesOrderRecord.OperDate) 
            } as SalesOrder;

            fRefundTempo.SalesRefundRecord = fRealRefund;
            fRefundTempo.SalesOrderRecord = fRealOrder;

            fList.push(fRefundTempo);
          });


          this.listOfUsers = oRes.listOfUsers!;
          this.listOfCustomers = oRes.listOfCustomers!;
          this.listOfProducts = oRes.listOfProducts!;
          this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;
          this.lstOfSalesRefundsInfo = fList;

          this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
          this.listOfTaxSettings = oRes.listOfTaxSettings!;

          this.isStillLoading = false;
        });
    }
})
