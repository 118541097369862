
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent, toRaw } from 'vue';
    import { AppYearBook } from '@/models/managttypes';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    
    const emptyYearBook: AppYearBook = {
        YearBookId: 0,
        YearWork: new Date().getFullYear() + 1,
        StartMonth: 1,
        EndMonth: 12,
        MonthsLength: 12,
        Locked: false
    };
    
    export default defineComponent({
        name: 'YearBookSettModal',
        emits: ['closeForm'],
        props: ["visible", "initialValues", "recordStatus"],
        data(){
            return{
                v$: useVuelidate(),
                language,
                dataRecord: {...emptyYearBook},
                lstYears: [] as number[],
            }
        },
        methods: {
            getListYears(): number[]{
                const newDate =  new Date();
                const yearStart = newDate.getFullYear() - 5,
                yearEnd = newDate.getFullYear() + 5;
                const lstYears = Array(yearEnd - yearStart + 1)
                .fill('').map((_, idx) => yearStart + idx);

                return lstYears;
            },
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.$emit('closeForm', {returnType: 1, returnData: this.dataRecord});
            }
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: AppYearBook = {...oValues};

                        if(this.recordStatus == 0){
                            this.v$.$reset();
                        }

                        this.dataRecord = fValues;
                    }
                }
            }
        },
        computed: {        
            windowTitle():string {
                return this.recordStatus === 0 ? language.add_year_book:
                    language.edit_year_book;
            }
        },
        mounted(){
            this.lstYears = this.getListYears();
        }
    })
