import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container login-area" }
const _hoisted_2 = { class: "row vh-100 align-items-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResetWaitForm = _resolveComponent("ResetWaitForm")!
  const _component_AccountImageArea = _resolveComponent("AccountImageArea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ResetWaitForm),
      _createVNode(_component_AccountImageArea)
    ])
  ]))
}