
    import { useVuelidate } from '@vuelidate/core';
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators';

    import { defineComponent } from 'vue';
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import NetServices from '@/tools/netservices';
    import { ProdSubCategory } from '@/models/producttypes';
    import Swal from 'sweetalert2';

    const language = GetLanguage(),
        appFxs = GeneralFx();
    
    const emptySubCategory: ProdSubCategory = {
        ProdCategoryId: 0,
        Code: '',
        SubCode: '',
        NameEn: '',
        NameFr: '',
        NameRw: '',
        Description: ''
    };

    export default defineComponent({
        name: 'LisOfSubCategoriesComp',
        props: {
            categoryCode: {
                type: String,
                required: true,
            }
        },
        data(){
            return {
                f$: useVuelidate(),
                language,
                appFxs,
                id: Math.random().toString(36).substring(7),
                categoryVal: this.categoryCode,
                decodeCAtegVal: window.atob(this.categoryCode),
                isLoading: true,
                lstSubCategories: [] as ProdSubCategory[],
                
                isLoadingError: false,
                loadingErrorMsg: '',

                
                operationStatus: 0,
                recordStatus: 0,
                selectedRecord: {...emptySubCategory, Code: window.atob(this.categoryCode)} as ProdSubCategory,
                isSavingRecord: false,
            }
        },
        computed: {
            editorTitle(): string{
                return this.recordStatus == 0 ? language.new_sub_categ : `${language.edit}: ${this.selectedRecord.Code}`;
            }
        },
        methods: {            
            getCategoryName(oCateg: ProdSubCategory) {
                const oLang = appFxs.getLocalLanguage();
                return oLang == 'en' ? oCateg.NameEn :
                    oLang == 'fr' ? oCateg.NameFr :
                        oLang == 'rw' ? oCateg.NameRw :
                            oCateg.NameEn;
            },
            populateFirstReords(){
                NetServices.requestGet(`products/categs/listofsubcategories/${this.categoryVal}`)
                    .then(oRes => {
                        if(oRes.bReturn){
                            this.isLoadingError = true;
                            this.loadingErrorMsg = oRes.msgBody;
                            this.isLoading = false;
                            return;
                        }

                        const fList: ProdSubCategory[] = [];
                        oRes.listOfProdSubCategories!.forEach(oEmail => {
                            const fEmail = { ...oEmail }
                            fList.push(fEmail);
                        });

                        this.lstSubCategories = fList;
                    });
            },
            reloadListSubCategs(){
                this.isLoading = true;
            },            
            selectOnFocus(evt: Event) {
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            goBackToList(){
                this.recordStatus = 0;
                this.selectedRecord = {...emptySubCategory, Code: this.decodeCAtegVal};
                this.operationStatus = 0;
            },
            gotoEditRecord(iStatus: number, oRecord: ProdSubCategory|any){
                this.recordStatus = iStatus;

                if(iStatus==0){
                    this.selectedRecord = {...emptySubCategory, Code: this.decodeCAtegVal};                    
                }else{
                    this.selectedRecord = oRecord as ProdSubCategory;
                }                
                this.operationStatus = 1;
            },
            saveSubCategory(){
                this.f$.$validate();
                if(this.f$.$invalid){
                    appFxs.showAlert(language.error, language.form_error_invalid);
                    return;
                }

                const oRecord = this.selectedRecord;
                const fRecord = {...oRecord, SubCode: oRecord.SubCode.toUpperCase()} as ProdSubCategory;
                
                this.saveOperation(fRecord, false);
            },
            deleteRecord(oRecord: ProdSubCategory){
                appFxs.showConfirm(language.delete, language.delete_quest_rec)
                    .then(oRes => {
                        if(!oRes.isConfirmed)return;
                        this.saveOperation(oRecord, true);
                    })
            },
            saveOperation(oRecord: ProdSubCategory, bDel: boolean){
                appFxs.showAjaxLoader();
                if(!bDel){
                    this.isSavingRecord = true;
                }
                NetServices.requestPost('products/categs/savesubcategoryrecord', {
                    prodSubCategoryRecord: oRecord,
                    toDelete: bDel,
                })
                    .then(oRes => {
                        if(!oRes.bReturn){
                            Swal.close();
                            if (!bDel) {
                                this.isSavingRecord = false;
                            }
                            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                            return;
                        }

                        const fList: ProdSubCategory[] = [];
                        oRes.listOfProdSubCategories!.forEach(oEmail => {
                            const fEmail = { ...oEmail }
                            fList.push(fEmail);
                        });

                        this.lstSubCategories = fList;

                        if(!bDel){
                            this.isSavingRecord = false;
                            this.resetToStart();
                        }

                        Swal.close();                        
                    });
            },
            resetToStart(){
                this.f$.$reset();
                this.operationStatus = 0;
                this.recordStatus = 0;

                this.selectedRecord = {
                    ...emptySubCategory, 
                    Code: window.atob(this.categoryCode)
                } as ProdSubCategory;
                this.isSavingRecord = false;
            }
        },
        mounted(){
            //this.tags = this.initialTags as string[];
            this.decodeCAtegVal = window.atob(this.categoryCode),
            this.populateFirstReords();
            appFxs.updateLoadedScripts();
        },
        watch: {
            categoryCode: {
                handler(newV, oldV){
                    if(newV != oldV){
                        this.categoryVal = newV;
                        this.decodeCAtegVal = window.atob(newV),
                        this.populateFirstReords();
                    }                    
                }
            }
        },
        validations() {
            return {
                selectedRecord: {
                    Code: {
                      required: helpers.withMessage(language.fldRequired, required),
                      $autoDirty: true,
                    },
                    SubCode: {
                      required: helpers.withMessage(language.fldRequired, required),
                      $autoDirty: true,
                    },
                    NameEn: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    NameFr: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    NameRw: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                }
            }
        }
    })
