
    import GetLanguage from '@/tools/language';
    import AccountHeader from '@/components/general/ByihuseAccountHeader.vue';
  
    export default {
      components: {
        AccountHeader,
      },
      data(){
        return {
          language: GetLanguage(),
        }
      }
    }
  