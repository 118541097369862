
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { ProductVariant } from '@/models/producttypes';
    import NetServices from '@/tools/netservices';
    import { defineComponent } from 'vue';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    const emptyVariant: ProductVariant = {
        IdVariant: 0,
        ProductSKU: '',
        VariantCode: '',
    }
    export default defineComponent({
        name: 'ProdVariantModal',
        emits: ['closeForm'],
        props: ["visible", "initialValues", "recordStatus"],
        data(){
            return {
                language,
                appFxs,
                dataRecord: {...emptyVariant} as ProductVariant,
                isSavingRecord: false,
                
            }
        },
        computed: {
            windowTitle():string {
                return this.recordStatus === 0 ? language.new_variant:
                language.edit_variant;
            },
            validationRules(){
                const ProductSKUValid = this.dataRecord.ProductSKU.length > 0;
                const ProductSKUMsg = [] as string[];
                if(!ProductSKUValid) ProductSKUMsg.push(language.fldRequired);
                
                const VariantCodeValid = this.dataRecord.VariantCode.length > 0;
                const VariantCodeMsg = [] as string[];
                if(!VariantCodeValid) VariantCodeMsg.push(language.fldRequired);

                const formInvalid = !ProductSKUValid || !VariantCodeValid;

                return {
                    $invalid: formInvalid,
                    fields: {
                        ProductSKU: {
                            $error: !ProductSKUValid,
                            $errors: VariantCodeValid
                        },
                        VariantCode: {
                            $error: !VariantCodeValid,
                            $errors: VariantCodeMsg
                        },
                    }
                }
            },
        },
        methods: {
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.isSavingRecord = true;
                NetServices.requestPost(`products/variants/savevariantoperrecord`, {
                    variantDataRecord: this.dataRecord,
                    toDelete: false,
                    populateData: true,
                })
                .then(oRes => {
                    if(!oRes.bReturn){
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        this.isSavingRecord = false;
                        return;
                    }

                    this.$emit('closeForm', {returnType: 1, returnData: {
                        bReturn: true,
                        listOfItems: oRes.lstOfProdVariants!,
                    }});
                    this.isSavingRecord = false;
                });

            },
            
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: ProductVariant = {...oValues};


                        this.dataRecord = fValues as ProductVariant;
                    }
                }
            }
        },
    })
