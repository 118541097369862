
    import { defineComponent } from "vue";
    import router from '@/router/index';
  
    import NetServices from '@/tools/netservices';
  
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import DataFxs from '@/tools/data_functions';
  
    import Swal from "sweetalert2";
    import { ProdProduct, ProductVariantDetail } from "@/models/producttypes";
    import { Customer, TaxSett } from "@/models/managttypes";
  
    import { SalesOrder, SalesRefund, SalesRefundDetail, SalesRefundPayment } from "@/models/salestypes";
    import { SalesRefundDetailPrep, DiscountWithRules, SalesRefundInfo, SalesWithRefundInfo } from "@/models/notdb";
  
  
    import RefunInputData from '@/components/smalls/RefundInputData.vue';
    import RefundOperPaytModal from '@/components/modals/RefundOperPaytModal.vue';

  
    const language =GetLanguage(),
      appFxs = GeneralFx(),
      dataFxs = DataFxs();
    const fNewDate = new Date(),
      year = fNewDate.getFullYear();
  
    const emptySalesRefund:SalesRefund = {
        IdRefund: 0,
        RefundKey: '',
        OrderKey: '',
        RefundNbr: '',
        ClientKey: '',
        OperDate: fNewDate,
        UserEmail: '',
        RefundStatus: 0,
        PaytStatus: 0,
        EntryStockStatus: 0,
        DeviseISO: '',
        OfficeCode: '',
        RefundNote: '',
        RefundBarcode: '',
        RefundQRCode: '',
        RefundCreatedBy: '',
        FiscalYear: 0,
        TempoKey: '',
    };
    const emptyRefundDetail: SalesRefundDetail = {
        IdRefundDet: 0,
        RefundKey: '',
        RefundDetailKey: '',
        ProductSKU: '',
        ProductOrient: 0,
        VariantValues: '',
        Quantity: 1,
        UnitPrice: 1,
        SubTotal: 1,
        DiscountAmount: 0,
        TaxableAmount: 0,
        TaxKey: '',
        TaxRate: 0,
        TaxValue: 0,
        TotalAmt: 0,
        FiscalYear: year,
        TempoKey: '',
        DiscountKeys: '',
        OrderDetKey: '',
        OrderKey: '',
    };
    const emptyRefundPayt: SalesRefundPayment = {
        IdRefundPayt: 0,
        RefundKey : '',
        RefundPaytKey: '',
        ReceiptNbr: '',
        AmtPaid: 0,
        CashierEmail: '',
        PaytType: 0,
        PaytDate: new Date(),
        PaytBarcode: '',
        PaytQRCode: '',
    } 
    const emptySalesWithRefDet: SalesWithRefundInfo = {
      orderNbr: '',
      customerName: '',
      staffName: '',
      orderedQty: 0,
      orderedAmtValue: 0,
      refundedAmtValue: 0,
      refundedQty: 0,
      remainingAmtValue: 0,
      remainingQty: 0,
    }
    export default defineComponent({
      name: 'SalesNewOps',
      components: {
        RefunInputData,
        RefundOperPaytModal,
      },
      data(){
        return {
          appFxs,
          language,        
          isStillLoading: true,
          itemSearchKey: '',
          itemsPerPage: 5,
          selectedYear: year,
          currCode: 'RWF',
          userEmail: '',
  
          currentCustomer: null as Customer|any,
          currentRefundOper: {...emptySalesRefund} as SalesRefund,
          currentSalesOper: null as SalesOrder | any,

          orderWithRefInfo: {...emptySalesWithRefDet} as SalesWithRefundInfo,



          lstSalesRefundDataForPrep: [] as SalesRefundDetailPrep[],
          itemsPageElts: [] as SalesRefundDetailPrep[],

  
          isSavingRecord: false,
  
          listOfProducts: [] as ProdProduct[],
          lstOfProdVariantValues: [] as ProductVariantDetail[],
          listOfDiscountWithRules: [] as DiscountWithRules[],
          listOfTaxSettings: [] as TaxSett[],
  
          savedSalesOperKey: '',
          salesRefundInfo: null as SalesRefundInfo|any,
  
          isPrintvisible: false,
          printInitialValues: {
            ...appFxs.getReportingData(),
            ReportToPrint: 8,
            ReportTitle: language.list_of_brands
          } as any,
          printExtraData: {},
  
  
          isSalesDetailVis: false,
          salesDetInitVals: {...emptyRefundDetail} as SalesRefundDetail,
          salesDetRecStatus: 0,
  
          isSalesPaytVis: false,
          salesPaytInitVals: {...emptyRefundPayt} as SalesRefundPayment,
          salesPaytRecStatus: 0,
          salesRemAmt: 0,
        }
      },
      computed: {      
        filteredRecordItems(): SalesRefundDetailPrep[] {
          let searchKey = this.itemSearchKey;
  
          if (searchKey.length === 0) return this.lstSalesRefundDataForPrep;
          searchKey = searchKey.toLowerCase();
  
          const filterd = this.lstSalesRefundDataForPrep.filter(oDev => {
            return oDev.ProductSKU.toLowerCase().includes(searchKey);
          });
          return filterd;
        },
        detailListToSave(): SalesRefundDetailPrep[]{
          return this.lstSalesRefundDataForPrep.filter(o => o.RefundNowQty > 0);
        },
        relatedPurchase(): { bReady: boolean, record: SalesRefund } {
          const bOReady = this.currentRefundOper.ClientKey.length > 0;
  
  
          return {
            bReady: bOReady,
            record: this.currentRefundOper,
          }
        },
        totalSalesRef():SalesRefundDetailPrep{
          const lst = [...this.lstSalesRefundDataForPrep];

          const firstElt = lst[0];

          const oQty = lst.reduce((acct, o) => {
            return acct + o.Quantity;
          }, 0);
          const txPerUnit = firstElt == undefined ? 0:  firstElt.TaxValuePerUnit;
          const qtyRef = lst.reduce((acct, o) => {
            return acct + o.QuantityRefunded;
          }, 0);
          const remQty = oQty - qtyRef;
          const qtyNowRef = lst.reduce((acct, o) => {
            return acct + o.RefundNowQty;
          }, 0);
          const unitPrice = firstElt == undefined ? 0:  firstElt.UnitPrice;
          const refPrice = firstElt == undefined ? 0:   firstElt.UnitPrice;
          const subTotal =  qtyNowRef * unitPrice;
          const refTax =  qtyNowRef * txPerUnit;
          const totalAmt = lst.reduce((acct, o) => {
            return acct + o.RefundNowTotalAmt;
          }, 0);
          const taxKeys = firstElt == undefined ? '':firstElt.RefundNowTaxKey; 

          return {
            OperationPos: 0,
            OperationKey: '',
            OrderKey: '',
            OrderDetailKey: '',
            ProductSKU: '',
            ProductOrient: 0,
            VariantValues: '',

            DiscountAmount: 0,
            SubTotal: 0,
            TaxableAmount: 0,
            TaxValue: 0,
            TotalAmt: 0,
            

            Quantity: oQty,
            UnitPrice: unitPrice,
            TaxValuePerUnit: txPerUnit,
            QuantityRefunded: qtyRef,
            RemainingQuantity: remQty,
            RefundNowQty: qtyNowRef,
            RefundNowPrice: refPrice,
            RefundNowSubTotal: subTotal,
            RefundNowTaxUnit: txPerUnit,
            RefundNowTaxAmt: refTax,
            RefundNowTotalAmt: totalAmt,
            RefundNowTaxKey: taxKeys,


          };
        }
      },
      methods: {
        onRefundUpdate(evt: any) {
          const fData = evt as SalesRefundDetailPrep;
          console.log('fData', fData);
          const lst = this.lstSalesRefundDataForPrep;
          const fRemains = lst.filter(o => o.OperationKey != fData.OperationKey);
          fRemains.push(fData);

          fRemains.sort((a, b) => {
            return a.OperationPos - b.OperationPos
          })

          this.lstSalesRefundDataForPrep = fRemains;
        },
        saveRecordData(){
          appFxs.showConfirm(language.save, language.save_sales_ref_oper_qst_msg)
          .then(vRes => {
            if(vRes.isConfirmed){
              this.saveRecordDataFinal();
            }
          });
        },
        saveRecordDataFinal(){
          const oRefRec  = appFxs.getProxyData(this.currentRefundOper) as SalesRefund;
          const oDetails  = appFxs.getProxyData(this.detailListToSave) as SalesRefundDetailPrep[];

          console.log('oRefRec', oRefRec);
          console.log('oDetails', oDetails);

          appFxs.showAjaxLoader();


          NetServices.requestPost('salesorders/refund/saverefundoperationrec',{
            salesRefundRecord: oRefRec,
            salesRefundDetailPrep: oDetails,
          }).then(oRes => {
            if(!oRes.bReturn){
              Swal.close();
              appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
              return;
            }
            const currentOrderKey = oRes.currentOrderKey!;
            this.savedSalesOperKey = currentOrderKey;
  
            Swal.close();
  
            if(oRes.typeReturn == 1){
              router.replace({path: '/app/sales/refund/list', replace: true});
              return;
            }
            
            this.salesRefundInfo = oRes.salesRefundInfo!;
            console.log('oRes', oRes);
            appFxs.showConfirm(language.payment, language.sales_refund_process_payt_qst)
              .then(vRes => {
                if(vRes.isConfirmed){
                  this.processPaymentForSales();
                }else{
                  this.processDeliveryForSales();
                }
              });
          });
        },
        processPaymentForSales(){
          const oSalesOrder = this.salesRefundInfo as SalesRefundInfo;
          const fValues = {
            ...emptyRefundPayt,
            RefundKey: oSalesOrder.SalesRefundRecord.RefundKey,
            IdRefundPayt: 0,
            RefundPaytKey: appFxs.generateUUID(),
            OrderKey: oSalesOrder.SalesRefundRecord.OrderKey,
            ReceiptNbr: ('RF-RCPT' + appFxs.generateTextNbr(7)).toUpperCase(),
            AmtPaid: oSalesOrder.RemainingToPay,
            CashierEmail: this.userEmail,
            PaytType: 0,
            PaytDate: oSalesOrder.SalesRefundRecord.OperDate,
            PaytBarcode:appFxs.getRandomDigits(12),
            PaytQRCode:appFxs.getRandomDigits(15),
          } as SalesRefundPayment;
          this.salesPaytInitVals = fValues;
          this.salesPaytRecStatus = 0;
          this.salesRemAmt = oSalesOrder.RemainingToPay;
          this.isSalesPaytVis = true;
        },
        onClosePaytModal(evt: any){
          if (evt.returnType == 0) {
            this.isSalesPaytVis = false;
            router.push({
              name: 'appsalesrefundlist',
            });
            return;
          }
  
          this.isSalesPaytVis = false;
  
          this.processDeliveryForSales();
        },
        processDeliveryForSales(){
          appFxs.showConfirm(language.delivery, language.sales_refund_process_delivery_qst)
              .then(vRes => {
                if(vRes.isConfirmed){
                  this.processDeliveryForSalesFinal();
                }else{
                  router.push({
                    name: 'appsalesrefundlist',
                  });
                }
              });
        },
        processDeliveryForSalesFinal(){
          const oSalesOrder = this.salesRefundInfo as SalesRefundInfo;

          appFxs.showAjaxLoader();
          NetServices.requestPost('salesorders/refund/stockentries/savedirectdeliveryentryoper', {
            refundOperKey: oSalesOrder.SalesRefundRecord.RefundKey
          })
          .then(oRes => {
            if(!oRes.bReturn){
              Swal.close();
              appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
              .then(vRes => {
                router.push({
                  name: 'appsalesrefundlist',
                });
              })
              return;
            }
  
            Swal.close();
            router.push({
              name: 'appsalesrefundlist',
            });
          });
        },
  
  
  
  
  
        getProductName(oDetail: SalesRefundDetailPrep): string{
          const prodType = oDetail.ProductOrient;
          const lstProds = this.listOfProducts;
          const lstVars = this.lstOfProdVariantValues;
          
          const oneProduct = lstProds.find(o => o.SKU == oDetail.ProductSKU);
  
          if(!oneProduct) {
            return '';
          }
  
          if(prodType==0) return oneProduct.Name;
  
          const oVar = lstVars.find(o => o.VariantValues == oDetail.VariantValues);
          if(!oVar)return oneProduct.Name;
  
          return `${oneProduct.Name} (${oVar.VariantValues})`;
        },
        onPageChange(evt: any){
              this.itemsPageElts = appFxs.getProxyData(evt);
        },
        
        
        printListItems(){
          const dPrint = appFxs.getReportingData();
          dPrint.ReportTitle = language.list_of_brands;
          dPrint.ReportToPrint = 12;
  
          this.printInitialValues = dPrint;
  
          this.isPrintvisible = true;
        },      
        onPrintClose(evt: any) {
          this.isPrintvisible = false;
          if (evt.returnType === 0) return;
        },
      },
      mounted(){   
        document.title = this.language.new_ref_operation + ' - ' + this.language.appName;
        this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
        this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
        this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
        this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));
  
        const operState = appFxs.getLocalStorageByKey(appDeclarations.salesNewOperationState, '0');
  
        if(operState == '0'){
          appFxs.showAlert(language.missing_settings, language.missing_settings_msg)
          .then(vRes => {
            router.push({
              name: 'appsalesrefundlist',
            });
          });
          return;
        }
  
        const tempoSalesRefund = JSON.parse(appFxs.getLocalStorageByKey(appDeclarations.tempoSalesRefund, '{}'));
        const tempoSalesRecord = JSON.parse(appFxs.getLocalStorageByKey(appDeclarations.tempoSalesRecord, '{}'));
        const tempoLstProducts = appFxs.getLocalStorageByKey(appDeclarations.tempoLstProducts, '[]');
        const tempoLstVariants = appFxs.getLocalStorageByKey(appDeclarations.tempoLstVariants, '[]');
        const tempoCustomerRec = appFxs.getLocalStorageByKey(appDeclarations.tempoCustomerRecord, '{}');
  
        
        const tempoLstTaxes = appFxs.getLocalStorageByKey(appDeclarations.tempoLstTaxes, '[]');
        const tempoLstDiscountsWRules = appFxs.getLocalStorageByKey(appDeclarations.tempoLstDiscountsWRules, '[]');
  
        const tempoSalesRefundF = {...tempoSalesRefund, OperDate: new Date(tempoSalesRefund.OperDate)};
        const tempoSalesRecordF = {...tempoSalesRecord, OperDate: new Date(tempoSalesRecord.OperDate)};
  
        this.currentSalesOper = tempoSalesRecordF as SalesOrder;
        this.currentRefundOper = tempoSalesRefundF as SalesRefund;
        this.listOfProducts = JSON.parse(tempoLstProducts) as ProdProduct[];
        this.lstOfProdVariantValues = JSON.parse(tempoLstVariants) as ProductVariantDetail[];
  
        const salesOrser = tempoSalesRecordF as SalesOrder;
        
        this.listOfTaxSettings = JSON.parse(tempoLstTaxes) as TaxSett[];
        this.listOfDiscountWithRules = JSON.parse(tempoLstDiscountsWRules) as DiscountWithRules[];
  
        this.currentCustomer = JSON.parse(tempoCustomerRec) as Customer;
  
  
  
        appFxs.removeLocalStorageByKey(appDeclarations.tempoSalesRecord);
        appFxs.removeLocalStorageByKey(appDeclarations.tempoLstProducts);
        appFxs.removeLocalStorageByKey(appDeclarations.tempoLstVariants);
        appFxs.removeLocalStorageByKey(appDeclarations.tempoCustomerRecord);
        
        appFxs.setLocalStorage(appDeclarations.salesNewOperationState, '0');


        NetServices.requestGet(`salesorders/refund/getrefunddetailforprep/${salesOrser.OrderKey}`)
        .then(oRes => {
          if(!oRes.bReturn){
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
            .then(vRes => {
              router.push({
                  name: 'appsalesrefundlist',
                });
                this.isStillLoading = false;  
            });

            return;
          }


          this.lstSalesRefundDataForPrep = oRes.lstSalesRefundDataForPrep!;
          this.orderWithRefInfo = oRes.orderWithRefInfo!;
          this.isStillLoading = false;  
        });
        
      }
    })
  