
import { defineComponent } from "vue";
import router from '@/router/index';

import NetServices from '@/tools/netservices';

import GetLanguage from '@/tools/language';
import GeneralFx from '@/tools/general_fx';
import appDeclarations from '@/tools/declarations';

import Swal from "sweetalert2";
import { ProdProduct, ProdStockExit, ProductVariantDetail } from "@/models/producttypes";
import { Customer, ManagtUser } from "@/models/managttypes";

import StockExitModal from '@/components/modals/StockExitModal.vue';

const language = GetLanguage(),
    appFxs = GeneralFx();
const newDate = new Date();
const emptyExitRecord: ProdStockExit = {
    IdStockExit: 0,
    ExitKey: '',
    ProductSku: '',
    ProductVariantKey: '',
    ProductType: 0,
    ExitType: 1,
    OrderKey: '',
    UserEmail: '',
    CustomerKey: '',
    Quantity: 0,
    Price: 0,
    ExitDate: new Date(),
    Description: '',
    FiscalYear: newDate.getFullYear(),
    TotalPrice: 0,
};

export default defineComponent({
    components: {
        StockExitModal
    },
    data(){
        return{             
            appFxs,
            language,
            isStillLoading: true,
            itemSearchKey: '',
            listOfStockExits: [] as ProdStockExit[],
            itemPageElts: [] as ProdStockExit[],
            itemsPerPage: 5,
            userEmail: '',
            fiscalYear: newDate.getFullYear(),

            lstOfProducts: [] as ProdProduct[],
            lstOfVariantDetails: [] as ProductVariantDetail[],
            lstCustomers: [] as Customer[],
            lstOfUsers: [] as ManagtUser[],

            isExitModal: false,
            exitRecord: {...emptyExitRecord} as ProdStockExit,
            recordStatus: 0,


            isPrintvisible: false,
            printInitialValues: {
                ...appFxs.getReportingData(),
                ReportToPrint: 8,
                ReportTitle: language.list_of_brands
            } as any,
            printExtraData: {},

            currCode: 'RWF',
        }
    },
    computed: {
      filteredItems(): ProdStockExit[] {
        let searchKey = this.itemSearchKey;

        if (searchKey.length === 0) return this.listOfStockExits;
        searchKey = searchKey.toLowerCase();

        const filterd = this.listOfStockExits.filter(oDev => {
          return oDev.ExitKey.toLowerCase().includes(searchKey) ||
            oDev.ProductSku.toLowerCase().includes(searchKey) ||
            oDev.ProductVariantKey.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
    },
    methods: {
        getTotalTransact(oRecord: ProdStockExit): number{
            return oRecord.Price * oRecord.Quantity;
        },
        getStaffName(oRecord: ProdStockExit): string{
            const oStaff = this.lstOfUsers.find(oC => oC.Email == oRecord.UserEmail);
            if(oStaff == null) return '';
            return `${oStaff.FirstName} ${oStaff.LastName}`;
        },
        getCustomerName(oRecord: ProdStockExit): string{
            if(oRecord.ExitType !=2) return '';
            const oCustomer = this.lstCustomers.find(oC => oC.ClientKey == oRecord.CustomerKey);
            if(oCustomer == null) return '';
            return oCustomer.ClientName;
        },
        getOperType(oRecord: ProdStockExit): string {
            return oRecord.ExitType == 0 ? language.closing:
                oRecord.ExitType == 1 ? language.manual:
                oRecord.ExitType == 2 ? language.sales:
                language.closing;

        },
        getProdName(oRecord: ProdStockExit): string{
            const oProd = this.lstOfProducts.find(o => o.SKU == oRecord.ProductSku);
            if(oProd==null)return '';
            const typeProd = oRecord.ProductType;
            if(typeProd==0){
                return oProd.Name;
            }

            const varVal = this.lstOfVariantDetails.find(o => o.ProductSku == oProd.SKU && o.VariantValues == oRecord.ProductVariantKey);
            if(varVal==null)return oProd.Name;

            return `${oProd.Name} (${varVal.VariantValues})`;
        },


        showAddOperation(iStatus: number, oRecord: ProdStockExit | any) {
            if(iStatus==0){
                this.exitRecord = {...emptyExitRecord} as ProdStockExit;
                this.recordStatus = 0;
                this.exitRecord.FiscalYear = this.fiscalYear;
                this.exitRecord.UserEmail = this.userEmail;
            }else{
                this.exitRecord = {...oRecord} as ProdStockExit;
                this.recordStatus = 1;
            }

            this.isExitModal = true;
        },
        onDetailClose(evt: any) {
            if (evt.returnType == 0) {
                this.isExitModal = false;
                return;
            }
            if (!evt.returnData.bReturn) {
                this.isExitModal = false;
                return;
            }

            this.listOfStockExits = evt.returnData.listItems as ProdStockExit[];

            this.isExitModal = false;
        },
        onPageChange(evt: any) {
            this.itemPageElts = appFxs.getProxyData(evt);
        },
        refreshList() {
            appFxs.showAjaxLoader();

            NetServices.requestGet('stockops/stockexits/getlistofstexits')
                .then(oRes => {
                    if (!oRes.bReturn) {
                        Swal.close();
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: ProdStockExit[] = [];
                    oRes.listOfStockExits!.forEach(oEmail => {
                        const fEmail = { ...oEmail, ExitDate: new Date(oEmail.ExitDate) } as ProdStockExit;
                        fList.push(fEmail);
                    });

                    this.lstCustomers = oRes.listOfCustomers!;
                    this.lstOfProducts = oRes.listOfProducts!;
                    this.lstOfVariantDetails = oRes.lstOfProdVariantValues!;
                    this.lstOfUsers = oRes.listOfUsers!;

                    this.listOfStockExits = fList;

                    Swal.close();
                });
        },
        printListItems() {
            const dPrint = appFxs.getReportingData();
            dPrint.ReportTitle = language.stock_exit;
            dPrint.ReportToPrint = 13;

            this.printInitialValues = dPrint;
            const oPr = {
                CurrentYear: this.fiscalYear,
            };
            this.printExtraData = oPr;

            this.isPrintvisible = true;
        },
        onPrintClose(evt: any) {
            this.isPrintvisible = false;
            if (evt.returnType === 0) return;
        },
    },
    mounted() {
        document.title = this.language.stock_exit + ' - ' + this.language.appName;
        this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
        this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
        this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
        this.fiscalYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, newDate.getFullYear().toString()));
        
        NetServices.requestGet('stockops/stockexits/getlistofstexits')
            .then(oRes => {
                if (!oRes.bReturn) {
                    appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                        .then(xRes => {
                            router.replace({ path: '/app/myprofile', replace: true });
                        });
                    return;
                }

                const fList: ProdStockExit[] = [];
                oRes.listOfStockExits!.forEach(oEmail => {
                    const fEmail = { ...oEmail, ExitDate: new Date(oEmail.ExitDate) } as ProdStockExit;
                    fList.push(fEmail);
                });

                this.lstCustomers = oRes.listOfCustomers!;
                this.lstOfProducts = oRes.listOfProducts!;
                this.lstOfVariantDetails = oRes.lstOfProdVariantValues!;
                    this.lstOfUsers = oRes.listOfUsers!;

                this.listOfStockExits = fList;

                this.isStillLoading = false;
            });
    }
})
