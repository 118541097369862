import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.isStillLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_font_awesome_icon, { icon: ['fa', 'fa-spinner'] }),
        _createTextVNode(" " + _toDisplayString(_ctx.language.loading) + "... ", 1)
      ]))
    : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.currCode) + " " + _toDisplayString(_ctx.appFxs.formatNumbers(_ctx.totalTaxIncluded)), 1))
}