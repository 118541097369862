  
  import { defineComponent, Teleport } from "vue";
  import {RouterLink } from 'vue-router';
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';

  import Swal from "sweetalert2";
  import { ProdCategory } from "@/models/producttypes";

  const language = GetLanguage(),
    appFxs = GeneralFx();
  
  export default defineComponent({
    data() {
      return {
        appFxs,
        language,
        isStillLoading: true,
        listOfProdCategs: [] as ProdCategory[],
        prodCategsPageElts: [] as ProdCategory[],
        categSearchKey: '',
        itemsPerPage: 5,

        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 9,
          ReportTitle: language.list_of_categs
        } as any,
        printExtraData: {},
      }
    },
    computed: {
      filteredProdCategs(): ProdCategory[] {
        let searchKey = this.categSearchKey;

        if (searchKey.length === 0) return this.listOfProdCategs;
        if(this.listOfProdCategs.length ==0) return this.listOfProdCategs;

        searchKey = searchKey.toLowerCase();

        const filterd = this.listOfProdCategs.filter(oDev => {
          return oDev.Code.toLowerCase().includes(searchKey) ||
          oDev.NameEn.toLowerCase().includes(searchKey) ||
          oDev.NameFr.toLowerCase().includes(searchKey) ||
          oDev.NameRw.toLowerCase().includes(searchKey) ||
            oDev.Description.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
    },
    methods: {
      getCategoryName(oCateg: ProdCategory) {
        const oLang = appFxs.getLocalLanguage();
        return oLang == 'en' ? oCateg.NameEn:
          oLang == 'fr' ? oCateg.NameFr:
          oLang == 'rw' ? oCateg.NameRw:
          oCateg.NameEn;
      },
      showAddCategAction(iStatus: number, oRecord: ProdCategory | any) {
        if (iStatus == 0) {
          router.replace({ path: '/app/products/categories/new', replace: true });
        } else {
          const fSupplier = oRecord as ProdCategory;
          const fKey = window.btoa(fSupplier.Code)
          const oPath = `/app/products/categories/editor/${fKey}`;
          router.replace({ path: oPath, replace: true });
        }
      },
      onPageChange(evt: any) {
        this.prodCategsPageElts = appFxs.getProxyData(evt);
      },
      deleteProdCategRecord(data: ProdCategory) {
        appFxs.showConfirm(language.delete, language.delete_quest_rec)
          .then(oResp => {
            if (!oResp.isConfirmed) return;
            this.finalDeleteCateg(data);
          });
      },
      finalDeleteCateg(data: ProdCategory) {
        appFxs.showAjaxLoader();

        NetServices.requestPost('products/categs/saveprodcategory', {
          prodCategoryRecord: data,
          toDelete: true,
        }).then(oRes => {
          if (!oRes.bReturn) {
            Swal.close();

            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }

          const fList: ProdCategory[] = [];
          oRes.listOfProdCategories!.forEach(oEmail => {
            const fEmail = { ...oEmail }
            fList.push(fEmail);
          });

          this.listOfProdCategs = fList;

          Swal.close();
        });
      },
      refreshList() {
        appFxs.showAjaxLoader();

        NetServices.requestGet('products/categs/listofprodcategs')
          .then(oRes => {
            if (!oRes.bReturn) {
              appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                .then(xRes => {
                  //router.replace({path: '/app/myprofile', replace: true});
                  Swal.close();
                });
              return;
            }

            const fList: ProdCategory[] = [];
            oRes.listOfProdCategories!.forEach(oEmail => {
              const fEmail = { ...oEmail }
              fList.push(fEmail);
            });

            this.listOfProdCategs = fList;
            Swal.close();
          });
      },
      printListItems() {
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.list_of_categs;
        dPrint.ReportToPrint = 9;

        this.printInitialValues = dPrint;


        this.isPrintvisible = true;
      },
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },
    },
    mounted() {
      document.title = this.language.list_of_categs + ' - ' + this.language.appName;
      this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));

      NetServices.requestGet('products/categs/listofprodcategs')
        .then(oRes => {
          if (!oRes.bReturn) {
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
              .then(xRes => {
                router.replace({ path: '/app/myprofile', replace: true });
              });
            return;
          }

          const fList: ProdCategory[] = [];
          oRes.listOfProdCategories!.forEach(oEmail => {
            const fEmail = { ...oEmail }
            fList.push(fEmail);
          });

          this.listOfProdCategs = fList;

          this.isStillLoading = false;
        });
    }
  })
