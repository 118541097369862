import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64ef3264"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "data-area",
  class: "col-lg-4 col-md-5 col-sm-6 col-xs-12 no-float form-part position-relative"
}
const _hoisted_2 = { class: "data-series-history" }
const _hoisted_3 = { class: "login-form mt-3" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-sm-12" }
const _hoisted_6 = { class: "tiny_bottom_margin size-17rem" }
const _hoisted_7 = { class: "medium_bottom_margin" }
const _hoisted_8 = { class: "align_left small_top_margin size-small-3 mt-5" }
const _hoisted_9 = {
  href: "http://byihuse.rw",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccountHeader = _resolveComponent("AccountHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AccountHeader),
      _createElementVNode("form", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h1", _hoisted_6, _toDisplayString($data.language.checkUrEmail), 1),
            _createElementVNode("p", _hoisted_7, _toDisplayString($data.language.checkUrEmailPasswordReset), 1),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("a", _hoisted_9, _toDisplayString($data.language.goToBySite), 1)
            ])
          ])
        ])
      ])
    ])
  ]))
}