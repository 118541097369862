
  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';

  import Swal from "sweetalert2";
  import { ProdProduct, ProductVariantDetail } from "@/models/producttypes";
  import { Customer, ManagtUser, TaxSett } from "@/models/managttypes";

  import { ShortData } from "@/tools/smarttypes";
  import { SalesQuote } from "@/models/salestypes";
  import { DiscountWithRules, SalesQuoteInfo } from "@/models/notdb";
  import SalesQuoteModal from '@/components/modals/SalesQuoteModal.vue';


  const language =GetLanguage(),
    appFxs = GeneralFx();
  const fNewDate = new Date();

  const emptySalesQuote:SalesQuote = {
    IdQuote: 0,
    QuoteKey: '',
    QuoteNbr: '',
    ClientKey: '',
    OperDate: fNewDate,
    UserEmail: '',
    QuoteStatus: 0,
    DeviseISO: '',
    OfficeCode: '',
    QuoteNote: '',
    QuoteBarcode: '',
    QuoteQRCode: '',
    QuoteCreatedBy: '',
    FiscalYear: 0,
    TempoKey: '',
    GeneratedOrderKey: '',
  }
  const newDate = new Date(),
    month = newDate.getMonth() +1,
    year = newDate.getFullYear();

  export default defineComponent({
    components: {
      SalesQuoteModal,
    },
    data(){
      return {
        appFxs,
        language,        
        isStillLoading: true,
        itemSearchKey: '',
        itemsPerPage: 5,
        lstOfSalesQuotesInfo: [] as SalesQuoteInfo[],
        itemsPageElts: [] as SalesQuoteInfo[],

        listOfDiscountWithRules: [] as DiscountWithRules[],
        listOfTaxSettings: [] as TaxSett[],

        listOfCustomers: [] as Customer[],
        listOfUsers: [] as ManagtUser[],

        listOfProducts: [] as ProdProduct[],
        lstOfProdVariantValues: [] as ProductVariantDetail[],
        clientRecord: null as Customer|any,

        isSalesQuoteVis: false,
        salesOrderData: {...emptySalesQuote} as SalesQuote,
        salesOrderStatus: 0,




        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 8,
          ReportTitle: language.list_of_brands
        } as any,
        printExtraData: {},

        currCode: 'RWF',
        userEmail: '',

        selectedMonth: month,
        selectedYear: year,
        listOfMonths: [] as ShortData[],
      }
    },
    computed: {
      selectedMonthLabel(): string{
        return this.listOfMonths[this.selectedMonth - 1].label;
      },
      filteredRecordItems(): SalesQuoteInfo[] {
        let searchKey = this.itemSearchKey;

        if (searchKey.length === 0) return this.lstOfSalesQuotesInfo;
        searchKey = searchKey.toLowerCase();

        const filterd = this.lstOfSalesQuotesInfo.filter(oDev => {
          return oDev.SalesQuoteRecord.QuoteNbr.toLowerCase().includes(searchKey) ||          
            oDev.CustomerRecord.ClientName.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
    },
    methods: {
      completeOrder(oQuote: SalesQuote) {
        appFxs.showConfirm(language.lock_quote, language.lock_quote_msg_qst)
        .then(vRes => {
          if(!vRes.isConfirmed)return;
          this.completeOrderFinal(oQuote);
        });
      },
      completeOrderFinal(oQuote: SalesQuote) {
        appFxs.showAjaxLoader();
        NetServices.requestPost(`salesorders/quotes/completesalesquote`,{
          quoteOperKey: oQuote.QuoteKey
        })
          .then(oRes => {
            if (!oRes.bReturn) {
              Swal.close();
              appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
              return;
            }

            const fList: SalesQuoteInfo[] = [];
            oRes.lstOfSalesQuotesInfo!.forEach(oSalesOrder => {
              const fOrderTempo = { ...oSalesOrder } as SalesQuoteInfo;
              const fRealOrder = {...fOrderTempo.SalesQuoteRecord,OperDate: new Date(fOrderTempo.SalesQuoteRecord.OperDate) } as SalesQuote;
              fOrderTempo.SalesQuoteRecord = fRealOrder;
              fList.push(fOrderTempo);
            });

            this.listOfUsers = oRes.listOfUsers!;
            this.listOfCustomers = oRes.listOfCustomers!;
            this.listOfProducts = oRes.listOfProducts!;
            this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;
            this.lstOfSalesQuotesInfo = fList;

            this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
            this.listOfTaxSettings = oRes.listOfTaxSettings!;

            Swal.close();
          });
      },
      addSalesQuoteRecord(iStatus: number, oRecord: SalesQuote|any){
        if(iStatus == 0){
          const fRecord = {
            ...emptySalesQuote,
            UserEmail: this.userEmail,
            DeviseISO: this.currCode,
            OfficeCode: 'HQ',
            QuoteBarcode: appFxs.getRandomDigits(12),
            QuoteQRCode: appFxs.getRandomDigits(15),
            QuoteCreatedBy: this.userEmail,
            FiscalYear: this.selectedYear,
            ClientKey: '',
            TempoKey: appFxs.getRandomDigits(35)
          } as SalesQuote;

          this.salesOrderData = fRecord;
        }else{
          this.salesOrderData = {...oRecord} as SalesQuote;
        }

        this.isSalesQuoteVis = true;
      },
      onDetailClose(evt: any) {
        if (evt.returnType == 0) {
          this.isSalesQuoteVis = false;
          return;
        }

        this.isSalesQuoteVis = false;

        const finalRet = appFxs.getProxyData(evt.returnData) as SalesQuote;
        finalRet.QuoteKey = appFxs.generateUUID();

        const newClient = this.listOfCustomers.find(o => o.ClientKey == finalRet.ClientKey);
        
        if(!newClient){
          appFxs.showAlert(language.miss_client, language.miss_client_msg)
          return;
        }
        
        appFxs.setLocalStorage(appDeclarations.tempoCustomerRecord, JSON.stringify(newClient));
        appFxs.setLocalStorage(appDeclarations.tempoSalesRecord, JSON.stringify(finalRet));
        appFxs.setLocalStorage(appDeclarations.tempoLstProducts, JSON.stringify(this.listOfProducts));
        appFxs.setLocalStorage(appDeclarations.tempoLstVariants, JSON.stringify(this.lstOfProdVariantValues));

        appFxs.setLocalStorage(appDeclarations.tempoLstTaxes, JSON.stringify(this.listOfTaxSettings));
        appFxs.setLocalStorage(appDeclarations.tempoLstDiscountsWRules, JSON.stringify(this.listOfDiscountWithRules));

        appFxs.setLocalStorage(appDeclarations.salesNewOperationState, '1');

        router.push({
          name: 'appsalesquotesnew',
        });
      },
      deleteQuoteRecord(oQuote: SalesQuote){
        appFxs.showConfirm(language.delete, language.delete_quest_rec)
          .then(vRes => {
            if(vRes.isConfirmed)this.finalDeleteQuoteRecord(oQuote);
          });
      },
      finalDeleteQuoteRecord(oQuote: SalesQuote){
        appFxs.showAjaxLoader();
        NetServices.requestPost('salesorders/quotes/deletequoteoperationrec', {
          quoteOperKey: oQuote.QuoteKey
        })
          .then(oRes => {
            if(!oRes.bReturn){
              Swal.close();
              appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
              return;
            }


            const fList: SalesQuoteInfo[] = [];
            oRes.lstOfSalesQuotesInfo!.forEach(oSalesOrder => {
              const fOrderTempo = { ...oSalesOrder } as SalesQuoteInfo;
              const fRealOrder = { ...fOrderTempo.SalesQuoteRecord, OperDate: new Date(fOrderTempo.SalesQuoteRecord.OperDate) } as SalesQuote;
              fOrderTempo.SalesQuoteRecord = fRealOrder;
              fList.push(fOrderTempo);
            });

            this.lstOfSalesQuotesInfo = fList;
            this.listOfUsers = oRes.listOfUsers!;
            this.listOfCustomers = oRes.listOfCustomers!;
            this.listOfProducts = oRes.listOfProducts!;
            this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;

            this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
            this.listOfTaxSettings = oRes.listOfTaxSettings!;

            Swal.close();
          });
      },
      convertQuoteToOrder(oQuote: SalesQuote){
        appFxs.showConfirm(language.convert_to_order, language.convert_to_order_msg_qst)
          .then(vRes => {

            if(vRes.isConfirmed) this.convertQuoteToOrderFinal(oQuote);
          });
      },
      convertQuoteToOrderFinal(oQuote: SalesQuote){
        appFxs.showAjaxLoader();
        const oTempoNbr = appFxs.generatedSalesOrderNbr();

        NetServices.requestPost('salesorders/quotes/convertsalesquotetoorder',
        {
          quoteOperKey: oQuote.QuoteKey,
          tempoSalesOrderNbr: oTempoNbr,
        })
        .then(oRes => {
          if(!oRes.bReturn){
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }

          const fList: SalesQuoteInfo[] = [];
            oRes.lstOfSalesQuotesInfo!.forEach(oSalesOrder => {
              const fOrderTempo = { ...oSalesOrder } as SalesQuoteInfo;
              const fRealOrder = { ...fOrderTempo.SalesQuoteRecord, OperDate: new Date(fOrderTempo.SalesQuoteRecord.OperDate) } as SalesQuote;
              fOrderTempo.SalesQuoteRecord = fRealOrder;
              fList.push(fOrderTempo);
            });

            this.lstOfSalesQuotesInfo = fList;
            this.listOfUsers = oRes.listOfUsers!;
            this.listOfCustomers = oRes.listOfCustomers!;
            this.listOfProducts = oRes.listOfProducts!;
            this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;

            this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
            this.listOfTaxSettings = oRes.listOfTaxSettings!;

            Swal.close();
        });
      },
      rejectSalesQuote(oQuote: SalesQuote){
        appFxs.showConfirm(language.reject, language.reject_msg_qst)
          .then(vRes => {

            if(vRes.isConfirmed) this.rejectSalesQuoteFinal(oQuote);
          });
      },
      rejectSalesQuoteFinal(oQuote: SalesQuote){
        appFxs.showAjaxLoader();
        NetServices.requestPost('salesorders/quotes/rejectsalesquote',
        {
          quoteOperKey: oQuote.QuoteKey
        })
        .then(oRes => {
          if(!oRes.bReturn){
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }

          const fList: SalesQuoteInfo[] = [];
            oRes.lstOfSalesQuotesInfo!.forEach(oSalesOrder => {
              const fOrderTempo = { ...oSalesOrder } as SalesQuoteInfo;
              const fRealOrder = { ...fOrderTempo.SalesQuoteRecord, OperDate: new Date(fOrderTempo.SalesQuoteRecord.OperDate) } as SalesQuote;
              fOrderTempo.SalesQuoteRecord = fRealOrder;
              fList.push(fOrderTempo);
            });

            this.lstOfSalesQuotesInfo = fList;
            this.listOfUsers = oRes.listOfUsers!;
            this.listOfCustomers = oRes.listOfCustomers!;
            this.listOfProducts = oRes.listOfProducts!;
            this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;

            this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
            this.listOfTaxSettings = oRes.listOfTaxSettings!;

            Swal.close();
        });
      },
      confirmSalesQuote(oQuote: SalesQuote){
        appFxs.showConfirm(language.confirm, language.confirm_msg)
          .then(vRes => {

            if(vRes.isConfirmed) this.confirmSalesQuoteFinal(oQuote);
          });
      },
      confirmSalesQuoteFinal(oQuote: SalesQuote){
        appFxs.showAjaxLoader();
        NetServices.requestPost('salesorders/quotes/confirmsalesquote',
        {
          quoteOperKey: oQuote.QuoteKey
        })
        .then(oRes => {
          if(!oRes.bReturn){
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }

          const fList: SalesQuoteInfo[] = [];
            oRes.lstOfSalesQuotesInfo!.forEach(oSalesOrder => {
              const fOrderTempo = { ...oSalesOrder } as SalesQuoteInfo;
              const fRealOrder = { ...fOrderTempo.SalesQuoteRecord, OperDate: new Date(fOrderTempo.SalesQuoteRecord.OperDate) } as SalesQuote;
              fOrderTempo.SalesQuoteRecord = fRealOrder;
              fList.push(fOrderTempo);
            });

            this.lstOfSalesQuotesInfo = fList;
            this.listOfUsers = oRes.listOfUsers!;
            this.listOfCustomers = oRes.listOfCustomers!;
            this.listOfProducts = oRes.listOfProducts!;
            this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;

            this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
            this.listOfTaxSettings = oRes.listOfTaxSettings!;

            Swal.close();
        });
      },
      getQuoteStatus(iStatus: number): string{
        return iStatus == 0 ? language.draft:
          iStatus == 1 ? language.confirmed:
          iStatus == 2 ? language.converted:
          iStatus == 3 ? language.rejected:
          language.completed;
      },
      changeMonth(oMonth: number){
        this.selectedMonth = oMonth;
        this.refreshList();
      },
      onPageChange(evt: any){
            this.itemsPageElts = appFxs.getProxyData(evt);
      },
      refreshList(){
        appFxs.showAjaxLoader();

        NetServices.requestGet(`salesorders/quotes/listofsalesquotesbymonth/${this.selectedMonth}`)
          .then(oRes => {
            if (!oRes.bReturn) {
              appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                .then(xRes => {
                  Swal.close();
                  appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                });
              return;
            }

            const fList: SalesQuoteInfo[] = [];
            oRes.lstOfSalesQuotesInfo!.forEach(oSalesOrder => {
              const fOrderTempo = { ...oSalesOrder } as SalesQuoteInfo;
              const fRealOrder = {
                 ...fOrderTempo.SalesQuoteRecord, 
                 OperDate: new Date(fOrderTempo.SalesQuoteRecord.OperDate) 
                } as SalesQuote;
              fOrderTempo.SalesQuoteRecord = fRealOrder;
              fList.push(fOrderTempo);
            });

            this.lstOfSalesQuotesInfo = fList;
            this.listOfUsers = oRes.listOfUsers!;
            this.listOfCustomers = oRes.listOfCustomers!;
            this.listOfProducts = oRes.listOfProducts!;
            this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;

            this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
            this.listOfTaxSettings = oRes.listOfTaxSettings!;

            Swal.close();
          });
      },
      
      printListItems(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.list_of_quotes;
        dPrint.ReportToPrint = 17;

        this.printInitialValues = dPrint;
        this.printExtraData = {
          CurrentYear: this.selectedYear,
          CurrentMonth: this.selectedMonth,
        };


        this.isPrintvisible = true;
      },      
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },
      printSalesQuoteRecord(oRecord: SalesQuoteInfo) {
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = `${language.sales_quote} - ${oRecord.SalesQuoteRecord.QuoteNbr}`;
        dPrint.ReportToPrint = 18;

        this.printInitialValues = dPrint;

        const oPr = {
          SalesQuoteKey: oRecord.SalesQuoteRecord.QuoteKey,
        };
        this.printExtraData = oPr;

        this.isPrintvisible = true;
      },
    },
    mounted(){      
      document.title = this.language.list_of_quotes + ' - ' + this.language.appName;
      this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
      this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
      this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));
      this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');

      this.listOfMonths = appFxs.getListMonthsByYear(this.selectedYear);

      NetServices.requestGet(`salesorders/quotes/listofsalesquotesbymonth/${this.selectedMonth}`)
        .then(oRes => {
          if (!oRes.bReturn) {
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
              .then(xRes => {
                router.replace({ path: '/app/myprofile', replace: true });
              });
            return;
          }

          const fList: SalesQuoteInfo[] = [];
          oRes.lstOfSalesQuotesInfo!.forEach(oSalesOrder => {
            const fOrderTempo = { ...oSalesOrder } as SalesQuoteInfo;
            const fRealOrder = {
              ...fOrderTempo.SalesQuoteRecord,
              OperDate: new Date(fOrderTempo.SalesQuoteRecord.OperDate)
             } as SalesQuote;
            fOrderTempo.SalesQuoteRecord = fRealOrder;
            fList.push(fOrderTempo);
          });

          const oCustomers = oRes.listOfCustomers!;


          this.listOfUsers = oRes.listOfUsers!;
          this.listOfCustomers = oCustomers.filter(o => o.ClientKey != 'GLOBALCUST');
          this.listOfProducts = oRes.listOfProducts!;
          this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;
          this.lstOfSalesQuotesInfo = fList;

          this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
          this.listOfTaxSettings = oRes.listOfTaxSettings!;

          this.isStillLoading = false;
        });
    }
  })
