import { NetReturn, SenderData } from "@/models/netreturn";
import AppDeclarations from "./declarations"
import GeneralFx from "./general_fx";
import GetLanguage from "./language";

import jquery from 'jquery';

const $ = jquery;
const appFxs = GeneralFx();
const language = GetLanguage();

async function requestGet(methodName: string): Promise<NetReturn> {
    const url = `${AppDeclarations.apiRoot}${methodName}`;
    const headers = appFxs.getManagtDefaultHeaders();

    const response  = await window.fetch(url, {
        method: 'GET',
        headers
    });

    if(response.ok){
        const json = await response.json();
        const commits = json as NetReturn;
        return commits;
    }else{
        return {
            bReturn: false,
            msgTitle: language.networkError,
            msgBody: language.networkErrorMsg,
            typeReturn: 0,
        }
    }
}

async function requestPost(methodName: string, data: SenderData): Promise<NetReturn> {
    const url = `${AppDeclarations.apiRoot}${methodName}`;
    const fheaders = appFxs.getManagtDefaultHeaders();
    const headers = {...fheaders, 'Content-Type': 'application/json',}

    const response  = await window.fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    });

    if(response.ok){
        const json = await response.json();
        const commits = json as NetReturn;
        return commits;
    }else{
        return {
            bReturn: false,
            msgTitle: language.networkError,
            msgBody: language.networkErrorMsg,
            typeReturn: 0,
        }
    }
}

async function requestPostWithFile(methodName: string, data: FormData): Promise<NetReturn> {
    const url = `${AppDeclarations.apiRoot}${methodName}`;
    const fheaders = appFxs.getManagtDefaultHeaders();
    const headers = {...fheaders}

    const response  = await window.fetch(url, {
        method: 'POST',
        headers,
        body: data
    });

    if(response.ok){
        const json = await response.json();
        const commits = json as NetReturn;
        return commits;
    }else{
        return {
            bReturn: false,
            msgTitle: language.networkError,
            msgBody: language.networkErrorMsg,
            typeReturn: 0,
        }
    }
}

function requestPrinting(data: any, returnFn: Function, errorFn: Function ): void {
    const url = `${AppDeclarations.apiRoot}comm/printing/prepareprintsystem`;
    const headers = appFxs.getManagtDefaultHeaders();

    $.ajaxSetup({
        headers
    });

    $.post(url, data, (resp) => {
        returnFn(resp);
    }).fail((err) => {
        errorFn(err);
    });
}


const NetServices = {
    requestGet,
    requestPost,
    requestPostWithFile,
    requestPrinting
}

export default NetServices;