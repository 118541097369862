    
    import { defineComponent } from "vue";
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import DataFxs from '@/tools/data_functions';

    import Swal from "sweetalert2";
    import { ProdProduct } from "@/models/producttypes";

    import { SalesOrder, SalesOrderDetail } from "@/models/salestypes";
    import { Customer } from "@/models/managttypes";
    const language = GetLanguage(),
        appFxs = GeneralFx(),
        dataFxs = DataFxs();

    const fNewDate = new Date(),
        year = fNewDate.getFullYear();
    
    export default defineComponent({
      name: 'SalesOperListDet',
      data(){
        return {
          appFxs,
          language,        
          isStillLoading: true,
          itemSearchKey: '',
          itemsPerPage: 5,
          selectedYear: year,
          currCode: 'RWF',
          userEmail: '',

          selectedOrderKey: '',

          listOfOrderDetails: [] as SalesOrderDetail[],
          itemsPageElts: [] as SalesOrderDetail[],
          listOfProducts: [] as ProdProduct[],
          salesOrderElement: null as any,
          oneCustomerRec: null as any,

          isPrintvisible: false,
          printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 8,
          ReportTitle: language.list_of_brands
          } as any,
          printExtraData: {},
        }
      },
      computed: {
        totalSalesDetails(): SalesOrderDetail {
          const lst = this.listOfOrderDetails;
          const first = lst[0];
          const unitPr = first.UnitPrice;
          const qty = lst.reduce((acct, o) => {
            return acct + o.Quantity;
          }, 0);
          const sub = lst.reduce((acct, o) => {
            return acct + o.SubTotal;
          }, 0);
          const disc = lst.reduce((acct, o) => {
            return acct + o.DiscountAmount;
          }, 0);
          const txAmt = lst.reduce((acct, o) => {
            return acct + o.TaxableAmount;
          }, 0);
          const txVal = lst.reduce((acct, o) => {
            return acct + o.TaxValue;
          }, 0);
          const totAmt = lst.reduce((acct, o) => {
            return acct + o.TotalAmt;
          }, 0);

          return {
            IdOrderDet: 0,
            OrderKey: '',
            OrderDetailKey: '',
            ProductSKU: '',
            ProductOrient: 0,
            VariantValues: '',
            Quantity: qty,
            UnitPrice: first.UnitPrice,
            SubTotal: sub,
            DiscountKeys: first.DiscountKeys,
            DiscountAmount: disc,
            TaxableAmount: txAmt,
            TaxKey: '',
            TaxRate: 0,
            TaxValue: txVal,
            TotalAmt: totAmt,
            FiscalYear: 0,
            TempoKey: '',
          }
        },
        filteredRecordItems(): SalesOrderDetail[] {
          let searchKey = this.itemSearchKey;

          if (searchKey.length === 0) return this.listOfOrderDetails;
          searchKey = searchKey.toLowerCase();

          const filterd = this.listOfOrderDetails.filter(oDev => {
            return oDev.OrderKey.toLowerCase().includes(searchKey);
          });
          return filterd;
        },
        getInfoRecord(): {
              orderKey: string;
              orderNbr: string;
              customerName: string;
            }{
              const oFinal = {
                orderKey: '',
                orderNbr: '',
                customerName: '',
              };

              if(!this.salesOrderElement || !this.oneCustomerRec)
                return oFinal;

                const fOrder = this.salesOrderElement as SalesOrder;
                const fCustomer = this.oneCustomerRec as Customer;

              return {
                orderNbr: fOrder.OrderNbr,
                orderKey: fOrder.OrderKey,
                customerName: fCustomer.ClientName
              }
            }
      },
      methods: {
          getProductRecord(oDetail: SalesOrderDetail): string{
              const oProd = this.listOfProducts.find(o => o.SKU == oDetail.ProductSKU);
              if(!oProd)return '';
              if(oDetail.ProductOrient==0) return  oProd.Name;
              return `${oProd.Name} (${oDetail.VariantValues})`;
          },
        refresListItems() {
          appFxs.showAjaxLoader();
          NetServices.requestGet(`salesorders/sales/getlistoforderdetails/${this.selectedOrderKey}`)
            .then(oRes => {
              if (!oRes.bReturn) {
                Swal.close();
                appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                return;
              }

              this.listOfOrderDetails = oRes.listOfOrderDetails!;
              this.listOfProducts = oRes.listOfProducts!
              this.salesOrderElement = oRes.salesOrderElement;
              this.oneCustomerRec = oRes.oneCustomerRec;

              Swal.close();
            });

        },
        onPageChange(evt: any) {
          this.itemsPageElts = appFxs.getProxyData(evt);
        },
        printSalesQuoteRecord() {
          const dPrint = appFxs.getReportingData();
          dPrint.ReportTitle = `${language.sales_order} - ${this.getInfoRecord.orderNbr}`;
          dPrint.ReportToPrint = 20;

          this.printInitialValues = dPrint;

          const oPr = {
            SalesOrderKey: this.getInfoRecord.orderKey,
          };
          this.printExtraData = oPr;

          this.isPrintvisible = true;
        },
        onPrintClose(evt: any) {
          this.isPrintvisible = false;
          if (evt.returnType === 0) return;
        },
      },
      mounted(){
        document.title = this.language.order_details + ' - ' + this.language.appName;
        this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
        this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
        this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
        this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));

        const salesKey = this.$route.params.salesKey;

        if (!salesKey) {
          router.push({
            name: 'appsalesdelivlist',
          });
          return;
        }
        const _orderKey = salesKey as string;

        this.selectedOrderKey = _orderKey;

        NetServices.requestGet(`salesorders/sales/getlistoforderdetails/${this.selectedOrderKey}`)
          .then(oRes => {
            if (!oRes.bReturn) {
              appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                .then(vRes => {
                  router.push({
                    name: 'SalesMonthListPage',
                  });
                });
              return;
            }



            this.listOfOrderDetails = oRes.listOfOrderDetails!;
            this.listOfProducts = oRes.listOfProducts!;
            this.salesOrderElement = oRes.salesOrderElement;
            this.oneCustomerRec = oRes.oneCustomerRec;

            this.isStillLoading = false;
          });
      }
    })
