import appDeclarations from "@/tools/declarations";


const getAppLang = (): string => {
    const elt = localStorage.getItem(appDeclarations.currentLang);
    return elt ?? 'en';
}
const retLang = (enCont: string, frCont: string, rwCont: string) :string =>{
    const lang =  getAppLang();

    return lang === 'en' ? enCont:
    lang === 'fr' ? frCont:
    lang === 'rw' ? rwCont:
    enCont;
}
function GetLanguage() {
    
    return {
        loading: retLang('Loading','Chargement',''),
        login: retLang('Login','Connection',''),
        loginToByihuse: retLang('Sign in to Byishuse','Se connecter à Byihuse',''),
        enter: retLang('Enter','Entrez',''),
        your: retLang('Your','Votre',''),
        email: retLang('Email','Email',''),
        and: retLang('And','Et',''),
        password: retLang('Password','Mot de passe',''),
        signInTo: retLang('Sign in to','Connectez-vous à',''),
        signIn: retLang('Sign in','Connection',''),
        signOut: retLang('Sign out','Se deconnecter',''),
        rememberMe: retLang('Remember me','Souviens-toi de moi',''),
        forgotPassWord: retLang('Forgot password?','Mot de passe oublié?',''),
        appName: retLang('Byihuse Management App','Byihuse - Appli de Gestion',''),
        enterYour: retLang('Enter your','Entrez votre',''),
        emailEx: retLang('you@example.com','vous@exemple.com',''),
        reset: retLang('Reset','Réinitialiser',''),
        logInInstead: retLang('I have account, log in instead','J\'ai déja un compte. Veuillez me connecter',''),
        resetPassword: retLang('Reset your password','Modifier votre mot de passe',''),
        setPasswordWithNew: retLang('Set your password with a new one','Configurez votre mot de passe avec un nouveau',''),
        confirmPasword: retLang('Confirm your password','Confirmer votre mot de passe',''),
        weak: retLang('Weak','Faible',''),
        good: retLang('Good','Bon',''),
        strong: retLang('Strong','Fort',''),
        very_strong: retLang('Very strong','Très fort',''),
        passwordReqEmailSent: retLang('Waiting for password reset', 'En attente de la réinitialisation du mot de passe', ''),
        checkUrEmail: retLang('Check your email', 'Vérifiez votre courrier électronique.', ''),
        checkUrEmailPasswordReset: retLang(
            'Check your email for password reset process.',
            'Vérifiez votre courrier électronique pour le processus de réinitialisation du mot de passe.', ''),
        goToBySite: retLang('Go to Byihuse website', 'Visitez le website de Byihuse', ''),
        networkError: retLang('Network error', 'Erreur de réseau', ''),
        networkErrorMsg: retLang(
            'There is a network error on this device. Check this issue and try again.', 
            'Il y a une erreur réseau sur cet appareil. Vérifiez ce problème et réessayez.', ''),
        fldRequired: retLang('This field is required', 'Ce champ est obligatoire', ''),
        emailFormat: retLang('The email format seems wrong.', 'Le format de l\'e-mail semble incorrect.', ''),
        passLengthMsg: retLang('Password must be at least 8 characters.', 
            'Le mot de passe doit comporter au moins 8 caractères.', ''),
        passValidMsg: retLang('The password strength is too weak to be valid.', 
            'Le niveau de sécurité du mot de passe est trop faible pour être valide.', ''),
        sameAsPasswordMsg: retLang('You are unable to confirm your password correctly.', 
            'Vous n\'arrivez pas à confirmer votre mot de passe correctement.', ''),
        
        phonenbrLengthMsg: retLang('The phone number must be 12 caracters long.', 
            'Le numéro de téléphone doit être de 12 caractères.', ''),
        
        mainMenu: retLang('Main menu', 'Menu principal', ''),
        dashboard: retLang('Dashboard', 'Tableau de bord', ''),
        customers: retLang('Customers', 'Clients', ''),

        products: retLang('Products', 'Produits', ''),
        list_of_products: retLang('List of products', 'Liste des produits', ''),
        list_of_products_msg: retLang(
            `
            Products are the backbone of your business. They can be physical 
            products or services—whatever your organization sells. Your sales 
            reps use the products you create in Sales to generate sales quotes, 
            marketing campaigns, orders, and invoices. Your customer service reps 
            might also use them when they create customer service cases.
            `, 
            `
            Les produits sont l'épine dorsale de votre entreprise. Il peut 
            s'agir de produits ou de services physiques, quels que soient les 
            produits vendus par votre organisation. Vos commerciaux utilisent 
            les produits que vous créez dans Sales pour générer des devis, des 
            campagnes marketing, des commandes et des factures. Vos représentants 
            du service client peuvent également les utiliser lorsqu'ils créent 
            des requêtes de service client.
            `, ''),
        add_product: retLang('Add product', 'Ajouter produit', ''),
        edit_product: retLang('Edit product', 'Modifier produit', ''),

        settings: retLang('Settings', 'Paramètres', ''),
        analyticals: retLang('Analytics', 'Analytiques', ''),
        myProfile: retLang('My profile', 'Mon profile', ''),
        yesBtn: retLang('Yes', 'Oui', ''),
        noBtn: retLang('No', 'Non', ''),
        cancelBtn: retLang('Cancel', 'Annuler', ''),
        exitApp: retLang('Exit the application', 'Quitter l\'application', ''),
        exitAppMsg: retLang(
            'Do you want to exit the application Byihuse',
            'Voulez-vous quitter l\'application Byihuse', ''),
        loadingUserData: retLang(
            'Loading user data from server...', 
            'Chargement des données utilisateur à partir du serveur...', ''),
        profileDetail: retLang(
            'Kindly detail your identity information.',
            'Veuillez détailler les informations relatives à votre identité.', ''),
        profileDetails: retLang('Profile details', 'Détails du profil', ''),
        firstName: retLang('First name', 'Prénom', ''),
        lastName: retLang('Last name', 'Nom de famille', ''),
        displayName: retLang('Display name', 'Nom d\'affichage', ''),
        jobTitle: retLang('Job Title', 'Profession', ''),
        shortBio: retLang('Short bio', 'Courte bio', ''),
        mobilePhone: retLang('Mobile phone', 'Téléphone mobile', ''),
        sex: retLang('Sex', 'Sexe', ''),
        dateOfBirth: retLang('Date of birth', 'Date de naissance', ''),
        facebookAcct: retLang('Facebook account', 'Compte Facebook', ''),
        twitterAcct: retLang('Twitter account', 'Twitter Facebook', ''),
        linkedInAcct: retLang('LinkedIn account', 'LinkedIn Facebook', ''),
        country: retLang('Country', 'Pays', ''),
        city: retLang('City', 'Ville', ''),
        male: retLang('Male', 'Masculin', ''),
        female: retLang('Female', 'Féminin', ''),
        save: retLang('Save', 'Enregistrer', ''),

        changeCredentials: retLang('Change credentials', 'Modifier les identifiants', ''),
        otherSettings: retLang('Other Settings', 'Autres paramètres', ''),
        myCompany: retLang('My company', 'Mon entreprise', ''),
        emailSettings: retLang('Email settings', 'Paramètres email', ''),
        users: retLang('Users', 'Utilisateurs', ''),
        billingInfo: retLang('Billing info', 'Info facture', ''),
        smsSettings: retLang('SMS Settings', 'Paramètres SMS', ''),
        invoiceOptions: retLang('Invoice Options', 'Options facturation', ''),
        financialYears: retLang('Financial years', 'Années fiscales', ''),
        financialYear: retLang('Financial year', 'Année fiscale', ''),
        currencies: retLang('Currencies', 'Devises', ''),
        currencies_msg: retLang(
            'This command allows you to create, modify, display ' +
            'currencies and set their current prices.',
            'Cette commande permet de créer, modifier, visualiser ' +
            'les dévises ainsi que paramétrer leurs cours actuelles.',
            ''),
        currency: retLang('Currency', 'Devise', ''),
        change_cred_def: retLang(
            'Change credentials refers to the change of user password. ' +
            'You have to provide the former one before setting the new one. ' +
            'In order to save the changes, you have also to confirm the new ' +
            'password so that we make sure you know the newly set password.',
            'Modifier les informations d\'identification fait référence au ' +
            'changement du mot de passe de l\'utilisateur. Vous devez fournir ' +
            'l\'ancien avant de définir le nouveau. Afin d\'enregistrer les ' +
            'modifications, vous devez également confirmer le nouveau mot de passe ' +
            'afin que nous nous assurons que vous connaissez le nouveau mot de passe.', ''),
        old_pw: retLang('Old password', 'Ancien mot de passe', ''),
        new_pw: retLang('New password', 'Nouveau mot de passe', ''),
        conf_pw: retLang('Confirm Password', 'Confirmer mot de passe', ''),
        language: retLang('Language', 'Langue', ''),
        lang_en: retLang('English', 'Anglais', ''),
        lang_fr: retLang('French', 'Français', ''),
        conf_pw_sh: retLang('Conf. password', 'Confirmer mot de passe', ''),
        pw_change: retLang('Password change', 'Change de mot de passe', ''),
        pw_change_msg_succ: retLang('Password has been changed successfully.',
            'Le mot de passe a été modifié avec succès.', ''),

        miss_email_format: retLang('An email is missing or it has a wrong email format.',
            'Un email est manquant ou il a un mauvais format d\'email.', ''),
        weve_emailed_special_link_to: retLang(
            'We\'ve emailed a special link to',
            'Nous avons envoyé un lien spécial à', ''),
        try_email_ask_admin: retLang('Looking for a different domain? You can try another email address or ask your domain Administrator for an invitation.',
            'Vous cherchez un domaine différent? Vous pouvez essayer une autre adresse e-mail ou demander à votre Administrateur de domaine une invitation.', ''),
        set_ur_pw: retLang('Set your password', 'Définir votre mot de passe', ''),
        choose_pw_to_stockix: retLang('Choose a password for signing in to Byihuse.', 'Choisissez un mot de passe pour vous connecter à Byihuse.', ''),
        refresh: retLang('Refresh', 'Rafraichir', ''),
        other_setts_def: retLang(
            'Other settings refer to the accounting currency, the running ' +
            'office, the running fiscal year as well as the used language.',
            'Les autres paramètres se réfèrent à la devise de comptabilisation, ' +
            'au bureau en cours d\'exécution, à l\'exercice comptable en cours ' +
            'ainsi qu\'à la langue utilisée.', ''),
        taxSettings: retLang('Tax settings', 'Paramètres des taxes', ''),
        taxes: retLang('Taxes', 'Taxes', ''),
        office: retLang('Office', 'Bureau', ''),
        defSmsScct: retLang('Default SMS Account', 'Compte SMS par défaut', ''),
        defSmsScctMsg: retLang(
            'This setting will enable sending SMS without first selecting a sending account.',
            'Ce paramétrage permettra d\'activer l\'envoi des SMS sans sélectionner préalablement un compte d\'envoi.', ''),
        missing_def_sms_acct: retLang(
            'The default SMS account seems missing. Please go to Settings to specify one and try again.',
            'Le compte SMS par défaut semble manquant. Veuillez vous rendre dans les paramètres pour en spécifier un et réessayer.', ''),
        available: retLang('Available', 'Disponible', ''),
        not_available: retLang('Not Available', 'Non Disponible', ''),
        itemsPerPage: retLang('Items per page', 'Éléments par page', ''),
        none: retLang('None', 'Aucun', ''),
        waitServerResp: retLang('Waiting for server response...', 'Veuillez patienter la reponse du serveur...', ''),
        my_comp_setts: retLang('My Company Infos', 'Ma Société - Infos', ''),
        my_comp_setts_msg: retLang(
            'Your company\'s information is important for identifying your ' +
            'management documents. They can appear in the header of the reports, ' +
            'in the automatically generated emails, as well as in your ' +
            'correspondences.',
            'Les informations de votre société sont importantes pour ' +
            'l\'identification de vos documents de gestion. Elles peuvent ' +
            'apparaitre dans l\'entête des rapports, dans les emails ' +
            'générés automatiquement, ainsi que dans vos correspondances.',''),
        def_sms_acct: retLang('Default SMS Account', 'Compte SMS par défaut', ''),
        def_sms_acct_msg: retLang(
            'This setting will enable sending SMS without first selecting a sending account.',
            'Ce paramétrage permettra d\'activer l\'envoi des SMS sans sélectionner préalablement un compte d\'envoi.', ''),
        

        po_box: retLang('PO Box', 'Bte Postale', ''),
        fax: retLang('Fax', 'Fax', ''),
        short_name: retLang('Short name', 'Abrégé', ''),
        comp_email: retLang('Company email', 'Email société', ''),
        admin_email: retLang('Administrator email', 'Email administrateur', ''),
        street_adress: retLang('Street', 'Rue', ''),
        building_adr: retLang('Building address', 'Adresse du bâtiment', ''),
        company_name: retLang('Company name', 'Nom société', ''),
        address: retLang('Address', 'Adresse', ''),
        mobile_phone: retLang('Mobile phone', 'Téléphone mobile', ''),
        office_phone: retLang('Office phone', 'Téléphone bureau', ''),
        website: retLang('Website', 'Site web', ''),
        logo: retLang('Logo', 'Logo', ''),
        email_seems_not_valid: retLang('This email format seems not valid (name@domain.com)', "Ce format d'e-mail ne semble pas valide (nom@domaine.com) ", ''),
        tax_number: retLang('Tax number', 'N° Taxe', ''),
        minLengthReqMsg: retLang(
            'The text length does not match the required minimum length',
            'La longueur du texte ne correspond pas à la longueur minimale requise.', ''),
        maxLengthReqMsg: retLang(
            'The text length does not match the required maximum length',
            'La longueur du texte ne correspond pas à la longueur maximale requise.', ''),
    
        file_selected_size_error: retLang('Selected file size is greater than allowed.',
            'La taille du fichier sélectionné est supérieure à celle permise.', ''),
        file_selected_error: retLang('Selected file is invalid. (only file type png, jpg and gif and 1 mb size are allowed)',
            'Le fichier sélectionné n\'est pas valide. (Seul le type de fichier png, jpg et gif et 1 mb de taille sont autorisés)', ''),
        file_selected_off_error: retLang('Selected file is invalid. (only file type png, jpg, gif, doc, docx, xls, xlsx, and pdf and 2 mb size are allowed)',
            'Le fichier sélectionné n\'est pas valide. (Seul le type de fichier png, jpg, gif, doc, docx, xls, xlsx, et pdf et 2 mb de taille sont autorisés)', ''),
        miss_file_select_error: retLang('Missing File. It seems you have not yet selected the file to upload.',
            'Fichier manquant. Il semble que vous n\'avez pas encore sélectionné le fichier à télécharger.)', ''),
        url_link_error_msg: retLang('The url link seems wrong or ill formatted.',
            'Le lien url semble faux ou mal formaté.)', ''),
        error: retLang('Error', 'Erreur', ''),
        emails_setts: retLang('Email settings', 'Paramètres email', ''),
        emails_setts_def: retLang(
            'You need the emails to be used in Marketing operations. You can create as many as you want and the Byihuse app will use them as needed.',
            'Vous avez besoin des emails à utiliser dans les opération du Marketing. Vous pouvez en créer autant que vous voulez et l\'application Byihuse les utilsera selon les besoins.', ''),
            emails_setts_msg: retLang('Byihuse can connect to your email account and sync with your email data. Once connected, you’ll be able to interact with client email right on each individual Client page.',
            'Byihuse peut se connecter à votre compte de messagerie et se synchroniser avec vos données de messagerie. Une fois connecté, vous pourrez interagir directement avec le client sur chaque page client.', ''),
        sms_setts_msg: retLang('Byihuse can connect to your sms account and send sms to your contacts. Once connected, you’ll be able to interact with contacts right on each individual contact page.',
            'Byihuse peut se connecter à votre compte sms et envoyer des sms à vos contacts. Une fois connecté, vous pourrez interagir avec les contacts directement sur chaque page de contact individuelle.', ''),
        cal_setts_msg: retLang('Byihuse can connect to your Google Calendar and Outlook Calendar account and sync with your calendar data. Once connected, creating events in Byihuse will create them in Google as well as in Outlook Calendar and vice versa. Wonderful!',
            'Byihuse peut se connecter à votre compte Calendrier Google et Calendrier Outlook et vous synchroniser avec vos données de calendrier. Une fois connecté, la création d\'événements dans Byihuse les créera dans Google ainsi que dans Outlook Calendar et vice versa. Formidable!', ''),
        no_acct_connected: retLang('No account connected', 'Aucun compte connecté', ''),
        no_financial_y_f: retLang('No financial year found', 'Aucun exercice trouvé', ''),
        add_acct: retLang('Add account', 'Ajouter un compte', ''),
        email_account: retLang('Email account', 'Compte de messagerie', ''),
        email_provider: retLang('Email provider', 'Fournisseur email', ''),
        delete: retLang('Delete', 'Supprimer', ''),
        edit: retLang('Edit', 'Modifier', ''),
        
        reminder: retLang('Reminder', 'Rappel', ''),
        reminders: retLang('Reminders', 'Rappels', ''),
        guests: retLang('Guests', 'Invités', ''),


        last_saved: retLang('Last saved', 'Recemment enregistré', ''),
        last_sync: retLang('Last Sync', 'Dernière Synchro', ''),
        delete_email_actt_qst: retLang('Do you want to delete this email setting account?', 'Voulez-vous supprimer ce compte de configuration de messagerie?', ''),
        delete_fin_year_qst: retLang('Do you want to delete this financial year setting?', 'Voulez-vous supprimer cette année fiscale (exercice financier)?', ''),
        delete_sms_acct_qst: retLang('Do you want to delete this SMS setting account?', 'Voulez-vous supprimer ce compte de configuration de SMS?', ''),
        delete_calendar_qst: retLang('Do you want to delete this Calendar setting?', 'Voulez-vous supprimer cette configuration Calendrier?', ''),

        close: retLang('Close', 'Fermer', ''),


        sms_account: retLang('SMS Account', 'Compte SMS', ''),
        account_name: retLang('Account name', 'Nom du compte', ''),
        sms_account_name: retLang('SMS Account Name', 'Nom du Compte SMS', ''),
        sms_account_pw: retLang('SMS Account Password', 'Mot de passe du Compte SMS', ''),
        sms_server_name: retLang('SMS Server Name', 'Serveur SMS', ''),
        total_entries: retLang('Total entries', 'Total des saisies', ''),
        general_sending: retLang('General sending', 'Evoi Général', ''),
        marketing: retLang('Marketing', 'Marketing', ''),

        acct_name: retLang('Account name', 'Nom du compte', ''),
        acct_type: retLang('Account type', 'Type du compte', ''),
        acct_email: retLang('Account email', 'Email du compte', ''),
        acct_password: retLang('Account password', 'M. Passe du compte', ''),
        acct_port: retLang('Account port', 'Port du compte', ''),
        acct_server: retLang('Account server', 'Serveur du compte', ''),
        contact_name: retLang('Contact name', 'Nom du contact', ''),

        email_sent_as: retLang('Email sent as', 'Courrier envoyé en tant que', ''),
        imap_server: retLang('IMAP Server', 'Serveur IMAP', ''),
        smtp_server: retLang('SMTP Server', 'Serveur SMTP', ''),
        pop_server: retLang('POP Server', 'Serveur POP', ''),
        port: retLang('Port', 'Port', ''),
        ssl: retLang('SSL', 'SSL', ''),
        provider_name: retLang('Provider name', 'Nom fournisseur', ''),
        add_email_acct: retLang('Add email account',
            'Ajout compte email', ''),
        edit_email_acct: retLang('Edit email account',
            'Modifier compte email', ''),     
        administrator: retLang('Administrator', 'Administrateur', ''),
        admin_sh: retLang('Admin', 'Admin', ''),
        search: retLang('Search', 'Chercher', ''),
    
        
        owner: retLang('Owner', 'Propriétaire', ''),
        cover_photo: retLang('Cover photo', 'Photo de couverture', ''),
        add_user: retLang('Add user', 'Ajouter utilisateur', ''),
        edit_user: retLang('Edit user', 'Modifier utilisateur', ''),
        team: retLang('Team', 'Equipe', ''),
        team_member: retLang('Team member', 'Membre de l\'équipe', ''),
        team_leader: retLang('Team leader', 'Chef d\'équipe', ''),
        leader: retLang('Leader', 'Chef', ''),
        resend_invite: retLang('Resend Invite', 'Renvoyer une invitation', ''),
        resend: retLang('Resend', 'Renvoyer', ''),
        delete_user: retLang('Delete User', 'Supprimer Utilisateur', ''),
        imei: retLang('IMEI', 'IMEI', ''),
        ser_nbr: retLang('Serial nbr', 'N° Série', ''),
        chat: retLang('Chat', 'Chat', ''),
        make_phone_call: retLang('Phone calls', 'Appels tél.', ''),
        make_video_call: retLang('Video calls', 'Appels Vidéo.', ''),
        vide_confer: retLang('Video confer.', 'Vidéo confer.', ''),
        create_group: retLang('Create group', 'Création groupe', ''),
        is_active: retLang('Is active?', 'Actif?', ''),
        is_admin: retLang('Is admin?', 'Administrateur?', ''),
        is_default: retLang('Is default?', 'Par défaut?', ''),
        can_print: retLang('Can print?', 'Peut Imprimer?', ''),
        can_delete: retLang('Can delete?', 'Peut supprimer?', ''),
        rec_mark_email: retLang('Marketing emails', 'Email marketing', ''),
        forgot_pw: retLang('Forgot password', 'Mot de passe oublié', ''),
        forgot_which_email: retLang('Forgot which email you used', 'Vous avez oublié quel email vous avez utilisé', ''),

        twitter: retLang('Twitter', 'Twitter', ''),
        facebook: retLang('Facebook', 'Facebook', ''),
        linkedin: retLang('LinkedIn', 'LinkedIn', ''),

        
        sms_date: retLang('SMS date', 'Date SMS', ''),
        sms_content: retLang('SMS content', 'Contenu SMS', ''),
        sms: retLang('SMS', 'SMS', ''),
        send: retLang('Send', 'Envoyer', ''),
        ngo: retLang('NGO', 'ONG', ''),
        ngos: retLang('NGOs', 'ONGs', ''),
        individuals: retLang('Individuals', 'Pers. Physiques', ''),
        individual: retLang('Individual', 'Pers. Physique', ''),
        corporates: retLang('Corporates', 'Entreprises', ''),
        corporate: retLang('Corporate', 'Entreprise', ''),
        businesses: retLang('Businesses', 'Affaires', ''),
        business: retLang('Business', 'Affaire', ''),
        governments: retLang('Governments', 'Gouvernements', ''),
        government: retLang('Government', 'Gouvernement', ''),
        religinsts: retLang('Religious Inst.', 'Inst. Réligieuses', ''),
        religinst: retLang('Religious Inst.', 'Inst. Réligieuses', ''),
        intern_organ: retLang('International org.', 'Organisation Int.', ''),

        latest_projects: retLang('Latest projects', 'Derniers projets', ''),
        delete_quest_rec: retLang('Do you want to delete this record?', 'Voulez-vous supprimer cet enregistrement?', ''),
        delete_all_quest_rec: retLang('Do you want to delete all the records in this list?', 'Voulez-vous supprimer tous les enregistrements de cette liste?', ''),
        joinByihuseCompany: retLang('Join Byihuse', 'Rejoindre Byihuse', ''),
        resetUrPw: retLang('Reset your password', 'Réinitialisez votre mot de passe', ''),
        setUrPw: retLang('Set your password', 'Définir votre mot de passe', ''),
        setPwNewOne: retLang('Set password with a new one', 'Définir le mot de passe avec un nouveau', ''),
        setPwMsg: retLang(
            'We just need your password so that we activate your account.',
            'Nous avons juste besoin de votre mot de passe pour activer votre compte.', ''),
        missing_settings: retLang('Missing settings', 'Paramètres manquants', ''),
        missing_settings_msg: retLang(
            'Some critical settings seem missing to run this command. You\'ll be redirected to the login page.', 
            'Certains paramètres critiques semblent manquants pour exécuter cette commande. Vous serez redirigé vers la page de connexion.', 
            ''),
        billing_info: retLang('Billing info', 'Infos. facturation', ''),
        cancel: retLang('Cancel', 'Annuler', ''),
        cred_card: retLang('Credit card', 'Carte de crédit', ''),
        connect: retLang('Connect', 'Relier', ''),
        my_curr_plan: retLang('My current plan', 'Mon plan actuel', ''),
        change_plan: retLang('Change plan', 'Modification du plan', ''),
        change_card: retLang('Change card', 'Changer la carte', ''),
        cancel_acct: retLang('Cancel account', 'Annuler compte', ''),
        formated_phone_nbr: retLang('Formatted phone', 'N° Tél formaté', ''),
        phone_nbr: retLang('Phone number', 'N° Téléphone', 'N° Telefone'),
        telephone_code: retLang('Telephone code', 'Code téléphone', ''),
        plan_msg_crt: retLang(
            'Unlimited Monthly Plan (US$25 billed monthly)',
            'Forfait mensuel illimité (25$ US facturés par mois)', ''),
        cred_card_info: retLang('Credit Card Info', 'Info. Carte de Crédit', ''),
        your_plan: retLang('Your plan', 'Votre plan', ''),
        name_card: retLang('Name on card', 'Nom sur carte', ''),
        card_nbr: retLang('Card number', 'N° de carte', ''),
        expiration: retLang('Expiration', 'Expiration', ''),
        sec_code: retLang('Security code', 'Code de sécurité', ''),
        info_cred_statement: retLang('Payments will appear on your credit card statement under the name Photo Products.',
            'Les paiements apparaîtront sur votre relevé de carte de crédit sous le nom Photo Products.', ''),
        billing_plan: retLang('Billing plan', 'Plan de facturation', ''),
        cvv2: retLang('CVV2', 'CVV2', ''),
        cred_card_deb: retLang('Credit/Debit card', 'Carte de crédit/débit ', ''),
        cred_card_deb_msg: retLang(
            'Your account is in free format and therefore cannot be canceled by this command.',
            'Votre compte est au format libre et ne peut donc pas être résilié par cette commande.', ''),
        free_account: retLang('Free account', 'Compte libre', ''),
        free_account_msg: retLang('This is a free format account. Kindly connect to your customer portal to change your account setting.',
            'Il s\'agit d\'un compte au format libre. Veuillez vous connecter à votre portail client pour modifier les paramètres de votre compte.', ''),

        payt_reminders: retLang('Payment reminders', 'Rappels de paiement', ''),
        not_activate: retLang('Not Active', 'Pas actif', ''),
        invoice_reminder_data: retLang('When active, an invoice reminder will be sent to your clients the day before a payment is due, as well as once a month for overdue payments.',
            'Lorsqu\'il est actif, un rappel de facture sera envoyé à vos clients le jour précédant le paiement, ainsi qu\'une fois par mois pour les paiements en souffrance.', ''),
        edit_invoice_options: retLang('Edit invoice options', 'Modifier les options de facture', ''),
        recommended_payt_options: retLang('Recommended payment option', 'Option de paiement recommandée', ''),

        square_payt_opt_msg: retLang('Square makes credit card processing simple. Accept Visa, Mastercard, Discover, and American Express all for one low rate, with no surprise fees. See deposits in your bank account as soon as the next business day.',
            'Square rend le traitement de carte de crédit simple. Acceptez Visa, Mastercard, Discover et American Express tous pour un faible taux, sans frais de surprise. Voir les dépôts dans votre compte bancaire dès le prochain jour ouvrable.', ''),
        square_accepted_cred: retLang('Accept credit cards in less than five minutes with no monthly fees and no setup fees.',
            'Acceptez les cartes de crédit en moins de cinq minutes sans frais mensuels ni frais d\'installation.', ''),
        square_accept_funds: retLang('Funds are deposited in your bank account in 1-2 business days', 'Les fonds sont déposés dans votre compte bancaire dans 1-2 jours ouvrables.', ''),
        square_accept_support: retLang('24/7 Support', 'Soutien 24/7', ''),

        other_payts_options: retLang('Other payment options', 'Autres moyens de paiement', ''),
        other_payts_options_msg: retLang('With Byihuse, you can easily accept online payments via Stripe, Square, PayPal, 2CheckOut, or Authorize.net. To save money on transaction fees and add more flexibility to your billing, enable ACH payments through Stripe which allow your clients to pay you via eCheck.',
            'Avec Byihuse, vous pouvez facilement accepter les paiements en ligne via Stripe, Square, PayPal, 2CheckOut ou Authorize.net. Pour économiser de l\'argent sur les frais de transaction et ajouter plus de souplesse à votre facturation, activez les paiements ACH via Stripe qui permettent à vos clients de vous payer via eCheck.', ''),

        mtn_mobile_money_rate: retLang('For usage tarif, check the following link', 'Pour le tarif d\'utilisation, vérifiez le lien suivant', ''),
        mtn_mobile_money_phone: retLang('Enter your mobile MTN telephone number', 'Entrez votre numéro de téléphone mobile MTN', ''),
        mtn_mobile_money_pw: retLang('Enter your MTN mobile money passcode', 'Entrez votre mot de passe pour MTN Mobile Money', ''),
        note: retLang('Note', 'Note', ''),
        add_note: retLang('Add Note', 'Ajouter Note', ''),
        days: retLang('Days', 'Jours', ''),
        unit: retLang('Unit', 'Unité', ''),

        tigo_cash_rate: retLang('For usage tarif, check the following link', 'Pour le tarif d\'utilisation, vérifiez le lien suivant', ''),
        tigo_cash_phone: retLang('Enter your mobile TIGO telephone number', 'Entrez votre numéro de téléphone mobile TIGO', ''),
        tigo_cash_pw: retLang('Enter your TIGO Cash passcode', 'Entrez votre mot de passe pour TIGO Cash', ''),
        airtel_cash_phone: retLang('Enter your mobile Airtel telephone number', 'Entrez votre numéro de téléphone mobile Airtel', ''),
        airtel_cash_pw: retLang('Enter your Airtel Money passcode', 'Entrez votre mot de passe pour Airtel Money', ''),
        make_this_select_default: retLang('Make this item selected by default', 'Régler cet élément par défaut', ''),
        allow_clt_choose_qty: retLang('Allow clients to choose a quantity', 'Autoriser les clients à choisir une quantité', ''),
        allowed_amt: retLang('Allowed amount', 'Montant plafond', ''),

        send_inv_reminder: retLang('Send invoice reminders', 'Envoyer des rappels de facture', ''),
        dt_send_inv_reminder: retLang('Do not send invoice reminders', 'Ne pas envoyer de rappel de facture', ''),

        jan_lng: retLang('January', 'Janvier', 'Mutarama'),
        feb_lng: retLang('February', 'Février', 'Gashyantare'),
        mar_lng: retLang('March', 'Mars', 'Werurwe'),
        apr_lng: retLang('April', 'Avril', 'Mata'),
        may_lng: retLang('May', 'Mai', 'Gicurasi'),
        jun_lng: retLang('Jun', 'Juin', 'Kamena'),
        jul_lng: retLang('July', 'Juillet', 'Nyakanga'),
        aug_lng: retLang('August', 'Août', 'Kanama'),
        sept_lng: retLang('September', 'Septembre', 'Nzeri'),
        oct_lng: retLang('October', 'Octobre', 'Ukwakira'),
        nov_lng: retLang('November', 'Novembre', 'Ugushyingo'),
        dec_lng: retLang('December', 'Decembre', 'Ukuboza'),

        jan_shrt: retLang('Jan', 'Janr', 'Muta'),
        feb_shrt: retLang('Feb', 'Fév', 'Gashy'),
        mar_shrt: retLang('Mar', 'Mars', 'Weru'),
        apr_shrt: retLang('Apr', 'Avr', 'Mata'),
        may_shrt: retLang('May', 'Mai', 'Gicu'),
        jun_shrt: retLang('Jun', 'Juin', 'Kame'),
        jul_shrt: retLang('July', 'Juil', 'Nyaka'),
        aug_shrt: retLang('Aug', 'Août', 'Kana'),
        sept_shrt: retLang('Sept', 'Sept', 'Nzeri'),
        oct_shrt: retLang('Oct', 'Oct', 'Ukwa'),
        nov_shrt: retLang('Nov', 'Nov', 'Ugus'),
        dec_shrt: retLang('Dec', 'Dec', 'Uku'),

        monday_lng: retLang('Monday', 'Lundi', ''),
        tuesday_lng: retLang('Tuesday', 'Mardi', ''),
        wednday_lng: retLang('Wednesday', 'Mercredi', ''),
        thursday_lng: retLang('Thursday', 'Jeudi', ''),
        friday_lng: retLang('Friday', 'Vendredi', ''),
        saturday_lng: retLang('Saturday', 'Samedi', ''),
        sunday_lng: retLang('Sunday', 'Dimance', ''),

        monday_shrt: retLang('Mon', 'Lun', ''),
        tuesday_shrt: retLang('Tue', 'Mar', ''),
        wednday_shrt: retLang('Wed', 'Mer', ''),
        thursday_shrt: retLang('Thu', 'Jeu', ''),
        friday_shrt: retLang('Fri', 'Ven', ''),
        saturday_shrt: retLang('Sat', 'Sam', ''),
        sunday_shrt: retLang('Sun', 'Dim', ''),


        credit_card_error: retLang('Credit card error', 'Erreur carte de crédit', ''),
        credit_card_error_msg: retLang(
            'It is a proprietary product and therefore you do not need any payment to process your app operations.', 
            'Il s\'agit d\'un produit propriétaire et vous n\'avez donc besoin d\'aucun paiement pour traiter vos opérations d\'application.', ''),
        sms_setts: retLang('SMS settings', 'Paramètres SMS', ''),

        add_sms_acct: retLang("Add SMS account", "Ajouter compte SMS", ''),
        edit_sms_acct: retLang("Edit SMS account", "Modifier compte SMS", ''),
        edit_sms: retLang('Edit SMS', 'Modifier SMS', ''),

        invoice_options: retLang('Invoice options', 'Paramètres facture', ''),
        current_settings: retLang('Current settings', 'Paramètres actuels', ''),
        footer: retLang('Footer', 'Pied de page', ''),
        no_footer: retLang('No footer', 'Pas de pied de page', ''),
        invoice_footer: retLang('Invoice footer', 'Pied de page/Facture', ''),
        rate_per: retLang('Rate', 'Taux', ''),
        disc_rate_per: retLang('Discount rate', 'Taux remise', ''),

        other: retLang('Other', 'Autre', ''),
        activate: retLang('Activate', 'Activer', ''),
        deactivate: retLang('Disable', 'Désactiver', ''),
        activate_qst: retLang('Would you like to activate this user?', 'Voudriez-vous activer cet utilisateur?', ''),
        deactivate_qst: retLang('Would you like to Disable this user?', 'Voudriez-vous désactiver cet utilisateur?', ''),
        activated: retLang('Activated', 'Activé', ''),
        deactivated: retLang('Disabled', 'Désactivé', ''),
        active: retLang('Active', 'Actif', ''),
        inactive: retLang('Inactive', 'Inactif', ''),
        status: retLang('Status', 'Etat', ''),

        
        sign_up: retLang('Sign Up', 'S\'inscrire', ''),
        sign_out: retLang('Sign out', 'Se déconnecter', ''),

        paypal_accept_rate: retLang('Costs: 2.9% + $0.30 per transaction', 'Coûts: 2,9% + 0,30 $ par transaction', ''),
        paypal_accept_plc: retLang('Enter Paypal Email Address', 'Entrez l\'adresse e-mail de Paypal', ''),


        authorize_payt_opt_msg: retLang('Authorize.net is a U.S.-based gateway, designed for high volume merchants. You will need to apply for a merchant account through your bank or another reseller.',
            'Authorize.net est une passerelle basée aux États-Unis, conçue pour les marchands à gros volumes. Vous devrez demander un compte marchand via votre banque ou un autre revendeur.', ''),
        authorize_api_key_place_log: retLang('Enter Authorize.net API key', 'Entrez la clé de l\'API Authorize.net', ''),
        authorize_api_key_place_pw: retLang('Enter Authorize.net Transaction Key', 'Entrez la clé de transaction Authorize.net', ''),
        authorize_enable_det_msg: retLang('Also, please enable the Transaction Details API on your account if you connect your Authnet account.',
            'De plus, activez l\'API des détails de la transaction sur votre compte si vous connectez votre compte Authnet.', ''),

        authorize_web_portal: retLang('Go to https://account.authorize.net/ portal',
            'Accédez au portail https://account.authorize.net/', ''),
        authorize_web_portal_click_acct: retLang('click "Account"',
            'Cliquez sur "Compte"', ''),
        authorize_web_portal_click_trans: retLang('click "Transaction Details API"',
            'Cliquez sur "API des détails de la transaction"', ''),
        authorize_web_portal_click_enable: retLang('then click "Enable Transaction Details API"',
            'Puis cliquez sur "Activer l\'API des détails de la transaction"', ''),

        stripe_accept_cred: retLang('Costs: Credit cards processed through the system have the following processing fee:',
            'Coûts: les cartes de crédit traitées dans le système ont les frais de traitement suivants:', ''),
        stripe_accept_rate: retLang('2.9% + $0.30 per transaction.', '2,9% + 0,30 $ par transaction.', ''),
        stripe_us_rates: retLang('All U.S. rates, others vary by country', 'Tous les taux des États-Unis, d\'autres varient selon le pays', ''),
        stripe_bk_pyts_days: retLang('Paid to your bank account within 2 business days', 'Payé sur votre compte bancaire dans les 2 jours ouvrables', ''),
        stripe_bk_pyt_name_prev: retLang('Credit card statement displays your name', 'Le relevé de carte de crédit affiche votre nom', ''),
        stripe_bk_pyt_setup_fees: retLang('No setup, monthly or card storage fees, no minimum charges, or penalties for failed payments',
            'Pas de configuration, les frais de stockage mensuels ou de carte, pas de frais minimaux ou les pénalités pour les paiements échoués', ''),
        
        fin_year: retLang('Financial year', 'Année fiscale', ''),
        fin_years: retLang('Financial years', 'Années fiscales', ''),
        fin_years_msg: retLang(
            'Fiscal years make it possible to group your financial transactions according to an annual and monthly grouping. So it is easy for you to search your data according to these settings.',
            'Les années fiscales permettent de regrouper vos transactions financières selon un groupement annuele et mensuel. Ainsi il vous est facile de chercer vos données selon ces paramètres.',
            ''),
        add_year_book: retLang('Add financial year', 'Ajouter année fiscale', ''),
        edit_year_book: retLang('Edit financial year', 'Modifier année fiscale', ''),
        locked: retLang('Locked', 'Verrouillé', ''),
        year: retLang('Year', 'Année', ''),
        
        name: retLang('Name', 'Nom', ''),
        menu: retLang('Menu', 'Menu', ''),
        files: retLang('Files', 'Fichiers', ''),
        code: retLang('Code', 'Code', ''),

        new: retLang('New', 'Nouveau', ''),
        print: retLang('Print', 'Imprimer', ''),
        printing: retLang('Printing', 'Impression', ''),
        


        sigle: retLang('Acronym', 'Sigle', ''),
        sub_unit: retLang('Sub unit', 'Sous unité', ''),
        iso_code: retLang('ISO code', 'Code ISO', ''),
        currency_rate: retLang('Currency rate', 'Cours devise', ''),
        rate_type: retLang('Rate type', 'Type taux', ''),
        sales_unit: retLang('Sales unit', 'Unité de vente', ''),

        purchase_price: retLang('Purchase price', 'Prix d\'achat', ''),

        selling_price: retLang('Selling price', 'Prix de vente', ''),
        min_qty_order: retLang('Minimum Order Qty', 'Qté Minimum d\'Achat', ''),
        edit_currency: retLang('Edit currency', 'Modifier devise', ''),
        format: retLang('Format', 'Format', ''),
        cotation_money: retLang('Trading', 'Cotation', ''),
        certain: retLang('Certain', 'Certain', ''),
        uncertain: retLang('Uncertain', 'Incertain', ''),
        trading_currency: retLang('Trading currency', 'Devise de cotation', ''),
        closing_price: retLang('Closing price', 'Cours clôture', ''),
        period_price: retLang('Period price', 'Uncertain', ''),
        product_line: retLang('Period price', 'Uncertain', ''),
        add_currency: retLang('Add currency', 'Ajouter devise', ''),
        closing_rate: retLang('Closing rate', 'Cours clôture', ''),
        period_rate: retLang('Period rate', 'Cours periode', ''),
        disc_by_quant: retLang('Discount by quantity', 'Remise par quantité', ''),
        disc_by_amt: retLang('Discount by amount', 'Remise par montant', ''),
        net_price: retLang('Net price', 'Prix net', ''),
        disc_type: retLang('Discount type', 'Type remise', ''),
        disc_value: retLang('Discount value', 'Valeur remise', ''),
        disc_options: retLang('Discount options', 'Options de réduction', ''),
        def_disc_type: retLang('Discount options', 'Options de réduction', ''),
        
        first_p: retLang('First', 'Première', ''),
        last_p: retLang('Last', 'Dernière', ''),
        prev_p: retLang('Previous', 'Précédente', ''),
        next_p: retLang('Next', 'Suivante', ''),
        tax_options: retLang('Tax options', 'Paramètres taxes', ''),
        tax_options_msg: retLang(
            'The products are taxed. Before creating the products, you must make sure that the legal taxes are already configured.',
            'Les produits sont taxés. Avant de créer les produits, vous devez vous assurer que les taxes légales sont déjà configurées.', ''),
        no_tax_fnd: retLang('No tax setting foud so far.', 'Aucun paramètre de taxe trouvé jusqu\'à présent.', ''),
        tax_type: retLang('Tax type', 'Type taxe', ''),
        tax_name: retLang('Tax name', 'Nom taxe', ''),
        add_tax: retLang('Add tax', 'Ajouter taxe', ''),
        edit_tax: retLang('Edit tax', 'Modifier taxe', ''),
        greaterThanZeroMSg: retLang(
            'The number must be greater than zero.', 
            'Le nombre doit être supérieur à zéro.', ''),
        printing_system: retLang('Printing system', 'Système d\'impression', ''),
        send_email: retLang('Send email', 'Envoyer email', ''),
        send_sms: retLang('Send SMS', 'Envoyer SMS', ''),
        make_call: retLang('Make a call', 'Passer un appel', ''),
        
        list_users: retLang('List of users', 'Liste des utilisateurs', ''),
        title: retLang('Title', 'Titre', ''),
        print_options: retLang('Printing settings', 'Paramètres d\'impression', ''),

        print_prev: retLang('Print preview', 'Aperçu avant impression', ''),
        just_print: retLang('Just print', 'Imprimer', ''),
        export_to_msword: retLang('Export to Ms Word', 'Exporter vers Ms Word', ''),
        export_to_msexcel: retLang('Export to Ms Excel', 'Exporter vers Ms Excel', ''),
        export_to_pdf: retLang('Export to PDF', 'Exporter vers PDF', ''),
        export_to_image: retLang('Export to Image', 'Exporter vers l\'image', ''),
        compose_email_content: retLang('Compose email content', 'Ecrire le contenu du message', ''),
        email_recipient: retLang('Email recipient', 'Destinataire du courriel', ''),
        email_subject: retLang('Email subject', 'Objet du message', ''),
        email_content: retLang('Email content', 'Contenu du message', ''),
        compose_email: retLang('Compose email', 'Composer un email', ''),
        add_recipient: retLang('Add recipient', 'Ajouter destinataire', ''),
        from: retLang('From', 'De', ''),
        subject: retLang('Subject', 'Objet', ''),
        
        add_new_rec_email: retLang('Add new recipient email', 'Ajouter un nouvel e-mail de destinataire', ''),
        add_new_rec_email_cc: retLang('Add new recipient email (CC)', 'Ajouter un nouvel e-mail de destinataire (CC)', ''),
        add_new_rec_email_bcc: retLang('Add new recipient email (BCC)', 'Ajouter un nouvel e-mail de destinataire (BCC)', ''),

        show_recipients_cc: retLang('Show recipients input area (CC)', 'Afficher la zone de saisie des destinataires (CC)', ''),
        show_recipients_bcc: retLang('Show recipients input area (BCC)', 'Afficher la zone de saisie des destinataires (BCC)', ''),
        hide_recipients_cc: retLang('Hide recipients input area (CC)', 'Masquer la zone de saisie des destinataires (CC)', ''),
        hide_recipients_bcc: retLang('Hide recipients input area (BCC)', 'Masquer la zone de saisie des destinataires (BCC)', ''),

        email_failure_sent: retLang(
            'Your email has not been sent successfully. Kindly check the related Email account and try again.', 
            'Votre Email n\'a pas été envoyé avec succès. Veuillez vérifier le compte Email associé et réessayer.', ''),
        
        countr_miss_msg: retLang(
            'The related country seems missing from the database. Try again.',
            'Le pays concerné semble absent de la base de données. Essayer à nouveau.', ''),
        success: retLang('Success', 'Réussite', ''),
        sms_failure_sent: retLang(
            'Your SMS has not been sent successfully. Kindly check the related SMS account and try again.', 
            'Votre SMS n\'a pas été envoyé avec succès. Veuillez vérifier le compte SMS associé et réessayer.', ''),
        sms_success_sent: retLang('SMS sent with success', 'SMS envoyé avec succès', ''),
        email_sent_success: retLang('Email sent with success', 'Email envoyé avec succès', ''),
        list_of_currencies: retLang(
            'List of currencies', 
            'Liste des dévises', ''),
        list_of_taxes: retLang(
            'List of taxes', 
            'Liste des taxes', ''),
        list_of_fin_years: retLang(
            'List of financial years', 
            'Liste des années fiscales', ''),
        list_of_sms_settings: retLang(
            'List of sems settings', 
            'Liste des paramètres SMS', ''),
    
        list_of_email_settings: retLang(
            'List of email settings', 
            'Liste des paramètres Email', ''),
        
        terms_m: retLang('Terms', 'Termes', ''),
        cookies_m: retLang('Cookies', 'Cookies', ''),
        privacy_m: retLang('Privacy', 'Vie privée', ''),
        contactus_m: retLang('Contact Us', 'Nous contacter', ''),
        terms_serv: retLang('Terms of Service', 'Conditions d\'utilisation', ''),
        priv_policy: retLang('Privacy Policy', 'Politique de confidentialité', ''),
        all_rights_reserved: retLang('All Rights Reserved', 'Tous droits reservés', ''),
        cookie_policy: retLang('Cookie Policy', 'Politique de cookie', ''),
        
        english_m: retLang('English', 'Anglais', ''),
        french_m: retLang('French', 'Français', ''),
        rwanda_m: retLang('Kinyarwanda', 'Kinyarwanda', ''),
        swahili_m: retLang('Swahili', 'Swahili', ''),
        lingala_m: retLang('Lingala', 'Lingala', ''),
        other_m: retLang('Other language', 'Autre langue', ''),
        
        required: retLang('Required', 'Obkigatoire', ''),
        address_line_1: retLang('Address line 1', 'Ligne d\'adresse 1', ''),
        address_line_2: retLang('Address line 2', 'Ligne d\'adresse 2', ''),
        region: retLang('Region', 'Région', ''),
        phoneSample: retLang('078 895 3336', '078 895 3336', ''),
        emailSample: retLang('someone@byihuse.rw', 'quelqun@byihuse.rw', ''),
        type: retLang('Type', 'Type', ''),
        automatic: retLang('Automatic', 'Automatique', ''),
        manual: retLang('Manual', 'Manuel', ''),
        new_customer: retLang('New customer', 'Nouveau client', ''),
        form_error_invalid: retLang(
            'The form seems invalid. Some required fields need to be informed before proceeding.', 
            'Le formulaire semble invalide. Certains champs obligatoires doivent être renseignés avant de continuer.', ''),
        
        add_sub_categ: retLang('Add subcategory', 'Ajouter sous-catégorie', ''),
        edit_sub_categ: retLang('Edit subcategory', 'Modifier sous-catégorie', ''),
        edit_categ: retLang('Edit category', 'Modifier catégorie', ''),

        sub_code: retLang('Sub code', 'Sous Code', ''),
        reload: retLang('Reload', 'Recharger', ''),
        add_new_subcateg: retLang('Add new sub-category', 
            'Ajouter une nouvelle sous-catégorie', ''),
        delete_subcateg: retLang('Delete sub-category', 
            'Supprimer sous-catégorie', ''),        
        save_subcateg: retLang('Save sub-category', 
            'Enregistrer sous-catégorie', ''),       
        all_sub_categs: retLang('All related sub-categories', 
            'Toutes les sous-catégories connexes', ''),            
        sub_categories: retLang('Subcategories', 
            'Sous-catégories', ''),
        list_of_subcategs: retLang('List of subcategories', 
            'Liste des sous-catégories', ''),        
        list_of_subcategs_msg: retLang(
            `
            Byihuse supports subcategories. Subcategories allow sellers to classify 
            different products into sub-categories and thus allow analytical analysis 
            by sub-category.
            `, 
            `
            Byihuse prend en charge les sous-catégories. Les sous-catégories 
            permettent aux vendeurs de classer différents produits en sous-catégories 
            et permettent ainsi une analyse analytique par sous-catégorie.
            `,
            ''),

        
        loading_subcategs: retLang('Loading related sub-categories',
            'Chargement des sous-catégories associées', ''),
        
        no_subcateg_found: retLang(
            'No sub-category record has been found in the database', 
            'Aucun enregistrement de sous-catégorie n\'a été trouvé dans la base de données', ''),
        no_rec_fnd: retLang('No record has been found in the database', 'Aucun enregistrement n\'a été trouvé dans la base de données', ''),
        origin: retLang('Origin', 'Origine', ''),
        phone: retLang('Phone', 'Téléphone', ''),
        tags: retLang('Tags', 'Mots clés', ''),
        tag: retLang('Tag', 'Mot clé', ''),
        new_tag: retLang('New tag', 'Nouveau mot clé', ''),
        accept_marketing: retLang('Accept marketing', 'Accepter le marketing', ''),
        tax_exempted: retLang('Tax exempted', 'Exonéré d\'impôt', ''),
        
        lists: retLang('Lists', 'Listes', ''),
        list: retLang('List', 'Liste', ''),
        list_of_customers: retLang('List of customers', 'Liste des clients', ''),
        list_of_customers_msg: retLang(
            `Byihuse customers are people from different entities who make the decision to 
            buy online the products sold by Finvia Group or one of its partners. Some of 
            these customers may go directly to the offices of Finvia Group or one of its 
            partners. Their information can be entered automatically or manually.`, 
            `Les clients de Byihuse sont des personnes de différentes entités qui prennent 
            la décision d'acheter en ligne les produits vendus par Finvia Group ou 
            l'un de ses partenaires. Certains de ces clients peuvent s'adresser directement 
            aux bureaux de Finvia Group ou de l'un de ses partenaires. Leurs informations 
            peuvent être saisies automatiquement ou manuellement.`, ''),

        now: retLang('Now', 'Maintent', ''),
        select: retLang('Select', 'Sélectionner', ''),
        failure: retLang('Failure', 'Echec', ''),

        sincha_call_error: retLang('There is no Voice account. Please setup one voice account before proceeding.',
            'Il n\'y a pas de compte Voice. Veuillez configurer un compte vocal avant de continuer.', ''),
        phone_format_error: retLang('Phone format error', 'Erreur de format de téléphone', ''),
        phone_format_error_msg: retLang('The phone number should be in the format (+000 000 000 000)', 'Le numéro de téléphone devrait être au format (+000 000 000 000)', ''),
        phone_format_error_change_msg: retLang('Please, change the phone number before proceeding.', 'Veuillez changer le numéro de téléphone avant de continuer.', ''),
        email_format_error_change_msg: retLang('Please, change the email before proceeding.', 'Veuillez changer l\'email avant de continuer.', ''),

        supplier: retLang('Supplier', 'Fournisseur', ''),
        suppliers: retLang('Suppliers', 'Fournisseurs', ''),
        list_of_suppliers_msg: retLang(
            `
                Byihuse does not only sell its products. It mainly relies on the products of its 
                suppliers in order to display them online for the benefit of its customers. By having 
                the list of suppliers in the database, it is possible to make product statistics by supplier 
                but also to determine the commissions that each supplier is supposed to pay to the Flivia 
                Group account.
            `, 
            `
                Byihuse ne vend pas seulement ses produits. Elle s'appuie principalement sur les produits de 
                ses fournisseurs afin de les afficher en ligne au profit de ses clients. En ayant la liste des 
                fournisseurs dans la base de données, il est possible de faire des statistiques produits par 
                fournisseur mais aussi de déterminer les commissions que chaque fournisseur est censé verser 
                sur le compte Flivia Group.
            `,
             ''),
        list_of_suppliers: retLang('List of suppliers', 'Liste des fournisseurs', ''),
        new_supplier: retLang('New supplier', 'Nouveu fournisseur', ''),
        
        
        buy_online_happy: retLang('Buy online and be happy!', 'Achetez en ligne et soyez heureux !', ''),
        brands: retLang('Brands', 'Marques', ''),
        new_brand: retLang('New brand', 'Nouvelle marque', ''),
        description: retLang('Description', 'Description', ''),

        new_sub_categ: retLang('New Sub-Category', 'Nouvelle Sous-Catégorie', ''),
        list_sub_categ: retLang('Sub Categories', 'Sous Catégories', ''),
        new_categ: retLang('New category', 'Nouvelle catégori', ''),
        categories: retLang('Categories', 'Catégories', ''),
        list_of_categs: retLang('List of categories', 'Liste des catégories', ''),
        list_of_categs_msg: retLang(
            `
            A product category is a way for businesses to group and organize products or 
            services they sell. When products are categorized, it is easier for customers 
            to find what they're looking for on a website and employees to quickly refer 
            to and find a product. 
            `, 
            `
            Une catégorie de produits est un moyen pour les entreprises de regrouper et d'organiser 
            les produits ou services qu'elles vendent. Lorsque les produits sont catégorisés, il est 
            plus facile pour les clients de trouver ce qu'ils recherchent sur un site Web et pour les 
            employés de se référer et de trouver rapidement un produit.
            `, 
            ``),

        list_of_brands: retLang('List of Brands', 'Liste des Marques', ''),
        list_of_brands_msg: retLang(
            `
            Product brands are essential for establishing good sales statistics. 
            They make it possible to know the brand relating to each product but also to 
            know the best-selling brand over a period of time. This information will serve as 
            a benchmark for making decisions about which products are most likely to sell easily.
            `, 
            `
            Les marques de produits sont essentielles pour établir 
            de bonnes statistiques de ventes. Ils permettent de 
            connaître la marque relative à chaque produit mais 
            aussi de connaître la marque la plus vendue sur une 
            période de temps. Ces informations serviront de 
            référence pour prendre des décisions sur les produits 
            les plus susceptibles de se vendre facilement.
            `, 
            ''),
        
        barcode: retLang('Barcode', 'Code à barre', ''),
        miss_barcode: retLang(
            'Kindly provide a barcode before processing.',
            'Veuillez fournir un code-barres avant le traitement.', ''),
        success_barcode_available: retLang(
            'This barcode is available to be used.',
            'Ce code-barres est disponible pour être utilisé.', ''),

        name_prod_info_msg: retLang(
            'The item name is used to identify products in different reports',
            'Le nom de l\'article est utilisé pour identifier les produits dans différents rapports.', ''),
        sku_min_err_msg: retLang(
            'The SKU requires a minimum of 12 digits.',
            'Le UGS nécessite un minimum de 12 chiffres.',
            ''),
        barcode_min_err_msg: retLang(
            'The barcode requires a minimum of 10 digits.',
            'Le code-barres nécessite un minimum de 10 chiffres.',
            ''),
        barcode_info_msg: retLang(
            "A barcode is an image that consists of a series of parallel black and white bars that can be read by a barcode scanner. Barcodes are applied to products to quickly identify them. Among their many uses, barcodes are typically used in retail stores as a part of the purchasing process, in warehouses to track and manage inventory and on invoices to help with accounting. ",
            "Un code-barres est une image constituée d'une série de barres parallèles noires et blanches qui peuvent être lues par un lecteur de codes-barres. Des codes-barres sont appliqués aux produits pour les identifier rapidement. Parmi leurs nombreuses utilisations, les codes-barres sont généralement utilisés dans les magasins de détail dans le cadre du processus d'achat, dans les entrepôts pour suivre et gérer les stocks et sur les factures pour faciliter la comptabilité.",
            ''),
        sku_ex: retLang('ex: 120252232100', 'ex: 120252232100', ''),
        sku: retLang('SKU', 'UGS', ''),
        sku_info_msg: retLang(
            'In the field of inventory management, a stock keeping unit (SKU /ˌɛsˌkeɪˈjuː, ˈsk(j)uː/) ' +
            'is a distinct type of item for sale, [1]: 122  such as a product or service, and all ' +
            'attributes associated with the item type that distinguish it from other item types. For ' +
            'a product, these attributes can include manufacturer, description, material, size, color, ' +
            'packaging, and warranty terms.When a business takes inventory of its stock, it counts ' +
            'the quantity it has of each SKU.',
            'L\'unité de gestion des stocks ou UGS également appelé unité de vente ' +
            'consommateur ou UVC est la traduction de l\'anglais SKU, qui signifie ' +
            'stock keeping unit.Il ne s\'agit pas d\'une unité de mesure mais d\'une ' +
            'référence unique de produit utilisée en gestion des stocks.Elle désigne ' +
            'une référence élémentaire(par exemple une marque ou un modèle), à un ' +
            'niveau suffisamment déterminé pour servir à la gestion précise des ' +
            'volumes disponibles2.Chaque UGS est généralement désignée par un code, ' +
            'mais il n\'y a pas de standardisation dans ce domaine.',
            ''),
        barcode_ex: retLang('ex: 1285632145', 'ex: 1285632145', ''),
        qty_in_stock_sh: retLang('Qty in Stock', 'Qté en stock', ''),
        qty_in_stock_sh_err_msg: retLang(
            'You are trying to add in stock more items than what remains in the purchase order. Check this out before proceeding.', 
            'Vous essayez d\'ajouter en stock plus d\'articles que ce qui reste dans le bon de commande. Vérifiez ceci avant de continuer.', ''),
        sell_price: retLang('Selling price', 'Prix de vente', ''),
        show_variants: retLang('Show the variants', 'Afficher les variantes', ''),
        ed_variant_opt: retLang('eg. Color, Size', 'Ex: Couleur, Taille', ''),
        ed_variant_opt_val: retLang('eg. Red, Green, Yellow, L, S, M, XL', 'Ex: Rouge, Vert, Jaune, L, S, M, XL', ''),


        new_product: retLang('New product', 'New product', ''),
        generate_barcode: retLang('Generate barcode', 'Générer un code-barres', ''),
        select_category: retLang('Select category', 'Choisir une catégorie', ''),
        remove_category: retLang('Remove category', 'Supprimer la catégorie', ''),
        add_sub_category: retLang('Add subcategory', 'Ajouter sous-catégorie', ''),
        remove_sub_category: retLang('Select category', 'Supprimer sous-catégorie', ''),
        no_categ_selected: retLang('No categories selected', 'Aucune catégorie sélectionnée', ''),
        no_sub_categ_selected: retLang('No subcategories selected', 'Aucune sous-catégorie sélectionnée', ''),
        product: retLang('Product', 'Produit', ''),
        service: retLang('Service', 'Service', ''),
        select_brand: retLang('Select brand', 'Sélectionnez la marque', ''),
        select_supplier: retLang('Select supplier', 'Sélectionnez le fournisseur', ''),
        brand: retLang('Brand', 'Marque', ''),
        none_selected: retLang('None selected', 'Aucun sélectionné', ''),
        
        track_stock: retLang('Track Stock', 'Suivi des stocks', ''),
        track_stock_info_msg: retLang(
            'Check this option if you are keeping track of this item in your stock inventory.',
            'Cochez cette option si vous gardez une trace de cet article dans votre inventaire de stock.', ''),
        is_taxable: retLang('Is Taxable', 'Taxable', ''),
        is_taxable_info_msg: retLang(
            'Check this option is the item is taxable.',
            'Cochez cette option si l\'article est taxable.',
            ''),
        allow_sales_out_stock: retLang('Allow sales out stock', 'Autoriser les soldes de stock', ''),
        allow_sales_out_stock_info_msg: retLang(
            'Allow the product to be sold out of stock.',
            'Permettre au produit d\'être vendu en rupture de stock.',
            ''),
        
        reorder_quantity: retLang('Reorder quantity', 'Quantité de réapprovisionnement', ''),
        reorder_quantity_info_msg: retLang(
            'Reorder quantity is the number of units that should be included in the new order. Reorder level decides when to order a new stock of raw materials. The number of units to be ordered is decided based on the reorder quantity.',
            'La quantité de réapprovisionnement est le nombre d\'unités qui doivent être incluses dans la nouvelle commande.Le niveau de réapprovisionnement décide quand commander un nouveau stock de matières premières.Le nombre d\'unités à commander est déterminé en fonction de la quantité à commander.', ''),
        minimum_order_qty: retLang('Minimum order quantity', 'Quantité minimum d\'achat', ''),
        minimum_order_qty_info_msg: retLang(
            'A minimum order quantity is the fewest number of units required to be purchased at one time.',
            'Une quantité minimale de commande est le plus petit nombre d\'unités devant être achetées en même temps.', ''),
        
        purchase_price_info_msg: retLang(
            'The purchase price is the price an investor pays for an investment, and the price becomes the investor’s cost basis for calculating gain or loss when selling the investment. It can be changed during the creation of purchase order.',
            'Le prix d\'achat est le prix qu\'un investisseur paie pour un investissement, et le prix devient la base de coût de l\'investisseur pour calculer le gain ou la perte lors de la vente de l\'investissement. Il peut être modifié lors de la création du bon de commande.',
            ''),
        selling_price_info_msg: retLang(
            'The selling price of a product or service is the seller’s final price, i.e., how much the customer pays for something. It can be changed during the creation of Quotation or Sales Order.',
            'Le prix de vente d\'un produit ou d\'un service est le prix final du vendeur, c\'est - à - dire combien le client paie pour quelque chose.Il peut être modifié lors de la création du Devis ou du Bon de Commande.',
            ''),
        
        description_prod_info_msg: retLang(
            'Description of product to describe better a product.',
            'Décrire mieux un produit',
            ''),
        product_type_info_msg: retLang(
            'Byihuse handles two kinds of products. A service and a product. Services are intangible products.',
            'Byihuse gère deux types de produits. Un service et un produit. Les services sont des produits immatériels.',
            ''),
        tags_info_details: retLang(
            'Tag values can be used as filters in the product listing page when searching for specific product, multiple tags are allowed for a single product or item.',
            'Les valeurs des balises peuvent être utilisées comme filtres dans la page de liste de produits lors de la recherche d\'un produit spécifique, plusieurs balises sont autorisées pour un seul produit ou article.',
            ''),
        brand_info_msg: retLang(
            'A brand is a name, term, design, symbol or any other feature that identifies one seller\'s good or service as distinct from those of other sellers. Brands are used in business, marketing, and advertising for recognition and, importantly, to create and store value as brand equity for the object identified, to the benefit of the brand\'s customers, its owners and shareholders.',
            'Une marque est un nom, un terme, un dessin, un symbole ou toute autre caractéristique qui identifie le bien ou le service d\'un vendeur par rapport à ceux d\'autres vendeurs. Les marques sont utilisées dans les affaires, le marketing et la publicité pour la reconnaissance et, surtout, pour créer et stocker de la valeur en tant que capital de marque pour l\'objet identifié, au profit des clients de la marque, de ses propriétaires et de ses actionnaires.', ''),
    
        default_supplier_info_msg: retLang(
            'If you think that this product has a default supplier, select it so that the system proposes it by default during purchasing operations.',
            'Si vous pensez que ce produit a un fournisseur par défaut, sélectionnez-le pour que le systèle le propose par défaut lors des opérations d\'achats.', ''),
        
        tax: retLang('Tax','Taxe',''),
        select_tax: retLang('Select tax','Sélectionnez taxe',''),
        default_supplier: retLang('Default supplier','fournisseur par défaut',''),
        default_tax: retLang('Default tax','Taxe par défaut',''),
        default_tax_info_msg: retLang(
            'In a multi-tax environment, it is possible to select a default tax that can be applied directly to the product. This operation is required when a product or service is taxable.', 
            'Dans un environnement multi-taxe, il est possible de sélectionner une taxe par défaut qui pourra être appliqué directement au produit. Cette opération est requise quand un produit ou service est taxable.',''),
    
        default_tax_msg_error: retLang(
            'When the product or service is taxable, the default tax field becomes mandatory.', 
            'Lorsque le produit ou le service est taxable, le champ de taxe par défaut devient obligatoire.',''),

        uom_sh: retLang('UoM', 'UGS', ''),
        uom: retLang('Unity of Measure', 'Unité de Gestion de Stock', ''),
        uom_info_msg: retLang(
            'The unit of measure (UoM) helps us count “How much” of an item or “How many” counts of an item is involved in a transaction or is stored in a subinventory and/or locator.',
            'L\'unité de mesure(UdM) nous aide à compter «Combien» d\'un article ou «Combien» d\'un article est impliqué dans une transaction ou est stocké dans un magasin et/ou un emplacement.',
            ''),
        uom_ex: retLang('ex: bottle', 'ex: bouteille', ''),
        uom_more_details: retLang(
            'Specify the smallest product unit that will be sold in. If this field is left blank, the system will take base UoM as "Unit(s)"',
            'Spécifiez la plus petite unité de produit qui sera vendue. Si ce champ est laissé vide, le système prendra l\'UGS de base comme "Unité(s)" ', ''),
    
        stock_entry_op: retLang('Stock Entry Operation', 'Opération d\'entrée de stock', ''),
        stock_entry_op_qst_msg: retLang(
            'Do you want to record this stock operation? This operation, once done, cannot be undone. Proceed?', 
            'Voulez-vous enregistrer cette opération de stock ? Cette opération, une fois effectuée, ne peut pas être annulée. Procéder?', ''),
        stock_exit_op: retLang('Stock Exit Operation', 'Opération de Sortie de stock', ''),
        entry_type: retLang('Entry type', 'Type entrée', ''),
        exit_type: retLang('Exit type', 'Type sortie', ''),
        closing_transact: retLang('Closing transaction', 'Opération de clôture', ''),
        entry_operation: retLang('Entry operation', 'Opération d\'entrée', ''),
        exit_operation: retLang('Exit operation', 'Opération de sortie', ''),
        sales_order: retLang('Sales order', 'Commande client', ''),
        sales_orders: retLang('Sales orders', 'Commandes client', ''),
        operation_date: retLang('Operation date', 'Date opération', ''),
        salesman: retLang('Salesman', 'Vendeur', ''),
    
        past_due: retLang('Past due', 'En retard', ''),
        elt_done: retLang('Done', 'Terminé', ''),
        upcoming: retLang('Upcoming', 'A venir', ''),
        stock_entry: retLang('Stock entry', 'Entrée de stock ', ''),
        stock_exit: retLang('Stock exit', 'Sortie de stock ', ''),
        variants: retLang('Variants', 'Variantes', ''),
        add_variant: retLang('Add variants', 'Ajouter variante', ''),
        add_variant_det: retLang('Add variant detail', 'Ajouter détail variante', ''),
        no_variant_fnd: retLang('No variant found', 'Aucune variante trouvée', ''),
        variant_values: retLang('Variant values', 'Valeur variantes', ''),
        no_variant_val_fnd: retLang('No variant values found', 'Aucune valeur variante trouvée', ''),
        new_variant: retLang('New variant', 'Nouvelle variante', ''),
        edit_variant: retLang('Edit variant', 'Modifier variante', ''),
        variant: retLang('Variant', 'Variante', ''),
        preview: retLang('Preview', 'Visualiser', ''),
        delete_variant_code_req_msg: retLang(
            'Do you want to delete this variant code related to this product?', 
            'Voulez-vous supprimer ce code de variante lié à ce produit ?', ''),
        new_variant_det: retLang('New variant detail','Nouveau detail variante',''),
        edit_variant_det: retLang('Edit variant detail','Modifier detail variante',''),
        sell_price_zero_err: retLang(
            'The selling price must be greater than zero',
            'Le prix de vente doit être supérieur à zéro..',''),
        purchase_price_zero_err: retLang(
            'The purchase price must be greater than zero',
            'Le prix d\'achat doit être supérieur à zéro..',''),
        delete_variant_detail_req_msg: retLang(
            'Do you want to delete this variant deleted related to this product?', 
            'Voulez-vous supprimer ce détail de variante lié à ce produit ?', ''),
        variant_detail: retLang('Variant details', 'Détails variantes', ''),
        no_variant_det_fnd: retLang('No variant detail found', 'Aucun détail des variantes trouvé', ''),
        manage_variants_prod: retLang('Manage product\'s variants', 'Gérer les variantes du produit', ''),
        details: retLang('Details', 'Détails', ''),
        values: retLang('Values', 'Valeurs', ''),
        codes: retLang('Codes', 'Codes', ''),
        purchases: retLang('Purchases', 'Achats', ''),
        list_of_purchase_ops: retLang(
            'List of purchase operations', 
            'Liste des opérations d\'achat', ''),
        list_of_purchase_ops_msg: retLang(
            `
                Purchase is the movement by which a natural or legal person becomes 
                the possessor of something in exchange for the financial value 
                attributed to it, expressed in currency (which is not necessarily 
                the local currency), given to his ex- possessor. The act takes place 
                within the framework of a process comprising a series of decisions 
                and an execution. The object of purchase is acquisition and not 
                everything offered for sale is necessarily purchased.
            `, 
            `
                L'achat est le mouvement par lequel une personne physique ou morale 
                devient possesseur de quelque chose en échange de la valeur financière 
                attribuée à celle-ci, exprimée en monnaie (qui n'est pas forcément la 
                monnaie localenote), donné à son ex-possesseur. L'acte s'opère dans 
                le cadre d'un processus comprenant une série de décisions et une 
                exécution. L'objet de l'achat est l'acquisition et tout ce qui est 
                mis en vente n'est pas obligatoirement acheté.
            `, ''),
        purch_order: retLang('Purchase Order', 'Bon de Commande', ''),
        purch_order_sh: retLang('P.O.', 'BC', ''),
        purch_date: retLang('P. Date', 'Date Cde', ''),
        purch_order_value: retLang('P. Value', 'Valeur Cde', ''),
        po_status: retLang('P.O. Status', 'Etat Cde', ''),
        not_paid: retLang('Not paid', 'Non payé', ''),
        part_paid: retLang('Partly paid', 'Payé partiellement', ''),
        fully_paid: retLang('Fully paid', 'Payé', ''),
        new_purch_op: retLang('New purchase operation', 'Nouvelle operation d\'achat', ''),
        edit_purch_op: retLang('Edit purchase operation', 'Modifier operation d\'achat', ''),
        oper_date: retLang('Oper. date', 'Date opér.', ''),
        errorDateBetween: (fDate: string, lDate: string) => {
            return retLang(
                `This date must be between ${fDate} and ${lDate}.`, 
                `La date devrait être entre ${fDate} and ${lDate}.`, '')
        },
        draft: retLang('Draft', 'Bruillon', ''),
        accepted: retLang('Accepted', 'Accepté', ''),
        confirmed: retLang('Confirmed', 'Confirmé', ''),
        show_details: retLang('Show details', 'voir les détails', ''),
        confirm: retLang('Confirm', 'Confirmer', ''),
        confirm_msg: retLang(
            'Kindly confirm the acceptance and the validity of this purchase order. Once confirm, you can no longer modify it.', 
            'Veuillez confirmer l\'acceptation et la validité de ce bon de commande. Une fois confirmé, vous ne pourrez plus le modifier.', ''),
        loading_purchase_details: retLang('Loading purchase order details', 'Chargement des détails de la commande d\'achat', ''),
        process_paymet: retLang('Process payment', 'Traiter le paiement', ''),
        process_order_paymet: retLang('Process purchase order payment', 'Traiter le paiement du bon de commande', ''),
        order_dets: retLang('Order details', 'Détails de commande', ''),
        order_dets_msg: retLang(
            `
                In this window, you are asked to add the details of the underlying order. 
                You will specify the products or their variants, the quantity and the 
                desired discount. Please check before validating the operation.
            `, 
            `
                Dans cette fenêtre, il vous est demandé d'ajouter les détails de 
                la commande sous-jacente. Vous préciserez les produits ou leurs variantes, 
                la quantité et la remise souhaitée. Veuillez vérifier avant de valider 
                l'opération.
            `, 
            ``),
        purchase_order_dets: retLang('Purchase order details', 'Détails du bon de commande', ''),
        purchase_order_dets_msg: retLang(
            `
                This list contains the details, reference made to the products, of the relative order form.
                Be sure to add only the details regarding the purchase order of the associated number below.
                Once the purchase order is confirmed, you can transfer these order details to your stock so that they are available for sale.
            `, 
            `
                Cette liste contient les détails, référence faite aux produits, du bon de commande relatif. 
                Assurez-vous d'y ajouter seulement les détails concernant le bon de commande du numéro associé ci-bas.
                Une fois le bon de commande est confirmé, vous pouvez transférer ces détails dans votre stock pour qu'ils soient accessibles à la vente.
            `, ''),
            confirm_purch_order_quest: retLang(
                `Do you want to confirm this purchase order?\n
                Once confirmed, this PO cannot be used for details or deleted.\n
                Proceed?`, 
                `
                Voulez-vous confirmer ce bon de commande?\n
                Une fois confirmé, ce bon de commande ne peut plus être utilisé pour des détails ou être supprimé.\n
                Procéder?
                `, ''),
            total_amt: retLang('Total amt', 'Totam amt', ''),
            prod_variant: retLang('Prod./Variant', 'Prod./Variant', ''),
            qty: retLang('Qty', 'Qté', ''),
            stock_info: retLang('Stock info', 'Info stock', ''),
            remaining: retLang('Remaining', 'Restant', ''),
            in_stock: retLang('In stock', 'En stock', ''),
            out_stock: retLang('Out of stock', 'Stortie stock', ''),
            critic: retLang('Critic', 'Critique', ''),
            stock_value: retLang('Stock value', 'Valeur de stock', ''),
            po: retLang('PO', 'BC', ''),
            add_purchase_detail: retLang('Add purchase detail', 'Ajouter detail vente', ''),
            edit_purchase_detail: retLang('Edit purchase detail', 'Modifier detail vente', ''),
            select_product: retLang('Select product', 'sélectionner le produit', ''),
            select_variant: retLang('Select variant', 'sélectionner variante', ''),
            unit_price: retLang('Unit price', 'Prix unitaire', ''),
            sub_total: retLang('Sub total', 'Sous total', ''),
            items_elts: retLang('Items', 'Eléments', ''),
            items_arts: retLang('Items', 'Articles', ''),
            show_payts: retLang('Show payments', 'Afficher les paiements', ''),
            payments: retLang('Payments', 'Paiements', ''),
            list_of_payments: retLang('List of payments', 'Liste des Paiements', ''),
            list_of_payments_msg: retLang(
                `
                Payment transactions are made within the framework of orders placed 
                with suppliers of services or products for sale online or directly 
                with buyers who present themselves directly or who buy online.
                `, 
                `
                Les opérations de paiement s'effectuent dans le cadre de commandes 
                passées auprès de fournisseurs de services ou de produits en vente 
                en ligne ou directement auprès d'acheteurs qui se présentent directement 
                ou qui achètent en ligne.
                `, 
                ''),
            doct_nbr: retLang('Doct. nbr.', 'N° Doct.', ''),
            date: retLang('Date', 'Date', ''),
            payt_date: retLang('Payt. date', 'Date paiement', ''),
            payt_amt: retLang('Payt. amount', 'Montant paiement', ''),
            payt_status: retLang('Payt. status', 'Etat paiement', ''),
            delivery_status: retLang('Delivery status', 'Etat livraison', ''),
            add_payt: retLang('Add payment', 'Ajouter apiement', ''),
            edit_payt: retLang('Edit payment', 'Modifier apiement', ''),
            all_payts: retLang('All payments', 'Tous paiments', ''),
            amt_paid: retLang('Amount paid', 'Montant payé paiments', ''),
            suppl_req_miss: retLang(
                'The required supplier seems missing. Try again.', 
                'Le fournisseur requis semble manquant. Essayer à nouveau.', ''),
            lock_purch_order: retLang('Lock purchase order', 'Vérouiller la commande', ''),
            lock_purch_order_msg: retLang(
                'This purchase order is fully paid. You can lock it once for all.', 
                'Ce bon de commande est entièrement payé. Vous pouvez le verrouiller une fois pour toutes.', ''),
            lock_purch_order_qst: retLang(
                `You're about to lock this purchase order. This operation cannot be undone. Proceed?`, 
                'Vous êtes sur le point de verrouiller ce bon de commande. Cette opération ne peut pas être annulée. Procéder?', ''),
        
            add_stock_oper: retLang('Add stock operation', 'Ajouter une opération de stock', ''),
            edit_stock_oper: retLang('Edit stock operation', 'Modifier une opération de stock', ''),


            rem_purch_miss: retLang('Quantity settings error', 'Erreur de paramétrage de quantité', ''),
            rem_purch_miss_msg: retLang(
                'The remaining quantity to be transfered to the stock is unsufficient for this operation to be carried out.', 
                'La quantité restante à transférer au stock est insuffisante pour que cette opération puisse être effectuée.', 
                ''),
            pces_per_unit: retLang('Pieces per unit', 'Pièces par unité', ''),
            pces_per_unit_msg: retLang(
                'This field defines how many pieces are in one defined product unit.',
                'Ce champ définit le nombre de pièces dans une unité de produit définie.',
                ''),
            prod_miss: retLang('Product missing', 'Produit manquant', ''),
            prod_miss_msg: retLang(
                'The product affected by this operation seems to be missing from the database. Try again.', 
                'Le produit concerné par cette opération semble manquante de la base de données. Essayez encore.', ''),
            prod_name: retLang('Prod. name', 'Nom produit', ''),
            stock_status: retLang('Stock status', 'Etat du stock', ''),
            stock_status_msg: retLang(
                `
                This list shows the stock status in the current fiscal year. In your settings, 
                you can change the fiscal year to see how the stock is doing in the selected fiscal 
                year. Products or product variants are displayed with their estimated values as 
                of today's date.
                `, 
                `
                Cette liste présente l'état du stock dans l'exercice fiscal en cours. 
                Dans vos paramètres, vous puvez changer l'exercice fiscal pour voir comment 
                le stock se porte dans l'exercice fiscal sékectionné. Les produits ou les 
                variantes des produits sont affichés avec leur valeurs estimées à la date du jour.
                `, 
                ``),
            po_qty: retLang('PO Qty', 'Qté BC', ''),
            to_be_transf: retLang('To be transfered', 'A être transféré', ''),
            cost_price: retLang('Cost price', 'Prix de revient', ''),
            fully_transf: retLang('Fully transferred', 'Entièrement transféré', ''),
            price_settings: retLang('Price settings', 'Paramètre des prix', ''),
            price_settings_stock_err_msg: retLang(
                'It seems that there\'s an error in your price settings. Review this issue before proceeding.', 
                'Il semble qu\'il y ait une erreur dans vos paramètres de prix. Examinez ce problème avant de continuer.', 
                ''),
            preview_details: retLang('View details', 'Visualiser les détails', ''),
            stock_details_by_prod: retLang('Stock details/product', 'Détails du stock/produit', ''),
            stocksit_by_prod: retLang('Stock situation/product', 'Situation de stock/produit', ''),
            stocksit_by_prod_msg: retLang(
                `
                This command displays stock details for the selected product. The stock situation will 
                take into account entries, exits as well as the records generated at the end of the 
                fiscal year.
                `, 
                `
                Cette commande affiche les détails du stock pour le produit sélectionné. L'état des 
                stocks tiendra compte des entrées, des sorties ainsi que des enregistrements générés 
                en fin d'exercice.
                `,
                ``),
            oper_type: retLang('Oper. Type', 'Type opération', ''),
            price: retLang('Prix', 'Prix', ''),
            variant_codes: retLang('Variant codes', 'Codes variantes', ''),
            stock_exit_msg: retLang(
                `
                Cash out operations provide information on the situation of operations carried out during 
                product deliveries from stock in progress, manual outgoing operations and 
                fiscal year closing operations.
                `, 
                `
                Les opérations de sortie de caisse informent sur la situation des opérations effectuées 
                lors de livraisons des produits à partir des stock en cours, les opérations de sortie 
                manuelles et les opérations de clôture de l'exercice fiscal.
                `,
                ``),
            prod_type: retLang('Prod. type', 'Type produit', ''),
            staff: retLang('Staff', 'Utilisateur', ''),
            customer: retLang('Customer', 'Client', ''),
            total: retLang('Total', 'Total', ''),
            closing: retLang('Closing', 'Clôture', ''),
            sales: retLang('Sales', 'Ventes', ''),
            add_exit_oper: retLang('Add exit operation', 'Ajouter opération sortie', ''),
            edit_exit_oper: retLang('Edit exit operation', 'Modifier opération sortie', ''),
            deliveries: retLang('Deliveries', 'Livraisons', ''),
            list_of_sales_orders: retLang('List of sales orders', 'Liste des commandes clients', ''),
            list_of_sales_orders_msg: retLang(
                `
                Sales is a term used to describe the activities that lead to the selling of 
                goods or services. Businesses have sales organizations that are broken up 
                into different teams. And these sales teams are often determined based on 
                the region they're selling to, the product or service they're selling, 
                and the target customer.
                `, 
                `
                Les ventes sont un terme utilisé pour décrire les activités qui mènent à 
                la vente de biens ou de services. Les entreprises ont des organisations de 
                vente divisées en différentes équipes. Et ces équipes de vente sont souvent 
                déterminées en fonction de la région à laquelle elles vendent, du produit ou 
                du service qu'elles vendent et du client cible.
                `, 
                ''),
            order_nbr: retLang('Order nbr.', 'N° Cde', ''),
            not_delivered: retLang('Not delivered', 'non livré', ''),
            part_delivery: retLang('Part. delivery', 'Livr. partielle', ''),
            full_delivery: retLang('Fully delivered', 'Livr. complète', ''),
            order_status: retLang('Order status', 'Etat cde.', ''),
            completed: retLang('Completed', 'Terminé', ''),
            add_sales_record: retLang('Add sales operation', 'Ajouter opération ventes', ''),
            edit_sales_record: retLang('Edit sales operation', 'Modifier opération ventes', ''),
            select_customer: retLang('Select customer', 'Choisir le client', ''),

            created_by: retLang('Created by', 'Créé par', ''),
            select_staff: retLang('Select staff', 'Choisir personnel', ''),
            miss_client: retLang('Missing customer', 'Client manquant', ''),
            miss_client_msg: retLang(
                'The selected customer seems missing from the database. Kindly review this issue before proceeding.', 
                'Le client sélectionné semble absent de la base de données. Veuillez examiner ce problème avant de continuer.', ''),
            discount: retLang('Discount', 'Remise', ''),
            new_sales_oeration: retLang('New sales operation', 'Nlle. opération des ventes', ''),
            add_operation: retLang('Add operation', 'Ajouter opération', ''),
            edit_operation: retLang('Edit operation', 'Modifier opération', ''),
            taxable_amt: retLang('Taxable amt.', 'Montant taxable', ''),
            discounts: retLang('Discounts', 'Remises', ''),
            list_of_discounts: retLang('List of discounts', 'Liste des Remises', ''),
            list_of_discounts_msg: retLang(
                `
                A sales discount is a reduction in the price of a product or service that is 
                offered by the seller, in exchange for early payment by the buyer. A sales 
                discount may be offered when the seller is short of cash, or if it wants to 
                reduce the recorded amount of its receivables outstanding for other reasons.
                `, 
                `
                Une remise de vente est une réduction du prix d'un produit ou d'un service 
                qui est offerte par le vendeur, en échange d'un paiement anticipé par 
                l'acheteur. Une remise sur vente peut être offerte lorsque le vendeur 
                est à court de liquidités ou s'il souhaite réduire le montant enregistré 
                de ses créances impayées pour d'autres raisons.
                `,
                ''),
            start: retLang('Start', 'Debut', ''),
            end: retLang('End', 'Fin', ''),
            endless_date: retLang('Endless date', 'Fin', ''),
            endless: retLang('Endless', 'Sans fin', ''),
            all_clients: retLang('All clients', 'Tous clients', ''),
            specific_clients: retLang('Specific clients', 'Clients spécifiques', ''),
            all_products: retLang('All products', 'Tous produits', ''),
            specific_products: retLang('Specific products', 'Produits spécifiques', ''),
            new_discount: retLang('New discount', 'Nouvelle remise', ''),
            edit_discount: retLang('Edit discount', 'Modifier remise', ''),
            has_end_date: retLang('Has end date?', 'Une date de fin', ''),
            end_date_error_msg: retLang(
                'The end date should greater than the start date', 
                'La date de fin devrait être supérieure à la date de début', ''),
            apply_all_clients: retLang('Apply to all clients?', 'Appliquer à tous les clients ?', ''),
            apply_all_prods: retLang('Apply to all products?', 'Appliquer à tous les produits ?', ''),
            selected_customers: retLang('Selected customers', 'Clients sélectionnés', ''),
            selected_products: retLang('Selected products', 'Produits sélectionnés', ''),
            has_rules: retLang('Has rules', 'Avec règles', ''),
            value: retLang('Value', 'Valeur', ''),
            show_rules: retLang('Show rules', 'Afficher les règles', ''),
            add_rule: retLang('Add rule', 'Ajouter une règle', ''),
            new_rule: retLang('New rule', 'Nouvelle règle', ''),
            edit_rule: retLang('Edit rule', 'Modifier règle', ''),
            min_qty: retLang('Minimum qty', 'Qté minimum', ''),
            max_qty: retLang('Maximum qty', 'Qté maximum', ''),
            tax_included: retLang('Tax included', 'Taxe incluse', ''),
            save_sales_oper_qst_msg: retLang(
                'Would you like to save this sales operation?', 
                'Souhaitez-vous enregistrer cette opération de vente ?', ''),
            save_quote_oper_qst_msg: retLang(
                'Would you like to save this quote operation?', 
                'Souhaitez-vous enregistrer cette opération de devis ?', ''),
            payment: retLang('Payment', 'Paiement', ''),
            sales_orderprocess_payt_qst: retLang(
                'This sales order is not yet paid. Process payment?', 
                'Cette commande client n\'est pas encore payée. Traiter le paiement ?', ''),
            add_payment: retLang('Add payment', 'Ajouter paiement', ''),
            edit_payment: retLang('Edit payment', 'Modifier paiement', ''),
            receipt_nbr: retLang('Receipt nbr', 'N° Reçu', ''),
            payt_confirm_qst: retLang(
                'Do you want to save this payment operate?', 
                'Voulez-vous enregistrer cette opération de paiement ?', ''),
            mobile_money: retLang('Mobile money', 'Transfert mobile', ''),
            cash: retLang('Cash', 'Espèces', ''),
            cash_payt: retLang('Cash payt', 'Paiemt. espèces', ''),
            delivery: retLang('Delivery', 'Livraison', ''),
            sales_orderprocess_delivery_qst: retLang(
                'This sales order is not yet delivered. Process delivery?', 
                'Cette commande client n\'est pas encore livrée. Traiter la livraison ?', ''),
            list_of_sales_payts: retLang('List of sales payments', 'Liste des paiements des ventes', ''),
            list_of_sales_payts_msg: retLang(
                `
                    Payment orders are a request to push or pull funds from one bank account to another. 
                    Payment orders that push funds from one of your accounts to another 
                    (whether internal or external) are credit payments. Payment orders that 
                    attempt to pull funds from an external account are debit payments.
                `, 
                `
                Les ordres de paiement sont une demande de transfert ou de retrait de fonds d'un compte bancaire à un autre.
                     Ordres de paiement qui poussent des fonds d'un de vos comptes vers un autre
                     (qu'ils soient internes ou externes) sont des paiements à crédit. Les ordres de paiement qui
                     tenter de retirer des fonds d'un compte externe sont des paiements par débit.
                `, 
                ''),
            balance: retLang('Balance', 'Solde', ''),
            sales_payts: retLang('Sales payments', 'Paiements des ventes', ''),
            sales_date: retLang('Sales date', 'Date de vente', ''),
            order_amt: retLang('Order amount', 'Mt. vente', ''),
            paid_amt: retLang('Paid amount', 'Mt. payé', ''),
            rem_amt: retLang('Rem. amount', 'Mt. restant', ''),
            lock: retLang('Lock', 'Clôturer', ''),
            view_payments: retLang('View payments', 'Afficher paiements', ''),
            payts_by_order: retLang('Payments by order', 'Paiements par commande', ''),
            list_of_payt_by_order: retLang('List of payments by order', 'Liste des paiements par commande', ''),
            list_of_payt_by_order_msg: retLang(
                `
                This page lists the payments made in relation to the referenced 
                customer order. You can add payments but the sum of these payments 
                should never exceed the total amount of the sales order.
                `, 
                `
                Cette page liste les paiements effectués en rapport avec la commande 
                client referencée. Vous pouvez ajouter les paiemnets mais la somme 
                de ces paiement ne devraient jamais dépasser le montant total de la 
                commande client.
                `, ''),
            payt_nbr: retLang('Payt nbr', 'N° Payt', ''),
            amount: retLang('Amount', 'Montant', ''),
            amt_topay_greater: retLang('The amount seems greater that what is expected.', 'Le montant semble supérieur à ce qui est attendu.', ''),
            list_of_sales_delivs: retLang('List of sales orders deliveries', 
                'Liste des livraisons des commandes clients', ''),
            sales_delivs: retLang('Sales orders deliveries', 
                'Livraisons des commandes clients', ''),
            list_of_sales_delivs_msg: retLang(
                `
                Deliveries of orders are made according to the rules defined in the
                 product sheets. These are adapted according to the information
                 defined in the client files. These operations cannot
                 be canceled because they have a direct impact on the stocks of
                 products concerned.
                `, 
                `
                Les livraisons des commandes se font les règles définies dans les 
                fiches des produits. Celles-ci sont adaptées selon les informations 
                définies dans les fichiers clients. Ces opérations ne peuvent pas 
                être annulées car elles ont un impact direct sur les stocks des 
                produits concernés.
                `,
                ``),
            deliv_nbr: retLang('Delivered nbr', 'N° Livraison', ''),
            deliv_qty: retLang('Delivered qty', 'Qté livré', ''),
            delivs_by_order: retLang('Deliveries by sales order', 'Livraisons par commande client', ''),
            delivs_by_order_msg: retLang(
                `
                    This page lists all deliveries related to the selected sales order. 
                    You can add new deliveries, in competition with the remaining quantity, 
                    but it is impossible to delete any because each delivery operation directly 
                    impacts the outgoing stock operations. If you realize that this operation is in error, 
                    you can perform stock entry operations to establish your stock balance.
                `, 
                `
                    Cette page répertorie toutes les livraisons liées à la commande client sélectionnée. 
                    Vous pouvez ajouter de nouvelles livraisons, en concurrence avec la quantité restante, 
                    mais il est impossible d'en supprimer car chaque opération de livraison impacte directement 
                    les opérations sortantes. Si vous vous rendez compte que cette opération est erronée, 
                    vous pouvez effectuer des opérations de saisie de stock pour établir votre solde de stock.
                `, 
                ``),
            ordered_qty: retLang('Ordered qty', 'Qté cdée', ''),
            rem_qty: retLang('Remain. qty', 'Qté rest.', ''),
            view_product_detail: retLang('View product details', 'Afficher les détails du produit', ''),
            lock_order: retLang('Lock order', 'Clôturer commande client', ''),
            lock_order_msg_qst: retLang(
                'Are you sure you want to lock this sales order? This operation can no longer be undone. Proceed?', 
                'Voulez-vous vraiment verrouiller cette commande client ? Cette opération ne peut plus être annulée. Procéder?', ''),
            
            add_delivery_oper: retLang('Add delivery operation', 'Ajouter une opération de livraison', ''),
            edit_delivery_oper: retLang('Edit delivery operation', 'Modifier une opération de livraison', ''),
            reciepient_name: retLang('Recipient name', 'Nom du destinataire', ''),
            reciepient_phone: retLang('Recipient pnone', 'Tél. du destinataire', ''),
            delivery_details: retLang('Delivery details', 'Détails de livraison', ''),
            to_deliver: retLang('To deliver', 'A livrer', ''),
            save_deliv_ops: retLang('Save delivery operation', 'Enregistrer l\'opération de livraison', ''),
            save_deliv_ops_msg: retLang(
                'Do you want to save this delivery operation. This command can no longer be undone once carried out. Proceed?', 
                'Voulez-vous enregistrer cette opération de livraison. Cette commande ne peut plus être annulée une fois exécutée. Procéder?', ''),
            
            delivery_details_msg: retLang(
                `
                This preview displays the list of delivery details that were performed during the delivery 
                operation. It should be remembered that a customer order can have several deliveries, 
                which in turn may concern only a few quantities of the products. These details cannot 
                be changed or deleted.
                `,
                `Cet aperçu affiche la liste des details de la livraison qui ont été effectués durant 
                l'opération de la livraison. Il faut se rapeller qu'une commande client peut avoir plusieurs 
                livraisons, qui à leur tour peur vent concerner seulement quelques quantités des produits. 
                Ces détails ne peuvent ni être modifiés ou supprimés.`,
                ``
            ),
            order_details: retLang('Order details', 'Détails de comande', ''),
            order_details_msg: retLang(
                `
                This preview displays the list of customer order details that have 
                been recorded in the order form. These details are saved according 
                to the products and variants selected. The discounts are applied directly 
                according to the configurations applied in the discount files.
                `,
                `
                Cet aperçu affiche la liste des details de la commande client qui ont 
                été enregistrée dans la fiche de la commande. Ces détails sont enregistrés 
                selon les produits et les variantes sélectionnées. Les les rabais sont 
                appliqués directement selong les configurations appliquées dans les 
                fichiers des rabais.
                `,
                ``),
            type_order_nbr: retLang('Type sales order number', 'Tapez le numéro de commande client', ''),
            refund: retLang('Refund', 'Remboursement', ''),
            sales_refunds: retLang('Refund operations', 'Opérations de remboursement', ''),
            list_of_refund: retLang('List of refund operations', 'Liste des opérations de remboursement', ''),
            list_of_refund_msg: retLang(
                `
                Refund requests are usually sent via email, which is convenient for both eCommerce 
                companies and customers. These forms contain important details such as the reason 
                for the refund, the date of purchase, the amount of the product, etc. Customer 
                refund request forms also help companies track customer behaviour and preferences.
                `, 
                `
                Les demandes de remboursement sont généralement envoyées par e-mail, ce qui est 
                pratique pour les entreprises de commerce électronique et les clients. Ces 
                formulaires contiennent des détails importants tels que la raison du remboursement, 
                la date d'achat, le montant du produit, etc. Les formulaires de demande de 
                remboursement des clients aident également les entreprises à suivre le comportement 
                et les préférences des clients.
                `, 
                ''),
            add_refund_oper: retLang('Add refund operation', 'Ajouter opération remboursement', ''),
            edit_refund_oper: retLang('Edit refund operation', 'Modifier opération remboursement', ''),
            refund_nbr: retLang('Refund nbr', 'N° remb.', ''),
            refund_status: retLang('Refund status', 'Sit. remb.', ''),
            //stock_status: retLang('Refund status', 'Sit. remb.', ''),
            not_transfered: retLang('Not transfered', 'Non transféré', ''),
            part_transfered: retLang('Part. transfered', 'Transf. part.', ''),
            full_transfered: retLang('Full. transfer.', 'Transf. total.', ''),
            new_ref_operation: retLang('New refund operation', 'Nouvelle opération de remboursement', ''),
            ref_qty: retLang('Ref. qty', 'Qté ret', ''),
            to_refund: retLang('To refund', 'A retourner', ''),
            amt_to_ref: retLang('Amt refund', 'Mt. à retourner', ''),
            totals: retLang('Totals', 'Totaux', ''),
            ref_details: retLang('Refund details', 'Détails du remboursement', ''),
            ref_details_msg: retLang(
                `
                The list presented to you below includes all the details of the 
                underlying command. Please only specify the quantity of products 
                that the customer wants us to reimburse him. The other calculations 
                will be automatically based on mathematical formulas in the command sheet.
                `,
                `
                La liste qui vous est présentée ci-dessous reprend tous les détails de 
                la commande sous-jacente. Veuillez préciser seulement la quantité des 
                produits que le client veut qu'on puisse lui rembourser. Les autres 
                calculs se feront automatiquement se basant sur les formules 
                mathématiques se trouvant dans la fiche des commandes.
                `,
                ``),
            save_sales_ref_oper_qst_msg: retLang(
                'Would you like to save this sales refund operation?', 
                'Souhaitez-vous enregistrer cette opération de remboursement ?', ''),
            sales_refund_process_payt_qst: retLang(
                'This sales refund is not yet paid. Process payment?', 
                'Ce remboursement n\'est pas encore payé. Traiter le paiement ?', ''),
            sales_refund_process_delivery_qst: retLang(
                'This sales refund is not yet entered into the stock. Proceed stock entry operation?', 
                'Ce remboursement des ventes n\'est pas encore entré dans le stock. Procéder à une opération d\'entrée en stock?', ''),
            refund_details: retLang('Refund details', 'Détails remboursements', ''),
            refund_details_msg: retLang(
                `
                This preview displays the list of refund details that have 
                been recorded in the refund operation form. These details are saved according 
                to the refund policy and in relation with the selected order. 
                The discounts are applied directly according to the configurations 
                applied in the discount files.
                `,
                `
                Cet aperçu affiche la liste des détails de remboursement qui ont été 
                enregistrés dans le formulaire d'opération de remboursement. Ces données 
                sont enregistrées conformément à la politique de remboursement et en relation 
                avec la commande sélectionnée. Les remises sont appliquées directement selon 
                les paramétrages appliqués dans les fichiers de remises.
                `,
                ``),
            confirm_refund_msg: retLang(
                'Kindly confirm the acceptance and the validity of this sales refund. Once confirm, you can no longer modify it.', 
                'Veuillez confirmer l\'acceptation et la validité de cette opération de remboursement. Une fois confirmé, vous ne pourrez plus le modifier.', ''),
            refund_payts: retLang('Refund payments', 'Paiements des remboursements', ''),
            list_of_refund_payts: retLang('List of refund payments', 'Liste des paiements des remboursements', ''),
            list_of_refund_payts_msg: retLang(
                `
                The amount recorded as refunds on sales is intended for balancing the 
                customer account. Depending on the customer's requirements and in accordance 
                with the refund policy, the products in question may be replaced or refunded 
                in cash. Ultimately, the customer account will be able to shed light on 
                the situation.
                `, 
                `
                Le montant enregistré au titre des restitutions sur ventes est destiné pour 
                l'équilibrage du compte client. Selon les exigences du client et en accord 
                avec la politique de remboursement, les produits mis en cause peuvent être 
                remplacés ou remboursés en numéraires. En définitive, le compte client pourra 
                éclairer la situation.
                `, 
                ''),
            entries_by_refuund: retLang('Entries by refund', 'Entrées par remboursement', ''),
            stock_entry_by_refuund: retLang('Stock entries by refund', 'Entrées en stock par remboursement', ''),
            stock_entry_by_refuund_msg: retLang(
                `
                    This page lists all stock entries related to the selected refund request. You can add 
                    new stock entries, in competition with the remaining quantity, but it is impossible 
                    to delete any because each stock entry operation is directly validated forever. 
                    If you realize that this operation is wrong, you can perform inventory entry 
                    operations to establish your inventory balance.
                `, 
                `
                    Cette page répertorie toutes les entrées en stock liées à la demande de remboursement sélectionnée. 
                    Vous pouvez ajouter de nouvelles entrées en stock, en concurrence avec la quantité restante, 
                    mais il est impossible d'en supprimer car chaque opération d'entrée en stock est 
                    directement validée pour toujours. Si vous vous rendez compte que cette opération est erronée, 
                    vous pouvez effectuer des opérations de saisie de stock pour établir votre solde de stock.
                `, 
                ``),
            entered_qty: retLang('Entered qty', 'Qté entrée', ''),
            entry_oper_nbr: retLang('Entry oper nbr', 'N° Oper. Entrée', ''),
            add_ref_stock: retLang('Add refund stock oper. (From refund)', 'Ajouter opér stoc (Remboursement)', ''),
            edit_ref_stock: retLang('Edit refund stock oper. (From refund)', 'Modifier opér stoc (Remboursement)', ''),
            refund_stock_details: retLang('Refund - Stock entry details', 'Remboursement - Détails entrée de stock', ''),
            refund_stock_details_msg: retLang(
                `
                This page displays details of stock entries from refund transactions. 
                You cannot remove them because they have a counterpart in socks 
                operations.
                `,
                `
                Cette page affiche les détails des entrées en stock à 
                partir des opérations des remboursements. Vous ne pouvez pas 
                les supprimer car elles ont une contrepartie dans les opérations 
                des socks. `,
                ``),
            quotes: retLang('Quotes', 'Devis', ''),
            sales_quotes: retLang('Sales quotes', 'Devis de vente', ''),
            sales_quote: retLang('Sales quote', 'Devis de vente', ''),
            list_of_quotes: retLang('List of sales quotes', 'Liste des devis', ''),
            list_of_quotes_msg: retLang(
                `
                A sales quote is simply a document that states a price for goods and services. 
                Quotes can be fixed and may include detailed item-by-item breakdowns. Alternatively, 
                quotes may provide an estimate for a project.`, 
                `
                Un devis de vente est simplement un document qui indique un prix pour 
                des biens et des services. Les devis peuvent être fixes et peuvent inclure des 
                ventilations détaillées article par article. Alternativement, les devis peuvent 
                fournir une estimation pour un projet.
                `, 
                ''),
            converted: retLang('Converted', 'Converti', ''),
            rejected: retLang('Rejected', 'Rejeté', ''),
            convert_to_order: retLang('Convert to order sales', 'Convertir en commande client', ''),
            convert_to_order_msg_qst: retLang(
                'Do you want to convert this quote into a sales order?', 
                'Voulez-vous convertir ce devis en commande client?', ''),
            convert: retLang('Convert', 'Convertir', ''),
            convert_to_order_title: retLang('Covert this quote to sales order', 'Convertir ce devis en commande client', ''),
            quote_nbr: retLang('Quote nbr.', 'N° Devis', ''),
            quote_status: retLang('Quote status', 'Etat Devis', ''),
            lock_quote: retLang('Lock quote', 'Clôturer devis', ''),
            lock_quote_msg_qst: retLang(
                'Are you sure you want to lock this sales quote? This operation can no longer be undone. Proceed?', 
                'Voulez-vous vraiment verrouiller ce devis? Cette opération ne peut plus être annulée. Procéder?', ''),
            reject: retLang('Reject', 'Rejeter', ''),
            reject_title: retLang('In case the client rejects this sales quote, kindly reject it.',
                'Dans le cas où le client rejette cette offre de vente, veuillez la rejeter.', ''),
            reject_msg_qst: retLang('Do you want to reject this sales quote?',
                'Voulez-vous rejecter cette offre de devis?', ''),
            add_sales_quote: retLang('Add sales quote', 'Ajouter un devis', ''),
            edit_sales_quote: retLang('Edit sales quote', 'Modifier un devis', ''),
            new_quote_oper: retLang('New quote operation', 'Devis: Nouvelle opération', ''),
            quote_details: retLang('Quote details', 'Devis: Détails', ''),
            quote_details_msg: retLang(
                `
                    In this window, you are asked to add the details of the underlying sales quote. 
                    You will specify the products or their variants, the quantity and the 
                    desired discount. Please check before validating the operation.
                `, 
                `
                    Dans cette fenêtre, il vous est demandé d'ajouter les détails de 
                    devis sous-jacent. Vous préciserez les produits ou leurs variantes, 
                    la quantité et la remise souhaitée. Veuillez vérifier avant de valider 
                    l'opération.
                `, 
                ``),
            purchase_order: retLang('Purchase order', 'Bon de commande', ''),
            delivery_note: retLang('Delivery note', 'Bon de livraison', ''),
            sales_recipt: retLang('Sales receipt', 'Reçu de vente', ''),
            print_receipt: retLang('Print receipt', 'Imprimer reçu', ''),
            print_refund_note: retLang('Print Refund note', 'Imprimer Note de remboursement', ''),
            refund_note: retLang('Refund note', 'Note de remboursement', ''),
            situation: retLang('Situation', 'Situation', ''),
            print_list: retLang('Print list', 'Impression liste', ''),
            print_situation: retLang('Print situation', 'Impression situation', ''),
            total_revenue: retLang('Total revenue', 'Revenu total', ''),
            zero_growth: retLang('Zero growth', 'Croissance nulle', ''),
            positive_growth: retLang('Positive growth', 'Croissance positive', ''),
            negative_growth: retLang('Negative growth', 'Croissance négative', ''),
            total_invoices: retLang('Total invoices', 'Total des factures', ''),
            total_quotes: retLang('Total quotes', 'Total des devis', ''),
            new_customers: retLang('New customers', 'Nouveaux clients', ''),
            images: retLang('Images', 'Images', ''),
            delete_prod_image_qst: retLang(
                'Do you want to remove this image from this product or item?',
                'Voulez-vous supprimer cette image de ce produit ou article ?',
                ''),
            default_image: retLang('Default image', 'Image par défaut', ''),
            e_comm_images: retLang('E-commerce images', 'Images pour le commerce électronique', ''),
            add_image: retLang('Add image', 'Ajouter image', ''),
            remove_this_img: retLang('Remove this image', 'Supprimer cette image', ''),
            save_this_img: retLang('Save this image', 'Enregistrer cette image', ''),
            select_error: retLang('Selection error', 'Erreur de selection', ''),
            overall_sales_comparison: retLang(
                'Overall Sales Comparison', 'Comparaison globale des ventes', ''),
            top_ten_customers: retLang(
                'Top Ten Customers', 'Dix principaux clients', ''),
            top_ten_products: retLang(
                'Top Ten Products', 'Dix principaux produits', ''),
            top_ten_sales: retLang(
                'Top Ten Sales', 'Dix principalles Ventes', ''),
            top_ten_quotes: retLang(
                'Top Ten Quotes', 'Dix principaux Devis', ''),
            top_ten_employees: retLang(
                'Top Ten Employees', 'Dix principaux Employés', ''),





        }
};


export default GetLanguage;