
    import { defineComponent } from "vue";
    import GetLanguage from '@/tools/language';
    import {SayElt, SayingType} from '@/tools/smarttypes';

    export default defineComponent({
        data(){
            return {
                language: GetLanguage(),
                sayings: [
                    { say: 'Success is not final; failure is not fatal: it is the courage to continue that counts.', author: 'Winston Churchill' },
                    { say: 'Play by the rules, but be ferocious.', author: 'Phil Knight' },
                    { say: 'Business opportunities are like buses, there\'s always another one coming.', author: 'Richard Branson' },
                    { say: 'Every problem is a gift—without problems we would not grow.', author: 'Anthony Robbins' },
                    { say: 'You only have to do a few things right in your life so long as you don\'t do too many things wrong.', author: 'Warren Buffett' },
                    { say: 'Success usually comes to those who are too busy to be looking for it.', author: 'Henry David Thoreau' },
                    { say: 'And the day came when the risk to remain tight in a bud was more painful than the risk it took to blossom.', author: 'Anaïs Nin' },
                    { say: 'There\'s no shortage of remarkable ideas, what\'s missing is the will to execute them.', author: 'Seth Godin' },
                    { say: 'I owe my success to having listened respectfully to the very best advice, and then going away and doing the exact opposite.', author: 'G.K. Chesterton' },
                    { say: 'I don\'t know the word \'quit\'. Either I never did, or I have abolished it.', author: 'Susan Butcher' },
                    { say: 'Far and away the best prize that life offers is the chance to work hard at work worth doing.', author: 'Theodore Roosevelt' },
                    { say: 'If you really look closely, most overnight successes took a long time.', author: 'Steve Jobs' },
                    { say: 'Almost everything worthwhile carries with it some sort of risk, whether it\'s starting a new business, whether it\'s leaving home, whether it\'s getting married, or whether it\'s flying into space.', author: 'Chris Hadfield' },
                    { say: 'Even if you are on the right track, you\'ll get run over if you just sit there.', author: 'Will Rodgers' },
                    { say: 'The real test is not whether you avoid this failure, because you won\'t. It\'s whether you let it harden or shame you into inaction, or whether you learn from it; whether you choose to persevere.', author: 'Barack Obama' },
                    { say: 'Forget past mistakes. Forget failures. Forget everything except what you\'re going to do now and do it.', author: 'William Durant' },
                    { say: 'Imagination is everything. It is the preview of life\'s coming attractions.', author: 'Albert Einstein' },
                    { say: 'Character cannot be developed in ease and quiet. Only through experience of trial and suffering can the soul be strengthened, ambition inspired and success achieved.', author: 'Helen Keller' },
                    { say: 'The first one gets the oyster, the second gets the shell.', author: 'Andrew Carnegie' },
                    { say: 'The way to get started is to quit talking and begin doing.', author: 'Walt Disney' },
                ] as SayingType[] ,
                business_pics: [
                    'work_shop_1.jpg','work_shop_2.jpg',
                    'work_shop_3.jpg','work_shop_4.jpg',
                    'work_shop_5.jpg','work_shop_6.jpg'
                ],
            }
        },
        computed: {
            sayingElement(): SayElt {
                return {
                    saying: this.sayings[Math.floor(Math.random() * this.sayings.length)],
                    bg: { 'background-image': 'url(' + require('@/assets/images/quotes/' + this.business_pics[Math.floor(Math.random() * this.business_pics.length)]) + ")", }
                }
            }
        },
        mounted() {

        }
    });
