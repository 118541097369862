import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnalogClockScreen = _resolveComponent("AnalogClockScreen")!
  const _component_AnimatedHotCup = _resolveComponent("AnimatedHotCup")!
  const _component_LoadingAnimation = _resolveComponent("LoadingAnimation")!
  const _component_BouncingCircle = _resolveComponent("BouncingCircle")!
  const _component_GlassBallParticle = _resolveComponent("GlassBallParticle")!
  const _component_BulbAnimation = _resolveComponent("BulbAnimation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.animationPos === 0)
      ? (_openBlock(), _createBlock(_component_AnalogClockScreen, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.animationPos === 1)
      ? (_openBlock(), _createBlock(_component_AnimatedHotCup, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.animationPos === 2)
      ? (_openBlock(), _createBlock(_component_LoadingAnimation, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.animationPos === 3)
      ? (_openBlock(), _createBlock(_component_BouncingCircle, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.animationPos === 4)
      ? (_openBlock(), _createBlock(_component_GlassBallParticle, { key: 4 }))
      : _createCommentVNode("", true),
    (_ctx.animationPos === 5)
      ? (_openBlock(), _createBlock(_component_BulbAnimation, { key: 5 }))
      : _createCommentVNode("", true)
  ], 64))
}