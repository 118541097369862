import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vh-100 d-flex justify-content-center align-items-center"
}
const _hoisted_2 = {
  key: 1,
  class: "container"
}
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "flex-fill" }
const _hoisted_5 = { class: "h3" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "container-fluid no-padding-style" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-lg-3 col-md-6 pb-2" }
const _hoisted_10 = { class: "col-lg-3 col-md-6 pb-2" }
const _hoisted_11 = { class: "col-lg-3 col-md-6 pb-2" }
const _hoisted_12 = { class: "col-lg-3 col-md-6 pb-2" }
const _hoisted_13 = { class: "container-fluid no-padding-style mt-2 card" }
const _hoisted_14 = { class: "card-body" }
const _hoisted_15 = { class: "text-center fs-5 mb-2" }
const _hoisted_16 = { class: "container-fluid no-padding-style mt-2 card" }
const _hoisted_17 = { class: "card-body" }
const _hoisted_18 = { class: "text-center fs-5 mb-2" }
const _hoisted_19 = { class: "container-fluid no-padding-style mt-2 card" }
const _hoisted_20 = { class: "card-body" }
const _hoisted_21 = { class: "text-center fs-5 mb-2" }
const _hoisted_22 = { class: "container-fluid no-padding-style mt-2 card" }
const _hoisted_23 = { class: "card-body" }
const _hoisted_24 = { class: "text-center fs-5 mb-2" }
const _hoisted_25 = { class: "container-fluid no-padding-style mt-2 card" }
const _hoisted_26 = { class: "card-body" }
const _hoisted_27 = { class: "text-center fs-5 mb-2" }
const _hoisted_28 = { class: "container-fluid no-padding-style mt-2 card" }
const _hoisted_29 = { class: "card-body" }
const _hoisted_30 = { class: "text-center fs-5 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_DashboardElt = _resolveComponent("DashboardElt")!
  const _component_DashSalesCompare = _resolveComponent("DashSalesCompare")!
  const _component_TopTenCustomer = _resolveComponent("TopTenCustomer")!
  const _component_TopTenProducts = _resolveComponent("TopTenProducts")!
  const _component_TopTenSales = _resolveComponent("TopTenSales")!
  const _component_TopTenQuotes = _resolveComponent("TopTenQuotes")!
  const _component_TopTenEmployees = _resolveComponent("TopTenEmployees")!

  return (_ctx.isStillLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['fa', 'fa-spinner'],
          class: "fs-1"
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.language.dashboard), 1)
          ]),
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("select", {
              class: "form-control-sel outline-transp",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentMonth) = $event))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getListMonths, (oMonth) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: oMonth.id,
                  value: oMonth.id
                }, _toDisplayString(oMonth.label), 9, _hoisted_6))
              }), 128))
            ], 512), [
              [_vModelSelect, _ctx.currentMonth]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_DashboardElt, {
                listclasses: "bi bi-bank col-green",
                labelText: _ctx.language.total_revenue,
                hasCurrency: true,
                currencyLabel: _ctx.currencyLabel,
                typeOperation: 0,
                monthPeriod: _ctx.currentMonth
              }, null, 8, ["labelText", "currencyLabel", "monthPeriod"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_DashboardElt, {
                listclasses: "bi bi-receipt-cutoff col-blue",
                labelText: _ctx.language.total_invoices,
                hasCurrency: false,
                currencyLabel: _ctx.currencyLabel,
                typeOperation: 1,
                monthPeriod: _ctx.currentMonth
              }, null, 8, ["labelText", "currencyLabel", "monthPeriod"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_DashboardElt, {
                listclasses: "bi bi-journal-album col-amber",
                labelText: _ctx.language.total_quotes,
                hasCurrency: false,
                currencyLabel: _ctx.currencyLabel,
                typeOperation: 2,
                monthPeriod: _ctx.currentMonth
              }, null, 8, ["labelText", "currencyLabel", "monthPeriod"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_DashboardElt, {
                listclasses: "bi bi-people col-purple",
                labelText: _ctx.language.new_customers,
                hasCurrency: false,
                currencyLabel: _ctx.currencyLabel,
                typeOperation: 3,
                monthPeriod: _ctx.currentMonth
              }, null, 8, ["labelText", "currencyLabel", "monthPeriod"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("h4", _hoisted_15, _toDisplayString(_ctx.language.overall_sales_comparison), 1),
            _createVNode(_component_DashSalesCompare, {
              currentYear: _ctx.currentYear,
              typeOperation: 4,
              monthPeriod: _ctx.currentMonth
            }, null, 8, ["currentYear", "monthPeriod"])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("h4", _hoisted_18, _toDisplayString(_ctx.language.top_ten_customers), 1),
            _createVNode(_component_TopTenCustomer, {
              currentYear: _ctx.currentYear,
              typeOperation: 5,
              monthPeriod: _ctx.currentMonth
            }, null, 8, ["currentYear", "monthPeriod"])
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("h4", _hoisted_21, _toDisplayString(_ctx.language.top_ten_products), 1),
            _createVNode(_component_TopTenProducts, {
              currentYear: _ctx.currentYear,
              typeOperation: 8,
              monthPeriod: _ctx.currentMonth
            }, null, 8, ["currentYear", "monthPeriod"])
          ])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("h4", _hoisted_24, _toDisplayString(_ctx.language.top_ten_sales), 1),
            _createVNode(_component_TopTenSales, {
              currentYear: _ctx.currentYear,
              typeOperation: 6,
              monthPeriod: _ctx.currentMonth
            }, null, 8, ["currentYear", "monthPeriod"])
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("h4", _hoisted_27, _toDisplayString(_ctx.language.top_ten_quotes), 1),
            _createVNode(_component_TopTenQuotes, {
              currentYear: _ctx.currentYear,
              typeOperation: 7,
              monthPeriod: _ctx.currentMonth
            }, null, 8, ["currentYear", "monthPeriod"])
          ])
        ]),
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("h4", _hoisted_30, _toDisplayString(_ctx.language.top_ten_employees), 1),
            _createVNode(_component_TopTenEmployees, {
              currentYear: _ctx.currentYear,
              typeOperation: 9,
              monthPeriod: _ctx.currentMonth
            }, null, 8, ["currentYear", "monthPeriod"])
          ])
        ])
      ]))
}