
    import { defineComponent } from "vue";

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { ShortData } from "@/tools/smarttypes";

    import DashboardElt from '@/components/smalls/DashboardElement.vue';
    import DashSalesCompare from '@/components/smalls/DashboardComparisonSales.vue';    
    import TopTenCustomer from '@/components/smalls/DashboardTopCustomers.vue';  
    import TopTenProducts from '@/components/smalls/DashboardTopProducts.vue';    
    import TopTenSales from '@/components/smalls/DashboardTopSales.vue';    
    import TopTenQuotes from '@/components/smalls/DashboardTopQuotes.vue';    
    import TopTenEmployees from '@/components/smalls/DashboardTopStaff.vue';    

    const language =GetLanguage(),
        appFxs = GeneralFx(),
        fDate = new Date(); 

    export default defineComponent({
        components: {
            DashboardElt,
            DashSalesCompare,
            TopTenCustomer,
            TopTenProducts,
            TopTenSales,
            TopTenEmployees,
            TopTenQuotes,
        },
        data(){
            return {
                language,
                appFxs,
                isStillLoading: false,
                currentYear: fDate.getFullYear(),
                currentMonth: fDate.getMonth()+1,
                currencyLabel: 'RWF',
            }
        },
        computed: {
            getListMonths(): ShortData[] {
                return appFxs.getListMonthsByYear(this.currentYear);
            }
        },
        mounted(){
            document.title = this.language.dashboard + ' - ' + this.language.appName;
            this.currentYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook,
                fDate.getFullYear().toString()));

            

        }
    })
