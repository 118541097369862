
    import { defineComponent } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';
    
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';

    const language =GetLanguage(),
        appFxs = GeneralFx();

    export default defineComponent({
        data(){
            return {
                language,
                appFxs,
                yearApp: (new Date()).getFullYear(),
            }
        },
        methods: {
            setLang(lang: string){
                appFxs.setLocalStorage(appDeclarations.currentLang, lang);
                window.location.reload();
            }
        }
    });
