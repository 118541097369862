
    import { defineComponent } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';

    const language =GetLanguage(),
    appFxs = GeneralFx();
    export default defineComponent({
        data(){
            return {
                language,
            }
        },
        methods: {
            submitPasswordChange(){}
        },
        mounted(){
            document.title = `${this.language.settings}/${this.language.changeCredentials} - ${this.language.appName}`;

        }
    })
