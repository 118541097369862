import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withKeys as _withKeys, withModifiers as _withModifiers, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c393626"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "tags",
  ref: "tagsUl"
}
const _hoisted_2 = ["onClick", "disabled"]
const _hoisted_3 = { class: "flex-grow-1" }
const _hoisted_4 = ["title", "placeholder", "list", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex align-items-center flex-wrap", { 'with-count': _ctx.showCount }])
  }, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: tag,
          class: "tag"
        }, [
          _createTextVNode(_toDisplayString(tag) + " ", 1),
          _createElementVNode("button", {
            class: "delete",
            onClick: ($event: any) => (_ctx.removeTag(index)),
            disabled: _ctx.disabled
          }, "x", 8, _hoisted_2)
        ]))
      }), 128)),
      _createElementVNode("li", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          title: _ctx.language.new_tag,
          placeholder: '(' + _ctx.fPlaceHolder.toLowerCase() + ')',
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newTag) = $event)),
          list: _ctx.id,
          autocomplete: "off",
          onKeydown: [
            _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.addTag(_ctx.newTag)), ["enter"])),
            _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.addTag(_ctx.newTag)), ["prevent"]), ["tab"])),
            _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.newTag.length || _ctx.removeTag(_ctx.tags.length - 1)), ["delete"]))
          ],
          disabled: _ctx.disabled
        }, null, 40, _hoisted_4), [
          [_vModelText, _ctx.newTag]
        ])
      ])
    ], 512)
  ], 2))
}