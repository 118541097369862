
    import { defineComponent } from 'vue';
    import GetLanguage from '@/tools/language';
      
    const language = GetLanguage();
      
    
      export default defineComponent({
          name: "AnimatedHotCup",
          data(){
              return{
                language,
              }
          },
          methods: {
          },
          mounted(){
          },
          unmounted(){
          }
      })
  