
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, sameAs } from '@vuelidate/validators'
    import { defineComponent } from 'vue';
     
    import AccountHeader from '@/components/general/ByihuseAccountHeader.vue';
    import PasswordChecked from '@/components/general/PasswordChecker.vue';
    import AlertError from '@/components/general/AlertError.vue';

    import GetLanguage from '@/tools/language';  
    import AppFxs from '@/tools/general_fx';

    const language = GetLanguage();
    const appFxs = AppFxs();

    const passValidator = (value: string) => {
        return appFxs.passwordStrengthCheck(value) > 70;
    }


    export default defineComponent({
        emits: ["onFormSubmit", "onCleanError"],
        props: {
            isNetLoading: {
                type: Boolean,
                default: false,
                required: true
            },
            errorMessage: {
                type: String,
                default: ''
            },
            isErrorVisible: {
                type: Boolean,
                default: false,
            }
        },
        components: {
            AccountHeader,
            PasswordChecked,
            AlertError,
        },
        data(){
            return {
                v$: useVuelidate(),
                language,
                resetPwRecord: {
                    passwordResetKey: this.$route.params.resetKey,
                    password: '',
                    confPassword: '',
                }
            }
        },
        computed: {
            isFormError():boolean{
                return this.isNetLoading || 
                    !this.resetPwRecord.password || 
                    !this.resetPwRecord.confPassword ||
                    !(this.resetPwRecord.password === this.resetPwRecord.confPassword);
            },
            showLoading(): boolean{
                return this.isNetLoading;
            },
            showAlertError():boolean{
                return this.isErrorVisible;
            }
        },
        methods: {
            submitThisForm(evt: Event){
                this.$emit('onFormSubmit', {
                    password: this.resetPwRecord.password,
                    confPassword: this.resetPwRecord.confPassword,
                });
            },
            closeAlertError(){
                this.$emit('onCleanError')
            }
        },
        validations() {
            return {
                userEmail: {
                    required: helpers.withMessage(language.fldRequired, required), 
                    email: helpers.withMessage(language.emailFormat, email), 
                    $autoDirty: true
                },
                userPassword: {
                    required: helpers.withMessage(language.fldRequired, required),
                    passValid: helpers.withMessage(language.passValidMsg, passValidator),
                    minLength: helpers.withMessage(language.passLengthMsg, minLength(8)),
                    $autoDirty: true
                },
                resetPwRecord: {
                    passwordResetKey: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true
                    },
                    password: {
                        required: helpers.withMessage(language.fldRequired, required),
                        passValid: helpers.withMessage(language.passValidMsg, passValidator),
                        minLength: helpers.withMessage(language.passLengthMsg, minLength(8)),
                        $autoDirty: true
                    },
                    confPassword: {
                        required: helpers.withMessage(language.fldRequired, required),
                        sameAs: helpers.withMessage(language.sameAsPasswordMsg, sameAs(this.resetPwRecord.password)),
                        $autoDirty: true
                    },
                }
            }
        }
    })
