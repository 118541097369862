  
  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';
  import DataFxs from '@/tools/data_functions';

  import Swal from "sweetalert2";
  import { ProdProduct, ProductVariantDetail } from "@/models/producttypes";
  import {ManagtUser } from "@/models/managttypes";

  import { SalesRefundEntryStock } from "@/models/salestypes";
  import { RefundEntryRemainingData, RefundEntryStockStatus, RefundEntryWithInfo } from "@/models/notdb";

  import RefundEntryStockModal from '@/components/modals/RefundEntryStockModal.vue';
  import RefundEntryStockDetailsModal from '@/components/modals/RefundEntryStockDetailsModal.vue';

  const language = GetLanguage(),
    appFxs = GeneralFx(),
    dataFxs = DataFxs();
  const fNewDate = new Date(),
    year = fNewDate.getFullYear();
  
  const emptyDeliverOper: SalesRefundEntryStock = {
      IdRefundEntry: 0,
      RefundKey: '',
      RefundEntryKey: '',
      RefundNbr: '',
      RefundEntryStatus: 0,
      OrderNbr: '',
      OrderKey: '',
      RefundEntryDate: fNewDate,
      UserEmail: '',
      Barcode: '',
      QrCode: '',
      ReceiverName: '',
      ReceiverTel: '',
      CreatedBy: '',
      RefundEntryNbr: '',
  };

  export default defineComponent({ 
    name: 'SalesEntryStockByRefund',
    components: {
      RefundEntryStockModal,
      RefundEntryStockDetailsModal,
    },
    data(){
      return {
        appFxs,
        language,        
        isStillLoading: true,
        itemSearchKey: '',
        itemsPerPage: 5,
        selectedYear: year,
        currCode: 'RWF',
        userEmail: '',

        selectedRefundKey: '',

        lstRefEntryInfoByRefund: [] as RefundEntryWithInfo[],
        itemsPageElts: [] as RefundEntryWithInfo[],

        refundEntryStockStatus: null as RefundEntryStockStatus | any,
        listOfProducts: [] as ProdProduct[],
        lstOfProdVariantValues: [] as ProductVariantDetail[],
        listOfUsers: [] as ManagtUser[],

        isDeliverModVis: false,
        deliverOpsRecord: {...emptyDeliverOper} as SalesRefundEntryStock,
        deliverOpsStatus: 0,

        createdRefundOp: {...emptyDeliverOper} as SalesRefundEntryStock,

        serverLstOfRemainings: [] as RefundEntryRemainingData[],
        toSaveLstOfRemainings: [] as RefundEntryRemainingData[],

        isDelivDetailVisisble: false,
        
        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 8,
          ReportTitle: language.list_of_brands
        } as any,
        printExtraData: {},
      }
    },
    computed: {
      filteredRecordItems(): RefundEntryWithInfo[] {
        let searchKey = this.itemSearchKey;

        if (searchKey.length === 0) return this.lstRefEntryInfoByRefund;
        searchKey = searchKey.toLowerCase();

        const filterd = this.lstRefEntryInfoByRefund.filter(oDev => {
          return oDev.RefundStockOper.OrderNbr.toLowerCase().includes(searchKey) || 
            oDev.RefundStockOper.Barcode.toLowerCase().includes(searchKey) || 
            oDev.RefundStockOper.QrCode.toLowerCase().includes(searchKey) || 
            oDev.RefundStockOper.OrderNbr.toLowerCase().includes(searchKey) || 
            oDev.RefundStockOper.ReceiverName.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
      oneRefundEntryStatus():{orderNbr: string;orderQty: number;delivQty: number;remQty: number;customerName: string;staffName: string;}{
          if(this.refundEntryStockStatus == null){
            return {
              orderNbr: '',
              orderQty: 0,
              delivQty: 0,
              remQty: 0,
              customerName: '',
              staffName: '',
            }
          }
          const final = this.refundEntryStockStatus as RefundEntryStockStatus;
          return {
            orderNbr: final.SalesRefund.RefundNbr,
            orderQty: final.RefundQty,
            delivQty: final.EnteredQty,
            remQty: final.RemainingQty,
            customerName: final.Customer.ClientName,
            staffName: `${final.Staff.FirstName + ' ' + final.Staff.LastName}`,
          }
      },
    },
    methods: {
      addStockEntryOper(iStatus: number, oRecord: any){
        const oStatus = {...this.refundEntryStockStatus} as RefundEntryStockStatus;

        if(iStatus == 0){
          const fNew = {...emptyDeliverOper} as SalesRefundEntryStock;

          fNew.Barcode = appFxs.getRandomDigits(12).toUpperCase();
          fNew.QrCode = appFxs.generateTextNbr(15).toUpperCase();
          fNew.RefundEntryKey = appFxs.generateTextNbr(15).toUpperCase();
          fNew.RefundEntryNbr = appFxs.generateRefEntrStockOperNbr();
          fNew.RefundEntryStatus = 0;
          fNew.RefundNbr = oStatus.SalesRefund.RefundNbr;
          fNew.RefundKey = oStatus.SalesRefund.RefundKey;
          fNew.OrderKey = oStatus.SalesRefund.OrderKey;
          fNew.OrderNbr = oStatus.SalesOrder.OrderNbr;
          fNew.RefundEntryDate = new Date();
          fNew.UserEmail = this.userEmail;
          fNew.CreatedBy = this.userEmail;

          this.deliverOpsRecord = fNew;
          this.deliverOpsStatus = 0
        }else{
          const fEdit = {...oRecord} as RefundEntryWithInfo;
          this.deliverOpsRecord = {...fEdit.RefundStockOper} as SalesRefundEntryStock;
          this.deliverOpsStatus = 1
        }
        this.isDeliverModVis = true;
      },
      onCloseDelivery(evt: any){
        if (evt.returnType == 0) {
          this.isDeliverModVis = false;
          return;
        }

        this.createdRefundOp = evt.returnData as SalesRefundEntryStock;
        
        this.isDeliverModVis = false;
        this.getDataForRefEntryOper(this.selectedRefundKey);
      },
      getDataForRefEntryOper(refundKey: string){
        console.log('refundKey', refundKey);
        
        appFxs.showAjaxLoader();
        NetServices.requestGet(`salesorders/refund/stockentries/getremainingdetail/${refundKey}`)
        .then(oRes => {
          if (!oRes.bReturn) {
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);              
            return;
          }

          this.serverLstOfRemainings = oRes.lstOfRefundStockDetails!;
          Swal.close();

          console.log('this.serverLstOfRemainings', appFxs.getProxyData(this.serverLstOfRemainings));
          this.openDetailforSaving();
        });
      },      
      openDetailforSaving(){
        this.isDelivDetailVisisble = true;
      },
      onClodeDelivDetails(evt: any){
        if (evt.returnType == 0) {
          this.isDelivDetailVisisble = false;
          return;
        }

        const finalList = appFxs.getProxyData(evt.returnData) as RefundEntryRemainingData[];
        const toFinal = finalList.filter(o => o.ToDeliverQty > 0);
        console.log('toFinal', toFinal);
        this.toSaveLstOfRemainings = toFinal;
        

        if(toFinal.length>0) this.saveFinalsRecords();
      },
      saveFinalsRecords(){
        appFxs.showConfirm(language.save_deliv_ops, language.save_deliv_ops_msg)
          .then(vRes => {
            if(!vRes.isConfirmed)return;
            this.saveFinalsRecordsFinal();
          });
      },
      saveFinalsRecordsFinal(){
        const oneDelivery = appFxs.getProxyData(this.createdRefundOp) as SalesRefundEntryStock;
        const detailsRemainings = appFxs.getProxyData(this.toSaveLstOfRemainings) as RefundEntryRemainingData[];

        console.log('oneDelivery', oneDelivery);
        console.log('detailsRemainings', detailsRemainings);

        appFxs.showAjaxLoader();
        NetServices.requestPost('salesorders/refund/stockentries/saverefundstockoperation',{
          refundStockOperation: oneDelivery,
          lstOfRefundStockRemains: detailsRemainings,
        })
        .then(oRes=>{
          if(!oRes.bReturn){
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }

          const finalList: RefundEntryWithInfo[] = [];
          const fList = oRes.lstRefEntryInfoByRefund!;
          fList.forEach(cList => {
            const final = {...cList} as RefundEntryWithInfo;
            finalList.push(final);
          });

          this.lstRefEntryInfoByRefund = finalList;   
          this.listOfProducts = oRes.listOfProducts!
          this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!
          this.refundEntryStockStatus = oRes.refundEntryStockStatus!;
          this.listOfUsers = oRes.listOfUsers!;

          this.isDelivDetailVisisble = false;
          Swal.close();
        });
      },
      
      refresListItems() {
          appFxs.showAjaxLoader();
          NetServices.requestGet(`salesorders/refund/stockentries/getlistofentrystockbyrefund/${this.selectedRefundKey}`)
            .then(oRes => {
              if (!oRes.bReturn) {
                Swal.close();
                appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                return;
              }

            const finalList: RefundEntryWithInfo[] = [];
            const fList = oRes.lstRefEntryInfoByRefund!;
            fList.forEach(cList => {
              const final = {...cList} as RefundEntryWithInfo;
              finalList.push(final);
            });

            this.lstRefEntryInfoByRefund = finalList;   
            this.listOfProducts = oRes.listOfProducts!
            this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!
            this.refundEntryStockStatus = oRes.refundEntryStockStatus!;
            this.listOfUsers = oRes.listOfUsers!;

            Swal.close();
          });
      },
      onPageChange(evt: any) {
        this.itemsPageElts = appFxs.getProxyData(evt);
      },
      printListItems() {
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.stock_entry_by_refuund;
        dPrint.ReportToPrint = 31;

        this.printInitialValues = dPrint;
        this.printExtraData = {
          SalesRefundPaytKey: this.selectedRefundKey
        }

        this.isPrintvisible = true;
      },
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },
    },
    mounted(){
      document.title = this.language.stock_entry_by_refuund + ' - ' + this.language.appName;
      this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
      this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
      this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
      this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));

      const refundKey = this.$route.params.refundKey;

      if(refundKey == null){
        router.push({
          name: 'appsalesrefundlist',
        });
        return;
      }
      const _key = refundKey as string;
      this.selectedRefundKey = _key;

      NetServices.requestGet(`salesorders/refund/stockentries/getlistofentrystockbyrefund/${_key}`)
        .then(oRes => {
          if(!oRes.bReturn){
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
            .then(vRes => {
              router.push({
                name: 'appsalesrefundlist',
              });
            });            
            return;
          }

          const finalList: RefundEntryWithInfo[] = [];
          const fList = oRes.lstRefEntryInfoByRefund!;
          fList.forEach(cList => {
            const final = {...cList} as RefundEntryWithInfo;
            finalList.push(final);
          });

          this.lstRefEntryInfoByRefund = finalList;   
          this.listOfProducts = oRes.listOfProducts!
          this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!
          this.refundEntryStockStatus = oRes.refundEntryStockStatus!;
          this.listOfUsers = oRes.listOfUsers!;

          this.isStillLoading = false;
        });
    }
  })
