    
    import { defineComponent } from "vue";
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';

    import Swal from "sweetalert2";
    import { ProdProduct, ProductVariantDetail, StockSituationProduct } from "@/models/producttypes";

    const language = GetLanguage(),
        appFxs = GeneralFx();

    export default defineComponent({
        data(){
            return {             
                appFxs,
                language,
                isStillLoading: true,
                itemsPerPage: 5,
                listOfSitStockDetail: [] as StockSituationProduct[],
                productsPageElts: [] as StockSituationProduct[],
                itemSearchKey: '',
                currentYear: 2000,

                productSku: '',
                variantDetailSku: '',

                oneProductRecord: null as ProdProduct|any,
                oneVariantDetail: null as ProductVariantDetail|any,


                isPrintvisible: false,
                printInitialValues: {
                ...appFxs.getReportingData(),
                ReportToPrint: 8,
                ReportTitle: language.list_of_brands
                } as any,
                printExtraData: {},

                currCode: 'RWF',
            }
        },
        computed: {
            filteredProdBrands(): StockSituationProduct[] {
                let searchKey = this.itemSearchKey;

                if (searchKey.length === 0) return this.listOfSitStockDetail;
                searchKey = searchKey.toLowerCase();

                const filterd = this.listOfSitStockDetail.filter(oDev => {
                    return oDev.OperationKey.toLowerCase().includes(searchKey) ||
                        oDev.ProductSku.toLowerCase().includes(searchKey) ||
                        oDev.VariantCodes.toLowerCase().includes(searchKey) ||
                        oDev.VariantSku.toLowerCase().includes(searchKey) ||
                        oDev.VariantValues.toLowerCase().includes(searchKey);
                });
                return filterd;
            },            
            getProdName(): string{
                return this.oneProductRecord == null ? language.loading + '...':
                    (this.oneProductRecord as ProdProduct).Name;
            },           
            getVariantValues(): string{
                return this.oneVariantDetail == null ? '':
                    (this.oneVariantDetail as ProductVariantDetail).VariantValues;
            },          
            getVariantCodes(): string{
                return this.oneVariantDetail == null ? '':
                    (this.oneVariantDetail as ProductVariantDetail).VariantCodes;
            },
        },
        methods: {
            getTypeProd(oRecord: StockSituationProduct): string{
                return oRecord.OperationType == 0 ? language.stock_entry: language.stock_exit;
            },
            onPageChange(evt: any) {
                this.productsPageElts = appFxs.getProxyData(evt);
            },
            refreshList(){
                const productSku = this.$route.params.productSku;
                const variantSku = this.$route.params.variantSku;

                if (!productSku) {
                    appFxs.showAlert(language.missing_settings, language.missing_settings_msg)
                        .then(vRes => {
                            const oPath = `/app/products/stockstatus/list`;
                            router.replace({ path: oPath, replace: true });
                        });
                    return;
                }

                const prodType = variantSku == undefined ? 0 : 1;
                const varSku = prodType == 0 ? '-' : variantSku;

                const finalVarSku = variantSku == undefined ? '' : variantSku as string;

                this.productSku = productSku as string;
                this.variantDetailSku = finalVarSku;


                appFxs.showAjaxLoader();

                NetServices.requestGet(`stockops/situations/getstocksituationdetails/${productSku}/${varSku}`)
                    .then(oRes => {
                        if (!oRes.bReturn) {
                            Swal.close();
                            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                            return;
                        }
                        

                        const fList: StockSituationProduct[] = [];
                        oRes.listStockSituationByProduct!.forEach(oEmail => {
                            const fEmail = { ...oEmail, OperationDate: new Date(oEmail.OperationDate) }
                            fList.push(fEmail);
                        });

                        this.listOfSitStockDetail = fList;
                        this.oneProductRecord = oRes.oneProductRecord!;
                        this.oneVariantDetail = oRes.oneVariantDetail!;

                        Swal.close();
                    });


            },
            printListItems() {
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = language.stocksit_by_prod;
                dPrint.ReportToPrint = 14;

                this.printInitialValues = dPrint;
                const oPr = {
                    CurrentYear: this.currentYear,
                    ProductSku: this.productSku,
                    VariantValue: this.variantDetailSku ?? '-',
                };
                console.log('oPr', oPr);
                this.printExtraData = oPr;


                this.isPrintvisible = true;
            }, 
            onPrintClose(evt: any) {
                this.isPrintvisible = false;
                if (evt.returnType === 0) return;
            },
        },
        mounted(){
            document.title = this.language.stock_details_by_prod + ' - ' + this.language.appName;
            this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
            this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
            this.currentYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, '2000'));
            
            const productSku = this.$route.params.productSku;
            const variantSku = this.$route.params.variantSku;

            if(!productSku){
                appFxs.showAlert(language.missing_settings, language.missing_settings_msg)
                    .then(vRes => {
                        const oPath = `/app/products/stockstatus/list`;
                        router.replace({ path: oPath, replace: true });
                    });
                return;
            }

            const prodType = variantSku == undefined ? 0 :1;
            const varSku = prodType == 0 ? '-' : variantSku;

            const finalVarSku = variantSku == undefined ? '' : variantSku as string;

            this.productSku = productSku as string;
            this.variantDetailSku = finalVarSku;


            NetServices.requestGet(`stockops/situations/getstocksituationdetails/${productSku}/${varSku}`)
                .then(oRes => {
                    if (!oRes.bReturn) {
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                            .then(vRes => {
                                const oPath = `/app/products/stockstatus/list`;
                                router.replace({ path: oPath, replace: true });
                            });                        
                        return;
                    }

                    const fList: StockSituationProduct[] = [];
                    oRes.listStockSituationByProduct!.forEach(oEmail => {
                        const fEmail = { ...oEmail, OperationDate: new Date(oEmail.OperationDate) }
                        fList.push(fEmail);
                    });

                    this.listOfSitStockDetail = fList;
                    this.oneProductRecord = oRes.oneProductRecord!;
                    this.oneVariantDetail = oRes.oneVariantDetail!;

                    this.isStillLoading = false;
                });


        }
    });
