
import { defineComponent } from "vue";
import {RouterLink } from 'vue-router';
import router from '@/router/index';

import NetServices from '@/tools/netservices';

import GetLanguage from '@/tools/language';
import GeneralFx from '@/tools/general_fx';
import appDeclarations from '@/tools/declarations';
import { NetReturn } from "@/models/netreturn";
import { ManagtUser } from "@/models/managttypes";

import AppFooter from '@/components/general/PageElts/AppFooter.vue';

const language =GetLanguage(),
    appFxs = GeneralFx();

export default defineComponent({
  components: {
    AppFooter
  },
  data(){
    return {
      language,
      isStillPreparing: true,
      hasNetworkError: false,
      currentUserName: '',
      userRecord: null as ManagtUser|any
    }
  },
  computed: {    
    imageFilePath(): string{
      const localPath = require('@/assets/images/user-tempo.png');
      return !this.userRecord ? localPath:
            !this.userRecord.PhotoPath ? localPath : 
            `${appDeclarations.apiUrl}cloud_data/managt_profiles/${this.userRecord.PhotoPath}`;
    }
  },
  methods: {
    onQuitApp(){
      appFxs.showConfirm(language.exitApp, language.exitAppMsg, language.cancelBtn, language.yesBtn)
      .then(o => {
        if(o.isConfirmed){
          appFxs.setLocalStorage(appDeclarations.userAccessKey, '');
          appFxs.setLocalStorage(appDeclarations.connectionStatus, '0');




          router.replace({path: '/', replace: true});
        }
      });
    },
    cleanAndGoLogin(){
      appFxs.setLocalStorage(appDeclarations.userAccessKey, '');
      appFxs.setLocalStorage(appDeclarations.connectionStatus, '0');

      router.replace({path: '/', replace: true});
    },
    setLang(lang: string){
      appFxs.setLocalStorage(appDeclarations.currentLang, lang);
      window.location.reload();
    }
  },
  mounted(){
    NetServices.requestGet('managt/account/collectuserdata')
      .then(oRes => {
        const retData = oRes as NetReturn;
        if(retData.bReturn){
          const curSymboles = JSON.parse(retData.currencySymbols!);         
          const oSymbols = [];

          for(let x in curSymboles){
            oSymbols.push(curSymboles[x])
          }

          appFxs.setLocalStorage(appDeclarations.connecteUserRecord, JSON.stringify(retData.connectedUser!));
          appFxs.setLocalStorage(appDeclarations.connectedCompany, JSON.stringify(retData.connectedDomain!));
          appFxs.setLocalStorage(appDeclarations.userEmail, retData.connectedUser!.Email);

          appFxs.setLocalStorage(appDeclarations.timeZones, JSON.stringify(JSON.parse(retData.timeZones!).TimeZones));
          appFxs.setLocalStorage(appDeclarations.telephonesCodes, JSON.stringify(JSON.parse(retData.telephoneCodes!).Countries));
          appFxs.setLocalStorage(appDeclarations.currencySymbols, JSON.stringify(oSymbols));

          appFxs.setLocalStorage(appDeclarations.listOfSmsAccounts, JSON.stringify(oRes.listOfSmsAccts));
          appFxs.setLocalStorage(appDeclarations.listOfEmailAccounts, JSON.stringify(oRes.lstEmailAccounts));

          const fUser = retData.connectedUser!;

          this.currentUserName = `${fUser.FirstName} ${fUser.LastName}`;
          this.userRecord = fUser;
          
          this.isStillPreparing = false;
        }else{
          this.hasNetworkError = true;
        }
      })
  }
})
