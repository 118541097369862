 
    import { defineComponent } from "vue";
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import DataFxs from '@/tools/data_functions';

    import Swal from "sweetalert2";
    import { ProdProduct } from "@/models/producttypes";

    import { SalesOrder, SalesOrderDelivery, SalesOrderDeliveryDetail } from "@/models/salestypes";
    const language = GetLanguage(),
        appFxs = GeneralFx(),
        dataFxs = DataFxs();
    const fNewDate = new Date(),
        year = fNewDate.getFullYear();


    export default defineComponent({    
        name: 'SalesDelivsDetByOder',
        data(){
            return{
                appFxs,
                language,        
                isStillLoading: true,
                itemSearchKey: '',
                itemsPerPage: 5,
                selectedYear: year,
                currCode: 'RWF',
                userEmail: '',

                selectedOrderKey: '',
                selectedDeliveryKey: '',

                salesOrderElement: null as any,
                salesDeliveryElement: null as any,

                lstSalesDeliveryDetails: [] as SalesOrderDeliveryDetail[],
                itemsPageElts: [] as SalesOrderDeliveryDetail[],
                listOfProducts: [] as ProdProduct[],

                isPrintvisible: false,
                printInitialValues: {
                ...appFxs.getReportingData(),
                ReportToPrint: 8,
                ReportTitle: language.list_of_brands
                } as any,
                printExtraData: {},
            }
        },
        computed: {
            filteredRecordItems(): SalesOrderDeliveryDetail[] {
                let searchKey = this.itemSearchKey;

                if (searchKey.length === 0) return this.lstSalesDeliveryDetails;
                searchKey = searchKey.toLowerCase();

                const filterd = this.lstSalesDeliveryDetails.filter(oDev => {
                    return oDev.DeliveryDetKey.toLowerCase().includes(searchKey);
                });
                return filterd;
            },
            getInfoRecord(): {
              orderKey: string;
              orderNbr: string;
              deliveryKey: string;
              deliveryNbr: string;
            }{
              const oFinal = {
                orderKey: '',
                orderNbr: '',
                deliveryKey: '',
                deliveryNbr: ''
              };

              if(!this.salesOrderElement || !this.salesDeliveryElement)
                return oFinal;

                const fOrder = this.salesOrderElement as SalesOrder;
                const fDeliv = this.salesDeliveryElement as SalesOrderDelivery;

              return {
                deliveryKey: fDeliv.DeliveryKey,
                deliveryNbr: fDeliv.DeliveryNbr,
                orderNbr: fOrder.OrderNbr,
                orderKey: fOrder.OrderKey
              }
            }
        },
        methods: {
            getProductRecord(delDet: SalesOrderDeliveryDetail): string{
                const oProd = this.listOfProducts.find(o => o.SKU == delDet.ProductKey);
                if(!oProd)return '';
                if(delDet.ProductOrient==0) return  oProd.Name;
                return `${oProd.Name} (${delDet.VariantValue})`;
            },
            refresListItems() {
                appFxs.showAjaxLoader();
                NetServices.requestGet(`salesorders/delivery/getlistofdeliverdetailsbyorder/${this.selectedOrderKey}/${this.selectedDeliveryKey}`)
                    .then(oRes => {
                        if (!oRes.bReturn) {
                            Swal.close();
                            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                            return;
                        }

                        this.lstSalesDeliveryDetails = oRes.lstSalesDeliveryDetails!;
                        this.listOfProducts = oRes.listOfProducts!
                        this.salesOrderElement = oRes.salesOrderElement;
                        this.salesDeliveryElement = oRes.salesDeliveryElement;

                        Swal.close();
                    });

            },
            onPageChange(evt: any) {
                this.itemsPageElts = appFxs.getProxyData(evt);
            },
            printListItems() {
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = `${language.delivery_note} - ${this.getInfoRecord.deliveryNbr}`;
                dPrint.ReportToPrint = 23;

                this.printInitialValues = dPrint;
                this.printExtraData = {
                  SalesDeliveryKey: this.selectedDeliveryKey
                }

                this.isPrintvisible = true;
            },
            onPrintClose(evt: any) {
                this.isPrintvisible = false;
                if (evt.returnType === 0) return;
            },
        },
        mounted() {
            document.title = this.language.delivery_details + ' - ' + this.language.appName;
            this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
            this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
            this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
            this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));

            const orderKey = this.$route.params.orderKey;
            const deliveryKey = this.$route.params.deliveryKey;

            if (!orderKey || !deliveryKey) {
                router.push({
                    name: 'appsalesdelivlist',
                });
                return;
            }
            const _orderKey = orderKey as string;
            const _delKey = deliveryKey as string;

            this.selectedOrderKey = _orderKey;
            this.selectedDeliveryKey = _delKey;

            NetServices.requestGet(`salesorders/delivery/getlistofdeliverdetailsbyorder/${this.selectedOrderKey}/${this.selectedDeliveryKey}`)
                .then(oRes => {
                    if (!oRes.bReturn) {
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                            .then(vRes => {
                                router.push({
                                    name: 'appsalesdelivlist',
                                });
                            });
                        return;
                    }

                    

                    this.lstSalesDeliveryDetails = oRes.lstSalesDeliveryDetails!;
                    this.listOfProducts = oRes.listOfProducts!;
                    this.salesOrderElement = oRes.salesOrderElement;
                    this.salesDeliveryElement = oRes.salesDeliveryElement;

                    this.isStillLoading = false;
                });
        }
    })
