
    import { defineComponent } from 'vue';
    import GeneralFx from '@/tools/general_fx';

    const appFxs = GeneralFx();

    export default defineComponent({
        name: 'NumericFldInput',
        emits: ['update:modelValue', 'change'],
        props: {
            modelValue: {
                type: Number,
                default: '0',
                required: true,
            },
            classes: {
                type: String,
                default: 'form-control',
                required: false,
            },
            placeholder: {
                type: String,
                default: '',
                required: false,
            },
            disabled: {
                type: Boolean,
                default: false,
                required: false,
            },
            id: {
                type: String,
                default: appFxs.generateUUID(),
                required: false,
            },
            maxlength: {
                type: Number,
                default: 15,
                required: false,
            },
            isFloatFormat: {
                type: Boolean,
                default: false,
                required: false,
            }
        },
        data(){
            return {
                inputValue: this.modelValue as Number,
                isDisabled: this.disabled,
            }
        },
        computed: {
            displayValue(): string{
                const fValue = this.inputValue.toString();
                const numValue = fValue.length == 0 ? 0: Number(fValue);
                return appFxs.formatNumbers(numValue);
            },
            allClasses():string[] {
                const all = this.classes.split(' ');
                if(this.isFloatFormat){
                    all.push('commav');
                }else{
                    all.push('numeric');
                }
                return all;
            },
        },
        methods: {
            updateValue(evt: Event){
                const input = evt.target as HTMLInputElement;
                const strValue = input.value;
                const numericValue = strValue.trim();
                let finalNumeric = numericValue.replaceAll(',', '');

                this.inputValue = finalNumeric.length == 0 ? 0 : Number(finalNumeric);
                this.$emit('update:modelValue', this.inputValue);
            },
            selectOnFocus(evt: Event) {
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            inputChange(){
                this.$emit('change', null);
            }
        },
        watch: {
            disabled: {
                handler(newV, oldV) {
                    if (newV !== oldV) {
                        this.isDisabled = newV as boolean;
                    }
                },
            },
            modelValue: {
                handler(newV, oldV) {
                    if (newV !== oldV) {
                        this.inputValue = Number(newV as string);
                    }
                },
            },
        },
        mounted(){
            appFxs.updateLoadedScripts();
        }
    })
