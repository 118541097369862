import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal fade show",
  style: {"display":"block"}
}
const _hoisted_2 = { class: "modal-dialog wise-modal" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-md-6 form-group" }
const _hoisted_9 = { for: "DevISO" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = ["value"]
const _hoisted_12 = { for: "DevSigle" }
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_15 = { for: "DevName" }
const _hoisted_16 = ["placeholder"]
const _hoisted_17 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_18 = { for: "DevUnit" }
const _hoisted_19 = ["placeholder"]
const _hoisted_20 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_21 = { class: "col-md-6 form-group" }
const _hoisted_22 = { for: "DevSubUnit" }
const _hoisted_23 = ["placeholder"]
const _hoisted_24 = { for: "TauxCotation" }
const _hoisted_25 = ["placeholder"]
const _hoisted_26 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_27 = { class: "col-md-6 form-group" }
const _hoisted_28 = { for: "TypeCalcTaux" }
const _hoisted_29 = ["value"]
const _hoisted_30 = { class: "col-md-6 form-group" }
const _hoisted_31 = { for: "DeviseCot" }
const _hoisted_32 = {
  key: 0,
  value: ""
}
const _hoisted_33 = ["value"]
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = { class: "col-md-6 form-group" }
const _hoisted_36 = { for: "DevFormat" }
const _hoisted_37 = { for: "CoursPeriod" }
const _hoisted_38 = ["placeholder"]
const _hoisted_39 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_40 = { for: "CoursCloture" }
const _hoisted_41 = ["placeholder"]
const _hoisted_42 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_43 = { class: "modal-footer" }
const _hoisted_44 = { class: "d-flex justify-content-end" }
const _hoisted_45 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.windowTitle), 1),
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args))),
                type: "button",
                class: "btn-close"
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.language.iso_code), 1),
                  _withDirectives(_createElementVNode("select", {
                    id: "DevISO",
                    name: "DevISO",
                    class: "form-control-sel",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataRecord.DevISO) = $event)),
                    disabled: _ctx.dataRecord.IdDevise != 0,
                    onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCurrencyChange && _ctx.onCurrencyChange(...args)))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listOfNetCurrencies, (codeCurr) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: codeCurr.code,
                        value: codeCurr.code
                      }, _toDisplayString(codeCurr.name), 9, _hoisted_11))
                    }), 128))
                  ], 40, _hoisted_10), [
                    [_vModelSelect, _ctx.dataRecord.DevISO]
                  ])
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.DevSigle.$error}])
                }, [
                  _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.language.sigle), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "DevSigle",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataRecord.DevSigle) = $event)),
                    placeholder: '(' + _ctx.language.sigle.toLowerCase() + ')',
                    maxlength: "6",
                    autocomplete: "off",
                    onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_13), [
                    [_vModelText, _ctx.dataRecord.DevSigle]
                  ]),
                  (_ctx.v$.dataRecord.DevSigle.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.v$.dataRecord.DevSigle.$errors[0].$message), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-12 form-group", {'has-error': _ctx.v$.dataRecord.DevName.$error}])
                }, [
                  _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.language.name), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "DevName",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dataRecord.DevName) = $event)),
                    placeholder: '(' + _ctx.language.name.toLowerCase() + ')',
                    maxlength: "50",
                    autocomplete: "off",
                    onFocus: _cache[6] || (_cache[6] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_16), [
                    [_vModelText, _ctx.dataRecord.DevName]
                  ]),
                  (_ctx.v$.dataRecord.DevName.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.v$.dataRecord.DevName.$errors[0].$message), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.DevUnit.$error}])
                }, [
                  _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.language.unit), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "DevUnit",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dataRecord.DevUnit) = $event)),
                    placeholder: '(' + _ctx.language.unit.toLowerCase() + ')',
                    maxlength: "6",
                    autocomplete: "off",
                    onFocus: _cache[8] || (_cache[8] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_19), [
                    [_vModelText, _ctx.dataRecord.DevUnit]
                  ]),
                  (_ctx.v$.dataRecord.DevUnit.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.v$.dataRecord.DevUnit.$errors[0].$message), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.language.sub_unit), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "DevSubUnit",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dataRecord.DevSubUnit) = $event)),
                    placeholder: '(' + _ctx.language.sub_unit.toLowerCase() + ')',
                    maxlength: "10",
                    autocomplete: "off",
                    onFocus: _cache[10] || (_cache[10] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_23), [
                    [_vModelText, _ctx.dataRecord.DevSubUnit]
                  ])
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.TauxCotation.$error}])
                }, [
                  _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.language.currency_rate), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "TauxCotation",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.dataRecord.TauxCotation) = $event)),
                    placeholder: '(' + _ctx.language.currency_rate.toLowerCase() + ')',
                    maxlength: "15",
                    autocomplete: "off",
                    onkeypress: "return event.charCode >= 48 && event.charCode <= 57",
                    onFocus: _cache[12] || (_cache[12] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_25), [
                    [_vModelText, _ctx.dataRecord.TauxCotation]
                  ]),
                  (_ctx.v$.dataRecord.TauxCotation.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(_ctx.v$.dataRecord.TauxCotation.$errors[0].$message), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.language.rate_type), 1),
                  _withDirectives(_createElementVNode("select", {
                    id: "TypeCalcTaux",
                    name: "TypeCalcTaux",
                    class: "form-control-sel",
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.dataRecord.TypeCalcTaux) = $event))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeCot, (tCot) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: tCot.id,
                        value: tCot.id
                      }, _toDisplayString(tCot.label), 9, _hoisted_29))
                    }), 128))
                  ], 512), [
                    [_vModelSelect, _ctx.dataRecord.TypeCalcTaux]
                  ])
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.language.trading_currency), 1),
                  _withDirectives(_createElementVNode("select", {
                    id: "DeviseCot",
                    name: "DeviseCot",
                    class: "form-control-sel",
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.dataRecord.DeviseCot) = $event))
                  }, [
                    (_openBlock(), _createElementBlock("option", _hoisted_32, _toDisplayString(_ctx.language.none), 1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listOfDeviseCots, (tCot) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: tCot.DevISO,
                        value: tCot.DevISO
                      }, _toDisplayString(tCot.DevName), 9, _hoisted_33))
                    }), 128))
                  ], 512), [
                    [_vModelSelect, _ctx.dataRecord.DeviseCot]
                  ])
                ]),
                (_ctx.isDevCotSet)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "hint py-2",
                      innerHTML: _ctx.setTextDevCot
                    }, null, 8, _hoisted_34))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.language.format), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "DevFormat",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.dataRecord.DevFormat) = $event)),
                    placeholder: "# ##0.0",
                    maxlength: "10",
                    autocomplete: "off",
                    onFocus: _cache[16] || (_cache[16] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 544), [
                    [_vModelText, _ctx.dataRecord.DevFormat]
                  ])
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.CoursPeriod.$error}])
                }, [
                  _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.language.period_rate), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "CoursPeriod",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.dataRecord.CoursPeriod) = $event)),
                    placeholder: '(' + _ctx.language.period_rate.toLowerCase() + ')',
                    maxlength: "15",
                    autocomplete: "off",
                    onkeypress: "return event.charCode >= 48 && event.charCode <= 57",
                    onFocus: _cache[18] || (_cache[18] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_38), [
                    [_vModelText, _ctx.dataRecord.CoursPeriod]
                  ]),
                  (_ctx.v$.dataRecord.CoursPeriod.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_39, _toDisplayString(_ctx.v$.dataRecord.CoursPeriod.$errors[0].$message), 1))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.CoursCloture.$error}])
                }, [
                  _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.language.closing_rate), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "CoursCloture",
                    class: "form-control",
                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.dataRecord.CoursCloture) = $event)),
                    placeholder: '(' + _ctx.language.closing_rate.toLowerCase() + ')',
                    maxlength: "15",
                    autocomplete: "off",
                    onkeypress: "return event.charCode >= 48 && event.charCode <= 57",
                    onFocus: _cache[20] || (_cache[20] = ($event: any) => (_ctx.selectOnFocus($event)))
                  }, null, 40, _hoisted_41), [
                    [_vModelText, _ctx.dataRecord.CoursCloture]
                  ]),
                  (_ctx.v$.dataRecord.CoursCloture.$error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_42, _toDisplayString(_ctx.v$.dataRecord.CoursCloture.$errors[0].$message), 1))
                    : _createCommentVNode("", true)
                ], 2)
              ])
            ]),
            _createElementVNode("div", _hoisted_43, [
              _createElementVNode("div", _hoisted_44, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-secondary me-2",
                  onClick: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args)))
                }, _toDisplayString(_ctx.language.close), 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[22] || (_cache[22] = 
//@ts-ignore
(...args) => (_ctx.saveRecordData && _ctx.saveRecordData(...args))),
                  disabled: _ctx.v$.$invalid
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fa', 'fa-save'] }),
                  _createTextVNode(" " + _toDisplayString(_ctx.language.save), 1)
                ], 8, _hoisted_45)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}