
  import { useVuelidate } from '@vuelidate/core'
  import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import { ProdBrand } from '@/models/producttypes';

  const language =GetLanguage(),
  appFxs = GeneralFx();

  const emptyBrand: ProdBrand = {
    ProdBrandId: 0,
    Code: '',
    BrandName: '',
    Description: '',
  };

  export default defineComponent({
    data(){
      return {
        v$: useVuelidate(),
        language,
        appFxs,
        dataRecord: {...emptyBrand} as ProdBrand,
        isSavingRecord: false,
        TestNumeric: '0',
        isDisabled: true,
      }
    },
    methods: {         
      selectOnFocus(evt: Event) {
        const oInput = evt.target as HTMLInputElement;
        oInput.select();
      },
      saveNewBrand(){
        this.isSavingRecord = true;
        const fRecord = {...appFxs.getProxyData(this.dataRecord)};

        NetServices.requestPost('products/brands/saveprodbrand',{
          prodBrandRecord: fRecord,
          toDelete: false,
        }).then(oRes => {
          if (!oRes.bReturn) {
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            this.isSavingRecord = false;
            return;
          }

          const fList: ProdBrand[] = [];
          oRes.listOfProdBrands!.forEach(oEmail => {
            const fEmail = { ...oEmail}
            fList.push(fEmail);
          });

          const oPath = `/app/products/brands/list`;
          router.replace({path: oPath, replace: true});
        });
      }
    },
    mounted(){
      document.title = language.new_brand + ' - ' + this.language.appName;
    },
    validations(){
      return {
        dataRecord: {
          Code: {
              required: helpers.withMessage(language.fldRequired, required),                 
              $autoDirty: true,
          },
          BrandName: {
              required: helpers.withMessage(language.fldRequired, required),                 
              $autoDirty: true,
          },
        }
      }
    }
  })
