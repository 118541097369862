
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent, toRaw } from 'vue';
    import { TaxSett } from '@/models/managttypes';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';

    const language =GetLanguage(),
    appFxs = GeneralFx();
    const emptyTaxSett: TaxSett = {
        TaxSettId: 0,
        TaxKey: '',
        TaxName: '',
        TaxRate: 0,
        IsActive: true
    }
    const greaterThanZero = (nbr: number) => nbr > 0;

    export default defineComponent({
        name: 'TaxSettModal',
        emits: ['closeForm'],
        props: ["visible", "initialValues", "recordStatus"],
        data(){
            return {
                v$: useVuelidate(),
                language,
                appFxs,
                dataRecord: {...emptyTaxSett},

            }
        },
        methods: {
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.$emit('closeForm', {returnType: 1, returnData: this.dataRecord});
            },
            
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: TaxSett = {...oValues};

                        if(this.recordStatus == 0){
                            this.v$.$reset();
                        }

                        this.dataRecord = fValues as TaxSett;
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {
                    TaxName: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    TaxRate: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),                 
                        $autoDirty: true,
                    },
                }
            }
        },
        computed: {        
            windowTitle():string {
                return this.recordStatus === 0 ? language.add_tax:
                language.edit_tax;
            }
        },
    })
