
    import { defineComponent } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { AppBranch, AppDevise, AppYearBook, SMSAccountSett } from "@/models/managttypes";
    import Swal from "sweetalert2";

    const language = GetLanguage(),
        appFxs = GeneralFx();

    export default defineComponent({
        data(){
            return {
                language,
                isStartLoading: true,
                otherOptionsRec: {
                    OptLang: 'en',
                    OptBranch: 'HQ',
                    OptYear: '2023',
                    OptDevise: 'RWF',
                    DefaultSMSAcct: '-',
                    ItemsPerTable: 10,
                },
                listOfSmsAccts: [] as SMSAccountSett[],
                listOfFinYears: [] as AppYearBook[],
                listOfBranches: [] as AppBranch[],
                listOfDevises: [] as AppDevise[],
            }
        },
        methods: {
            saveOthersetts(){
                appFxs.showAjaxLoader();

                setTimeout(() => {
                    appFxs.setLocalStorage(appDeclarations.currentBranch, this.otherOptionsRec.OptBranch);
                    appFxs.setLocalStorage(appDeclarations.currentDevise, this.otherOptionsRec.OptDevise);
                    appFxs.setLocalStorage(appDeclarations.currentYearbook, this.otherOptionsRec.OptYear);
                    appFxs.setLocalStorage(appDeclarations.currentLang, this.otherOptionsRec.OptLang);
                    appFxs.setLocalStorage(appDeclarations.currentDefaultSmsAcct, this.otherOptionsRec.DefaultSMSAcct);
                    appFxs.setLocalStorage(appDeclarations.itemsPerPage, String(this.otherOptionsRec.ItemsPerTable));



                    Swal.close();
                    document.location.reload();
                }, 2000);
            }
        },
        mounted(){
            const fDate = new Date();
            document.title = `${this.language.settings}/${this.language.otherSettings} - ${this.language.appName}`;
            const oLang = appFxs.getLocalLanguage(),
                oBranch = appFxs.getLocalStorageByKey(appDeclarations.currentOffice, 'HQ'),
                oYear = appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, fDate.getFullYear().toString()),
                oDevise = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF'),
                oSmsAcct = appFxs.getLocalStorageByKey(appDeclarations.currentDefaultSmsAcct, '-'),
                itemsPerPage = appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5');
            this.otherOptionsRec.OptLang = oLang;
            this.otherOptionsRec.OptBranch = oBranch;
            this.otherOptionsRec.OptYear = oYear;
            this.otherOptionsRec.OptDevise = oDevise;
            this.otherOptionsRec.DefaultSMSAcct = oSmsAcct;
            this.otherOptionsRec.ItemsPerTable = Number(itemsPerPage);

            NetServices.requestGet('managt/settings/listofappinternsettings')
                .then(oRes => {
                    if(oRes.bReturn){
                        this.listOfSmsAccts = oRes.listOfSmsAccts!;
                        this.listOfFinYears = oRes.listOfFinYears!;
                        this.listOfBranches = oRes.listOfBranches!;
                        this.listOfDevises = oRes.listOfDevises!;
                    }

                    this.isStartLoading = false;
                });
        }
    })
