
  import { defineComponent, Teleport } from "vue";
  import {RouterLink } from 'vue-router';
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';

  import Swal from "sweetalert2";
  import { ProdBrand } from "@/models/producttypes";

  const language =GetLanguage(),
  appFxs = GeneralFx();


  export default defineComponent({
    data(){
      return {              
        appFxs,
        language,
        isStillLoading: true,
        brandsSearchKey: '',
        listOfProdBrands: [] as ProdBrand[],
        prodBrandsPageElts: [] as ProdBrand[],
        brandSearchKey: '',
        itemsPerPage: 5,

        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 8,
          ReportTitle: language.list_of_brands
        } as any,
        printExtraData: {},
      }
    },
    computed: {
        filteredProdBrands(): ProdBrand[] {
          let searchKey = this.brandsSearchKey;
  
          if (searchKey.length === 0) return this.listOfProdBrands;
          searchKey = searchKey.toLowerCase();
  
          const filterd = this.listOfProdBrands.filter(oDev => {
            return oDev.Code.toLowerCase().includes(searchKey) ||
              oDev.BrandName.toLowerCase().includes(searchKey) ||
              oDev.Description.toLowerCase().includes(searchKey);
          });
          return filterd;
        },
      },
    methods: {
      isDisabledByDef(oBrand: ProdBrand): boolean{
        return oBrand.Code === 'BR0001';
      },
      showAddBrandAction(iStatus: number, oRecord: ProdBrand | any){
        if(iStatus==0){
            router.replace({path: '/app/products/brands/new', replace: true});
          }else{
            const fSupplier = oRecord as ProdBrand;
            const oPath = `/app/products/brands/editor/${fSupplier.Code}`;
            router.replace({path: oPath, replace: true});
          }
      },
      onPageChange(evt: any){
            this.prodBrandsPageElts = appFxs.getProxyData(evt);
      },
      deleteProdBrandRecord(data: ProdBrand){
        appFxs.showConfirm(language.delete, language.delete_quest_rec)
          .then(oResp => {
            if (!oResp.isConfirmed) return;
            this.finalDeleteBrand(data);
          });
      },
      finalDeleteBrand(data: ProdBrand){
        appFxs.showAjaxLoader();

        NetServices.requestPost('products/brands/saveprodbrand', {
            prodBrandRecord: data,
            toDelete: true,
        }).then(oRes => {
            if(!oRes.bReturn){
                Swal.close();

                appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                return;
            }

            const fList: ProdBrand[] = [];
            oRes.listOfProdBrands!.forEach(oEmail => {
                const fEmail = {...oEmail}
                fList.push(fEmail);
            });

            this.listOfProdBrands = fList;
            
            Swal.close();
        });
      },
      refreshList(){
          appFxs.showAjaxLoader();

          NetServices.requestGet('products/brands/listofprodbrands')
          .then(oRes => {
              if(!oRes.bReturn){
                      appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                      .then(xRes => {
                          //router.replace({path: '/app/myprofile', replace: true});
                          Swal.close();
                      });
                      return;
                  }

              const fList: ProdBrand[] = [];
              oRes.listOfProdBrands!.forEach(oEmail => {
                  const fEmail = {...oEmail}
                  fList.push(fEmail);
              });

              this.listOfProdBrands = fList;
              Swal.close();
          });
      },
      printListItems(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.list_of_brands;
        dPrint.ReportToPrint = 8;

        this.printInitialValues = dPrint;


        this.isPrintvisible = true;
      },      
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },
    },
    mounted(){
        document.title = this.language.list_of_brands + ' - ' + this.language.appName;
        this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
  
        NetServices.requestGet('products/brands/listofprodbrands')
          .then(oRes => {
              if(!oRes.bReturn){
                      appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                      .then(xRes => {
                          router.replace({path: '/app/myprofile', replace: true});
                      });
                      return;
                  }
  
              const fList: ProdBrand[] = [];
              oRes.listOfProdBrands!.forEach(oEmail => {
                const fEmail = {...oEmail}
                    fList.push(fEmail);
              });
  
              this.listOfProdBrands = fList;
              
              this.isStillLoading = false;
          });
      }
  })
