
    import { defineComponent } from "vue";
    import { Line } from 'vue-chartjs'
    import { 
        Chart as ChartJS,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
     } from 'chart.js';

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import NetServices from "@/tools/netservices";
    import { DashboardSituation } from "@/models/notdb";

    const language = GetLanguage(),
        appFxs = GeneralFx(),
        fDate = new Date();

    const oDash: DashboardSituation = {
        TotalRevenuesCurrent: 0,
        TotalRevenuesPrevious: 0,
        TotalRevenuesGrowth: 0,
        TotalRevenuesStatus: 0,
        SalesComparatorCurr: [],
        SalesComparatorPrev: [],
    };

    export default defineComponent({
        name: 'DashboardSalesComparator',
        components: {Line},
        props: {
            currentYear: {
                type: Number,
                default: fDate.getFullYear(),
            },
            monthPeriod: {
                type: Number,
                default: fDate.getMonth() + 1,
            },
            typeOperation: {
                type: Number,
                default: 0,
            },
        },
        data(){
            return {
                appFxs,
                language,
                isLoading: true,
                monthValue: this.monthPeriod,
                dashBData: {...oDash} as DashboardSituation,
                compareChartData: {
                    labels: appFxs.wdMonthsShrt,
                    datasets: [
                        {
                            label: this.currentYear.toString(),
                            data: [] as number[],
                            backgroundColor: "rgba(54,73,93,.5)",
                            borderColor: "#36495d",
                            borderWidth: 3
                        },
                        {
                            label: (this.currentYear - 1).toString(),
                            data: [] as number[],
                            backgroundColor: "rgba(71, 183,132,.5)",
                            borderColor: "#47b784",
                            borderWidth: 3
                        },
                    ],                    
                },
                options: {
                    responsive: true,
                    lineTension: 1,
                    stacked: false,
                }
            }
        },
        methods: {
            populateData(){
                this.isLoading = true;
                NetServices.requestGet(`analytics/dashboard/getdashboardata/${this.monthValue}/${this.typeOperation}`)
                    .then(oRes => {
                        if(!oRes.bReturn){
                            this.isLoading = false;
                            return;
                        }

                        this.isLoading = false;
                        this.dashBData = oRes.dashboardDataInfo!;

                        this.compareChartData.datasets[0].data = this.dashBData.SalesComparatorCurr;
                        this.compareChartData.datasets[1].data = this.dashBData.SalesComparatorPrev;
                    });
            }
        },
        mounted(){
            this.populateData();
        },
        watch: {
            monthPeriod: {
                handler(newV, oldV) {
                    if (newV !== oldV) {
                        this.monthValue = newV as number;
                        this.populateData();
                    }
                },
            },
        },
    });
