
    import { useVuelidate } from '@vuelidate/core';
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators';
    import jquery from 'jquery';

    import { defineComponent } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { PurchasePayment } from '@/models/producttypes';
    import NetServices from '@/tools/netservices';

    const language =GetLanguage(),
        appFxs = GeneralFx(),
        $ = jquery;
    
    const emptyPaytRec: PurchasePayment = {
        IdPurchasePayment:0,
        PurchaseKey: '',
        PaytOpsKey: '',
        SupplierKey: '',
        PaytDoctNbr:  '',
        PaytDate: new Date(),
        PaytAmt: 0,
        UserEmail: '',
        PaytType: 0,
    }
    const greaterThanZero = (val: number) => val > 0;

    export default defineComponent({
        name: 'PurchPaytModal',
        emits: ['closeForm'],
        props: [
            "visible",
            "initialValues", 
            "recordStatus", 
            "purchaseRecord", 
            "listProducts", 
            "listVariants",
            "remainAmt",
        ],
        data(){
            return {
                v$: useVuelidate(),
                appFxs,
                language,
                dataRecord: {...emptyPaytRec} as PurchasePayment,

                viewStatus: this.recordStatus as number,
                theRemainAmt: this.remainAmt as number,
                isSavingRecord: false,
            }
        },
        computed: {
            windowTitle(){
                return this.recordStatus == 0 ? language.add_payt:
                language.edit_payt;
            },
        },
        methods: {
            generateReceiptNbr(){
                const otime = new Date();
                const oSec = otime.getSeconds();
                const oMins = otime.getMinutes();

                const oRandom = appFxs.getRandomDigits(2);
                const fSecs = oSec.toString().length == 2 ? 
                    oSec.toString() : '0' + oSec.toString();
                const fMins = oMins.toString().length == 2 ? 
                    oMins.toString() : '0' + oMins.toString();

                const oNbr = 'PPAYT' + appFxs.generateTextNbr(3) + `${oRandom}${fSecs}${fMins}`;
                this.dataRecord.PaytDoctNbr =  oNbr.toUpperCase();
            },
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.isSavingRecord = true;
                const oRec = {...this.dataRecord, PaytDoctNbr: this.dataRecord.PaytDoctNbr.toUpperCase()} as PurchasePayment;

                NetServices.requestPost('stockops/purchases/savepurchasepaytoper', {
                    purchasePaytRecord: oRec,
                    toDelete: false,
                }).then(oRes => {
                    if (!oRes.bReturn) {
                        this.isSavingRecord = false;
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    this.isSavingRecord = false;
                    this.v$.$reset();

                    this.$emit('closeForm', {returnType: 1, returnData: {
                        bReturn: true,
                        listItems: oRes.lstPaymentsForPurchaseOrder!,
                        purchaseInfoSit: oRes.purchaseInfoSit!,
                    }});
                });
            },
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: PurchasePayment = {...oValues} as PurchasePayment;
                        
                        console.log('this.remainAmt', this.remainAmt);

                        this.viewStatus = this.recordStatus as number;
                        this.theRemainAmt = this.remainAmt as number;

                        if(this.viewStatus==0){
                            fValues.PaytAmt = Number(this.theRemainAmt);
                        }

                        this.dataRecord = fValues;
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {
                    PurchaseKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    SupplierKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    PaytDoctNbr: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    UserEmail: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    PaytAmt: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThan: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),                 
                        $autoDirty: true,
                    },
                }
            }
        },
        mounted(){
            appFxs.updateLoadedScripts();
        }
    })
