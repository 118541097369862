import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-514f4fe1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vh-100 d-flex align-items-end" }
const _hoisted_2 = { class: "quote-cover text-center" }
const _hoisted_3 = { class: "quote-elt mb-1" }
const _hoisted_4 = { class: "quote-owner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "image-area",
    style: _normalizeStyle(_ctx.sayingElement.bg),
    class: "vh-100 col-lg-8 col-md-7 col-sm-6 d-none d-sm-block d-md-block d-lg-block d-xl-block no-float img-part bg-primary"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.sayingElement.saying.say), 1),
        _createElementVNode("span", _hoisted_4, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.sayingElement.saying.author), 1)
        ])
      ])
    ])
  ], 4))
}