
    import {defineComponent} from 'vue';
    import router from '@/router/index';

    import ProdVariantModal from '@/components/modals/ProdVariantModal.vue';
    import ProdVariantValueModal from '@/components/modals/ProdVariantValueModal.vue';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { Supplier, TaxSett } from "@/models/managttypes";

    import Swal from "sweetalert2";
    import { ProdProduct, ProductVariant, ProductVariantDetail } from '@/models/producttypes';
    import AppDeclarations from '@/tools/declarations';

    const language = GetLanguage(),
        appFxs = GeneralFx();
    const emptyProd: ProdProduct = {
        ProdProductId: 0,
        SKU: '',
        BarCode: '',
        Name: '',
        ProductType: 0,
        Categories: '',
        SubCategories: '',
        BrandCode: 'BR0001',
        IsKeepStock: true,
        IsTaxable: true,
        AllowPurchaseOutStock: false,
        PurchasePrice: 0,
        SellingPrice: 0,
        ReorderQuantity: 0,
        MinOrderQuantity: 0,
        Tags: '',
        Description: '',
        DefaultSupplier: '',
        DefaultTax: '',
        UnitOfMeasuremt: '',
        DefaultImage: '',
        ProdImage1: '',
        ProdImage2: '',
        ProdImage3: '',
        ProdImage4: '',
        ProdImage5: '',
        YoutubeVideoLink: '',
        PcePerUnit: 1,
    };
    const emptyVariantCode: ProductVariant = {
        IdVariant: 0,
        ProductSKU: '',
        VariantCode: '',
    }
    const emptyVariantDetail: ProductVariantDetail = {
        IdVariantDetail: 0,
        ProductSku: '',
        VariantValues: '',
        VariantCodes: '',
        VariantCodesObject: '',
        SKU: '',
        BarCode: '',
        PurchasePrice: 0,
        SellingPrice: 0,
    }
    const emptyTaxModel: TaxSett = {
        IsActive: true,
        TaxKey: '',
        TaxName: 'EmptyTax',
        TaxRate: 0,
        TaxSettId: 0,
    }


    export default defineComponent({
        components: {
            ProdVariantModal,
            ProdVariantValueModal,
        },
        data(){
            return{
                language,
                appFxs,
                lstProdVariants: [] as ProductVariant[],
                lstProdVariantValues: [] as ProductVariantDetail[],

                currCode: 'RWF',

                
                isStillLoading: true,
                produckSku: '',
                foundProduct: {...emptyProd} as ProdProduct,

                isVariantCodeDlgVisible: false,
                variantCodeRecord: {...emptyVariantCode} as ProductVariant,
                variantCodeStatus: 0,

                isVariantDetailDlgVisible: false,
                variantDetailRecord: {...emptyVariantDetail} as ProductVariantDetail,
                variantDetailStatus: 0,

                taxSettModel: {...emptyTaxModel} as TaxSett,
            }
        },
        methods: {        
            getTaxIncluded(varVal: ProductVariantDetail): number{
                const oProd = appFxs.getProxyData(this.foundProduct);
                const oTax = appFxs.getProxyData(this.taxSettModel) as TaxSett;

                const oFinal = varVal.SellingPrice * (1 +(oTax.TaxRate / 100));
                return oFinal;
            },
            addEditVariantCode(iStatus: number, oRecord: ProductVariant | any){
                if(iStatus==0){
                    const oRec = {...emptyVariantCode, ProductSKU: this.produckSku } as ProductVariant;
                    this.variantCodeRecord = oRec;
                    this.variantCodeStatus = 0,
                    this.isVariantCodeDlgVisible = true;
                }else{
                    const oRec = {...oRecord } as ProductVariant;
                    this.variantCodeRecord = oRec;
                    this.variantCodeStatus = 1,
                    this.isVariantCodeDlgVisible = true;
                }
            },
            deleteVariantCode(oRecord: ProductVariant){
                appFxs.showConfirm(language.delete, language.delete_variant_code_req_msg)
                    .then(vRes => {
                        if(!vRes.isConfirmed)return;

                        this.deleteVariantCodeFinal(oRecord);
                    });
            },
            deleteVariantCodeFinal(oRecord: ProductVariant){
                appFxs.showAjaxLoader();

                NetServices.requestPost(`products/variants/savevariantoperrecord`,
                {
                    variantDataRecord: oRecord,
                    toDelete: true,
                    populateData: true,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    this.lstProdVariants = oRes.lstOfProdVariants!;
                    Swal.close();
                });
            },
            onCloseProdeCodeDlg(evt: any){
                const retType = evt.returnType;
                    
                this.isVariantCodeDlgVisible = false;
                if(retType==0) return;
                const bRet = evt.returnData.bReturn,
                    lst = evt.returnData.listOfItems;
                if(!bRet) return;
                this.lstProdVariants = lst;
            },
            addEditVariantVal(iStatus: number, oRecord: ProductVariantDetail | any){
                if(iStatus==0){
                    const oRec = {
                        ...emptyVariantDetail,
                        ProductSku: this.produckSku
                    } as ProductVariantDetail;
                    this.variantDetailRecord = oRec;
                    this.variantDetailStatus = 0;
                    this.isVariantDetailDlgVisible = true;
                }else{
                    const oRec = {...oRecord } as ProductVariantDetail;
                    this.variantDetailRecord = oRec;
                    this.variantDetailStatus = 1;
                    this.isVariantDetailDlgVisible = true;
                }
            },
            deleteVariantDetail(oRecord: ProductVariantDetail){
                appFxs.showConfirm(language.delete, language.delete_variant_detail_req_msg)
                    .then(vRes => {
                        if(!vRes.isConfirmed)return;

                        this.deleteVariantDetailFinal(oRecord);
                    });
            },
            deleteVariantDetailFinal(oRecord: ProductVariantDetail){
                appFxs.showAjaxLoader();

                NetServices.requestPost(`products/variantdets/savevariantvaluerecord`,
                {
                    variantDetailRecord: oRecord,
                    toDelete: true,
                    populateData: true,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    this.lstProdVariantValues = oRes.lstOfProdVariantValues!;
                    Swal.close();
                });
            },
            onCloseProdeDetailDlg(evt: any){
                const retType = evt.returnType;
                    
                this.isVariantDetailDlgVisible = false;
                if(retType==0) return;
                const bRet = evt.returnData.bReturn,
                    lst = evt.returnData.listOfItems;
                if(!bRet) return;
                this.lstProdVariantValues = lst;
            }
        },
        mounted(){
            document.title = language.loading + ' - ' + this.language.appName;
            this.currCode = appFxs.getLocalStorageByKey(AppDeclarations.currentDevise, 'RWF');

            const prodSku = this.$route.params.productKey;
            if(!prodSku){
                appFxs.showAlert(language.missing_settings, language.missing_settings_msg)
                    .then(vRes => {
                        const oPath = `/app/products/products/list`;
                        router.replace({ path: oPath, replace: true });
                    });
                return;
            }
            const finalSku = prodSku as string;
            this.produckSku = finalSku;

            NetServices.requestGet(`products/products/getprodwithvariants/${finalSku}`)
                .then(oRes => {
                    if (!oRes.bReturn) {
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                            .then(vRes => {
                                const oPath = `/app/products/products/list`;
                                router.replace({ path: oPath, replace: true });
                            });                        
                        return;
                    }
                    
                    const oneFinalRet = oRes.productWithVar!;

                    
                    this.lstProdVariants = oneFinalRet.lstVariantCodes;
                    this.lstProdVariantValues = oneFinalRet.lstVariantDetails;
                    this.taxSettModel = oRes.oneTaxData!;

                    const tempoProd = oneFinalRet.oneProduct;

                    this.foundProduct = tempoProd;


                    document.title = language.manage_variants_prod + ' - ' + this.language.appName;

                    this.isStillLoading = false;
                });

        }
    })
