
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent, toRaw } from 'vue';
    import { InvoiceSett } from '@/models/managttypes';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
import { CurrencyCode } from '@/tools/smarttypes';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    
    const emptyInvoiceSett: InvoiceSett = {
        InvoiceSettId: 0,
        Currency: '',
        InvoiceFooter: '',
        SendReminder: 0,
    }

    export default defineComponent({
        name: 'InvoiceOptSettModal',
        emits: ['closeForm'],
        props: ["visible", "initialValues", "recordStatus", "lstOfCurrencies"],
        data(){
            return {
                v$: useVuelidate(),
                language,
                windowTitle: language.invoiceOptions,
                dataRecord: {...emptyInvoiceSett} as InvoiceSett,
                currencies: this.lstOfCurrencies as CurrencyCode[],
            }
        },
        methods: {
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.$emit('closeForm', {returnType: 1, returnData: this.dataRecord});
            }
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: InvoiceSett = {...oValues};

                        if(this.recordStatus == 0){
                            this.v$.$reset();
                        }

                        this.dataRecord = fValues;
                    }
                }
            }
        }
    })
