import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mt-3" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-3" }
const _hoisted_4 = { class: "list-group" }
const _hoisted_5 = { class: "list-group mt-4" }
const _hoisted_6 = { class: "col-md-9 app-sett-det" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_RouterLink, {
            to: "/app/settings/changecred",
            class: "list-group-item list-group-item-action"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'user'],
                class: "d-inline-block me-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.language.changeCredentials), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_RouterLink, {
            to: "/app/settings/othersetts",
            class: "list-group-item list-group-item-action"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'cog'],
                class: "d-inline-block me-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.language.otherSettings), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_RouterLink, {
            to: "/app/settings/mycompany",
            class: "list-group-item list-group-item-action"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'bank'],
                class: "d-inline-block me-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.language.myCompany), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_RouterLink, {
            to: "/app/settings/emailsetts",
            class: "list-group-item list-group-item-action"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'envelope'],
                class: "d-inline-block me-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.language.emailSettings), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_RouterLink, {
            to: "/app/settings/usersconfig",
            class: "list-group-item list-group-item-action"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'users'],
                class: "d-inline-block me-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.language.users), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_RouterLink, {
            to: "/app/settings/billinginfo",
            class: "list-group-item list-group-item-action"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'file-invoice'],
                class: "d-inline-block me-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.language.billingInfo), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_RouterLink, {
            to: "/app/settings/smssetts",
            class: "list-group-item list-group-item-action"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'sms'],
                class: "d-inline-block me-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.language.smsSettings), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_RouterLink, {
            to: "/app/settings/invoiceoptions",
            class: "list-group-item list-group-item-action"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'cube'],
                class: "d-inline-block me-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.language.invoiceOptions), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_RouterLink, {
            to: "/app/settings/financialyears",
            class: "list-group-item list-group-item-action"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'calendar'],
                class: "d-inline-block me-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.language.financialYears), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_RouterLink, {
            to: "/app/settings/currencies",
            class: "list-group-item list-group-item-action"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'dollar'],
                class: "d-inline-block me-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.language.currencies), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_RouterLink, {
            to: "/app/settings/taxes",
            class: "list-group-item list-group-item-action"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'percentage'],
                class: "d-inline-block me-1"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.language.taxes), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}