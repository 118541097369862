
import { defineComponent } from "vue";
import router from '@/router/index';

import NetServices from '@/tools/netservices';

import GetLanguage from '@/tools/language';
import GeneralFx from '@/tools/general_fx';
import appDeclarations from '@/tools/declarations';
import DataFxs from '@/tools/data_functions';

import Swal from "sweetalert2";
import { ProdProduct } from "@/models/producttypes";

import { SalesRefund, SalesRefundDetail } from "@/models/salestypes";
import { Customer } from "@/models/managttypes";
const language = GetLanguage(),
    appFxs = GeneralFx(),
    dataFxs = DataFxs();

const fNewDate = new Date(),
    year = fNewDate.getFullYear();

export default defineComponent({
    name: 'SalesOperListDet',
    data() {
        return {
            appFxs,
            language,
            isStillLoading: true,
            itemSearchKey: '',
            itemsPerPage: 5,
            selectedYear: year,
            currCode: 'RWF',
            userEmail: '',

            selectedRefundKey: '',

            listOfRefundDetails: [] as SalesRefundDetail[],
            itemsPageElts: [] as SalesRefundDetail[],
            listOfProducts: [] as ProdProduct[],
            salesRefundElement: null as any,
            oneCustomerRec: null as any,

            isPrintvisible: false,
            printInitialValues: {
                ...appFxs.getReportingData(),
                ReportToPrint: 8,
                ReportTitle: language.list_of_brands
            } as any,
            printExtraData: {},
        }
    },
    computed: {
        totalSalesDetails(): SalesRefundDetail {
            const lst = this.listOfRefundDetails;
            const first = lst[0];


            const discKeys = first == undefined ? '': first.DiscountKeys;
            const taxKeys = first == undefined ? '': first.TaxKey;
            const unitPr = first == undefined ? 0: first.UnitPrice;
            const qty = lst.reduce((acct, o) => {
                return acct + o.Quantity;
            }, 0);
            const sub = lst.reduce((acct, o) => {
                return acct + o.SubTotal;
            }, 0);
            const disc = lst.reduce((acct, o) => {
                return acct + o.DiscountAmount;
            }, 0);
            const txAmt = lst.reduce((acct, o) => {
                return acct + o.TaxableAmount;
            }, 0);
            const txVal = lst.reduce((acct, o) => {
                return acct + o.TaxValue;
            }, 0);
            const totAmt = lst.reduce((acct, o) => {
                return acct + o.TotalAmt;
            }, 0);

            return {
                IdRefundDet: 0,
                OrderKey: '',
                OrderDetKey: '',
                RefundDetailKey: '',
                RefundKey: '',
                ProductSKU: '',
                ProductOrient: 0,
                VariantValues: '',
                Quantity: qty,
                UnitPrice: unitPr,
                SubTotal: sub,
                DiscountKeys: discKeys,
                DiscountAmount: disc,
                TaxableAmount: txAmt,
                TaxKey: taxKeys,
                TaxRate: 0,
                TaxValue: txVal,
                TotalAmt: totAmt,
                FiscalYear: 0,
                TempoKey: '',
            }
        },
        filteredRecordItems(): SalesRefundDetail[] {
            let searchKey = this.itemSearchKey;

            if (searchKey.length === 0) return this.listOfRefundDetails;
            searchKey = searchKey.toLowerCase();

            const filterd = this.listOfRefundDetails.filter(oDev => {
                return oDev.OrderKey.toLowerCase().includes(searchKey);
            });
            return filterd;
        },
        getInfoRecord(): {orderKey: string;refundNbr: string;customerName: string;refundKey: string;} {
            const oFinal = {
                orderKey: '',
                customerName: '',
                refundNbr: '',
                refundKey: '',
            };

            if (!this.salesRefundElement || !this.oneCustomerRec)
                return oFinal;

            const fOrder = this.salesRefundElement as SalesRefund;
            const fCustomer = this.oneCustomerRec as Customer;

            return {
                refundNbr: fOrder.RefundNbr,
                refundKey: fOrder.RefundKey,
                orderKey: fOrder.OrderKey,
                customerName: fCustomer.ClientName
            }
        }
    },
    methods: {
        getProductRecord(oDetail: SalesRefundDetail): string {
            const oProd = this.listOfProducts.find(o => o.SKU == oDetail.ProductSKU);
            if (!oProd) return '';
            if (oDetail.ProductOrient == 0) return oProd.Name;
            return `${oProd.Name} (${oDetail.VariantValues})`;
        },
        refresListItems() {
            appFxs.showAjaxLoader();
            NetServices.requestGet(`salesorders/refund/getlistofrefunddetails/${this.selectedRefundKey}`)
                .then(oRes => {
                    if (!oRes.bReturn) {
                        Swal.close();
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    this.listOfRefundDetails = oRes.listOfRefundDetails!;
                    this.listOfProducts = oRes.listOfProducts!
                    this.salesRefundElement = oRes.salesRefundElement;
                    this.oneCustomerRec = oRes.oneCustomerRec;

                    Swal.close();
                });

        },
        onPageChange(evt: any) {
            this.itemsPageElts = appFxs.getProxyData(evt);
        },
        printListItems() {
            const dPrint = appFxs.getReportingData();
            dPrint.ReportTitle = `${language.refund_note} - ${this.getInfoRecord.refundNbr}`;
            dPrint.ReportToPrint = 28;

            this.printInitialValues = dPrint;

            this.printExtraData = {
              SalesRefundKey: this.selectedRefundKey,
            }

            this.isPrintvisible = true;
        },
        onPrintClose(evt: any) {
            this.isPrintvisible = false;
            if (evt.returnType === 0) return;
        },
    },
    mounted() {
        document.title = this.language.refund_details + ' - ' + this.language.appName;
        this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
        this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
        this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
        this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));

        const refundKey = this.$route.params.refundKey;

        if (!refundKey) {
            router.push({
                name: 'appsalesrefundlist',
            });
            return;
        }
        const _refundKey = refundKey as string;

        this.selectedRefundKey = _refundKey;

        NetServices.requestGet(`salesorders/refund/getlistofrefunddetails/${this.selectedRefundKey}`)
            .then(oRes => {
                if (!oRes.bReturn) {
                    appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                        .then(vRes => {
                            router.push({
                                name: 'appsalesrefundlist',
                            });
                        });
                    return;
                }



                this.listOfRefundDetails = oRes.listOfRefundDetails!;
                this.listOfProducts = oRes.listOfProducts!;
                this.salesRefundElement = oRes.salesRefundElement;
                this.oneCustomerRec = oRes.oneCustomerRec;

                console.log('this.listOfRefundDetails', this.listOfRefundDetails);

                this.isStillLoading = false;
            });
    }
})
