const AppDeclarations = {
    currentLang: 'current_language',
    connectionStatus: 'connect_status',
    apiRoot: 'https://byihuseapi.wiseobjects.com/api/',
    apiUrl: 'https://https://byihuseapi.wiseobjects.com/',

    userAccessKey: 'user_access_key',

    buyerAccessKey: 'buyer___access___key',

    company_code: 'WOBD213789-TV321',
    currentYearbook: 'current_year_book',
    currentDefaultSmsAcct: 'current_def_sms_acct',
    itemsPerPage: 'items_per_page',
    currentDevise: 'current_devise',
    currentBranch: 'current_branch',
    userEmail: 'user_email',
    userPassword: 'userPassword',

    connecteUserRecord: 'current_user_record',
    timeZones: 'time_zones',
    telephonesCodes: 'telephones_codes',
    currencySymbols: 'currency_symbols',
    currentOffice: 'current_office',
    connectedCompany: 'connected_company_001',
    listOfSmsAccounts: 'list___of_sms_accts____',
    listOfEmailAccounts: 'list___of_email_accts____',


    tempoCustomerRecord: 'tempo_customer_rec______',
    tempoSalesRefund: 'tempo_sales_refund___',
    tempoSalesRecord: 'tempo_sales_record___',
    tempoSalesRecordDetails: 'tempo_sales_record_details__',

    tempoLstProducts: 'tempo_list_products_____',
    tempoLstVariants: 'tempo_list_variants_____',
    salesNewOperationState: 'sales_operation_status___',

    tempoLstTaxes: 'tempo_list_taxes____',
    tempoLstDiscountsWRules:'tempo_discounts_rules____',
}

export default AppDeclarations;