
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent, toRaw } from 'vue';
    import { Email, EmailSenderSett } from '@/models/managttypes';
    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';

    const language =GetLanguage(),
        appFxs = GeneralFx();

    const emptyEmailData: Email = {
        EmailId: 0,
        EmailKey: '',
        EmailFrom: '',
        EmailSubject: '',
        Recipients: '',
        RecipientsCC: '',
        RecipientsBCC: '',
        EmailBody: '',
        DateSent: new Date(),
        UserEmail: '',
        EmailSource: 0,
        EmailSourceKey: '',
        EmailSent: false,
        IsRead: false,
        EmailImportant: false,
        EmailDeleted: false,
        EmailStatus: 0,
        EmailDataStatus: 0,
        HasAttachments: false,
        ListAttachments: '',
        Label: '',
        MessageUID: ''
    };
    export default defineComponent({
        name: 'EmailSenderDialog',
        emits: ['closeForm'],
        props: ["visible", "firstRecipients", "firstRecipientsCC", "firstRecipientsBCC"],
        data(){
            return {
                v$: useVuelidate(),
                language,
                appFxs,
                dataRecord: {...emptyEmailData},
                listOfEmailAccts: [] as EmailSenderSett[],
                editorContent: '',

                isSending: false,

                recipients: [] as string[],
                recipientsCC: [] as string[],
                recipientsBCC: [] as string[],

                isCCVisible: false,
                isBCCVisible: false,
            }
        },
        computed: {
            editor(){
                return this.$refs.htmlEditor;
            }
        },
        methods: {
            onHtmlChange(content: any){
                const htmlData = (this.editor as any).getHTML();
                const textFromHtml = appFxs.getTextFromHtml(htmlData);
                this.dataRecord.EmailBody = textFromHtml.length > 0 ?  htmlData: '';
            },
            sendRecordEmail(){
                const emailData = appFxs.getProxyData(this.dataRecord);
                const editType = (emailData as Email).EmailId == 0 ? 0: 1;

                this.isSending = true;
                NetServices.requestPost('comm/email/sendgeneralemailtobase', {
                    emailDataModel: emailData,
                    toDelete: false
                })
                .then(oRes => {
                    if(!oRes.bReturn){
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                        .then(vRes => {
                            this.isSending = false;
                        });

                        return;
                    };

                    this.isSending = false;
                    this.$emit('closeForm', {returnType: 1, returnData: {bReturn: oRes.bReturn}});
                }); 
            },
            closeFormRet(){
                this.isSending = false;
                this.$emit('closeForm', {returnType: 0, returnData: {bReturn: false}});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            setEmailAddresses(){
                const recipients = this.recipients.length == 0 ? '': (appFxs.getProxyData(this.recipients) as string[]).join(';');
                const recipientsCC = this.recipientsCC.length == 0 ? '': (appFxs.getProxyData(this.recipientsCC) as string[]).join(';');
                const recipientsBCC = this.recipientsBCC.length == 0 ? '': (appFxs.getProxyData(this.recipientsBCC) as string[]).join(';');


                this.dataRecord.Recipients = recipients;
                this.dataRecord.RecipientsCC = recipientsCC;
                this.dataRecord.RecipientsBCC = recipientsBCC;
            },
            updateRecipients(evt: any){
                this.setEmailAddresses();
            },
            updateRecipientsCC(evt: any){
                this.setEmailAddresses();
            },
            updateRecipientsBCC(evt: any){
                this.setEmailAddresses();
            },
            changeVisibleCC(){
                this.isCCVisible = !this.isCCVisible;
            },
            changeVisibleBCC(){
                this.isBCCVisible = !this.isBCCVisible;
            }
        },
        validations(){
            return {
                dataRecord: {
                    EmailFrom: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    Recipients: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    EmailSubject: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    EmailBody: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                }
            }
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if(newV != oldV){


                        this.dataRecord = {...emptyEmailData};
                        const userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
                        this.dataRecord.UserEmail = userEmail;

                        const oRecipients = this.firstRecipients as string[];
                        const oRecipientsCC = this.firstRecipientsCC as string[];
                        const oRecipientsBCC = this.firstRecipientsBCC as string[];                        

                        this.recipients = oRecipients == null || oRecipients == undefined ? []: appFxs.getProxyData(oRecipients);
                        this.recipientsCC = oRecipientsCC == null || oRecipientsCC == undefined ? []: appFxs.getProxyData(oRecipientsCC);
                        this.recipientsBCC = oRecipientsBCC == null || oRecipientsBCC == undefined ? []: appFxs.getProxyData(oRecipientsBCC);

                        this.setEmailAddresses();

                        this.isSending = false;
                        this.isCCVisible = false;
                        this.isBCCVisible = false;
                    }
                },
                deep: true
            }
        },
        mounted(){
            const lstEmailAccts = JSON.parse(appFxs.getLocalStorageByKey(appDeclarations.listOfEmailAccounts, '[]'));
            this.listOfEmailAccts = lstEmailAccts as EmailSenderSett[];
            const userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
            this.dataRecord.UserEmail = userEmail;
            
            this.isSending = false;
            this.isCCVisible = false;
            this.isBCCVisible = false;
        }
    });
