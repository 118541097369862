
  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';

  import Swal from "sweetalert2";
  import { ProdProduct, ProductVariantDetail } from "@/models/producttypes";
  import { Customer, ManagtUser, TaxSett } from "@/models/managttypes";

  import { ShortData } from "@/tools/smarttypes";
  import { SalesOrder } from "@/models/salestypes";
  import { DiscountWithRules, SalesOrderInfo } from "@/models/notdb";
  import SalesOrderModal from '@/components/modals/SalesOrderModal.vue';


  const language =GetLanguage(),
    appFxs = GeneralFx();
  const fNewDate = new Date();

  const emptySalesOrder:SalesOrder = {
    IdOrder: 0,
    OrderKey: '',
    OrderNbr: '',
    ClientKey: '',
    OperDate: fNewDate,
    UserEmail: '',
    OrderStatus: 0,
    PaytStatus: 0,
    DeliveryStatus: 0,
    DeviseISO: '',
    OfficeCode: '',
    OrderNote: '',
    OrderBarcode: '',
    OrderQRCode: '',
    OrderCreatedBy: '',
    FiscalYear: 0,
    TempoKey: '',
  }
  const newDate = new Date(),
    month = newDate.getMonth() +1,
    year = newDate.getFullYear();

  export default defineComponent({
    components: {
      SalesOrderModal,
    },
    data(){
      return {
        appFxs,
        language,        
        isStillLoading: true,
        itemSearchKey: '',
        itemsPerPage: 5,
        listOfSalesOrdersInfo: [] as SalesOrderInfo[],
        itemsPageElts: [] as SalesOrderInfo[],

        listOfDiscountWithRules: [] as DiscountWithRules[],
        listOfTaxSettings: [] as TaxSett[],

        listOfCustomers: [] as Customer[],
        listOfUsers: [] as ManagtUser[],

        listOfProducts: [] as ProdProduct[],
        lstOfProdVariantValues: [] as ProductVariantDetail[],
        clientRecord: null as Customer|any,

        isSalesOrderVis: false,
        salesOrderData: {...emptySalesOrder} as SalesOrder,
        salesOrderStatus: 0,




        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 8,
          ReportTitle: language.list_of_brands
        } as any,
        printExtraData: {},

        currCode: 'RWF',
        userEmail: '',

        selectedMonth: month,
        selectedYear: year,
        listOfMonths: [] as ShortData[],
      }
    },
    computed: {
      selectedMonthLabel(): string{
        return this.listOfMonths[this.selectedMonth - 1].label;
      },
      filteredRecordItems(): SalesOrderInfo[] {
        let searchKey = this.itemSearchKey;

        if (searchKey.length === 0) return this.listOfSalesOrdersInfo;
        searchKey = searchKey.toLowerCase();

        const filterd = this.listOfSalesOrdersInfo.filter(oDev => {
          return oDev.SalesOrderRecord.OrderNbr.toLowerCase().includes(searchKey) ||          
            oDev.CustomerRecord.ClientName.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
    },
    methods: {
      completeOrder(oOrder: SalesOrder) {
        appFxs.showConfirm(language.lock_order, language.lock_order_msg_qst)
        .then(vRes => {
          if(!vRes.isConfirmed)return;
          this.completeOrderFinal(oOrder);
        });
      },
      completeOrderFinal(oOrder: SalesOrder) {
        appFxs.showAjaxLoader();
        NetServices.requestPost(`salesorders/sales/completesalesorder`,{
          salesOperKey: oOrder.OrderKey
        })
          .then(oRes => {
            if (!oRes.bReturn) {
              Swal.close();
              appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
              return;
            }

            const fList: SalesOrderInfo[] = [];
            oRes.lstOfSalesOrdersInfo!.forEach(oSalesOrder => {
              const fOrderTempo = { ...oSalesOrder } as SalesOrderInfo;
              const fRealOrder = {...fOrderTempo.SalesOrderRecord,OperDate: new Date(fOrderTempo.SalesOrderRecord.OperDate) } as SalesOrder;
              fOrderTempo.SalesOrderRecord = fRealOrder;
              fList.push(fOrderTempo);
            });

            this.listOfUsers = oRes.listOfUsers!;
            this.listOfCustomers = oRes.listOfCustomers!;
            this.listOfProducts = oRes.listOfProducts!;
            this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;
            this.listOfSalesOrdersInfo = fList;

            this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
            this.listOfTaxSettings = oRes.listOfTaxSettings!;

            Swal.close();
          });
      },
      isCompleteReady(oOrder: SalesOrderInfo): boolean{
        const orderStatus = oOrder.SalesOrderRecord.OrderStatus;
        const paytStatus = oOrder.PaytStatus;
        const deliveryStatus = oOrder.DeliveryStatus;

        return orderStatus == 1 && paytStatus == 2 && deliveryStatus == 2;
      },
      addSalesOrderRecord(iStatus: number, oRecord: SalesOrder|any){
        if(iStatus == 0){
          const fRecord = {
            ...emptySalesOrder,
            UserEmail: this.userEmail,
            DeviseISO: this.currCode,
            OfficeCode: 'HQ',
            OrderBarcode: appFxs.getRandomDigits(12),
            OrderQRCode: appFxs.getRandomDigits(15),
            OrderCreatedBy: this.userEmail,
            FiscalYear: this.selectedYear,
            ClientKey: 'GLOBALCUST',
            TempoKey: appFxs.getRandomDigits(35),
            OrderNbr: appFxs.generatedSalesOrderNbr(),
          } as SalesOrder;

          this.salesOrderData = fRecord;
        }else{
          this.salesOrderData = {...oRecord} as SalesOrder;
        }

        this.isSalesOrderVis = true;
      },
      onDetailClose(evt: any) {
        if (evt.returnType == 0) {
          this.isSalesOrderVis = false;
          return;
        }

        this.isSalesOrderVis = false;

        const finalRet = appFxs.getProxyData(evt.returnData) as SalesOrder;
        finalRet.OrderKey = appFxs.generateUUID();
        const newClient = this.listOfCustomers.find(o => o.ClientKey == finalRet.ClientKey);
        
        if(!newClient){
          appFxs.showAlert(language.miss_client, language.miss_client_msg)
          return;
        }

        
        appFxs.setLocalStorage(appDeclarations.tempoCustomerRecord, JSON.stringify(newClient));
        appFxs.setLocalStorage(appDeclarations.tempoSalesRecord, JSON.stringify(finalRet));
        appFxs.setLocalStorage(appDeclarations.tempoLstProducts, JSON.stringify(this.listOfProducts));
        appFxs.setLocalStorage(appDeclarations.tempoLstVariants, JSON.stringify(this.lstOfProdVariantValues));

        appFxs.setLocalStorage(appDeclarations.tempoLstTaxes, JSON.stringify(this.listOfTaxSettings));
        appFxs.setLocalStorage(appDeclarations.tempoLstDiscountsWRules, JSON.stringify(this.listOfDiscountWithRules));

        appFxs.setLocalStorage(appDeclarations.salesNewOperationState, '1');

        router.push({
          name: 'appsalessalesnew',
        });
      },
      deleteSalesRecord(oRecord: SalesOrder){
        appFxs.showConfirm(language.delete, language.delete_quest_rec)
          .then(vRes => {
            if(vRes.isConfirmed)this.finalDeleteSalesRecord(oRecord);
          });
      },
      finalDeleteSalesRecord(oRecord: SalesOrder){
        appFxs.showAjaxLoader();
        NetServices.requestPost('salesorders/sales/deletesalesoperationrec', {
          salesOperKey: oRecord.OrderKey
        })
          .then(oRes => {
            if(!oRes.bReturn){
              Swal.close();
              appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
              return;
            }


            const fList: SalesOrderInfo[] = [];
            oRes.lstOfSalesOrdersInfo!.forEach(oSalesOrder => {
              const fOrderTempo = { ...oSalesOrder } as SalesOrderInfo;
              const fRealOrder = { ...fOrderTempo.SalesOrderRecord, OperDate: new Date(fOrderTempo.SalesOrderRecord.OperDate) } as SalesOrder;
              fOrderTempo.SalesOrderRecord = fRealOrder;
              fList.push(fOrderTempo);
            });

            this.listOfSalesOrdersInfo = fList;
            this.listOfUsers = oRes.listOfUsers!;
            this.listOfCustomers = oRes.listOfCustomers!;
            this.listOfProducts = oRes.listOfProducts!;
            this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;

            this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
            this.listOfTaxSettings = oRes.listOfTaxSettings!;

            Swal.close();
          });
      },
      confirmSalesOrder(oRecord: SalesOrder){
        appFxs.showConfirm(language.confirm, language.confirm_msg)
          .then(vRes => {

            if(vRes.isConfirmed) this.confirmSalesOrderFinal(oRecord);
          });
      },
      confirmSalesOrderFinal(oRecord: SalesOrder){
        appFxs.showAjaxLoader();
        NetServices.requestPost('salesorders/sales/confirmsalesorder',
        {
          salesOperKey: oRecord.OrderKey
        })
        .then(oRes => {
          if(!oRes.bReturn){
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }

          const fList: SalesOrderInfo[] = [];
            oRes.lstOfSalesOrdersInfo!.forEach(oSalesOrder => {
              const fOrderTempo = { ...oSalesOrder } as SalesOrderInfo;
              const fRealOrder = { ...fOrderTempo.SalesOrderRecord, OperDate: new Date(fOrderTempo.SalesOrderRecord.OperDate) } as SalesOrder;
              fOrderTempo.SalesOrderRecord = fRealOrder;
              fList.push(fOrderTempo);
            });

            this.listOfSalesOrdersInfo = fList;
            this.listOfUsers = oRes.listOfUsers!;
            this.listOfCustomers = oRes.listOfCustomers!;
            this.listOfProducts = oRes.listOfProducts!;
            this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;

            this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
            this.listOfTaxSettings = oRes.listOfTaxSettings!;

            Swal.close();
        });
      },
      getPaytStatus(iStatus: number): string{
        return iStatus == 0 ? language.not_paid:
          iStatus == 1 ? language.part_paid:
          language.fully_paid;
      },
      getDeliveryStatus(iStatus: number): string{
        return iStatus == 0 ? language.not_delivered:
          iStatus == 1 ? language.part_delivery:
          language.full_delivery;
      },
      getOrderStatus(iStatus: number): string{
        return iStatus == 0 ? language.draft:
          iStatus == 1 ? language.confirmed:
          language.completed;
      },
      changeMonth(oMonth: number){
        this.selectedMonth = oMonth;
        this.refreshList();
      },
      onPageChange(evt: any){
            this.itemsPageElts = appFxs.getProxyData(evt);
      },
      refreshList(){
        appFxs.showAjaxLoader();

        NetServices.requestGet(`salesorders/sales/listofsalesordersbymonth/${this.selectedMonth}`)
          .then(oRes => {
            if (!oRes.bReturn) {
              appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                .then(xRes => {
                  Swal.close();
                  appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                });
              return;
            }

            const fList: SalesOrderInfo[] = [];
            oRes.lstOfSalesOrdersInfo!.forEach(oSalesOrder => {
              const fOrderTempo = { ...oSalesOrder } as SalesOrderInfo;
              const fRealOrder = { ...fOrderTempo.SalesOrderRecord, OperDate: new Date(fOrderTempo.SalesOrderRecord.OperDate) } as SalesOrder;
              fOrderTempo.SalesOrderRecord = fRealOrder;
              fList.push(fOrderTempo);
            });

            this.listOfSalesOrdersInfo = fList;
            this.listOfUsers = oRes.listOfUsers!;
            this.listOfCustomers = oRes.listOfCustomers!;
            this.listOfProducts = oRes.listOfProducts!;
            this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;

            this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
            this.listOfTaxSettings = oRes.listOfTaxSettings!;

            Swal.close();
          });
      },
      
      printListItems(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.list_of_sales_orders;
        dPrint.ReportToPrint = 19;

        this.printInitialValues = dPrint;

        this.printExtraData = {
          CurrentYear: this.selectedYear,
          CurrentMonth: this.selectedMonth,
        }

        this.isPrintvisible = true;
      },      
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },
      printSalesQuoteRecord(oRecord: SalesOrder) {
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = `${language.sales_order} - ${oRecord.OrderNbr}`;
        dPrint.ReportToPrint = 20;

        this.printInitialValues = dPrint;

        const oPr = {
          SalesOrderKey: oRecord.OrderKey,
        };
        console.log('oPr', oPr)
        this.printExtraData = oPr;

        this.isPrintvisible = true;
      },
    },
    mounted(){      
      document.title = this.language.list_of_sales_orders + ' - ' + this.language.appName;
      this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
      this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
      this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));
      this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');

      this.listOfMonths = appFxs.getListMonthsByYear(this.selectedYear);

      NetServices.requestGet(`salesorders/sales/listofsalesordersbymonth/${this.selectedMonth}`)
        .then(oRes => {
          if (!oRes.bReturn) {
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
              .then(xRes => {
                router.replace({ path: '/app/myprofile', replace: true });
              });
            return;
          }

          const fList: SalesOrderInfo[] = [];
          oRes.lstOfSalesOrdersInfo!.forEach(oSalesOrder => {
            const fOrderTempo = { ...oSalesOrder } as SalesOrderInfo;
            const fRealOrder = {...fOrderTempo.SalesOrderRecord,OperDate: new Date(fOrderTempo.SalesOrderRecord.OperDate) } as SalesOrder;
            fOrderTempo.SalesOrderRecord = fRealOrder;
            fList.push(fOrderTempo);
          });

          this.listOfUsers = oRes.listOfUsers!;
          this.listOfCustomers = oRes.listOfCustomers!;
          this.listOfProducts = oRes.listOfProducts!;
          this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;
          this.listOfSalesOrdersInfo = fList;

          this.listOfDiscountWithRules = oRes.listOfDiscountWithRules!;
          this.listOfTaxSettings = oRes.listOfTaxSettings!;

          this.isStillLoading = false;
        });
    }
  })
