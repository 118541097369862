
    import { defineComponent } from 'vue';

    

    

    export default defineComponent({
        name: "AnalogClock",
        data(){
            return{
                timeOut: 0,
            }
        },
        methods: {
            setTime(){
                const hr = document.querySelector("#hr") as HTMLDivElement;
                const mn = document.querySelector("#mn") as HTMLDivElement;
                const sc = document.querySelector("#sc") as HTMLDivElement;

                const day = new Date();
                const hh = day.getHours() * 30;
                const mm = day.getMinutes() * 6;
                const ss = day.getSeconds() * 6;

                hr.style.transform = `rotateZ(${hh + (mm / 12)}deg)`;
                mn.style.transform = `rotateZ(${mm}deg)`;
                sc.style.transform = `rotateZ(${ss}deg)`;
            }
        },
        mounted(){
            this.timeOut = setInterval(() => {
                this.setTime();
            }, 1000);
        },
        unmounted(){
            clearTimeout(this.timeOut);
        }
    })
