import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-end"
}
const _hoisted_2 = { "aria-label": "breadcrumb" }
const _hoisted_3 = { class: "breadcrumb" }
const _hoisted_4 = { class: "breadcrumb-item" }
const _hoisted_5 = { class: "breadcrumb-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("nav", _hoisted_2, [
            _createElementVNode("ol", _hoisted_3, [
              _createElementVNode("li", _hoisted_4, [
                _createVNode(_component_RouterLink, { to: "/app/products/brands/list" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.language.list), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("li", _hoisted_5, [
                _createVNode(_component_RouterLink, { to: "/app/products/brands/new" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.language.new), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}