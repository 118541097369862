
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent, Teleport } from "vue";
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { Supplier, TaxSett } from "@/models/managttypes";

    import Swal from "sweetalert2";

    import { ProdBrand, ProdCategory, ProdProduct, ProdSubCategory, ProductVariant, ProductVariantDetail } from '@/models/producttypes';

    import ProdCategoryModal from '@/components/modals/ProdCategoryModal.vue';
    import ProdSubCategoryModal from '@/components/modals/ProdSubCategoryModal.vue';

    import ProdVariantModal from '@/components/modals/ProdVariantModal.vue';
    import ProdVariantValueModal from '@/components/modals/ProdVariantValueModal.vue';
    
    import $ from 'jquery';

    const language = GetLanguage(),
        appFxs = GeneralFx();

    const emptyProd: ProdProduct = {
        ProdProductId: 0,
        SKU: '',
        BarCode: '',
        Name: '',
        ProductType: 0,
        Categories: '',
        SubCategories: '',
        BrandCode: 'BR0001',
        IsKeepStock: true,
        IsTaxable: true,
        AllowPurchaseOutStock: false,
        PurchasePrice: 0,
        SellingPrice: 0,
        ReorderQuantity: 0,
        MinOrderQuantity: 0,
        Tags: '',
        Description: '',
        DefaultSupplier: '',
        DefaultTax: '',
        UnitOfMeasuremt: '',
        PcePerUnit: 1,
        DefaultImage: '',
        ProdImage1: '',
        ProdImage2: '',
        ProdImage3: '',
        ProdImage4: '',
        ProdImage5: '',
        YoutubeVideoLink: '',
    };
    const emptyCategory: ProdCategory = {
        ProdCategoryId: 0,
        Code: '',
        NameEn: '',
        NameFr: '',
        NameRw: '',
        Description: '',
    };
    const emptyVariantCode: ProductVariant = {
        IdVariant: 0,
        ProductSKU: '',
        VariantCode: '',
    }
    const emptyVariantDetail: ProductVariantDetail = {
        IdVariantDetail: 0,
        ProductSku: '',
        VariantValues: '',
        VariantCodes: '',
        VariantCodesObject: '',
        SKU: '',
        BarCode: '',
        PurchasePrice: 0,
        SellingPrice: 0,
    }
    
    const greaterThanZero = (val: number) => val > 0;
    
    export default defineComponent({
        components: {
            ProdCategoryModal,
            ProdSubCategoryModal,
            ProdVariantModal,
            ProdVariantValueModal,
        },
        data(){
            return{
                v$: useVuelidate(),
                language,
                appFxs,
                dataRecord: {...emptyProd} as ProdProduct,
                isSavingRecord: false,
                isStillLoading: true,

                produckSku: '',


                lstSuppliers: [] as Supplier[],
                lstTaxes: [] as TaxSett[],
                lstBrands: [] as ProdBrand[],
                lstCategories: [] as ProdCategory[],
                lstSubCategories: [] as ProdSubCategory[],

                isBarCodeReady: false,

                selectedCategories: [] as ProdCategory[],

                possibleSubCategories: [] as ProdSubCategory[],
                selectedSubCategories: [] as ProdSubCategory[],

                tags: [] as string[],

                isCategoryVisible: false,
                isSubCategoryVisible: false,
                newCategory: {...emptyCategory} as ProdCategory,

                foundProduct: {...emptyProd} as ProdProduct,

                lstProdVariants: [] as ProductVariant[],
                lstProdVariantValues: [] as ProductVariantDetail[],

                isVariantCodeDlgVisible: false,
                variantCodeRecord: {...emptyVariantCode} as ProductVariant,
                variantCodeStatus: 0,


                isVariantDetailDlgVisible: false,
                variantDetailRecord: {...emptyVariantDetail} as ProductVariantDetail,
                variantDetailStatus: 0,

                allImages: {
                    defaultImage: null as File | any,
                    image1: null as File | any,
                    image2: null as File | any,
                    image3: null as File | any,
                    image4: null as File | any,
                    image5: null as File | any,
                },
                selectedImageSett: -1,
                operationImageSett: -1,
            }
        },
        computed: {
            isDefaultTaxReqSet(): boolean{
                return this.dataRecord.IsTaxable ? this.dataRecord.DefaultTax.length>0: true;
            },
            isDefImgReady():boolean{
                return this.isImageReady(0);
            },
            isEComm1Ready():boolean{
                return this.isImageReady(1);
            },
            isEComm2Ready():boolean{
                return this.isImageReady(2);
            },
            isEComm3Ready():boolean{
                return this.isImageReady(3);
            },
            isEComm4Ready():boolean{
                return this.isImageReady(4);
            },
            isEComm5Ready():boolean{
                return this.isImageReady(5);
            },

            prodImgDefSrc(): string{
                return this.prodImageSrc(0);
            },
            prodImg1Src(): string{
                return this.prodImageSrc(1);
            },
            prodImg2Src(): string{
                return this.prodImageSrc(2);
            },
            prodImg3Src(): string{
                return this.prodImageSrc(3);
            },
            prodImg4Src(): string{
                return this.prodImageSrc(4);
            },
            prodImg5Src(): string{
                return this.prodImageSrc(5);
            },
        },
        methods: {
            removeImage(imgSett: number){
                this.selectedImageSett = imgSett;

                appFxs.showConfirm(language.delete, language.delete_prod_image_qst)
                    .then(vRes => {
                        if(vRes.isConfirmed){
                            this.removeImageFinal(imgSett);
                        }
                    });
            },
            removeImageFinal(imgSett: number){
                const oRecord = {...this.foundProduct};
                appFxs.showAjaxLoader();
                NetServices.requestPost('products/products/deleteproductimagefile', {
                    prodProductSku: oRecord.SKU,
                    imageIndex: imgSett,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    this.foundProduct = oRes.oneProductRecord!;
                    this.cleanImageSaved(imgSett);
                    Swal.close();
                });


            },
            saveImage(imgSett: number){
                this.operationImageSett = imgSett;
                const ofile =
                    (imgSett == 0) ? this.allImages.defaultImage :
                        (imgSett == 1) ? this.allImages.image1 :
                            (imgSett == 2) ? this.allImages.image2 :
                                (imgSett == 3) ? this.allImages.image3 :
                                    (imgSett == 4) ? this.allImages.image4 :
                                        (imgSett == 5) ? this.allImages.image5 :
                                            null;
                if (ofile == null) {
                    appFxs.showAlert(language.error,
                        language.file_selected_error)
                    return;
                }

                const oRecord = {...this.foundProduct};

                let fakeVar = { imageIndex: imgSett, prodProductSku: oRecord.SKU },
                    fData = appFxs.getFormData(fakeVar);
                fData.append('file', ofile);

                appFxs.showAjaxLoader();

                NetServices.requestPostWithFile('products/products/saveproductimagefile', fData)
                    .then(oRes => {
                        if(!oRes.bReturn){
                            Swal.close();
                            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                            return;
                        }

                        this.foundProduct = oRes.oneProductRecord!;
                        this.cleanImageSaved(imgSett);
                        Swal.close();
                    });

            },
            cleanImageSaved(imgSett: number){
                if (imgSett == 0) {
                    this.allImages.defaultImage = null;
                }
                if (imgSett == 1) {
                    this.allImages.image1 = null;
                }
                if (imgSett == 2) {
                    this.allImages.image2 = null;
                }
                if (imgSett == 3) {
                    this.allImages.image3 = null;
                }
                if (imgSett == 4) {
                    this.allImages.image4 = null;
                }
                if (imgSett == 5) {
                    this.allImages.image5 = null;
                }
            },
            resetImage(imgSett: number){
                if (imgSett == 0) {
                    this.allImages.defaultImage = null;
                }
                else if (imgSett == 1) {
                    this.allImages.image1 = null;
                }
                else if (imgSett == 2) {
                    this.allImages.image2 = null;
                }
                else if (imgSett == 3) {
                    this.allImages.image3 = null;
                }
                else if (imgSett == 4) {
                    this.allImages.image4 = null;
                }
                else if (imgSett == 5) {
                    this.allImages.image5 = null;
                }

                $('#imageFileSelectors').val('');
            },
            previewProdImage(imgSett: number){
                const oRec = {...this.foundProduct};
                const path0 = oRec.DefaultImage, path1 = oRec.ProdImage1,
                    path2 = oRec.ProdImage2, path3 = oRec.ProdImage3,
                    path4 = oRec.ProdImage4, path5 = oRec.ProdImage5;

                const finalImagePath =
                    (imgSett == 0) ? path0 : (imgSett == 1) ? path1 : (imgSett == 2) ? path2 :
                        (imgSett == 3) ? path3 : (imgSett == 4) ? path4 : (imgSett == 5) ? path5 :
                            null;

                let finalPath = "";

                const tempoPath = require('@/assets/images/products/white_product.jpg');
                if (finalImagePath) {
                    finalPath = `${appDeclarations.apiUrl}cloud_data/product_images/${finalImagePath}`;
                } else {
                    finalPath = tempoPath;
                }
                let wiewmin = window.open(finalPath, 'wiewmin', 'width=600, height=300');
            },
            prodImageSrc(imgSett: number): string{
                const oRec = this.foundProduct;
                const path0 = oRec.DefaultImage,
                    path1 = oRec.ProdImage1,
                    path2 = oRec.ProdImage2,
                    path3 = oRec.ProdImage3,
                    path4 = oRec.ProdImage4,
                    path5 = oRec.ProdImage5;
                const finalImagePath =
                    (imgSett == 0) ? path0 :
                        (imgSett == 1) ? path1 :
                            (imgSett == 2) ? path2 :
                                (imgSett == 3) ? path3 :
                                    (imgSett == 4) ? path4 :
                                        (imgSett == 5) ? path5 :
                                            null;
                const tempoPath = require('@/assets/images/products/white_product.jpg');
                if (finalImagePath) {
                    return `${appDeclarations.apiUrl}cloud_data/product_images/${finalImagePath}`;
                } else {
                    return tempoPath;
                }
            },
            uploadImage(imgSett: number){
                const all = this.allImages,
                    record = this.foundProduct;
                if(imgSett===1){
                    if(!record.DefaultImage){
                        const msgText = appFxs.returnLang(
                            'Kindly select the default image before this one.',
                            'Veuillez sélectionner l\'image par défaut avant celle-ci.',
                            '');
                        appFxs.showAlert(language.select_error, msgText);
                        return;
                    }
                }else{
                    const bNotPrevExist = 
                        (imgSett == 2) ? (!record.ProdImage1) :
                        (imgSett == 3) ? (!record.ProdImage2) :
                            (imgSett == 4) ? (!record.ProdImage3) :
                                (imgSett == 5) ? (!record.ProdImage4) :
                                    false;
                    const msgText = appFxs.returnLang(
                        `Kindly select the image -${(imgSett - 1)}- before this one.`,
                        `Veuillez sélectionner l\'image -${(imgSett - 1)}- avant celle-ci.`,
                        '');
                    if (bNotPrevExist) {
                        appFxs.showAlert(language.select_error, msgText);
                        return;
                    }
                }

                this.selectedImageSett = imgSett;
                $('#imageFileSelectors').click();
            },
            isImageReady(imgSett: number):boolean {
                const oRec = this.foundProduct;
                const path0 = oRec.DefaultImage,
                path1 = oRec.ProdImage1,
                path2 = oRec.ProdImage2,
                path3 = oRec.ProdImage3,
                path4 = oRec.ProdImage4,
                path5 = oRec.ProdImage5;

                const finalImageSelected =
                    (imgSett == 0) ? this.allImages.defaultImage :
                        (imgSett == 1) ? this.allImages.image1 :
                            (imgSett == 2) ? this.allImages.image2 :
                                (imgSett == 3) ? this.allImages.image3 :
                                    (imgSett == 4) ? this.allImages.image4 :
                                        (imgSett == 5) ? this.allImages.image5 :
                                            null;
                const finalImagePath =
                    (imgSett == 0) ? path0 :
                        (imgSett == 1) ? path1 :
                            (imgSett == 2) ? path2 :
                                (imgSett == 3) ? path3 :
                                    (imgSett == 4) ? path4 :
                                        (imgSett == 5) ? path5 :
                                            '';
                const resultV = (finalImageSelected != null) || (finalImagePath.length > 0);
                return resultV;
            },
            onSelectedImageToUpload(evt: Event){
                const fileInput = evt.target as HTMLInputElement;
                const files = fileInput.files!,
                setPos = this.selectedImageSett;


                if (files.length) {
                    if (setPos == 0) {
                        this.allImages.defaultImage = files[0];
                    }
                    if (setPos == 1) {
                        this.allImages.image1 = files[0];
                    }
                    if (setPos == 2) {
                        this.allImages.image2 = files[0];
                    }
                    if (setPos == 3) {
                        this.allImages.image3 = files[0];
                    }
                    if (setPos == 4) {
                        this.allImages.image4 = files[0];
                    }
                    if (setPos == 5) {
                        this.allImages.image5 = files[0];
                    }
                }

                this.updateImager(fileInput);
            },
            updateImager(fileInput: HTMLInputElement){
                let files = fileInput.files,
                    id = `#prodImage${this.selectedImageSett}`,
                    divParent = $(id),
                    img_container_1 = divParent.find('img')[0];

                if (FileReader && files && files.length) {
                    let fr = new FileReader();
                    fr.onload = function () {
                        $(img_container_1).attr('src', fr.result?.toString()!);
                    }
                    fr.readAsDataURL(files[0]);
                }
            },
            onTaxChanged(){
                if(!this.dataRecord.IsTaxable) this.dataRecord.DefaultTax = '';
            },
            getCategoryName(oCateg: ProdCategory) {
                const oLang = appFxs.getLocalLanguage();
                return oLang == 'en' ? oCateg.NameEn :
                    oLang == 'fr' ? oCateg.NameFr :
                        oLang == 'rw' ? oCateg.NameRw :
                            oCateg.NameEn;
            },
            selectCategory(oCateg: ProdCategory){
                const categs = this.selectedCategories;
                const bExistIn = categs.map(o => o.Code).includes(oCateg.Code);
                if(bExistIn)return;
                this.selectedCategories.push(oCateg);
                this.updateCategoriesField();
                this.updatePossibleSubCategs();
            },
            updateCategoriesField(){
                const categs = this.selectedCategories;
                const oCategs = categs.length == 0? '': categs.map(o => o.Code).join(';');
                this.dataRecord.Categories = oCategs;
            },
            removeSelectedCategory(oCateg: ProdCategory){
                const categs = this.selectedCategories;
                const remained = categs.filter(o => o.Code != oCateg.Code);
                this.selectedCategories = remained;
                this.updateCategoriesField();
                this.updatePossibleSubCategs();
            },
            updatePossibleSubCategs(){
                const categs = this.selectedCategories;
                const oCodesPossible = categs.map(o => o.Code);
                const lstfinal = this.lstSubCategories.filter(o => oCodesPossible.includes(o.Code));
                const fFinal = appFxs.getProxyData(lstfinal) as ProdSubCategory[];
                this.possibleSubCategories = fFinal;
                this.checkSubCategFieldAllExists();
            },


            getSubCategoryName(oCateg: ProdSubCategory) {
                const oLang = appFxs.getLocalLanguage();
                return oLang == 'en' ? oCateg.NameEn :
                    oLang == 'fr' ? oCateg.NameFr :
                        oLang == 'rw' ? oCateg.NameRw :
                            oCateg.NameEn;
            },
            selectSubCategory(oCateg: ProdSubCategory){
                const subCategs = this.selectedSubCategories;
                const bExistIn = subCategs.map(o => o.SubCode).includes(oCateg.SubCode);
                if(bExistIn)return;
                this.selectedSubCategories.push(oCateg);
                this.updateSubCategoriesField();
            },
            updateSubCategoriesField(){
                const categs = this.selectedSubCategories;
                const oCategs = categs.length == 0? '': categs.map(o => `${o.Code}-${o.SubCode}`).join(';');
                this.dataRecord.SubCategories = oCategs;
            },
            removeSelectedSubCategory(oCateg: ProdSubCategory){
                const categs = this.selectedSubCategories;
                const remained = categs.filter(oSubCde => {
                    const fCode = `${oSubCde.Code}-${oSubCde.SubCode}`;
                    const oCode = `${oCateg.Code}-${oCateg.SubCode}`;

                    return fCode != oCode;
                });
                this.selectedSubCategories = remained;
                this.updateSubCategoriesField();
            },
            checkSubCategFieldAllExists(){
                const fldVal = this.dataRecord.SubCategories;
                const arrValues = fldVal.length == 0 ? []: fldVal.split(';');
                const finalVals = arrValues as string[];

                
                if(this.possibleSubCategories.length==0){
                    this.dataRecord.SubCategories = '';
                    this.selectedSubCategories = [] as ProdSubCategory[];
                    return;
                }

                let arrApplies = [] as ProdSubCategory[];

                this.possibleSubCategories.forEach(oPoss => {
                    const fPoss = `${oPoss.Code}-${oPoss.SubCode}`;
                    if(finalVals.includes(fPoss)){
                        arrApplies.push(oPoss);
                    }
                });

                this.selectedSubCategories = arrApplies;
                if(this.selectedSubCategories.length==0){
                    this.dataRecord.SubCategories = '';
                    return;
                }

                const fListToJoin = this.selectedSubCategories.map(o => `${o.Code}-${o.SubCode}`).join(';');
                this.dataRecord.SubCategories = fListToJoin;
            },



            selectOnFocus(evt: Event) {
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            generateBarCode(code: any){
                const binary = (code == null) ? appFxs.getRandomDigits(10) : code as string;
                this.dataRecord.BarCode = binary;
                this.isBarCodeReady = true;
            },
            onKeyUpRawBarcode(evt: any){
                const val = (evt.target as HTMLInputElement).value;
                const length = val ? val.length : 0;
                if(length==10){
                    this.generateBarCode(null);
                }else{
                    this.isBarCodeReady = false;
                }
            },
            updateTags(evt: any){
                const fFinal = evt as string[];
                const ccF = fFinal.length > 0 ? fFinal.join(';') : '';
                this.dataRecord.Tags = ccF;
            },
            saveNewProduct() {
                this.isSavingRecord = true;
                const fRecord = { ...appFxs.getProxyData(this.dataRecord) };

                NetServices.requestPost('products/products/saveproduct', {
                    prodProductRecord: fRecord,
                    toDelete: false,
                    populateData: false,
                }).then(oRes => {
                    if (!oRes.bReturn) {
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        this.isSavingRecord = false;
                        return;
                    }

                    const oPath = `/app/products/products/list`;
                    router.replace({ path: oPath, replace: true });
                });
            },

            addCategoryElement(){
                this.isCategoryVisible = true;
            },
            onCloseCategory(evt: any){
                const retType = evt.returnType;
                this.isCategoryVisible = false;
                if(retType==0) return;
                const bRet = evt.returnData.bReturn,
                    lst = evt.returnData.listOfCategories;
                if(!bRet) return;
                this.lstCategories = lst;
            },

            addSubCategoryElement(){
                this.isSubCategoryVisible = true;
            },
            onCloseSubCategory(evt: any){
                const retType = evt.returnType;
                    
                this.isSubCategoryVisible = false;
                if(retType==0) return;
                const bRet = evt.returnData.bReturn,
                    lst = evt.returnData.listOfSubCategories;
                if(!bRet) return;
                this.lstSubCategories = lst;
            },

            addEditVariantCode(iStatus: number, oRecord: ProductVariant | any){
                if(iStatus==0){
                    const oRec = {...emptyVariantCode, ProductSKU: this.produckSku } as ProductVariant;
                    this.variantCodeRecord = oRec;
                    this.variantCodeStatus = 0,
                    this.isVariantCodeDlgVisible = true;
                }else{
                    const oRec = {...oRecord } as ProductVariant;
                    this.variantCodeRecord = oRec;
                    this.variantCodeStatus = 1,
                    this.isVariantCodeDlgVisible = true;
                }
            },
            deleteVariantCode(oRecord: ProductVariant){
                appFxs.showConfirm(language.delete, language.delete_variant_code_req_msg)
                    .then(vRes => {
                        if(!vRes.isConfirmed)return;

                        this.deleteVariantCodeFinal(oRecord);
                    });
            },
            deleteVariantCodeFinal(oRecord: ProductVariant){
                appFxs.showAjaxLoader();

                NetServices.requestPost(`products/variants/savevariantoperrecord`,
                {
                    variantDataRecord: oRecord,
                    toDelete: true,
                    populateData: true,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    this.lstProdVariants = oRes.lstOfProdVariants!;
                    Swal.close();
                });
            },
            onCloseProdeCodeDlg(evt: any){
                const retType = evt.returnType;
                    
                this.isVariantCodeDlgVisible = false;
                if(retType==0) return;
                const bRet = evt.returnData.bReturn,
                    lst = evt.returnData.listOfItems;
                if(!bRet) return;
                this.lstProdVariants = lst;
            },

            addEditVariantVal(iStatus: number, oRecord: ProductVariantDetail | any){
                if(iStatus==0){
                    const oRec = {
                        ...emptyVariantDetail,
                        ProductSku: this.produckSku
                    } as ProductVariantDetail;
                    this.variantDetailRecord = oRec;
                    this.variantDetailStatus = 0;
                    this.isVariantDetailDlgVisible = true;
                }else{
                    const oRec = {...oRecord } as ProductVariantDetail;
                    this.variantDetailRecord = oRec;
                    this.variantDetailStatus = 1;
                    this.isVariantDetailDlgVisible = true;
                }
            },
            deleteVariantDetail(oRecord: ProductVariantDetail){
                appFxs.showConfirm(language.delete, language.delete_variant_detail_req_msg)
                    .then(vRes => {
                        if(!vRes.isConfirmed)return;

                        this.deleteVariantDetailFinal(oRecord);
                    });
            },
            deleteVariantDetailFinal(oRecord: ProductVariantDetail){
                appFxs.showAjaxLoader();

                NetServices.requestPost(`products/variantdets/savevariantvaluerecord`,
                {
                    variantDetailRecord: oRecord,
                    toDelete: true,
                    populateData: true,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    this.lstProdVariantValues = oRes.lstOfProdVariantValues!;
                    Swal.close();
                });
            },
            onCloseProdeDetailDlg(evt: any){
                const retType = evt.returnType;
                    
                this.isVariantDetailDlgVisible = false;
                if(retType==0) return;
                const bRet = evt.returnData.bReturn,
                    lst = evt.returnData.listOfItems;
                if(!bRet) return;
                this.lstProdVariantValues = lst;
            }
        },
        mounted(){
            document.title = language.loading + ' - ' + this.language.appName;
            appFxs.updateLoadedScripts();

            const prodSku = this.$route.params.productKey;
            if(!prodSku){
                appFxs.showAlert(language.missing_settings, language.missing_settings_msg)
                    .then(vRes => {
                        const oPath = `/app/products/products/list`;
                        router.replace({ path: oPath, replace: true });
                    });
                return;
            }
            const finalSku = prodSku as string;
            this.produckSku = finalSku;

            NetServices.requestGet(`products/products/getoneproduct/${finalSku}`)
                .then(oRes => {
                    if (!oRes.bReturn) {
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                            .then(vRes => {
                                const oPath = `/app/products/products/list`;
                                router.replace({ path: oPath, replace: true });
                            });                        
                        return;
                    }

                    this.lstCategories = oRes.listOfProdCategories!;
                    this.lstSubCategories = oRes.listOfProdSubCategories!;
                    this.lstSuppliers = oRes.listOfSuppliers!;
                    this.lstTaxes = oRes.listOfTaxSettings!;
                    this.lstBrands = oRes.listOfProdBrands!;

                    
                    this.lstProdVariants = oRes.lstOfProdVariants!;
                    this.lstProdVariantValues = oRes.lstOfProdVariantValues!;

                    const tempoProd = appFxs.getProxyData(oRes.oneProductRecord!) as ProdProduct;

                    this.foundProduct = tempoProd;


                    //tags
                    const oTags: string[] = tempoProd.Tags.length > 0 ? tempoProd.Tags.split(';'): [];
                    this.tags = oTags;
                    //tags


                    //categories
                    const tempoCategs = tempoProd.Categories.length == 0 ? [] : tempoProd.Categories.split(';');
                    this.selectedCategories = this.lstCategories.filter(o => tempoCategs.includes(o.Code));
                    //categories

                    //subcategories
                    const mappedCategCodes = this.selectedCategories.map(o => o.Code);
                    this.possibleSubCategories = this.lstSubCategories.filter(o => mappedCategCodes.includes(o.Code));                    
                    const arrSubSelectedKeys = tempoProd.SubCategories.split(';');
                    this.selectedSubCategories = this.possibleSubCategories.filter(o => arrSubSelectedKeys.includes(`${o.Code}-${o.SubCode}`));
                    //subcategories



                    this.dataRecord = {...this.foundProduct}

                    document.title = this.foundProduct.Name + ' - ' + this.language.appName;

                    this.isStillLoading = false;
                });
        },
        validations(){
            return {
                dataRecord: {
                    SKU: {
                        required: helpers.withMessage(language.fldRequired, required),
                        minLength: helpers.withMessage(language.sku_min_err_msg, minLength(12)),
                        $autoDirty: true,
                    },
                    BarCode: {
                        required: helpers.withMessage(language.fldRequired, required),
                        minLength: helpers.withMessage(language.barcode_min_err_msg, minLength(10)),
                        $autoDirty: true,
                    },
                    Name: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    ProductType: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    Categories: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    SubCategories: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    BrandCode: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    PurchasePrice: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    SellingPrice: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    ReorderQuantity: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    MinOrderQuantity: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    UnitOfMeasuremt:{
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    PcePerUnit:{
                        required: helpers.withMessage(language.fldRequired, required),
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),
                        $autoDirty: true,
                    }
                }
            }
        }
    })
