
  import { useVuelidate } from '@vuelidate/core'
  import { required, email, helpers, minLength } from '@vuelidate/validators'
  import { defineComponent } from 'vue';

  import AccountHeader from '@/components/general/ByihuseAccountHeader.vue';
  import AlertError from '@/components/general/AlertError.vue';


  import GetLanguage from '@/tools/language';  
  import AppFxs from '@/tools/general_fx';

  const language = GetLanguage();
  const appFxs = AppFxs();

  const passValidator = (value: string) => {
    return appFxs.passwordStrengthCheck(value) > 70;
  }

  export default defineComponent({
    emits: ["onFormSubmit", "onCleanError"],
    props: {
      isNetLoading: {
        type: Boolean,
        default: false,
        required: true
      },
      errorMessage: {
        type: String,
        default: ''
      },
      isErrorVisible: {
        type: Boolean,
        default: false,
      }
    },
    components: {
      AccountHeader,
      AlertError
    },
    data(){
      return {
        v$: useVuelidate(),
        language,
        userEmail: '',
        userPassword: '',
      }
    },
    computed: {
      isFormError():boolean{
        return this.isNetLoading || 
          !this.userEmail || 
          !this.userPassword;
      },
      showLoading(): boolean{
        return this.isNetLoading;
      },
      showAlertError():boolean{
        return this.isErrorVisible;
      }
    },
    methods: {
      submitThisForm(evt: Event){
        this.$emit('onFormSubmit', {
          userEmail: this.userEmail,
          userPassword: this.userPassword,
        });
      },
      closeAlertError(){
        this.$emit('onCleanError')
      }
    },
    validations() {
      return {
        userEmail: {
          required: helpers.withMessage(language.fldRequired, required), 
          email: helpers.withMessage(language.emailFormat, email), 
          $autoDirty: true
        },
        userPassword: {
          required: helpers.withMessage(language.fldRequired, required),
          passValid: helpers.withMessage(language.passValidMsg, passValidator),
          minLength: helpers.withMessage(language.passLengthMsg, minLength(8)),
          $autoDirty: true
        },
      }
    }
  })
