
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { ProdProduct, ProductVariantDetail, PurchaseOperationDetail } from '@/models/producttypes';
    import NetServices from '@/tools/netservices';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    const emptyPODetail: PurchaseOperationDetail = {
        IdPurchaseDetail: 0,
        PurchaseKey: '',
        PurchaseDetKey: '',
        ProductSku: '',
        ProductType: 0,
        ProductBarcode: '',
        VariantSku: '',
        VariantBarcode: '',
        Qty: 0,
        UnitPrice: 0,
        SousTotal: 0,
        Tax: 0,
        TotalPrice: 0,
        StockTransferStatus: 0,
    }
    const greaterThanZero = (val: number) => val > 0;

    export default defineComponent({      
        name: 'PurchDetailModal',
        emits: ['closeForm'],
        props: [
            "visible",
            "initialValues", 
            "recordStatus", 
            "purchaseRecord", 
            "listProducts", 
            "listVariants"
        ],
        data(){
            return {
                v$: useVuelidate(),
                appFxs,
                language,
                dataRecord: {...emptyPODetail} as PurchaseOperationDetail,
                isSavingRecord: false,

                products: this.listProducts as ProdProduct[],
                variantDets: this.listVariants as ProductVariantDetail[],
                specificVariants: [] as ProductVariantDetail[],
            }
        },
        computed: {
            windowTitle(){
                return this.recordStatus == 0 ? language.add_purchase_detail:
                language.edit_purchase_detail;
            },
            isVariantSet(): boolean{
                return this.dataRecord.ProductType == 0 ? true:
                (this.dataRecord.VariantBarcode.length > 0 && this.dataRecord.VariantSku.length >0);
            },
        },
        methods: {
            onProductChange(){
                const prodSku = this.dataRecord.ProductSku;

                const finalAllProds = appFxs.getProxyData(this.listProducts) as ProdProduct[];
                const finalAllVar = appFxs.getProxyData(this.listVariants) as ProductVariantDetail[];

                if(!prodSku){
                    this.dataRecord.ProductType = 0;
                    this.dataRecord.ProductBarcode = '';
                    this.dataRecord.VariantSku = '';
                    this.dataRecord.VariantBarcode = '';

                    this.dataRecord.UnitPrice = 0;
                    return;
                }
                const realProdchecker = finalAllProds.find(o => o.SKU == prodSku);

                if(realProdchecker == null){
                    this.dataRecord.ProductType = 0;
                    this.dataRecord.ProductBarcode = '';
                    this.dataRecord.VariantSku = '';
                    this.dataRecord.VariantBarcode = '';

                    this.dataRecord.UnitPrice = 0;
                    return;
                }

                this.dataRecord.ProductBarcode = realProdchecker.BarCode;
                const specVariants = finalAllVar.filter(o => o.ProductSku == prodSku);                
                const hasVariants = specVariants.length > 0;

                this.dataRecord.VariantSku = '';
                this.dataRecord.VariantBarcode = '';
                this.specificVariants = specVariants;
                
                this.dataRecord.ProductType = hasVariants ? 1 : 0;
                if(!hasVariants){
                    this.dataRecord.UnitPrice = realProdchecker.PurchasePrice;
                }else{
                    this.dataRecord.UnitPrice = 0;
                }
            },
            onVariantChange(){
                const varSku = this.dataRecord.VariantSku;
                if(!varSku){
                    this.dataRecord.VariantBarcode = '';
                    this.dataRecord.UnitPrice = 0;
                    return;
                }
                const foundVars = this.specificVariants.find(o => o.VariantValues == varSku);
                if(!foundVars){
                    this.dataRecord.VariantBarcode = '';
                    this.dataRecord.UnitPrice = 0;
                    return;
                }

                this.dataRecord.VariantBarcode = foundVars!.BarCode;
                this.dataRecord.UnitPrice = foundVars!.PurchasePrice;
            },
            changeSousTotalRecs(){
                const oRec = this.dataRecord.Qty * this.dataRecord.UnitPrice;
                this.dataRecord.SousTotal = oRec;
                const finalTotal = oRec + Number(this.dataRecord.Tax);
                this.dataRecord.TotalPrice = finalTotal;
            },
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.isSavingRecord = true;
                NetServices.requestPost('stockops/purchases/savepurchasedetailops', {
                    purchaseOpsDetailRecord: this.dataRecord,
                    toDelete: false,
                }).then(oRes => {
                    if (!oRes.bReturn) {
                        this.isSavingRecord = false;
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    this.isSavingRecord = false;
                    this.v$.$reset();

                    this.$emit('closeForm', {returnType: 1, returnData: {
                        bReturn: true,
                        listItems: oRes.lstOfPurchaseOrderDetails!
                    }});
                });
            },
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: PurchaseOperationDetail = {...oValues} as PurchaseOperationDetail;

                        this.products = this.listProducts as ProdProduct[];
                        this.variantDets = this.listVariants as ProductVariantDetail[];

                        if(this.recordStatus == 0){
                            this.v$.$reset();
                        }else{
                            const isVariant = fValues.ProductType == 1;
                            const specific = isVariant ? this.variantDets.filter(o => o.ProductSku == fValues.ProductSku): [];

                            this.specificVariants = specific;
                        }

                        this.dataRecord = fValues;
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {
                    PurchaseKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    ProductSku: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    ProductBarcode: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    Qty: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThan: helpers.withMessage(language.fldRequired, greaterThanZero),                 
                        $autoDirty: true,
                    },                    
                    UnitPrice: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThan: helpers.withMessage(language.fldRequired, greaterThanZero),                 
                        $autoDirty: true,
                    },                   
                    SousTotal: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThan: helpers.withMessage(language.fldRequired, greaterThanZero),                 
                        $autoDirty: true,
                    },                  
                    Tax: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },                
                    TotalPrice: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThan: helpers.withMessage(language.fldRequired, greaterThanZero),                 
                        $autoDirty: true,
                    }, 
                }
            }
        },
        mounted(){
            appFxs.updateLoadedScripts();
        }

    })
