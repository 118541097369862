
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent, Teleport } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { Supplier, TaxSett } from "@/models/managttypes";

    import Swal from "sweetalert2";

    import { TelCode } from '@/tools/smarttypes';
    import { ProdBrand, ProdCategory, ProdProduct, ProdSubCategory } from '@/models/producttypes';

    import ProdCategoryModal from '@/components/modals/ProdCategoryModal.vue';
    import ProdSubCategoryModal from '@/components/modals/ProdSubCategoryModal.vue';

    const language =GetLanguage(),
        appFxs = GeneralFx();

    const emptyProd: ProdProduct = {
        ProdProductId: 0,
        SKU: '',
        BarCode: '',
        Name: '',
        ProductType: 0,
        Categories: '',
        SubCategories: '',
        BrandCode: 'BR0001',
        IsKeepStock: true,
        IsTaxable: true,
        AllowPurchaseOutStock: false,
        PurchasePrice: 0,
        SellingPrice: 0,
        ReorderQuantity: 0,
        MinOrderQuantity: 0,
        Tags: '',
        Description: '',
        DefaultSupplier: '',
        DefaultTax: '',
        UnitOfMeasuremt: '',
        DefaultImage: '',
        ProdImage1: '',
        ProdImage2: '',
        ProdImage3: '',
        ProdImage4: '',
        ProdImage5: '',
        YoutubeVideoLink: '',
        PcePerUnit: 1,
    };
    const emptyCategory: ProdCategory = {
        ProdCategoryId: 0,
        Code: '',
        NameEn: '',
        NameFr: '',
        NameRw: '',
        Description: '',
    };
    const greaterThanZero = (val: number) => val > 0;

    export default defineComponent({
        components: {
            ProdCategoryModal,
            ProdSubCategoryModal,
        },
        data(){
            return{
                v$: useVuelidate(),
                language,
                appFxs,
                dataRecord: {...emptyProd} as ProdProduct,
                isSavingRecord: false,
                isStillLoading: true,
                lstSuppliers: [] as Supplier[],
                lstTaxes: [] as TaxSett[],
                lstBrands: [] as ProdBrand[],
                lstCategories: [] as ProdCategory[],
                lstSubCategories: [] as ProdSubCategory[],

                isBarCodeReady: false,

                selectedCategories: [] as ProdCategory[],

                possibleSubCategories: [] as ProdSubCategory[],
                selectedSubCategories: [] as ProdSubCategory[],

                tags: [] as string[],

                isCategoryVisible: false,
                isSubCategoryVisible: false,
                newCategory: {...emptyCategory} as ProdCategory,
            }
        },
        computed: {
            isDefaultTaxReqSet(): boolean{
                return this.dataRecord.IsTaxable ? this.dataRecord.DefaultTax.length>0: true;
            }
        },
        methods: {
            onTaxChanged(){
                if(!this.dataRecord.IsTaxable) this.dataRecord.DefaultTax = '';
            },
            getCategoryName(oCateg: ProdCategory) {
                const oLang = appFxs.getLocalLanguage();
                return oLang == 'en' ? oCateg.NameEn :
                    oLang == 'fr' ? oCateg.NameFr :
                        oLang == 'rw' ? oCateg.NameRw :
                            oCateg.NameEn;
            },
            selectCategory(oCateg: ProdCategory){
                const categs = this.selectedCategories;
                const bExistIn = categs.map(o => o.Code).includes(oCateg.Code);
                if(bExistIn)return;
                this.selectedCategories.push(oCateg);
                this.updateCategoriesField();
                this.updatePossibleSubCategs();
            },
            updateCategoriesField(){
                const categs = this.selectedCategories;
                const oCategs = categs.length == 0? '': categs.map(o => o.Code).join(';');
                this.dataRecord.Categories = oCategs;
            },
            removeSelectedCategory(oCateg: ProdCategory){
                const categs = this.selectedCategories;
                const remained = categs.filter(o => o.Code != oCateg.Code);
                this.selectedCategories = remained;
                this.updateCategoriesField();
                this.updatePossibleSubCategs();
            },
            updatePossibleSubCategs(){
                const categs = this.selectedCategories;
                const oCodesPossible = categs.map(o => o.Code);
                const lstfinal = this.lstSubCategories.filter(o => oCodesPossible.includes(o.Code));
                const fFinal = appFxs.getProxyData(lstfinal) as ProdSubCategory[];
                this.possibleSubCategories = fFinal;
                this.checkSubCategFieldAllExists();
            },


            getSubCategoryName(oCateg: ProdSubCategory) {
                const oLang = appFxs.getLocalLanguage();
                return oLang == 'en' ? oCateg.NameEn :
                    oLang == 'fr' ? oCateg.NameFr :
                        oLang == 'rw' ? oCateg.NameRw :
                            oCateg.NameEn;
            },
            selectSubCategory(oCateg: ProdSubCategory){
                const subCategs = this.selectedSubCategories;
                const bExistIn = subCategs.map(o => o.SubCode).includes(oCateg.SubCode);
                if(bExistIn)return;
                this.selectedSubCategories.push(oCateg);
                this.updateSubCategoriesField();
            },
            updateSubCategoriesField(){
                const categs = this.selectedSubCategories;
                const oCategs = categs.length == 0? '': categs.map(o => `${o.Code}-${o.SubCode}`).join(';');
                this.dataRecord.SubCategories = oCategs;
            },
            removeSelectedSubCategory(oCateg: ProdSubCategory){
                const categs = this.selectedSubCategories;
                const remained = categs.filter(oSubCde => {
                    const fCode = `${oSubCde.Code}-${oSubCde.SubCode}`;
                    const oCode = `${oCateg.Code}-${oCateg.SubCode}`;

                    return fCode != oCode;
                });
                this.selectedSubCategories = remained;
                this.updateSubCategoriesField();
            },
            checkSubCategFieldAllExists(){
                const fldVal = this.dataRecord.SubCategories;
                const arrValues = fldVal.length == 0 ? []: fldVal.split(';');
                const finalVals = arrValues as string[];

                
                if(this.possibleSubCategories.length==0){
                    this.dataRecord.SubCategories = '';
                    this.selectedSubCategories = [] as ProdSubCategory[];
                    return;
                }

                let arrApplies = [] as ProdSubCategory[];

                this.possibleSubCategories.forEach(oPoss => {
                    const fPoss = `${oPoss.Code}-${oPoss.SubCode}`;
                    if(finalVals.includes(fPoss)){
                        arrApplies.push(oPoss);
                    }
                });

                this.selectedSubCategories = arrApplies;
                if(this.selectedSubCategories.length==0){
                    this.dataRecord.SubCategories = '';
                    return;
                }

                const fListToJoin = this.selectedSubCategories.map(o => `${o.Code}-${o.SubCode}`).join(';');
                this.dataRecord.SubCategories = fListToJoin;
            },



            selectOnFocus(evt: Event) {
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            generateBarCode(code: any){
                const binary = (code == null) ? appFxs.getRandomDigits(10) : code as string;
                this.dataRecord.BarCode = binary;
                this.isBarCodeReady = true;
            },
            onKeyUpRawBarcode(evt: any){
                const val = (evt.target as HTMLInputElement).value;
                const length = val ? val.length : 0;
                if(length==10){
                    this.generateBarCode(null);
                }else{
                    this.isBarCodeReady = false;
                }
            },
            updateTags(evt: any){
                const fFinal = evt as string[];
                const ccF = fFinal.length > 0 ? fFinal.join(';') : '';
                this.dataRecord.Tags = ccF;
            },
            saveNewProduct() {
                this.isSavingRecord = true;
                const fRecord = { ...appFxs.getProxyData(this.dataRecord) };

                NetServices.requestPost('products/products/saveproduct', {
                    prodProductRecord: fRecord,
                    toDelete: false,
                    populateData: false,
                }).then(oRes => {
                    if (!oRes.bReturn) {
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        this.isSavingRecord = false;
                        return;
                    }

                    /* const fList: ProdProduct[] = [];
                    oRes.listOfProducts!.forEach(oEmail => {
                        const fEmail = { ...oEmail }
                        fList.push(fEmail);
                    }); */

                    const oPath = `/app/products/products/list`;
                    router.replace({ path: oPath, replace: true });
                });
            },

            addCategoryElement(){
                this.isCategoryVisible = true;
            },
            onCloseCategory(evt: any){
                const retType = evt.returnType;
                this.isCategoryVisible = false;
                if(retType==0) return;
                const bRet = evt.returnData.bReturn,
                    lst = evt.returnData.listOfCategories;
                if(!bRet) return;
                this.lstCategories = lst;
            },

            addSubCategoryElement(){
                this.isSubCategoryVisible = true;
            },
            onCloseSubCategory(evt: any){
                const retType = evt.returnType;
                    
                this.isSubCategoryVisible = false;
                if(retType==0) return;
                const bRet = evt.returnData.bReturn,
                    lst = evt.returnData.listOfSubCategories;
                if(!bRet) return;
                this.lstSubCategories = lst;
            }
        },
        mounted(){
            document.title = language.new_product + ' - ' + this.language.appName;
            appFxs.updateLoadedScripts();

            NetServices.requestGet('products/products/datacreateproduct')
                .then(oRes => {
                    if (!oRes.bReturn) {
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                            .then(vRes => {
                                const oPath = `/app/products/products/list`;
                                router.replace({ path: oPath, replace: true });
                            });                        
                        return;
                    }

                    this.lstCategories = oRes.listOfProdCategories!;
                    this.lstSubCategories = oRes.listOfProdSubCategories!;
                    this.lstSuppliers = oRes.listOfSuppliers!;
                    this.lstTaxes = oRes.listOfTaxSettings!;
                    this.lstBrands = oRes.listOfProdBrands!;

                    this.isStillLoading = false;
                });
        },
        validations(){
            return {
                dataRecord: {
                    SKU: {
                        required: helpers.withMessage(language.fldRequired, required),
                        minLength: helpers.withMessage(language.sku_min_err_msg, minLength(12)),
                        $autoDirty: true,
                    },
                    BarCode: {
                        required: helpers.withMessage(language.fldRequired, required),
                        minLength: helpers.withMessage(language.barcode_min_err_msg, minLength(10)),
                        $autoDirty: true,
                    },
                    Name: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    ProductType: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    Categories: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    SubCategories: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    BrandCode: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    PurchasePrice: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    SellingPrice: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    ReorderQuantity: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    MinOrderQuantity: {
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },
                    UnitOfMeasuremt:{
                        required: helpers.withMessage(language.fldRequired, required),
                        $autoDirty: true,
                    },                    
                    PcePerUnit:{
                        required: helpers.withMessage(language.fldRequired, required),
                        greaterThan: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),
                        $autoDirty: true,
                    }
                }
            }
        }
    })
