
    import { defineComponent } from "vue";
    import NetServices from '@/tools/netservices';
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';

    const language =GetLanguage(),
        appFxs = GeneralFx();

    export default defineComponent({
        name: 'ProdTaxIncluded',
        props: ["productSku", "currCode"],
        data(){
            return {
                language,
                appFxs,
                isStillLoading: true,
                totalTaxIncluded: 0,
            }
        },
        mounted(){
            NetServices.requestGet(`products/products/getprodpricewithtax/${this.productSku}`)
                .then(oRes => {
                    if(!oRes.bReturn){
                        this.totalTaxIncluded = 0;
                        this.isStillLoading = false;
                        return;
                    }
                    
                    this.isStillLoading = false;
                    this.totalTaxIncluded = oRes.productTaxIncluded!;
                });
        }
    })
