
    import { useVuelidate } from '@vuelidate/core'
    import { required, helpers } from '@vuelidate/validators'

    import { defineComponent } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import NetServices from '@/tools/netservices';
    import { DiscountRule } from '@/models/salestypes';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    const newDate = new Date(),
        year = newDate.getFullYear();
    const greaterThanZero = (val: number) => val > 0;
    const emptyDiscountRule: DiscountRule = {
        IdDiscRule: 0,
        DiscountCode: '',
        RuleKey: '',
        MinQuant: 0,
        MaxQuant: 0,
        DiscValue: 0,
    };


    export default defineComponent({      
        name: 'DiscRuleModal',
        emits: ['closeForm'],
        props: ["visible", "initialValues", "recordStatus"],
        data(){
            return {
                v$: useVuelidate(),
                appFxs,
                language,
                dataRecord: {...emptyDiscountRule} as DiscountRule,
                isSavingRecord: false,
            }
        },
        computed: {
            windowTitle(){
                return this.recordStatus == 0 ? language.new_rule:
                language.edit_rule;
            },
            isValidMaximum():boolean{
                return this.dataRecord.MaxQuant >= this.dataRecord.MinQuant;
            }
        },
        methods: {
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.isSavingRecord = true;
                const opsData = {...this.dataRecord} as DiscountRule;
                NetServices.requestPost('salesorders/discountrecs/rules/savediscountrule', {
                    discountRuleModel: opsData,
                    toDelete: false,
                }).then(oRes => {
                    if (!oRes.bReturn) {
                        this.isSavingRecord = false;
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }
                    this.isSavingRecord = false;
                    this.$emit('closeForm', {returnType: 1, returnData: oRes.listOfDiscountWithRules!});
                });
            },
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: DiscountRule = {
                            ...oValues,
                        };

                        if(this.recordStatus == 0){
                            this.v$.$reset();
                        }

                        this.dataRecord = fValues;                        
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {
                    DiscountCode: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    MinQuant: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),                 
                        $autoDirty: true,
                    },
                    MaxQuant: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),                 
                        $autoDirty: true,
                    },
                    DiscValue: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),                 
                        $autoDirty: true,
                    },
                }
            }
        },
        mounted(){
            appFxs.updateLoadedScripts();
        }
    })
