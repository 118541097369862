
    import { defineComponent } from 'vue';
    import { PadLeftStType } from '@/tools/smarttypes';
    import GetLanguage from '@/tools/language';

    const language = GetLanguage();
    export default defineComponent({
        name: 'JWTagInput',
        props: {
            modelValue: { type: Array, default: ()=> [] },
            showCount: { type: Boolean, default: false },
            options: { type: [Array, Boolean], default: false },
            name: { type: String, default: "" },
            allowCustom: { type: Boolean, default: true },
            placeholder:{
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },
        emits: ['afterUpdate'],
        data(){
            return {
                language,
                tags: this.modelValue as string[],
                newTag: '',
                paddingLeft: 10,
                id: Math.random().toString(36).substring(7),
                duplicate: null as any,
                fPlaceHolder: !this.placeholder ? language.new_tag : this.placeholder
            }
        },
        computed: {
            availableOptions(){
                if (!this.options) return false;
                const options = this.options as any[];
                return options.filter((option) => !this.tags.includes(option));
            },
            inputStyle(): PadLeftStType{
                const pLeft = this.paddingLeft;
                return {'padding-left': `${pLeft}px`}
            }
        },
        methods: {
            handleDuplicate(tag: string){
                this.duplicate = tag;
                setTimeout(() => (this.duplicate = null), 1000);
                this.newTag = "";
            },
            addTag(tag: string){
                if (!tag) return;
                const oOptions = this.options as string[];
                if (!this.allowCustom && !oOptions.includes(tag)) return;
                if (this.tags.includes(tag)) {
                    this.handleDuplicate(tag);
                    return;
                }
                this.tags.push(tag);
                this.newTag = "";
            },
            removeTag(index: number){
                this.tags.splice(index, 1);
            },
            onTagsChange(){
                const extraCushion = 15;
                const ulElt = (this.$refs.tagsUl as HTMLUListElement);
                const wLeft = ulElt.clientWidth + extraCushion;
                this.paddingLeft = ulElt.clientWidth + extraCushion;                
                ulElt.scrollTo(ulElt.scrollWidth, 0);                
                ulElt.scrollTo(ulElt.scrollWidth, 0);
                this.$emit("afterUpdate", this.tags);
            }
        },
        mounted(){
            this.onTagsChange();
        },
        watch: {
            tags: {
                handler(newVal, oldV){
                    this.onTagsChange();
                },
                deep: true
            },
            modelValue: {
                handler(newVal, oldV){
                    this.onTagsChange();
                },
                deep: true,
            }
        }
});
