
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { ProdProduct, ProdStockEntry, ProductVariantDetail, PurchaseOperationDetail } from '@/models/producttypes';
    import NetServices from '@/tools/netservices';
    import { OrderDetailInfo } from '@/models/notdb';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    const greaterThanZero = (val: number) => val > 0;

    const emptyProd: ProdProduct = {
        ProdProductId: 0,
        SKU: '',
        BarCode: '',
        Name: '',
        ProductType: 0,
        Categories: '',
        SubCategories: '',
        BrandCode: 'BR0001',
        IsKeepStock: true,
        IsTaxable: true,
        AllowPurchaseOutStock: false,
        PurchasePrice: 0,
        SellingPrice: 0,
        ReorderQuantity: 0,
        MinOrderQuantity: 0,
        Tags: '',
        Description: '',
        DefaultSupplier: '',
        DefaultTax: '',
        UnitOfMeasuremt: '',
        DefaultImage: '',
        ProdImage1: '',
        ProdImage2: '',
        ProdImage3: '',
        ProdImage4: '',
        ProdImage5: '',
        YoutubeVideoLink: '',
        PcePerUnit: 1,
    };
    const emptyVariantDetail: ProductVariantDetail = {
        IdVariantDetail: 0,
        ProductSku: '',
        VariantValues: '',
        VariantCodes: '',
        VariantCodesObject: '',
        SKU: '',
        BarCode: '',
        PurchasePrice: 0,
        SellingPrice: 0,
    }
    const emptyStockEntry:ProdStockEntry = {
        IdStockEntry: 0,
        EntryKey: '',
        ProductSku: '',
        ProductVariantKey: '',
        EntryType: 0,
        PurchaseOrderKey: '',
        PurchaseOrderDetKey: '',
        UserEmail: '',
        SupplierKey: '',
        UnitType: '',
        PcesPerUnit: 0,
        UnitQty: 0,
        Quantity: 0,
        BuyingPrice: 0,
        CostPrice: 0,
        SellingPrice: 0,
        EntryDate: new Date(),
        Description: '',
        FiscalYear: 0,
    }
    const emptyPOInfo: OrderDetailInfo = {
        OderDetKey: '',
        OderKey: '',
        OrderQty: 0,
        RemainQty: 0,
        TasnsferQty: 0,
    }

    export default defineComponent({
        name: 'StockEntryModal',
        emits: ['closeForm'],
        props: [
            "visible",
            "initialValues", 
            "recordStatus",
            "purchaseDetailRecord",
            "purchDetailInfo",
            "productRecord",
            "variantValRecord",
            "productType",
        ],
        data(){
            return {
                v$: useVuelidate(),
                appFxs,
                language,
                dataRecord: {...emptyStockEntry} as ProdStockEntry,
                isSavingRecord: false,
                currCode: 'RWF',

                thePurchaseDetailRecord: null as PurchaseOperationDetail | any,
                thePurchaseDetailInfo: {...emptyPOInfo} as OrderDetailInfo,

                theProductRecord: {...emptyProd} as ProdProduct,
                theVariantValRecord: {...emptyVariantDetail} as ProductVariantDetail,
                theProductType: 0,
            }
        },
        computed: {
            windowTitle() {
                return this.recordStatus == 0 ? language.add_stock_oper :
                    language.edit_stock_oper;
            },
        },
        methods: {
            
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                if(this.dataRecord.Quantity > this.thePurchaseDetailInfo.RemainQty){
                    appFxs.showAlert(language.qty_in_stock_sh, language.qty_in_stock_sh_err_msg);
                    return;
                }
                
                if(this.dataRecord.BuyingPrice > this.dataRecord.CostPrice){
                    appFxs.showAlert(language.price_settings, language.price_settings_stock_err_msg);
                    return;
                }

                appFxs.showConfirm(language.stock_entry_op, language.stock_entry_op_qst_msg)
                    .then(vRes => {
                        if(vRes.isConfirmed){
                            this.saveFinalRecord();
                        }
                    });
            },
            saveFinalRecord(){
                this.isSavingRecord = true;
                NetServices.requestPost('stockops/purchases/stockop/addstockoperationbypurchasedet', {
                    stockEntryRecord: this.dataRecord,
                    toDelete: false,
                }).then(oRes => {
                    if (!oRes.bReturn) {
                        this.isSavingRecord = false;
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    this.isSavingRecord = false;
                    this.v$.$reset();

                    this.$emit('closeForm', {returnType: 1, returnData: {
                        bReturn: true,
                        listItems: oRes.lstOfPurchaseOrderDetails!
                    }});
                });
            },
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: ProdStockEntry = {...oValues} as ProdStockEntry;

                        this.thePurchaseDetailRecord = appFxs.getProxyData(this.purchaseDetailRecord) as PurchaseOperationDetail;
                        this.thePurchaseDetailInfo = appFxs.getProxyData(this.purchDetailInfo) as OrderDetailInfo;
                        this.theProductRecord = appFxs.getProxyData(this.productRecord) as ProdProduct;

                        this.theProductType = this.productType as number;

                        if(this.theProductType==1){
                            this.theVariantValRecord = appFxs.getProxyData(this.variantValRecord) as ProductVariantDetail;
                        }



                        this.v$.$reset();

                        this.dataRecord = fValues;
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {
                    ProductSku: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    PurchaseOrderKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    PurchaseOrderDetKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    SupplierKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    UnitType: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    BuyingPrice: {
                        required: helpers.withMessage(language.fldRequired, required),             
                        greaterThanZero: helpers.withMessage(language.fldRequired, greaterThanZero),                
                        $autoDirty: true,
                    },
                    CostPrice: {
                        required: helpers.withMessage(language.fldRequired, required),             
                        greaterThanZero: helpers.withMessage(language.fldRequired, greaterThanZero),                
                        $autoDirty: true,
                    },
                    SellingPrice: {
                        required: helpers.withMessage(language.fldRequired, required),               
                        greaterThanZero: helpers.withMessage(language.fldRequired, greaterThanZero),              
                        $autoDirty: true,
                    },
                    Quantity: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThanZero: helpers.withMessage(language.fldRequired, greaterThanZero),                 
                        $autoDirty: true,
                    },
                }
            }
        },
        mounted(){            
            this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
        }
    })
