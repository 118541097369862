
    import { defineComponent } from "vue";
    import NetServices from '@/tools/netservices';
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { PurchaseOperationDetail } from "@/models/producttypes";

    const language =GetLanguage(),
        appFxs = GeneralFx();
    
    
    export default defineComponent({
        name: 'StockInfoPO',
        props: ["purchaseDetail"],
        data(){
            return {
                language,
                appFxs,
                isStillLoading: true,
                nbrInStock: 0,
                remainingToEntry: 0,
            }
        },
        mounted(){
            const oDetail = this.purchaseDetail as PurchaseOperationDetail;

            NetServices.requestGet(`stockops/purchases/purchaseorderdetailinfostock/${oDetail.PurchaseKey}/${oDetail.PurchaseDetKey}`)
                .then(oRes => {
                    if(!oRes.bReturn){
                        this.nbrInStock = 0;
                        this.remainingToEntry = 0;
                        this.isStillLoading = false;
                        return;
                    }
                    
                    this.nbrInStock = oRes.nbrInStock!;
                    this.remainingToEntry = oRes.remainingToEntry!;
                    this.isStillLoading = false;
                });
        }
    })
