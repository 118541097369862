
    import { defineComponent } from "vue";
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';

    import Swal from "sweetalert2";
    import { StockSituation } from "@/models/notdb";

    const language = GetLanguage(),
        appFxs = GeneralFx();

    export default defineComponent({
        data(){
            return {            
                appFxs,
                language,
                isStillLoading: true,
                itemsPerPage: 5,
                itemSearchKey: '',
                currentYear: 2000,

                lisOfStockSituations: [] as StockSituation[],
                stockSitPageElts: [] as StockSituation[],


                isPrintvisible: false,
                printInitialValues: {
                    ...appFxs.getReportingData(),
                    ReportToPrint: 8,
                    ReportTitle: language.list_of_brands
                } as any,
                printExtraData: {},

                currCode: 'RWF',
            }
        },
        computed: {
            filteredStockSituations(): StockSituation[] {
                let searchKey = this.itemSearchKey;

                if (searchKey.length === 0) return this.lisOfStockSituations;
                searchKey = searchKey.toLowerCase();

                const filterd = this.lisOfStockSituations.filter(oDev => {
                    return oDev.ProductSku.toLowerCase().includes(searchKey) ||
                        oDev.ProductName.toLowerCase().includes(searchKey) ||
                        oDev.VariantSku.toLowerCase().includes(searchKey) ||
                        oDev.VariantValues.toLowerCase().includes(searchKey) ||
                        oDev.VariantCodes.toLowerCase().includes(searchKey) ||
                        oDev.ProductSellPrice.toString().toLowerCase().includes(searchKey) ||
                        oDev.QtyEntries.toString().toLowerCase().includes(searchKey) ||
                        oDev.QtyExits.toString().toLowerCase().includes(searchKey) ||
                        oDev.QtyRemaining.toString().toLowerCase().includes(searchKey) ||
                        oDev.StockValue.toString().toLowerCase().includes(searchKey);
                });
                return filterd;
            },
        },
        methods: {
            getProdName(oSitRecord: StockSituation): string{
                const prodName = oSitRecord.ProductName;
                const oValues = oSitRecord.VariantValues;
                return oSitRecord.ProductType == 0 ? 
                    prodName :
                    `${prodName} (${oValues})`;
            },
            showOperationDetails(oSitRecord: StockSituation){
                const oPathProd = `/app/products/stockstatus/${oSitRecord.ProductSku}/details`;
                const oPathVar = `/app/products/stockstatus/${oSitRecord.ProductSku}/${oSitRecord.VariantSku}/details`;
                const finalPath = oSitRecord.ProductType == 0 ? oPathProd: oPathVar;

                router.replace({path: finalPath, replace: true});
            },
            onPageChange(evt: any) {
                this.stockSitPageElts = appFxs.getProxyData(evt);
            },   
            onPrintClose(evt: any) {
                this.isPrintvisible = false;
                if (evt.returnType === 0) return;
            },
            refreshList(){
                appFxs.showAjaxLoader();

                NetServices.requestGet('stockops/situations/getstocksituations')
                    .then(oRes => {
                        if (!oRes.bReturn) {
                            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                                .then(xRes => {
                                    Swal.close();
                                    appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                                });
                            return;
                        }

                        const fList: StockSituation[] = [];
                        oRes.listOfStockSituations!.forEach(oEmail => {
                            const fEmail = { ...oEmail }
                            fList.push(fEmail);
                        });

                        this.lisOfStockSituations = fList;

                        Swal.close();
                    });
            },
            printListItems() {
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = language.stock_status;
                dPrint.ReportToPrint = 12;

                this.printInitialValues = dPrint;

                const oPr = {
                    CurrentYear: this.currentYear,
                };
                this.printExtraData = oPr;


                this.isPrintvisible = true;
            }, 
        },
        mounted(){
            document.title = this.language.stock_status + ' - ' + this.language.appName;
            this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
            this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
            this.currentYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, '2000'));
  
            NetServices.requestGet('stockops/situations/getstocksituations')
                .then(oRes => {
                    if (!oRes.bReturn) {
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                            .then(xRes => {
                                router.replace({ path: '/app/myprofile', replace: true });
                            });
                        return;
                    }

                    const fList: StockSituation[] = [];
                    oRes.listOfStockSituations!.forEach(oEmail => {
                        const fEmail = { ...oEmail }
                        fList.push(fEmail);
                    });

                    this.lisOfStockSituations = fList;

                    this.isStillLoading = false;
                });
        }
    })
