
  import { useVuelidate } from '@vuelidate/core'
  import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

  import { defineComponent, Teleport } from "vue";
  import {RouterLink } from 'vue-router';
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';
  import { Customer } from "@/models/managttypes";

  import Swal from "sweetalert2";
  
  import { TelCode } from '@/tools/smarttypes';

  const language =GetLanguage(),
  appFxs = GeneralFx();
  const emptyCustomer:Customer = {
    IdClient: 0,
    ClientKey: '',
    ClientOrigin: 1,
    ClientType: 0,
    ClientName: '',
    Phone: '',
    Email: '',
    AdressLine1: '',
    AdressLine2: '',
    City: '',
    Country: 'RW',
    Region: '',
    AcceptMarketing: true,
    TaxExempted: false,
    Note: '',
    Tags: '',
    DateCreated: new Date(),
    ClientLang: 0,
    Sex: 0,
  };


  export default defineComponent({
    data(){
      return {
        v$: useVuelidate(),
        language,
        appFxs,
        dataRecord: {...emptyCustomer} as Customer,
        countries: [] as TelCode[],
        languages: appFxs.listOfLangs(),
        sexes: appFxs.listOfSexes(),
        clientTypes: appFxs.clientTypes(),
        isSavingRecord: false,
        tags: [language.loading + '...'] as string[],
        options: [],
        isLoading: true,
        customerKey: '',
      }
    },
    computed: {
      windowTitle(): string{
        return this.isLoading ? `${language.loading}...`: `${this.dataRecord.ClientName}`;
      }
    },
    methods: {
      selectOnFocus(evt: Event) {
        const oInput = evt.target as HTMLInputElement;
        oInput.select();
      },
      updateTags(evt: any){
        const fFinal = evt as string[];
        const ccF = fFinal.length>0 ? fFinal.join(';'): '';
        this.dataRecord.Tags = ccF;
      },
      
      saveNewCustomer(){
        this.isSavingRecord = true;
        const fRecord = {...appFxs.getProxyData(this.dataRecord)};

        NetServices.requestPost('managt/customers/saveonecustomer',{
          customerRecord: fRecord,
          toDelete: false,
        }).then(oRes => {
          if (!oRes.bReturn) {
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            this.isSavingRecord = false;
            return;
          }

          const fList: Customer[] = [];
          oRes.listOfCustomers!.forEach(oEmail => {
            const fEmail = { ...oEmail, DateCreated: new Date(oEmail.DateCreated) }
            fList.push(fEmail);
          });

          const oPath = `/app/customers/list`;
          router.replace({path: oPath, replace: true});
        });
      }
    },
    mounted(){
      document.title = this.windowTitle + ' - ' + this.language.appName;
      const customerKey = this.$route.params.customerKey as string;
      if (!customerKey) {
        appFxs.showAlert(language.missing_settings, language.missing_settings_msg)
          .then(oRes => {
            router.replace({ path: '/app/customers/list', replace: true })
          });
        return;
      }
      const oCtries = appFxs.getLocalStorageByKey(appDeclarations.telephonesCodes, '[]');
      const countries = JSON.parse(oCtries);

      this.countries = countries;

      this.customerKey = customerKey;

      let customerTags = [] as string[];

      NetServices.requestGet(`managt/customers/getonecustomer/${customerKey}`)
        .then(oRes => {
          if (!oRes.bReturn) {
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
              .then(vRes => {
                router.replace({ path: '/app/customers/list', replace: true });
              });
            return;
          }

          const tempCustomer = oRes.oneCustomerRec!;
          const oFinalCust = {...tempCustomer, DateCreated: new Date(tempCustomer.DateCreated) };

          if(oFinalCust.Tags.length>0){
            const fTags = oFinalCust.Tags.split(';');
            if(fTags.length>0){
              customerTags = fTags;
            }
          }

          this.dataRecord = oFinalCust;
          this.isLoading = false;

          setTimeout(()=>{
            this.tags = customerTags;
          }, 200);
        });
    },    
    validations(){
      return {
        dataRecord: {
          ClientName: {
              required: helpers.withMessage(language.fldRequired, required),                 
              $autoDirty: true,
          },
          Phone: {
              required: helpers.withMessage(language.fldRequired, required),
              minLength: helpers.withMessage(language.minLengthReqMsg, minLength(10)),
              maxLength: helpers.withMessage(language.maxLengthReqMsg, maxLength(10)),
              $autoDirty: true,
          },
          Email: {
              required: helpers.withMessage(language.fldRequired, required),
              email: helpers.withMessage(language.emailFormat, email),                         
              $autoDirty: true,
          },
          AdressLine1: {
              required: helpers.withMessage(language.fldRequired, required),                 
              $autoDirty: true,
          },
          City: {
              required: helpers.withMessage(language.fldRequired, required),                 
              $autoDirty: true,
          },
          Region: {
              required: helpers.withMessage(language.fldRequired, required),                 
              $autoDirty: true,
          },
        }
      }
    }
  })
