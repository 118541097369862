 
      import { defineComponent } from "vue";
      import router from '@/router/index';
  
      import NetServices from '@/tools/netservices';
  
      import GetLanguage from '@/tools/language';
      import GeneralFx from '@/tools/general_fx';
      import appDeclarations from '@/tools/declarations';
      import DataFxs from '@/tools/data_functions';
  
      import Swal from "sweetalert2";
      import { ProdProduct } from "@/models/producttypes";
  
      import { SalesOrder, SalesOrderDelivery, SalesRefund, SalesRefundEntryStock, SalesRefundEntryStockDetail } from "@/models/salestypes";
      const language = GetLanguage(),
          appFxs = GeneralFx(),
          dataFxs = DataFxs();
      const fNewDate = new Date(),
          year = fNewDate.getFullYear();
  
  
      export default defineComponent({    
          name: 'SalesStockEntryDetailsByRef',
          data(){
              return{
                  appFxs,
                  language,        
                  isStillLoading: true,
                  itemSearchKey: '',
                  itemsPerPage: 5,
                  selectedYear: year,
                  currCode: 'RWF',
                  userEmail: '',
  
                  selectedRefundKey: '',
                  selectedRefundStockKey: '',
  
                  salesRefundElement: null as any,
                  salesRefundStockElement: null as any,
  
                  lstRefundStockDetails: [] as SalesRefundEntryStockDetail[],
                  itemsPageElts: [] as SalesRefundEntryStockDetail[],
                  listOfProducts: [] as ProdProduct[],
  
                  isPrintvisible: false,
                  printInitialValues: {
                  ...appFxs.getReportingData(),
                  ReportToPrint: 8,
                  ReportTitle: language.list_of_brands
                  } as any,
                  printExtraData: {},
              }
          },
          computed: {
              filteredRecordItems(): SalesRefundEntryStockDetail[] {
                  let searchKey = this.itemSearchKey;
  
                  if (searchKey.length === 0) return this.lstRefundStockDetails;
                  searchKey = searchKey.toLowerCase();
  
                  const filterd = this.lstRefundStockDetails.filter(oDev => {
                      return oDev.RefundDetKey.toLowerCase().includes(searchKey);
                  });
                  return filterd;
              },
              getInfoRecord(): {orderKey: string;orderNbr: string;deliveryKey: string;deliveryNbr: string;
              }{
                const oFinal = {
                  orderKey: '',
                  orderNbr: '',
                  deliveryKey: '',
                  deliveryNbr: ''
                };
  
                if(!this.salesRefundElement || !this.salesRefundStockElement)
                  return oFinal;
  
                  const fOrder = this.salesRefundElement as SalesRefund;
                  const fDeliv = this.salesRefundStockElement as SalesRefundEntryStock;
  
                return {
                  deliveryKey: fDeliv.RefundKey,
                  deliveryNbr: fDeliv.RefundEntryNbr,
                  orderNbr: fOrder.RefundNbr,
                  orderKey: fOrder.RefundKey
                }
              }
          },
          methods: {
              getProductRecord(delDet: SalesRefundEntryStockDetail): string{
                  const oProd = this.listOfProducts.find(o => o.SKU == delDet.ProductKey);
                  if(!oProd)return '';
                  if(delDet.ProductOrient==0) return  oProd.Name;
                  return `${oProd.Name} (${delDet.VariantValue})`;
              },
              refresListItems() {
                  appFxs.showAjaxLoader();
                  NetServices.requestGet(`salesorders/refund/stockentries/getlistofstockentrydetailsbyrefund/${this.selectedRefundKey}/${this.selectedRefundStockKey}`)
                      .then(oRes => {
                          if (!oRes.bReturn) {
                              Swal.close();
                              appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                              return;
                          }
  
                          this.lstRefundStockDetails = oRes.lstRefundStockDetails!;
                          this.listOfProducts = oRes.listOfProducts!
                          this.salesRefundElement = oRes.salesRefundElement;
                          this.salesRefundStockElement = oRes.salesRefundStockElement;
  
                          Swal.close();
                      });
  
              },
              onPageChange(evt: any) {
                  this.itemsPageElts = appFxs.getProxyData(evt);
              },
              printListItems() {
                  const dPrint = appFxs.getReportingData();
                  const v = this.salesRefundStockElement as SalesRefundEntryStock;
                  dPrint.ReportTitle = `${language.stock_entry_by_refuund} - ${v.RefundEntryNbr}`;
                  dPrint.ReportToPrint = 32;
  
                  this.printInitialValues = dPrint;
                  this.printExtraData = {
                    RefunEntryStockKey: v.RefundEntryKey
                  }
  
                  this.isPrintvisible = true;
              },
              onPrintClose(evt: any) {
                  this.isPrintvisible = false;
                  if (evt.returnType === 0) return;
              },
          },
          mounted() {
              document.title = this.language.refund_stock_details + ' - ' + this.language.appName;
              this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
              this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
              this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
              this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));
  
              const orderKey = this.$route.params.refundKey;
              const deliveryKey = this.$route.params.stockEntryKey;
  
              if (!orderKey || !deliveryKey) {
                  router.push({
                      name: 'appsalesrefundlist',
                  });
                  return;
              }
              const _orderKey = orderKey as string;
              const _delKey = deliveryKey as string;
  
              this.selectedRefundKey = _orderKey;
              this.selectedRefundStockKey = _delKey;
  
              NetServices.requestGet(`salesorders/refund/stockentries/getlistofstockentrydetailsbyrefund/${this.selectedRefundKey}/${this.selectedRefundStockKey}`)
                  .then(oRes => {
                      if (!oRes.bReturn) {
                          appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                              .then(vRes => {
                                  router.push({
                                      name: 'appsalesrefundlist',
                                  });
                              });
                          return;
                      }
  
                      
  
                      this.lstRefundStockDetails = oRes.lstRefundStockDetails!;
                      this.listOfProducts = oRes.listOfProducts!;
                      this.salesRefundElement = oRes.salesRefundElement!;
                      this.salesRefundStockElement = oRes.salesRefundStockElement!;
  
                      this.isStillLoading = false;
                  });
          }
      })
  