
  import { defineComponent } from 'vue';
  import router from '@/router/index';
  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import AppDeclarations from '@/tools/declarations';

  import AnalogClockScreen from '@/components/screensaver/AnalogClockScreen.vue';
  import AnimatedHotCup from '@/components/screensaver/AnimatedHotCupScreen.vue';
  import LoadingAnimation from '@/components/screensaver/LoadingAnimationScreen.vue';
  import BouncingCircle from '@/components/screensaver/BouncingCircleScreen.vue';
  import GlassBallParticle from '@/components/screensaver/GlassBallParticleScreen.vue';
  import BulbAnimation from '@/components/screensaver/BulbAnimationScreen.vue';



  const language = GetLanguage(),
  appFxs = GeneralFx();

  export default defineComponent({
    components: {
      AnalogClockScreen,
      AnimatedHotCup,
      LoadingAnimation,
      BouncingCircle,
      GlassBallParticle,
      BulbAnimation,
    },
    data(){
      return {
        language,
        appFxs,
        timeOut: 0,
        animationPos: appFxs.getRandomInt(0, 5)
      }
    },
    methods: {
      checkLoadingStatus(){
        const connectStatus = appFxs.toBoolFromStr( appFxs.getLocalStorageByKey(AppDeclarations.connectionStatus, '0'));
        if(connectStatus){
          router.replace({path: '/app', replace: true});
        }else{
          router.replace({path: '/account', replace: true});
        }
      }
      
    },
    mounted(){        
      document.title = language.loading + '... - ' + language.appName;
      this.timeOut = setTimeout(this.checkLoadingStatus, 5000);
    },
    unmounted(){
      clearTimeout(this.timeOut);
    }
  })
