     
    import { defineComponent, Teleport } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';

    import Swal from "sweetalert2";
    import { TelCode } from "@/tools/smarttypes";
    import { ProdCategory, ProdSubCategory } from "@/models/producttypes";

    import SubCategoryModal from '@/components/modals/SubCategoryModal.vue';

    const language = GetLanguage(),
        appFxs = GeneralFx();
    
    const emptyCategory: ProdCategory = {
        ProdCategoryId: 0,
        NameEn: '',
        Code: '',
        Description: '',
        NameFr: '',
        NameRw: '',
    };
    const emptySubCategory: ProdSubCategory = {
        ProdCategoryId: 0,
        NameEn: '',
        Code: '',
        Description: '',
        NameFr: '',
        NameRw: '',
        SubCode: '',
    };

    export default defineComponent({
        components: {
            SubCategoryModal,
        },
        data(){
            return {                
                appFxs,
                language,
                isStillLoading: true,
                listOfSubCategs: [] as ProdSubCategory[],
                subCategsPageElts: [] as ProdSubCategory[],
                subCategSearchKey: '',
                itemsPerPage: 5,

                categoryCode: '',
                decodedCategoyCode: '',
                oneProductCateg: null as ProdSubCategory | any,

                isPrintvisible: false,
                printInitialValues: {
                ...appFxs.getReportingData(),
                ReportToPrint: 10,
                ReportTitle: language.list_of_subcategs
                } as any,
                printExtraData: {'ProdCategCode': ''},

                isDialogOpen:false,
                dialogRecordData: {...emptySubCategory} as ProdSubCategory,
                dialogRecordStatus: 0,

            }
        },
        computed: {
            backToOneSpecificCateg(): string{
                const oCode = this.relatedCategory;
                if(!oCode.bReady) return '/app/products/categories/list';
                const fFinal = window.btoa(oCode.record.Code);

                return '/app/products/categories/editor/' + fFinal;
            },
            filteredSubCategs(): ProdSubCategory[] {
                let searchKey = this.subCategSearchKey;
                if (searchKey.length === 0) return this.listOfSubCategs;
                searchKey = searchKey.toLowerCase();

                const filterd = this.listOfSubCategs.filter(oDev => {
                    return oDev.Code.toLowerCase().includes(searchKey) ||
                        oDev.SubCode.toLowerCase().includes(searchKey) ||
                        oDev.NameEn.toLowerCase().includes(searchKey) ||
                        oDev.NameFr.toLowerCase().includes(searchKey) ||
                        oDev.NameRw.toLowerCase().includes(searchKey) ||
                        oDev.Description.toLowerCase().includes(searchKey);
                });
                return filterd;
            },
            relatedCategory(): {bReady: boolean, record: ProdCategory}{
                const bOReady = this.oneProductCateg != null;
                const oRecord = 
                    this.oneProductCateg != null ? 
                    this.oneProductCateg as ProdCategory : 
                    {...emptyCategory} as ProdCategory;

                return {
                    bReady: bOReady, 
                    record: oRecord,
                }
            }
        },
        methods: {
            getCategoryName(oCateg: ProdSubCategory) {
                const oLang = appFxs.getLocalLanguage();
                return oLang == 'en' ? oCateg.NameEn :
                    oLang == 'fr' ? oCateg.NameFr :
                        oLang == 'rw' ? oCateg.NameRw :
                            oCateg.NameEn;
            },            
            getRealCategoryName(oCateg: ProdCategory) {
                const oLang = appFxs.getLocalLanguage();
                return oLang == 'en' ? oCateg.NameEn :
                    oLang == 'fr' ? oCateg.NameFr :
                        oLang == 'rw' ? oCateg.NameRw :
                            oCateg.NameEn;
            },
            showAddSubCategAction(iStatus: number, data: ProdSubCategory | any) {
                if(iStatus===0){
                    const fNew = {...emptySubCategory};
                    this.dialogRecordData = (fNew as ProdSubCategory);
                    this.dialogRecordData.Code = this.decodedCategoyCode;
                    this.dialogRecordStatus = 0;
                }else{
                    this.dialogRecordData = data!;
                    this.dialogRecordStatus = 1;
                }
                
                this.isDialogOpen = true;
            },
            onPageChange(evt: any) {
                this.subCategsPageElts = appFxs.getProxyData(evt);
            },
            deleteSubCategRecord(data: ProdSubCategory) {
                appFxs.showConfirm(language.delete, language.delete_quest_rec)
                    .then(oResp => {
                        if (!oResp.isConfirmed) return;
                        this.finalDeleteSubCateg(data);
                    });
            },
            finalDeleteSubCateg(data: ProdSubCategory) {
                appFxs.showAjaxLoader();

                NetServices.requestPost('products/categs/savesubcategoryrecord', {
                    prodSubCategoryRecord: data,
                    toDelete: true,
                }).then(oRes => {
                    if (!oRes.bReturn) {
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: ProdSubCategory[] = [];
                    oRes.listOfProdSubCategories!.forEach(oEmail => {
                        const fEmail = { ...oEmail }
                        fList.push(fEmail);
                    });

                    this.listOfSubCategs = fList;

                    Swal.close();
                });
            },
            refreshList() {
                appFxs.showAjaxLoader();

                NetServices.requestGet(`products/categs/listofsubcategories/${this.categoryCode}`)
                    .then(oRes => {
                        if (!oRes.bReturn) {
                            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                                .then(xRes => {
                                    router.replace({path: '/app/products/categories/list', replace: true});
                                    Swal.close();
                                });
                            return;
                        }

                        const fList: ProdSubCategory[] = [];
                        oRes.listOfProdSubCategories!.forEach(oEmail => {
                            const fEmail = { ...oEmail }
                            fList.push(fEmail);
                        });

                        this.listOfSubCategs = fList;
                        Swal.close();
                    });
            },
            printListItems() {
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = language.list_of_subcategs;
                dPrint.ReportToPrint = 10;

                this.printExtraData = {'ProdCategCode': this.decodedCategoyCode};

                this.printInitialValues = dPrint;


                this.isPrintvisible = true;
            },
            onPrintClose(evt: any) {
                this.isPrintvisible = false;
                if (evt.returnType === 0) return;
            },

            onCloseForm(evt: any){
                this.isDialogOpen = false;
                if(evt.returnType===0)return;

                const lst = evt.returnData as ProdSubCategory[];
                this.listOfSubCategs = lst;
            }
        },
        mounted() {
            document.title = this.language.list_of_subcategs + ' - ' + this.language.appName;
            this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));

            const categorycodeParam = this.$route.params.categoryKey;
            if (!categorycodeParam) {
                appFxs.showAlert(language.missing_settings, language.missing_settings_msg)
                    .then(oRes => {
                        router.replace({ path: '/app/products/categories/list', replace: true })
                    });
                return;
            }
            const finalCode = categorycodeParam as string;
            this.categoryCode = finalCode;
            this.decodedCategoyCode = window.atob(finalCode);


            NetServices.requestGet(`products/categs/listofsubcategories/${finalCode}`)
                .then(oRes => {
                    if (!oRes.bReturn) {
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                            .then(xRes => {
                                router.replace({ path: '/app/products/categories/list', replace: true });
                            });
                        return;
                    }

                    this.oneProductCateg = oRes.oneProductCateg;

                    const fList: ProdSubCategory[] = [];
                    oRes.listOfProdSubCategories!.forEach(oEmail => {
                        const fEmail = { ...oEmail }
                        fList.push(fEmail);
                    });

                    this.listOfSubCategs = fList;

                    this.isStillLoading = false;
                });
        }
    });
