
    import { defineComponent } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { EmailSenderSett } from "@/models/managttypes";

    import EmailSettingsModal from "@/components/modals/EmailSettingsModal.vue";
    import Swal from "sweetalert2";

    const language =GetLanguage(),
    appFxs = GeneralFx();
    const emptyEmailSett = {
        IdEmail: 0,
        AccountKey: '',
        AccountEmail: 'lolo@ggg.com',
        AccountPassword: '',
        AccountServer: '',
        AccountPort: '456',
        EmailSentAs: '',
        CreatedDate: new Date(),
        IsDefaultNoReply: false,
        IsDefaultMarketing: false,
        AccountName: '',
        AccountType: 0,
    }

    export default defineComponent({
        components: {
            EmailSettingsModal,
        },
        data(){
            return {
                appFxs,
                language,
                isStillLoading: true,
                listOfEmailSetts: [] as EmailSenderSett[],
                isDialogOpen: false,
                dialogRecordStatus: 0,
                dialogRecordData: (emptyEmailSett as EmailSenderSett),
                eltSearchKey: '',

                
                isPrintvisible: false,
                printInitialValues: {...appFxs.getReportingData()} as any,
                printExtraData: {}
            }
        },
        computed: {
            filteredTaxes(): EmailSenderSett[] {
                let searchKey = this.eltSearchKey;

                if(searchKey.length === 0) return this.listOfEmailSetts;
                searchKey = searchKey.toLowerCase();
                
                const filterd = this.listOfEmailSetts.filter(oDev => {
                    return oDev.AccountName.toLowerCase().includes(searchKey) || 
                        oDev.AccountEmail.toLowerCase().includes(searchKey) || 
                        oDev.EmailSentAs.toLowerCase().includes(searchKey);
                });
                return filterd;
            },

        },
        methods: {
            refreshData(){
                appFxs.showAjaxLoader();

                NetServices.requestGet('managt/settings/getlistemailssetts')
                    .then(oRes => {
                        if(!oRes.bReturn){
                                appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                                .then(xRes => {

                                    Swal.close();
                                });
                                return;
                            }

                        const fList: EmailSenderSett[] = [];
                        oRes.listOfEmailSettings!.forEach(oEmail => {
                            const fEmail = {...oEmail, CreatedDate: new Date(oEmail.CreatedDate)};
                            fList.push(fEmail);
                        });

                        this.listOfEmailSetts = fList;
                        
                        Swal.close();
                    });
            },
            openMyEmailSetts(type: number, data?: EmailSenderSett){
                if(type===0){
                    const fNew = {...emptyEmailSett};
                    this.dialogRecordData = (fNew as EmailSenderSett);
                    this.dialogRecordStatus = 0;
                }else{
                    this.dialogRecordData = data!;
                    this.dialogRecordStatus = 1;
                }
                
                this.isDialogOpen = true;
            },
            deleteOneEmailSett(data: EmailSenderSett){
                appFxs.showConfirm(language.delete, language.delete_email_actt_qst)
                    .then(oResp => {
                        if(!oResp.isConfirmed)return;
                        this.finalDeleteEmailSett(data);
                    });
            },
            finalDeleteEmailSett(data: EmailSenderSett){
                appFxs.showAjaxLoader();

                NetServices.requestPost('managt/settings/saveoneemailsett', {
                    emailAccountSett: data,
                    toDelete: true,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: EmailSenderSett[] = [];
                    oRes.listOfEmailSettings!.forEach(oEmail => {
                        const fEmail = {...oEmail, CreatedDate: new Date(oEmail.CreatedDate)}
                        fList.push(fEmail);
                    });

                    this.listOfEmailSetts = fList;
                    
                    Swal.close();
                });
            },
            onCloseForm(evt: any){
                this.isDialogOpen = false;

                if(evt.returnType===0)return;

                const returnData = appFxs.getProxyData(evt.returnData);
                appFxs.showAjaxLoader();

                NetServices.requestPost('managt/settings/saveoneemailsett', {
                    emailAccountSett: returnData
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: EmailSenderSett[] = [];
                    oRes.listOfEmailSettings!.forEach(oEmail => {
                        const fEmail = {...oEmail, CreatedDate: new Date(oEmail.CreatedDate)}
                        fList.push(fEmail);
                    });

                    this.listOfEmailSetts = fList;
                    
                    Swal.close();
                });
            },

            
            
            printListItems(){
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = language.list_of_email_settings;
                dPrint.ReportToPrint = 5;

                this.printInitialValues = dPrint;


                this.isPrintvisible = true;
            },
            onPrintClose(evt: any){                
                this.isPrintvisible = false;
                if(evt.returnType===0)return;
            }
        },
        mounted(){
            document.title = `${this.language.settings}/${this.language.emailSettings} - ${this.language.appName}`;
            const fNew = {...emptyEmailSett};
            this.dialogRecordData = (fNew as EmailSenderSett);

            NetServices.requestGet('managt/settings/getlistemailssetts')
                .then(oRes => {
                    if(!oRes.bReturn){
                            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                            .then(xRes => {
                                router.replace({path: '/app/myprofile', replace: true});
                            });
                            return;
                        }

                    const fList: EmailSenderSett[] = [];
                    oRes.listOfEmailSettings!.forEach(oEmail => {
                        const fEmail = {...oEmail, CreatedDate: new Date(oEmail.CreatedDate)}
                        fList.push(fEmail);
                    });

                    this.listOfEmailSetts = fList;
                    
                    this.isStillLoading = false;
                });
        }
    })
