import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoadingPage from '@/views/LoadingPage.vue';

import MainAccountPage from '@/views/account/MainAccountPage.vue';
import LoginPage from '@/views/account/LoginPage.vue';
import ForgotPassword from '@/views/account/ForgotPasswordPage.vue';
import ResetPasswordPage from '@/views/account/ResetPasswordPage.vue';
import ResetWaitPage from '@/views/account/WaitResetPage.vue';
import JoinCompanyPage from '@/views/account/JoinCompanyPage.vue';

import MainAppPage from '@/views/app/MainAppPage.vue';
import AppDashboardPage from '@/views/app/pages/dashboard/AppDashboardPage.vue';

import AppCustomersPage from '@/views/app/pages/customers/AppCustomersPage.vue';
import CustomersListPage from '@/views/app/pages/customers/CustomersListPage.vue';
import CustomersNewPage from '@/views/app/pages/customers/CustomersNewPage.vue';
import CustomersEditorPage from '@/views/app/pages/customers/CustomersEditorPage.vue';

import AppSuppliersPage from '@/views/app/pages/suppliers/AppSuppliersPage.vue';
import SuppliersListPage from '@/views/app/pages/suppliers/SuppliersListPage.vue';
import SuppliersNewPage from '@/views/app/pages/suppliers/SuppliersNewPage.vue';
import SuppliersEditorPage from '@/views/app/pages/suppliers/SuppliersEditorPage.vue';

import AppAnalyticsPage from '@/views/app/pages/AppAnalyticsPage.vue';
import AppSettingsPage from '@/views/app/pages/AppSettingsPage.vue';
import AppMyProfilePage from '@/views/app/pages/AppMyProfilePage.vue';

//settings
import BillingInfo from '@/views/app/pages/setts/BillingInfoPage.vue';
import ChangeCred from '@/views/app/pages/setts/ChangeCredPage.vue';
import CurrenciesSetts from '@/views/app/pages/setts/CurrenciesSettsPage.vue';
import EmailSettings from '@/views/app/pages/setts/EmailSettingsPage.vue';
import FinancialYears from '@/views/app/pages/setts/FinancialYearsPage.vue';
import InvoiceOptions from '@/views/app/pages/setts/InvoiceOptionsPage.vue';
import MyCompany from '@/views/app/pages/setts/MyCompanyPage.vue';
import OtherSetts from '@/views/app/pages/setts/OtherSettsPage.vue';
import SmsSettings from '@/views/app/pages/setts/SmsSettingsPage.vue';
import UserSettings from '@/views/app/pages/setts/UserSettingsPage.vue';
import TaxSettings from '@/views/app/pages/setts/TaxSettingsPagePage.vue';

import AppProductsPage from '@/views/app/pages/products/AppProductsPage.vue';

//brands
import ProdBrandViewPage from '@/views/app/pages/products/brands/ProdBrandViewPage.vue';
import ProdBrandListPage from '@/views/app/pages/products/brands/ProdBrandListPage.vue';
import ProdBrandNewPage from '@/views/app/pages/products/brands/ProdBrandNewPage.vue';
import ProdBrandEditorPage from '@/views/app/pages/products/brands/ProdBrandEditorPage.vue';

//Categories
import ProdCategViewPage from '@/views/app/pages/products/categories/ProdCategViewPage.vue';
import ProdCategoriesListPage from '@/views/app/pages/products/categories/ProdCategoriesListPage.vue';
import ProdCategoriesNewPage from '@/views/app/pages/products/categories/ProdCategoriesNewPage.vue';
import ProdCategoriesEditorPage from '@/views/app/pages/products/categories/ProdCategoriesEditorPage.vue';
import ProdSubcategoriesList from '@/views/app/pages/products/categories/ProdSubcategoriesListPage.vue';

//Products
import ProductsViewerPage from '@/views/app/pages/products/products/ProductsViewerPage.vue';
import ProductNewPage from '@/views/app/pages/products/products/ProductNewPage.vue';
import ProductsEditorPage from '@/views/app/pages/products/products/ProductsEditorPage.vue';
import ProductsListPage from '@/views/app/pages/products/products/ProductsListPage.vue';

import VariantsAndDetailsPage from '@/views/app/pages/products/variants/VariantsAndDetailsPage.vue';

//purchases
import ProductPurchasesView from '@/views/app/pages/products/purchases/ProductPurchasesView.vue';
import PurchasesListPage from '@/views/app/pages/products/purchases/PurchasesListPage.vue';
import PurchasePaytListPage from '@/views/app/pages/products/purchases/PurchasePaytListPage.vue';
import PurchasesDetailsListPage from '@/views/app/pages/products/purchases/PurchasesDetailsListPage.vue';

//stockstatus
import StockStatusViewPage from '@/views/app/pages/products/stockstatus/StockStatusViewPage.vue';
import StockStatusListPage from '@/views/app/pages/products/stockstatus/StockStatusListPage.vue';
import StockStatusProdDetailPage from '@/views/app/pages/products/stockstatus/StockStatusProdDetailPage.vue';
import StockExitsListPage from '@/views/app/pages/products/stockstatus/StockExitsListPage.vue';

//sales
import AppSalesPage from '@/views/app/pages/sales/AppSalesPage.vue';

import AppSalesViewerPage from '@/views/app/pages/sales/sales/AppSalesViewerPage.vue';
import SalesMonthListPage from '@/views/app/pages/sales/sales/SalesMonthListPage.vue';
import SalesNewOperPage from '@/views/app/pages/sales/sales/SalesNewOperPage.vue';
import SalesOperViewerPage from '@/views/app/pages/sales/sales/SalesOperViewerPage.vue';

import SalesDeliveriesViewerPage from '@/views/app/pages/sales/delivery/SalesDeliveriesViewerPage.vue';
import SalesDeliveriesMonthListPage from '@/views/app/pages/sales/delivery/SalesDeliveriesMonthListPage.vue';
import SalesDeliveriesOperEditorPage from '@/views/app/pages/sales/delivery/SalesDeliveriesOperEditorPage.vue';
import SalesDeliveryOperDetailsPage from '@/views/app/pages/sales/delivery/SalesDeliveryOperDetailsPage.vue';

import SalesPaytsViewerPage from '@/views/app/pages/sales/payts/SalesPaytsViewerPage.vue';
import SalesPaytsMonthListPage from '@/views/app/pages/sales/payts/SalesPaytsMonthListPage.vue';
import SalesPaytsOperEditorPage from '@/views/app/pages/sales/payts/SalesPaytsOperEditorPage.vue';

//discounts
import SalesDiscountsViewerPage from '@/views/app/pages/sales/discounts/SalesDiscountsViewerPage.vue';
import SalesDiscountsListPage from '@/views/app/pages/sales/discounts/SalesDiscountsListPage.vue';

import RefundViewerPage from '@/views/app/pages/sales/refund/RefundViewerPage.vue';
import RefundMonthListPage from '@/views/app/pages/sales/refund/RefundMonthListPage.vue';
import RefundNewOperPage from '@/views/app/pages/sales/refund/RefundNewOperPage.vue';
import RefundOperViewerPage from '@/views/app/pages/sales/refund/RefundOperViewerPage.vue';
import RefundListOfPayments from '@/views/app/pages/sales/refund/RefundListOfPayments.vue';
import RefundListOfEntries from '@/views/app/pages/sales/refund/RefundListOfEntries.vue';
import RefundEntryDetailsPage from '@/views/app/pages/sales/refund/RefundEntryDetailsPage.vue';

import SalesQuotesViewerPage from '@/views/app/pages/sales/quotes/SalesQuotesViewerPage.vue';
import SalesQuotesMonthListPage from '@/views/app/pages/sales/quotes/SalesQuotesMonthListPage.vue';
import SalesQuotesNewOperPage from '@/views/app/pages/sales/quotes/SalesQuotesNewOperPage.vue';
import SalesQuotesOperViewerPage from '@/views/app/pages/sales/quotes/SalesQuotesOperViewerPage.vue';



const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'loading',
    component: LoadingPage
  },
  {
    path: '/account',
    name: 'account',
    component: MainAccountPage,
    redirect: '/account/login',
    children: [
      {
        path: '/account/login',
        name: 'loginpage',
        component: LoginPage
      },
      {
        path: '/account/forgotpw',
        name: 'forgotpwpage',
        component: ForgotPassword
      },
      {
        path: '/account/resetwait',
        name: 'resetwaitpage',
        component: ResetWaitPage
      },
      {
        path: '/account/resetpw/:resetKey',
        name: 'resetpwpage',
        component: ResetPasswordPage
      },
      {
        path: '/account/joincompany/:joinCompanyKey',
        name: 'joincompanypage',
        component: JoinCompanyPage
      }
    ]
  },
  {
    path: '/app',
    name: 'app',
    component: MainAppPage,
    redirect: '/app/dashboard',
    children: [
      {
        path: '/app/dashboard',
        name: 'appdashboard',
        component: AppDashboardPage
      },
      {
        path: '/app/customers',
        name: 'appcustomers',
        component: AppCustomersPage,
        redirect: '/app/customers/list',
        children: [
          {
            path: '/app/customers/list',
            name: 'customerlist',
            component: CustomersListPage
          },
          {
            path: '/app/customers/new',
            name: 'customernew',
            component: CustomersNewPage
          },
          {
            path: '/app/customers/editor/:customerKey',
            name: 'customereditor',
            component: CustomersEditorPage
          },
        ],
      },
      {
        path: '/app/suppliers',
        name: 'appsuppliers',
        component: AppSuppliersPage,
        redirect: '/app/suppliers/list',
        children: [
          {
            path: '/app/suppliers/list',
            name: 'supplierslist',
            component: SuppliersListPage
          },
          {
            path: '/app/suppliers/new',
            name: 'suppliernew',
            component: SuppliersNewPage
          },
          {
            path: '/app/suppliers/editor/:supplierKey',
            name: 'suppliereditor',
            component: SuppliersEditorPage
          },
        ],
      },
      {
        path: '/app/products',
        name: 'appproducts',
        component: AppProductsPage,
        redirect: '/app/products/brands',
        children: [
          {
            path: '/app/products/brands',
            name: 'productbrandsview',
            component: ProdBrandViewPage,
            redirect: '/app/products/brands/list',
            children: [
              {
                path: '/app/products/brands/list',
                name: 'productbrandslist',
                component: ProdBrandListPage
              },
              {
                path: '/app/products/brands/new',
                name: 'productbrandsnew',
                component: ProdBrandNewPage
              },
              {
                path: '/app/products/brands/editor/:brandKey',
                name: 'productbrandseditor',
                component: ProdBrandEditorPage
              },
            ]
          },
          {
            path: '/app/products/categories',
            name: 'productcategsview',
            component: ProdCategViewPage,
            redirect: '/app/products/categories/list',
            children: [
              {
                path: '/app/products/categories/list',
                name: 'productcategslist',
                component: ProdCategoriesListPage
              },
              {
                path: '/app/products/categories/new',
                name: 'productcategsnew',
                component: ProdCategoriesNewPage
              },
              {
                path: '/app/products/categories/editor/:categoryKey',
                name: 'productcategseditor',
                component: ProdCategoriesEditorPage
              },
              {
                path: '/app/products/categories/:categoryKey/subcategories/list',
                name: 'productsubcategorieslist',
                component: ProdSubcategoriesList
              },
            ]
          },
          {
            path: '/app/products/products',
            name: 'productproductsview',
            component: ProductsViewerPage,
            redirect: '/app/products/products/list',
            children: [
              {
                path: '/app/products/products/list',
                name: 'productproductslist',
                component: ProductsListPage
              },
              {
                path: '/app/products/products/new',
                name: 'productproductsnew',
                component: ProductNewPage
              },
              {
                path: '/app/products/products/editor/:productKey',
                name: 'productproductseditor',
                component: ProductsEditorPage
              },
              {
                path: '/app/products/products/variants/:productKey',
                name: 'productproductsvariants',
                component: VariantsAndDetailsPage
              },
            ]
          },
          {
            path: '/app/products/purchases',
            name: 'productpurchasesview',
            component: ProductPurchasesView,
            redirect: '/app/products/purchases/list',
            children: [
              {
                path: '/app/products/purchases/list',
                name: 'productpurchaseslist',
                component: PurchasesListPage
              },
              {
                path: '/app/products/purchases/:purchaseKey/details/list',
                name: 'productpurchasesdetailslist',
                component: PurchasesDetailsListPage
              },
              {
                path: '/app/products/purchases/:purchaseKey/payts/list',
                name: 'productpurchasespaytslist',
                component: PurchasePaytListPage
              },
            ]
          },
          {
            path: '/app/products/stockstatus',
            name: 'productstockstatusview',
            component: StockStatusViewPage,
            redirect: '/app/products/stockstatus/list',
            children: [
              {
                path: '/app/products/stockstatus/list',
                name: 'productsrockstatuslist',
                component: StockStatusListPage
              },
              {
                path: '/app/products/stockstatus/:productSku/details',
                name: 'productstockstatusprodfets',
                component: StockStatusProdDetailPage
              },
              {
                path: '/app/products/stockstatus/:productSku/:variantSku/details',
                name: 'productvarstockstatusprodfets',
                component: StockStatusProdDetailPage
              },
            ]
          },
          {
            path: '/app/products/stockexits',
            name: 'productstockexitslist',
            component: StockExitsListPage,
          }
        ]
      },
      {
        path: '/app/analytics',
        name: 'appanalytics',
        component: AppAnalyticsPage
      },
      {
        path: '/app/settings',
        name: 'appsettings',
        component: AppSettingsPage,
        redirect: '/app/settings/changecred',
        children: [
          {
            path: '/app/settings/changecred',
            name: 'changecred',
            component: ChangeCred
          },
          {
            path: '/app/settings/othersetts',
            name: 'othersetts',
            component: OtherSetts
          },
          {
            path: '/app/settings/mycompany',
            name: 'mycompany',
            component: MyCompany
          },
          {
            path: '/app/settings/emailsetts',
            name: 'emailsetts',
            component: EmailSettings
          },
          {
            path: '/app/settings/usersconfig',
            name: 'usersconfig',
            component: UserSettings
          },
          {
            path: '/app/settings/billinginfo',
            name: 'billinginfo',
            component: BillingInfo
          },
          {
            path: '/app/settings/smssetts',
            name: 'smssetts',
            component: SmsSettings
          },
          {
            path: '/app/settings/invoiceoptions',
            name: 'invoiceoptions',
            component: InvoiceOptions
          },
          {
            path: '/app/settings/financialyears',
            name: 'financialyears',
            component: FinancialYears
          },
          {
            path: '/app/settings/currencies',
            name: 'currencies',
            component: CurrenciesSetts
          },
          {
            path: '/app/settings/taxes',
            name: 'taxes',
            component: TaxSettings
          },
        ]
      },
      {
        path: '/app/myprofile',
        name: 'appmyprofile',
        component: AppMyProfilePage
      },
      {
        path: '/app/sales',
        name: 'appsales',
        component: AppSalesPage,
        redirect: '/app/sales/quotes',
        children: [
          //quotes
          {
            path: '/app/sales/quotes',
            name: 'appsalesquotes',
            component: SalesQuotesViewerPage,
            redirect: '/app/sales/quotes/list',
            children: [
              {
                path: '/app/sales/quotes/list',
                name: 'appsalesquoteslist',
                component: SalesQuotesMonthListPage
              },
              {
                path: '/app/sales/quotes/new',
                name: 'appsalesquotesnew',
                component: SalesQuotesNewOperPage,
              },
              {
                path: '/app/sales/quotes/editor/:salesKey',
                name: 'appsalesquotesviewer',
                component: SalesQuotesOperViewerPage
              },
            ]
          },
          //sales
          {
            path: '/app/sales/sales',
            name: 'appsalessales',
            component: AppSalesViewerPage,
            redirect: '/app/sales/sales/list',
            children: [
              {
                path: '/app/sales/sales/list',
                name: 'appsalessaleslist',
                component: SalesMonthListPage
              },
              {
                path: '/app/sales/sales/new',
                name: 'appsalessalesnew',
                component: SalesNewOperPage,
              },
              {
                path: '/app/sales/sales/editor/:salesKey',
                name: 'appsalessalesviewer',
                component: SalesOperViewerPage
              },
            ]
          },
          //deliveries
          {
            path: '/app/sales/deliveries',
            name: 'appsalesdeliveries',
            component: SalesDeliveriesViewerPage,
            redirect: '/app/sales/deliveries/list',
            children: [
              {
                path: '/app/sales/deliveries/list',
                name: 'appsalesdelivlist',
                component: SalesDeliveriesMonthListPage
              },
              {
                path: '/app/sales/deliveries/editor/:orderKey',
                name: 'appsalesdelivviewer',
                component: SalesDeliveriesOperEditorPage
              },
              {
                path: '/app/sales/deliveries/:orderKey/:deliveryKey/details',
                name: 'appsalesdelivoperdetails',
                component: SalesDeliveryOperDetailsPage
              },
            ]
          },
          //payments
          {
            path: '/app/sales/payments',
            name: 'appsalespayments',
            component: SalesPaytsViewerPage,
            redirect: '/app/sales/payments/list',
            children: [
              {
                path: '/app/sales/payments/list',
                name: 'appsalespaytlist',
                component: SalesPaytsMonthListPage
              },
              {
                path: '/app/sales/payments/editor/:orderKey',
                name: 'appsalespaytiewer',
                component: SalesPaytsOperEditorPage
              },
            ]
          },
          //discounts
          {
            path: '/app/sales/discounts',
            name: 'appsalesdiscounts',
            component: SalesDiscountsViewerPage,
            redirect: '/app/sales/discounts/list',
            children: [
              {
                path: '/app/sales/discounts/list',
                name: 'appsalesdiscountslist',
                component: SalesDiscountsListPage
              },
              {
                path: '/app/sales/discounts/new',
                name: 'appsalesdiscountsnew',
                component: SalesDiscountsListPage
              },
            ]
          },
          //refund
          {
            path: '/app/sales/refund',
            name: 'appsalesrefund',
            component: RefundViewerPage,
            redirect: '/app/sales/refund/list',
            children: [
              {
                path: '/app/sales/refund/list',
                name: 'appsalesrefundlist',
                component: RefundMonthListPage
              },
              {
                path: '/app/sales/refund/new',
                name: 'appsalesrefundnew',
                component: RefundNewOperPage,
              },
              {
                path: '/app/sales/refund/editor/:refundKey',
                name: 'appsalesrefundviewer',
                component: RefundOperViewerPage
              },
              {
                path: '/app/sales/refund/payts/:refundKey/list',
                name: 'appsalesrefundpaytslist',
                component: RefundListOfPayments
              },
              {
                path: '/app/sales/refund/entries/:refundKey/list',
                name: 'appsalesrefundentrieslist',
                component: RefundListOfEntries
              },
              {
                path: '/app/sales/refund/entries/:refundKey/:stockEntryKey/details',
                name: 'appsalesrefundentriesdetails',
                component: RefundEntryDetailsPage
              },
            ]
          },          
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
