
  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';
  import DataFxs from '@/tools/data_functions';

  import Swal from "sweetalert2";
  import { ProdProduct, ProductVariantDetail } from "@/models/producttypes";
  import { Customer, TaxSett } from "@/models/managttypes";

  import { SalesOrder, SalesOrderDetail, SalesOrderPayment } from "@/models/salestypes";
  import { DiscountWithRules, SalesOrderInfo } from "@/models/notdb";

  import SalesOrderDetailModal from '@/components/modals/SalesOrderDetailModal.vue';
  import SalesOrderPaytModal from '@/components/modals/SalesOrderPaytModal.vue';



  const language =GetLanguage(),
    appFxs = GeneralFx(),
    dataFxs = DataFxs();
  const fNewDate = new Date(),
    year = fNewDate.getFullYear();

  const emptySalesOrder:SalesOrder = {
    IdOrder: 0,
    OrderKey: '',
    OrderNbr: '',
    ClientKey: '',
    OperDate: fNewDate,
    UserEmail: '',
    OrderStatus: 0,
    PaytStatus: 0,
    DeliveryStatus: 0,
    DeviseISO: '',
    OfficeCode: '',
    OrderNote: '',
    OrderBarcode: '',
    OrderQRCode: '',
    OrderCreatedBy: '',
    FiscalYear: 0,
    TempoKey: '',
  };
  const emptySalesDetail: SalesOrderDetail = {
    IdOrderDet: 0,
    OrderKey: '',
    OrderDetailKey: '',
    ProductSKU: '',
    ProductOrient: 0,
    VariantValues: '',
    Quantity: 1,
    UnitPrice: 1,
    SubTotal: 1,
    DiscountAmount: 0,
    TaxableAmount: 0,
    TaxKey: '',
    TaxRate: 0,
    TaxValue: 0,
    TotalAmt: 0,
    FiscalYear: year,
    TempoKey: '',
    DiscountKeys: '',
  };
  const emptySalesPayt: SalesOrderPayment = {
    IdOrderPayt: 0,
    OrderKey : '',
    OrderPaytKey: '',
    ReceiptNbr: '',
    AmtPaid: 0,
    CashierEmail: '',
    PaytType: 0,
    PaytDate: new Date(),
    PaytBarcode: '',
    PaytQRCode: '',
  }

  
  export default defineComponent({
    name: 'SalesNewOps',
    components: {
      SalesOrderDetailModal,
      SalesOrderPaytModal,
    },
    data(){
      return {
        appFxs,
        language,        
        isStillLoading: true,
        itemSearchKey: '',
        itemsPerPage: 5,
        selectedYear: year,
        currCode: 'RWF',
        userEmail: '',

        currentCustomer: null as Customer|any,
        currentSalesOper: {...emptySalesOrder} as SalesOrder,
        listOfSalesOrderDetails: [] as SalesOrderDetail[],
        itemsPageElts: [] as SalesOrderDetail[],

        isSavingRecord: false,



        listOfProducts: [] as ProdProduct[],
        lstOfProdVariantValues: [] as ProductVariantDetail[],
        listOfDiscountWithRules: [] as DiscountWithRules[],
        listOfTaxSettings: [] as TaxSett[],

        savedSalesOperKey: '',
        salesOrderInfo: null as SalesOrderInfo|any,

        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 8,
          ReportTitle: language.list_of_brands
        } as any,
        printExtraData: {},


        isSalesDetailVis: false,
        salesDetInitVals: {...emptySalesDetail} as SalesOrderDetail,
        salesDetRecStatus: 0,

        isSalesPaytVis: false,
        salesPaytInitVals: {...emptySalesPayt} as SalesOrderPayment,
        salesPaytRecStatus: 0,
        salesRemAmt: 0,
      }
    },
    computed: {      
      filteredRecordItems(): SalesOrderDetail[] {
        let searchKey = this.itemSearchKey;

        if (searchKey.length === 0) return this.listOfSalesOrderDetails;
        searchKey = searchKey.toLowerCase();

        const filterd = this.listOfSalesOrderDetails.filter(oDev => {
          return oDev.ProductSKU.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
      relatedPurchase(): { bReady: boolean, record: SalesOrder } {
        const bOReady = this.currentSalesOper.ClientKey.length > 0;


        return {
          bReady: bOReady,
          record: this.currentSalesOper,
        }
      },
      getCustomerName(): string {
        return this.currentCustomer == null ? '' : (this.currentCustomer as Customer).ClientName;
      },
      getTotalAmt(): number{
        const lst = this.listOfSalesOrderDetails;
        const total = lst.reduce((sum, curr) => sum + curr.TotalAmt, 0);
        return total;
      },
    },
    methods: {
      saveRecordData(){
        appFxs.showConfirm(language.save, language.save_sales_oper_qst_msg)
        .then(vRes => {
          if(vRes.isConfirmed){
            this.saveRecordDataFinal();
          }
        });
      },
      saveRecordDataFinal(){
        appFxs.showAjaxLoader();
        const oSales  = appFxs.getProxyData(this.currentSalesOper) as SalesOrder;
        const oDetails  = appFxs.getProxyData(this.listOfSalesOrderDetails) as SalesOrderDetail[];
        NetServices.requestPost('salesorders/sales/savesalesoperationrec',{
          salesOrderRecord: oSales,
          salesOrderDetails: oDetails,
        }).then(oRes => {
          if(!oRes.bReturn){
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }
          const currentOrderKey = oRes.currentOrderKey!;
          this.savedSalesOperKey = currentOrderKey;

          Swal.close();

          if(oRes.typeReturn == 1){
            router.replace({path: '/app/sales/sales/list', replace: true});
            return;
          }
          
          this.salesOrderInfo = oRes.salesOrderInfo!;
          console.log('oRes', oRes);
          appFxs.showConfirm(language.payment, language.sales_orderprocess_payt_qst)
            .then(vRes => {
              if(vRes.isConfirmed){
                this.processPaymentForSales();
              }else{
                this.processDeliveryForSales();
              }
            });
        });
      },
      processPaymentForSales(){
        const oSalesOrder = this.salesOrderInfo as SalesOrderInfo;
        console.log('oSalesOrder.RemainingToPay', oSalesOrder.RemainingToPay);
        const fValues = {
          ...emptySalesPayt, 
          OrderKey: oSalesOrder.SalesOrderRecord.OrderKey,
          ReceiptNbr: ('RCPT' + appFxs.generateTextNbr(7)).toUpperCase(),
          AmtPaid: oSalesOrder.RemainingToPay,
          CashierEmail: this.userEmail,
          PaytType: 0,
          PaytDate: oSalesOrder.SalesOrderRecord.OperDate,
          PaytBarcode:appFxs.getRandomDigits(12),
          PaytQRCode:appFxs.getRandomDigits(15),
        } as SalesOrderPayment;
        this.salesPaytInitVals = fValues;
        this.salesPaytRecStatus = 0;
        this.salesRemAmt = oSalesOrder.RemainingToPay;
        this.isSalesPaytVis = true;
      },
      onClosePaytModal(evt: any){
        if (evt.returnType == 0) {
          this.isSalesPaytVis = false;
          router.push({
            name: 'appsalessales',
          });
          return;
        }

        this.isSalesPaytVis = false;

        this.processDeliveryForSales();
      },
      processDeliveryForSales(){
        appFxs.showConfirm(language.delivery, language.sales_orderprocess_delivery_qst)
            .then(vRes => {
              if(vRes.isConfirmed){
                this.processDeliveryForSalesFinal();
              }else{
                router.push({
                  name: 'appsalessales',
                });
              }
            });
      },
      processDeliveryForSalesFinal(){
        const oSalesOrder = this.salesOrderInfo as SalesOrderInfo;
        appFxs.showAjaxLoader();
        NetServices.requestPost('salesorders/deliveries/savedirectdeliveryoper', {
          salesOperKey: oSalesOrder.SalesOrderRecord.OrderKey
        })
        .then(oRes => {
          if(!oRes.bReturn){
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
            .then(vRes => {
              router.push({
                name: 'appsalessales',
              });
            })
            return;
          }

          Swal.close();
          router.push({
            name: 'appsalessales',
          });
        });
      },





      getProductName(oDetail: SalesOrderDetail): string{
        const prodType = oDetail.ProductOrient;
        const lstProds = this.listOfProducts;
        const lstVars = this.lstOfProdVariantValues;
        
        const oneProduct = lstProds.find(o => o.SKU == oDetail.ProductSKU);

        if(!oneProduct) {
          return '';
        }

        if(prodType==0) return oneProduct.Name;

        const oVar = lstVars.find(o => o.VariantValues == oDetail.VariantValues);
        if(!oVar)return oneProduct.Name;

        return `${oneProduct.Name} (${oVar.VariantValues})`;
      },
      addSalesOrderDetails(iStatus: number, oRecord: SalesOrderDetail|any){
        if(iStatus == 0){
          const fTempo = {...emptySalesDetail} as SalesOrderDetail;
          fTempo.OrderKey = this.currentSalesOper.OrderKey;
          fTempo.OrderDetailKey = appFxs.generateUUID();
          fTempo.ProductOrient = 0;
          fTempo.FiscalYear = this.selectedYear;
          fTempo.TempoKey = this.currentSalesOper.TempoKey;

          this.salesDetInitVals = fTempo;
        }else{
          this.salesDetInitVals = {...oRecord} as SalesOrderDetail;
        }

        this.isSalesDetailVis = true;
      },
      onDetailClose(evt: any) {
        if (evt.returnType == 0) {
          this.isSalesDetailVis = false;
          return;
        }

        this.isSalesDetailVis = false;

        const finalRet = appFxs.getProxyData(evt.returnData);

        /* const oLst = [...this.listOfSalesOrderDetails];
        oLst.push(finalRet as SalesOrderDetail);
        this.listOfSalesOrderDetails = oLst; */

        this.insertOrUpdateSalesDetail(finalRet as SalesOrderDetail);
      },
      insertOrUpdateSalesDetail(oDetail: SalesOrderDetail){
        const existings = [...this.listOfSalesOrderDetails];
        const oneFound = existings.find(o => 
          o.ProductSKU == oDetail.ProductSKU && 
          o.VariantValues == oDetail.VariantValues);
        console.log('oneFound', oneFound);

        let bExist = oneFound != undefined;
        

        if(!bExist){
          existings.push(oDetail);
          this.listOfSalesOrderDetails = existings;
        }else{
          const toUpdates = existings.filter(o => {
            const bChecker = o.ProductSKU == oDetail.ProductSKU && 
                            o.VariantValues == oDetail.VariantValues;
            return !bChecker;
          });
          const oSelectedProd = this.listOfProducts.find(o => 
            o.SKU == oDetail.ProductSKU);
          if(oSelectedProd == undefined)return;
          if(oneFound == undefined) return;
          const finalProduc = oSelectedProd as ProdProduct;
          const finalFound = oneFound as SalesOrderDetail;
          console.log('finalProduc', finalProduc);
          const newUpdateRec = dataFxs.getNewSalesDetailOper(
            this.listOfDiscountWithRules,
            this.listOfTaxSettings,
            finalProduc,
            this.currentSalesOper,
            finalFound,
            oDetail
            );

            toUpdates.push(newUpdateRec);
            this.listOfSalesOrderDetails = toUpdates;
        }
      },

      deleteSalesDetail(oRecord: SalesOrderDetail){
        appFxs.showConfirm(language.delete, language.delete_quest_rec)
          .then(vRes => {
            if(vRes.isConfirmed){
              this.deleteSalesDetailFinal(oRecord);
            }
          })
      },
      deleteSalesDetailFinal(oRecord: SalesOrderDetail){
        const fRemains = this.listOfSalesOrderDetails.filter(o => o.OrderDetailKey != oRecord.OrderDetailKey);
        this.listOfSalesOrderDetails = fRemains;
      },
      refreshList(){},
      onPageChange(evt: any){
            this.itemsPageElts = appFxs.getProxyData(evt);
      },
      
      
      printListItems(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.list_of_brands;
        dPrint.ReportToPrint = 12;

        this.printInitialValues = dPrint;

        this.isPrintvisible = true;
      },      
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },
    },
    mounted(){   
      document.title = this.language.new_sales_oeration + ' - ' + this.language.appName;
      this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
      this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
      this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
      this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));

      const operState = appFxs.getLocalStorageByKey(appDeclarations.salesNewOperationState, '0');

      if(operState == '0'){
        appFxs.showAlert(language.missing_settings, language.missing_settings_msg)
        .then(vRes => {
          router.push({
            name: 'appsalessaleslist',
          });
        });
        return;
      }

      const tempoSalesRecord = JSON.parse(appFxs.getLocalStorageByKey(appDeclarations.tempoSalesRecord, '{}'));
      const tempoLstProducts = appFxs.getLocalStorageByKey(appDeclarations.tempoLstProducts, '[]');
      const tempoLstVariants = appFxs.getLocalStorageByKey(appDeclarations.tempoLstVariants, '[]');
      const tempoCustomerRec = appFxs.getLocalStorageByKey(appDeclarations.tempoCustomerRecord, '{}');
      
      const tempoLstTaxes = appFxs.getLocalStorageByKey(appDeclarations.tempoLstTaxes, '[]');
      const tempoLstDiscountsWRules = appFxs.getLocalStorageByKey(appDeclarations.tempoLstDiscountsWRules, '[]');

      const tempoSalesRecordF = {...tempoSalesRecord, OperDate: new Date(tempoSalesRecord.OperDate)};

      this.currentSalesOper = tempoSalesRecordF as SalesOrder;
      this.listOfProducts = JSON.parse(tempoLstProducts) as ProdProduct[];
      this.lstOfProdVariantValues = JSON.parse(tempoLstVariants) as ProductVariantDetail[];
      
      this.listOfTaxSettings = JSON.parse(tempoLstTaxes) as TaxSett[];
      this.listOfDiscountWithRules = JSON.parse(tempoLstDiscountsWRules) as DiscountWithRules[];

      this.currentCustomer = JSON.parse(tempoCustomerRec) as Customer;

      appFxs.removeLocalStorageByKey(appDeclarations.tempoSalesRefund);
      appFxs.removeLocalStorageByKey(appDeclarations.tempoSalesRecord);
      appFxs.removeLocalStorageByKey(appDeclarations.tempoLstProducts);
      appFxs.removeLocalStorageByKey(appDeclarations.tempoLstVariants);
      appFxs.removeLocalStorageByKey(appDeclarations.tempoCustomerRecord);
      
      appFxs.setLocalStorage(appDeclarations.salesNewOperationState, '0');
      this.isStillLoading = false;

    }
  })
