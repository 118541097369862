
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent, toRaw } from 'vue';
    import { AppDevise } from '@/models/managttypes';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { CurrencyCode, ShortData } from '@/tools/smarttypes';

    import appDeclarations from '@/tools/declarations';

    const language =GetLanguage(),
        appFxs = GeneralFx();

    const emptyDevise: AppDevise = {
        IdDevise: 0,
        DevISO: 'RWF',
        DevName: '',
        DevSigle: '',
        TypeCalcTaux: 0,
        TauxCotation: 0,
        DevUnit: '',
        DevSubUnit: '',
        DeviseCot: '',
        CoursPeriod: 0,
        CoursCloture: 0,
        DevFormat: '',
    };
    export default defineComponent({
        name: 'CurrencySettModal',
        emits: ['closeForm'],
        props: ["visible", "initialValues", "recordStatus", "listOfDeviseCot"],
        data(){
            return {
                v$: useVuelidate(),
                language,
                acctTypes: [
                    { id: 0, label: language.general_sending },
                    { id: 1, label: language.marketing },
                ] as ShortData[],
                dataRecord: {...emptyDevise},
                typeCot: [
                    { id: 0, label: language.certain },
                    { id: 1, label: language.uncertain },
                ] as ShortData[],
                listOfNetCurrencies: [] as CurrencyCode[],
                listOfDeviseCots: [] as AppDevise[],
            }
        },
        computed: {
            isDevCotSet(): boolean{
                let rec = this.dataRecord;
                return rec.DeviseCot.length > 0;
            },
            setTextDevCot(): string{
                const ret = this.dataRecord;
                const bCert = ret.TypeCalcTaux === 0;
                const bSet = ((ret.DeviseCot) && (ret.DeviseCot.length));
                const lstDev = this.listOfDeviseCot as AppDevise[];
                let sReturn = '';

                if (!bSet) {
                    return '';
                }
                if (!(lstDev.length > 0)) {
                    return '';
                }
                const devCot = appFxs.filterArrayByField(lstDev, 'DevISO', ret.DeviseCot, null);
                if (!devCot) {
                    return '';
                }

                sReturn = (bCert) ?
                    `1 <strong>${devCot.DevName}</strong> = ${ret.TauxCotation} <strong>${ret.DevName}</strong>` :
                    `1 <strong>${ret.DevName}</strong> = ${ret.TauxCotation} <strong>${devCot.DevName}</strong>`;


                return sReturn;
            },
            windowTitle():string {
                return this.recordStatus === 0 ? language.add_currency:
                language.edit_currency;
            }
        },
        methods: {
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.$emit('closeForm', {returnType: 1, returnData: this.dataRecord});
            },
            onCurrencyChange(){
                const oRec = this.dataRecord;
                if(oRec.IdDevise ! =0)return;
                const lst = this.listOfNetCurrencies;
                const selCurr = appFxs.filterArrayByField(lst, 'code', oRec.DevISO, null);
                if (!selCurr) return;
                if (selCurr.symbol_native) { this.dataRecord.DevSigle = selCurr.symbol_native; }
                if (selCurr.name) { this.dataRecord.DevName = selCurr.name; }
            }
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: AppDevise = {...oValues};

                        
                        const devCots = appFxs.getProxyData(this.listOfDeviseCot);
                        const lValues: AppDevise[] = [...devCots] as AppDevise[];

                        if(this.recordStatus == 0){
                            this.v$.$reset();
                        }

                        this.dataRecord = fValues;

                        let finalDevCots = [] as AppDevise[];

                        if(this.recordStatus == 1){                            
                            finalDevCots = lValues.filter(o => o.DevISO != this.dataRecord.DevISO);
                        }else{
                            finalDevCots = [...lValues];
                        }


                        this.listOfDeviseCots = finalDevCots;
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {
                    DevSigle: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    DevName: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    DevUnit: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    TauxCotation: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    CoursPeriod: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    CoursCloture: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    }
                }
            }
        },
        mounted(){
            const fListDev = appFxs.getLocalStorageByKey(appDeclarations.currencySymbols, '[]');
            const finalDevs = JSON.parse(fListDev) as CurrencyCode[];
            this.listOfNetCurrencies = finalDevs;
        }
        
    
    })
