import { ManagtUser } from "@/models/managttypes";

export type SayingType = {
    say: string;
    author: string;
}
export type BgType  = {
    ['background-image']: string;
}
export type PadLeftStType  = {
    ['padding-left']: string;
}
export type WidthCssStyle  = {
    width: string;
}
export type SayElt = {
    saying: SayingType;
    bg: BgType;
}
export type PasswordStyle = {
    width: string;
    bgMeterClass: string;
    infoClass: string;
}
export type PasswordStrengthType = {
    value: number;
    message: string;
    length: number;
}

export type HeaderType = {
    'user-access-key': string;
    lang: string;
    'company-code': string;
    'client-operation-date': string;
    'year-exercise-app': string;
    'default-sms-account': string;
    'current-devise-val': string;
    'current-branch-val': string;
    'user-email-value': string;
    'user-password-value': string;
}

export type TelCode = {
    name: string;
    dial_code: string;
    code: string;
}

export type CurrencyCode = {
    symbol: string;
    name: string;
    symbol_native: string;
    decimal_digits: number;
    rounding: number;
    code: string;
    name_plural: string;
}

export type TimeZoneCode = {
    value: string;
    abbr: string;
    offset: number;
    isdst: boolean;
    text: string;
    utc: string[];
}

export type ShortData = {
    id: number;
    label: string;
}

export type UserRow = {
    User: ManagtUser;
    IsExpended: boolean;
}

export enum DateOperation {
    Day,
    Month,
    Year,
};

export type JoinRecord = {
    JoinKey: string;
    Password: string;
    ConfirmPassword: string;
}

export type PrintRecType = {
    ReportTitle: string;
    TypeSoft: number;
    ReportToPrint: number;
    StylePrint: number;
    EmailPrint: string;
    LangPrint: string;
    EmailReceiver: string;
    EmailSubject: string;
    EmailMessage: string;
};








