
    import { defineComponent } from 'vue';
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import AppDeclarations from '@/tools/declarations';
    import { DeliveryRemainingData, SalesRefundDetailPrep } from '@/models/notdb';

    const language = GetLanguage(),
        appFxs = GeneralFx();

    export default defineComponent({
        name: 'RefunInputData',
        emits: ['onUpdate'],
        props: [
            "refundDataRecord"
        ],
        data(){
            return{
                language,
                appFxs,
                inputValue: this.refundDataRecord as SalesRefundDetailPrep,
            };
        },
        computed: {
            isAddPossible(): boolean{
                return this.inputValue.RefundNowQty < 
                this.inputValue.RemainingQuantity;
            },
            isSubPossible(): boolean{
                return this.inputValue.RefundNowQty > 0;
            },
            finalValue(): SalesRefundDetailPrep {
                const nowQty = this.inputValue.RefundNowQty;
                const nowPrice = this.inputValue.RefundNowPrice;
                const nowSubTotal = nowQty * nowPrice;
                const nowTaxAmtUnit = this.inputValue.RefundNowTaxUnit;
                const nowTaxTotal = nowQty * nowTaxAmtUnit;
                const nowTotalAmt = nowSubTotal + nowTaxTotal;
                
                const final = {
                    ...this.inputValue,
                    RefundNowSubTotal: nowSubTotal,
                    RefundNowTaxAmt: nowTaxTotal,
                    RefundNowTotalAmt: nowTotalAmt,
                } as SalesRefundDetailPrep;

                return final;
            }
        },
        methods: {

            subOper(){
                this.inputValue.RefundNowQty -= 1;
                this.inputValue = this.finalValue;

                this.$emit('onUpdate', this.finalValue);
            },
            addOper(){
                this.inputValue.RefundNowQty += 1;
                this.inputValue = this.finalValue;

                this.$emit('onUpdate', this.finalValue);
            },
            changeManualValue(){
                const oVal = this.inputValue.RefundNowQty;
                if(oVal > this.inputValue.RemainingQuantity)  {
                    this.inputValue.RefundNowQty = this.inputValue.RemainingQuantity;
                }
                this.inputValue = this.finalValue;
                this.$emit('onUpdate', this.finalValue);
            }
        },
        watch: {
            remainingDataRecord: {
                handler(newV, oldV) {
                    if (newV !== oldV) {
                        this.inputValue = newV as SalesRefundDetailPrep;
                    }
                },
            },
        },
    })
