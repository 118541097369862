import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "modal-dialog wise-modal" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { for: "ProductSku" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { value: "" }
const _hoisted_10 = ["value"]
const _hoisted_11 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_12 = { for: "ProductVariantKey" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = { value: "" }
const _hoisted_15 = ["value"]
const _hoisted_16 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_17 = { for: "Quantity" }
const _hoisted_18 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_19 = { for: "Price" }
const _hoisted_20 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_21 = { for: "TotalPrice" }
const _hoisted_22 = {
  id: "TotalPrice",
  class: "form-control numeric"
}
const _hoisted_23 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_24 = { for: "Description" }
const _hoisted_25 = ["disabled", "placeholder"]
const _hoisted_26 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_27 = { class: "modal-footer" }
const _hoisted_28 = { class: "d-flex justify-content-end" }
const _hoisted_29 = ["disabled"]
const _hoisted_30 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_numeric_fld_input = _resolveComponent("numeric-fld-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'d-none': !_ctx.visible}, "modal fade show"]),
    style: {"display":"block"}
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.windowTitle), 1),
          (!_ctx.isSavingRecord)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args))),
                type: "button",
                class: "btn-close"
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.ProductSku.$error}])
            }, [
              _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.language.product), 1),
              _withDirectives(_createElementVNode("select", {
                id: "ProductSku",
                name: "ProductSku",
                class: "form-control-sel",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataRecord.ProductSku) = $event)),
                disabled: _ctx.isSavingRecord || _ctx.dataRecord.IdStockExit != 0,
                onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onProductChange && _ctx.onProductChange(...args)))
              }, [
                _createElementVNode("option", _hoisted_9, _toDisplayString(_ctx.language.select_product), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (oProd) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: oProd.SKU,
                    value: oProd.SKU
                  }, _toDisplayString(oProd.Name), 9, _hoisted_10))
                }), 128))
              ], 40, _hoisted_8), [
                [_vModelSelect, _ctx.dataRecord.ProductSku]
              ]),
              (_ctx.v$.dataRecord.ProductSku.$error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.v$.dataRecord.ProductSku.$errors[0].$message), 1))
                : _createCommentVNode("", true)
            ], 2),
            (_ctx.dataRecord.ProductType == 1)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["col-md-6 form-group", {'has-error': !_ctx.isVariantSet}])
                }, [
                  _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.language.variant), 1),
                  _withDirectives(_createElementVNode("select", {
                    id: "ProductVariantKey",
                    name: "VariantSku",
                    class: "form-control-sel",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataRecord.ProductVariantKey) = $event)),
                    disabled: _ctx.isSavingRecord || _ctx.dataRecord.IdStockExit != 0,
                    onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onVariantChange && _ctx.onVariantChange(...args)))
                  }, [
                    _createElementVNode("option", _hoisted_14, _toDisplayString(_ctx.language.select_variant), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.specificVariants, (oVariant) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: oVariant.SKU,
                        value: oVariant.VariantValues
                      }, _toDisplayString(oVariant.VariantValues), 9, _hoisted_15))
                    }), 128))
                  ], 40, _hoisted_13), [
                    [_vModelSelect, _ctx.dataRecord.ProductVariantKey]
                  ]),
                  (!_ctx.isVariantSet)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.language.fldRequired), 1))
                    : _createCommentVNode("", true)
                ], 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.Quantity.$error}])
            }, [
              _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.language.qty), 1),
              _createVNode(_component_numeric_fld_input, {
                modelValue: _ctx.dataRecord.Quantity,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dataRecord.Quantity) = $event)),
                classes: 'form-control',
                placeholder: '(' + _ctx.language.qty.toLowerCase() + ')',
                disabled: _ctx.isSavingRecord || _ctx.dataRecord.IdStockExit != 0,
                id: "Quantity",
                maxlength: 15,
                onChange: _ctx.changeSousTotalRecs
              }, null, 8, ["modelValue", "placeholder", "disabled", "onChange"]),
              (_ctx.v$.dataRecord.Quantity.$error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.v$.dataRecord.Quantity.$errors[0].$message), 1))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.Price.$error}])
            }, [
              _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.language.price), 1),
              _createVNode(_component_numeric_fld_input, {
                modelValue: _ctx.dataRecord.Price,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dataRecord.Price) = $event)),
                classes: 'form-control',
                placeholder: '(' + _ctx.language.price.toLowerCase() + ')',
                disabled: _ctx.isSavingRecord || _ctx.dataRecord.IdStockExit != 0,
                id: "Price",
                onChange: _ctx.changeSousTotalRecs
              }, null, 8, ["modelValue", "placeholder", "disabled", "onChange"]),
              (_ctx.v$.dataRecord.Price.$error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.v$.dataRecord.Price.$errors[0].$message), 1))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-md-6 form-group", {'has-error': _ctx.v$.dataRecord.TotalPrice.$error}])
            }, [
              _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.language.total_amt), 1),
              _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.currCode) + " " + _toDisplayString(_ctx.appFxs.formatNumbers(_ctx.dataRecord.TotalPrice)), 1),
              (_ctx.v$.dataRecord.TotalPrice.$error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_ctx.v$.dataRecord.TotalPrice.$errors[0].$message), 1))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-md-12 form-group", {'has-error': _ctx.v$.dataRecord.Description.$error}])
            }, [
              _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.language.description), 1),
              _withDirectives(_createElementVNode("textarea", {
                type: "text",
                id: "Description",
                class: "form-control",
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dataRecord.Description) = $event)),
                disabled: _ctx.isSavingRecord,
                placeholder: '(' + _ctx.language.description.toLowerCase() + ')',
                maxlength: "1000",
                autocomplete: "off",
                onFocus: _cache[8] || (_cache[8] = ($event: any) => (_ctx.selectOnFocus($event))),
                rows: "3"
              }, null, 40, _hoisted_25), [
                [_vModelText, _ctx.dataRecord.Description]
              ]),
              (_ctx.v$.dataRecord.Description.$error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(_ctx.v$.dataRecord.Description.$errors[0].$message), 1))
                : _createCommentVNode("", true)
            ], 2)
          ])
        ]),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary me-2",
              onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args))),
              disabled: _ctx.isSavingRecord
            }, _toDisplayString(_ctx.language.close), 9, _hoisted_29),
            (_ctx.dataRecord.IdStockExit == 0)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.saveRecordData && _ctx.saveRecordData(...args))),
                  disabled: _ctx.v$.$invalid || _ctx.isSavingRecord
                }, [
                  (_ctx.isSavingRecord)
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 0,
                        icon: ['fa', 'fa-spinner']
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.isSavingRecord)
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 1,
                        icon: ['fa', 'fa-save']
                      }))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(_ctx.language.save), 1)
                ], 8, _hoisted_30))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ], 2))
}