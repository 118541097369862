
    import { defineComponent } from "vue";
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { ShortData } from "@/tools/smarttypes";
    import NetServices from "@/tools/netservices";
    import { DashboardSituation } from "@/models/notdb";

    const language = GetLanguage(),
        appFxs = GeneralFx(),
        fDate = new Date();

    const oDash: DashboardSituation = {
        TotalRevenuesCurrent: 0,
        TotalRevenuesPrevious: 0,
        TotalRevenuesGrowth: 0,
        TotalRevenuesStatus: 0,
        SalesComparatorCurr: [],
        SalesComparatorPrev: [],
    };
    export default defineComponent({
        name: 'DashboardEltData',
        props: {
            listclasses: {
                type: String,
                default: 'bi bi-bank'
            },
            labelText: {
                type: String,
                default: ''
            },
            hasCurrency: {
                type: Boolean,
                default: false,
            },
            currencyLabel: {
                type: String,
                default: 'RWF',
            },
            typeOperation: {
                type: Number,
                default: 0,
            },
            monthPeriod: {
                type: Number,
                default: fDate.getMonth() + 1,
            }
        },
        data(){
            return {                
                language,
                appFxs,
                valueComponent: 0,
                isLoading: true,
                monthValue: this.monthPeriod,
                dashBData: {...oDash} as DashboardSituation
            }
        },
        computed: {
            allCss():string[]{
                return this.listclasses?.split(' ');
            },
            growthTotalRevenuesLabel():string{
                const oStatus = this.dashBData.TotalRevenuesStatus;
                return oStatus == 0 ? language.zero_growth:
                    oStatus == 1 ? language.positive_growth:
                    language.negative_growth;
            }
        },
        methods: {
            populateData(){
                this.isLoading = true;
                NetServices.requestGet(`analytics/dashboard/getdashboardata/${this.monthValue}/${this.typeOperation}`)
                    .then(oRes => {
                        if(!oRes.bReturn){
                            this.isLoading = false;
                            return;
                        }

                        this.isLoading = false;
                        this.dashBData = oRes.dashboardDataInfo!;
                    });
            }
        },
        mounted(){
            this.populateData();
        },
        watch: {
            monthPeriod: {
                handler(newV, oldV) {
                    if (newV !== oldV) {
                        this.monthValue = newV as number;
                        this.populateData();
                    }
                },
            },
        },

    });
