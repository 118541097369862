
    import { defineComponent } from "vue";

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import NetServices from "@/tools/netservices";
    import { StaffDashInfo } from "@/models/notdb";

    const language = GetLanguage(),
        appFxs = GeneralFx(),
        fDate = new Date();

    export default defineComponent({
        name: 'DashboardTopTenStaff',
        components: {},
        props: {
            currentYear: {
                type: Number,
                default: fDate.getFullYear(),
            },
            monthPeriod: {
                type: Number,
                default: fDate.getMonth() + 1,
            },
            typeOperation: {
                type: Number,
                default: 0,
            },
        },
        data(){
            return {
                appFxs,
                language,
                isLoading: true,
                monthValue: this.monthPeriod,
                listOfStaffInfo:[] as StaffDashInfo[]
            }
        },
        methods: {
            populateData(){
                this.isLoading = true;
                NetServices.requestGet(`analytics/dashboard/getdashboardata/${this.monthValue}/${this.typeOperation}`)
                    .then(oRes => {
                        if(!oRes.bReturn){
                            this.isLoading = false;
                            return;
                        }

                        this.isLoading = false;
                        this.listOfStaffInfo = oRes.listOfStaffInfo!;
                    });
            }
        },
        mounted(){
            this.populateData();
        },
        watch: {
            monthPeriod: {
                handler(newV, oldV) {
                    if (newV !== oldV) {
                        this.monthValue = newV as number;
                        this.populateData();
                    }
                },
            },
        },
    })
