
  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';
  import DataFxs from '@/tools/data_functions';

  import Swal from "sweetalert2";
  import { ProdProduct } from "@/models/producttypes";
  import { Customer } from "@/models/managttypes";

  import { SalesOrder, SalesOrderDetail, SalesOrderPayment } from "@/models/salestypes";
  import { OrderPaytStatus, SalesOrderInfo } from "@/models/notdb";

  const language =GetLanguage(),
    appFxs = GeneralFx(),
    dataFxs = DataFxs();
  const fNewDate = new Date(),
    year = fNewDate.getFullYear();
 

  export default defineComponent({
    name: 'SalesPaytsList',
    components: {
    },
    data(){
      return {
        appFxs,
        language,        
        isStillLoading: true,
        itemSearchKey: '',
        itemsPerPage: 5,
        selectedYear: year,
        currCode: 'RWF',
        userEmail: '',

        lstOrderPaytSituations: [] as OrderPaytStatus[],
        itemsPageElts: [] as OrderPaytStatus[],

        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 8,
          ReportTitle: language.list_of_brands
        } as any,
        printExtraData: {},
      }
    },
    computed: {      
      filteredRecordItems(): OrderPaytStatus[] {
        let searchKey = this.itemSearchKey;

        if (searchKey.length === 0) return this.lstOrderPaytSituations;
        searchKey = searchKey.toLowerCase();

        const filterd = this.lstOrderPaytSituations.filter(oDev => {
          return oDev.SalesOrder.OrderNbr.toLowerCase().includes(searchKey) ||
            oDev.Customer.ClientName.toLowerCase().includes(searchKey) ||
            oDev.Staff.FirstName.toLowerCase().includes(searchKey) ||
            oDev.Staff.LastName.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
    },
    methods: {
      getRecordStatus(iStatus: number){
        return iStatus == 0 ? language.not_paid:
          iStatus == 1 ? language.part_paid:
          language.fully_paid;
      },

      refreshList(){
        appFxs.showAjaxLoader();
        NetServices.requestGet('salesorders/payts/getlistordersituations')
        .then(oRes => {
          if(!oRes.bReturn){
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
              .then(vRes => {
                Swal.close();
                router.replace({ path: '/app/sales', replace: true });
              });
            return;
          }

          this.lstOrderPaytSituations = oRes.lstOrderPaytSituations!;

          Swal.close();
        });
      },
      onPageChange(evt: any){
            this.itemsPageElts = appFxs.getProxyData(evt);
      },
      
      
      printListItems(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.payt_status;
        dPrint.ReportToPrint = 26;

        this.printInitialValues = dPrint;

        this.isPrintvisible = true;
      },      
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },
    },
    mounted(){   
      document.title = this.language.list_of_sales_payts + ' - ' + this.language.appName;
      this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
      this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
      this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
      this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));
      
      NetServices.requestGet('salesorders/payts/getlistordersituations')
        .then(oRes => {
          if(!oRes.bReturn){
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
              .then(vRes => {
                router.replace({ path: '/app/sales', replace: true });
              });
            return;
          }

          this.lstOrderPaytSituations = oRes.lstOrderPaytSituations!;

          this.isStillLoading = false;
        });
    }
  })
