
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent, ref } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import DataFxs from '@/tools/data_functions';
    import { AppBranch, AppDevise, AppYearBook, Company, SMSAccountSett } from "@/models/managttypes";
    import Swal from "sweetalert2";
    import { CurrencyCode, TelCode } from "@/tools/smarttypes";

    const language = GetLanguage(),
        appFxs = GeneralFx(),
        dataFxs = DataFxs();

    
    export default defineComponent({
        data(){
            return {
                v$: useVuelidate(),
                language,
                isStartLoading: true,
                myCompanyData: null as Company | any,
                lstTelCodes: [] as TelCode[],
                lstCurrencies: [] as CurrencyCode[],
                companyLogoFile: null as File | any,
            }
        },
        methods: {
            saveCompanyLogoPhoto(){
                appFxs.showAjaxLoader();

                if(!this.companyLogoFile){
                    Swal.close();
                    appFxs.showAlert(language.error,
                        language.miss_file_select_error);
                    return;
                }

                const _ff = this.companyLogoFile;

                if (!((_ff.type === 'image/png' || _ff.type === 'image/jpeg' ||
                    _ff.type === 'image/gif') && _ff.size <= (1000 * 1024))) {
                        Swal.close();
                        appFxs.showAlert(language.error,
                        language.file_selected_error);
                    return;
                }

                const oFileRec = appFxs.getFormData(null);
                oFileRec.append('file', _ff);

                NetServices.requestPostWithFile('managt/settings/savecompanylogophoto', oFileRec)
                    .then(oRes => {
                        if(!oRes.bReturn){
                            Swal.close();

                            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                            return;
                        }
                        const oFinal = {...oRes.myCompanySett,
                            DateCreated: oRes.myCompanySett!.DateCreated,
                            DateExpiryLicense: oRes.myCompanySett!.DateExpiryLicense
                        } as Company;

                        this.myCompanyData = oFinal;
                        Swal.close();
                    });
            },
            selectCompanyLogoPhotoFilePath(evt: Event){
                const files = (evt.target as HTMLInputElement).files;
                const oneFile = files![0];

                if(oneFile && oneFile.type.includes('image')){
                    const reader = new FileReader();
                    reader.readAsDataURL(oneFile);

                    reader.onloadend = (e) => {
                        (this.$refs.imageSelector as HTMLImageElement).src = e.target?.result as string
                    }

                    this.companyLogoFile = files![0];
                }
            },
            reloadPage(){
                document.location.reload();
            },
            saveMyCompanySettings(){
                appFxs.showAjaxLoader();

                const oRecord = {
                    ...this.myCompanyData,
                    DateCreated: appFxs.dateToUtcStr(this.myCompanyData.DateCreated),
                    DateExpiryLicense: appFxs.dateToUtcStr(this.myCompanyData.DateExpiryLicense),
                }

                NetServices.requestPost('managt/settings/savecompanyinfos', {
                    companyInfo: oRecord,
                }).then(oRes => {
                        if(!oRes.bReturn){
                            Swal.close();

                            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                            return;
                        }

                        const oFinal = {...oRes.myCompanySett,
                            DateCreated: oRes.myCompanySett!.DateCreated,
                            DateExpiryLicense: oRes.myCompanySett!.DateExpiryLicense
                        } as Company;

                        this.myCompanyData = oFinal;
                        Swal.close();
                    });
            },            
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
        },
        computed: {
            companyLogoPath(): string {
                const bNotReady = !this.myCompanyData.LogoPath,
                    localLogo = require('@/assets/images/logo_company_all.jpg'),
                    netLogo = bNotReady ? localLogo: `${appDeclarations.apiUrl}cloud_data/logo_images/${this.myCompanyData!.LogoPath}`
                return netLogo;
            }
        },
        validations(){
            return{
                myCompanyData: {
                    Email: {
                        required: helpers.withMessage(language.fldRequired, required), 
                        email: helpers.withMessage(language.emailFormat, email), 
                        $autoDirty: true
                    },
                    Country: {
                        required: helpers.withMessage(language.fldRequired, required), 
                        $autoDirty: true
                    },
                    AcctCurrency: {
                        required: helpers.withMessage(language.fldRequired, required), 
                        $autoDirty: true
                    },
                    MobilePhone: {
                        required: helpers.withMessage(language.fldRequired, required), 
                        minLength: helpers.withMessage(language.phonenbrLengthMsg, minLength(12)), 
                        maxLength: helpers.withMessage(language.phonenbrLengthMsg, maxLength(12)), 
                        $autoDirty: true
                    },
                    Fax: {
                        minLength: helpers.withMessage(language.phonenbrLengthMsg, minLength(12)), 
                        maxLength: helpers.withMessage(language.phonenbrLengthMsg, maxLength(12)), 
                        $autoDirty: true
                    },
                    OfficePhone: {
                        minLength: helpers.withMessage(language.phonenbrLengthMsg, minLength(12)), 
                        maxLength: helpers.withMessage(language.phonenbrLengthMsg, maxLength(12)), 
                        $autoDirty: true
                    },
                }
            }
        },
        mounted(){
            const fDate = new Date();
            document.title = `${this.language.settings}/${this.language.myCompany} - ${this.language.appName}`;

            const oCtries = appFxs.getLocalStorageByKey(appDeclarations.telephonesCodes, '[]');
            const oCounties = JSON.parse(oCtries) as TelCode[];
            
            const oCurrs = appFxs.getLocalStorageByKey(appDeclarations.currencySymbols, '[]');
            const oCurrencies = JSON.parse(oCurrs) as CurrencyCode[];

            this.lstTelCodes = oCounties;
            this.lstCurrencies = oCurrencies;

            NetServices.requestGet('managt/settings/getcompanyprepare')
            .then(oRes => {
                if(!oRes.bReturn){
                    appFxs.showAlert(oRes.msgTitle, oRes.msgBody).then(v => {
                        router.replace({path: '/app', replace: true});
                    });
                    return;
                }
                

                const myCompany = oRes.myCompanySetting!;                    
                const oFinal = {
                    ...myCompany, 
                    DateCreated: new Date(myCompany?.DateCreated!), 
                    DateExpiryLicense: new Date(myCompany?.DateExpiryLicense!)
                };

                this.myCompanyData = oFinal;

                

                this.isStartLoading = false;
            });
        }
    })
