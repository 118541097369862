
    import { defineComponent } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { AppDevise } from "@/models/managttypes";
    import Swal from "sweetalert2";

    const language =GetLanguage(),
    appFxs = GeneralFx();

    import CurrencySettModal from "@/components/modals/CurrencySettModal.vue";
    import { CurrencyCode } from "@/tools/smarttypes";

    const emptyDevise: AppDevise = {
        IdDevise: 0,
        DevISO: 'RWF',
        DevName: '',
        DevSigle: '',
        TypeCalcTaux: 0,
        TauxCotation: 0,
        DevUnit: '',
        DevSubUnit: '',
        DeviseCot: '',
        CoursPeriod: 0,
        CoursCloture: 0,
        DevFormat: '',
    };

    export default defineComponent({
        components: {
            CurrencySettModal,
        },
        data(){
            return {
                language,
                isStillLoading: true,
                itemsPerPage: 5,
                deviseSearchKey: '',
                listOfCurrencies: [] as AppDevise[],
                currenciesPageElts: [] as AppDevise[],
                searchKey: '',


                isDialogOpen: false,
                dialogRecordStatus: 0,
                dialogRecordData: (emptyDevise as AppDevise),
                listOfNetCurrencies: [] as CurrencyCode[],

                isPrintvisible: false,
                printInitialValues: {...appFxs.getReportingData()} as any,
                printExtraData: {}
            }
        },
        computed: {
            filteredCurrencies(): AppDevise[] {
                let searchKey = this.deviseSearchKey;

                if(searchKey.length === 0) return this.listOfCurrencies;
                searchKey = searchKey.toLowerCase();
                
                const filterd = this.listOfCurrencies.filter(oDev => {
                    return oDev.DevISO.toLowerCase().includes(searchKey) || 
                        oDev.DevName.toLowerCase().includes(searchKey) || 
                        oDev.DevUnit.toLowerCase().includes(searchKey) || 
                        oDev.DevSubUnit.toLowerCase().includes(searchKey) || 
                        oDev.DevSigle.toLowerCase().includes(searchKey);
                });
                return filterd;
            },

        },
        methods: {
            onPageCange(evt: any){
                this.currenciesPageElts = appFxs.getProxyData(evt);
            },
            openDeviseCurrSett(recStatus: number, data: AppDevise | any){
                if(recStatus===0){
                    const fNew = {...emptyDevise};
                    this.dialogRecordData = (fNew as AppDevise);
                    this.dialogRecordStatus = 0;
                }else{
                    this.dialogRecordData = data!;
                    this.dialogRecordStatus = 1;
                }
                
                this.isDialogOpen = true;
            },
            deleteDeviseRecSett(data: AppDevise){
                appFxs.showConfirm(language.delete, language.delete_email_actt_qst)
                    .then(oResp => {
                        if(!oResp.isConfirmed)return;
                        this.finalDeleteDeviseSett(data);
                    });
            },
            finalDeleteDeviseSett(data: AppDevise){
                appFxs.showAjaxLoader();

                NetServices.requestPost('managt/settings/savecurrencyrecord', {
                    currencySettRecord: data,
                    toDelete: true,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: AppDevise[] = [];
                    oRes.listOfCurrencies!.forEach(oEmail => {
                        const fEmail = {...oEmail}
                        fList.push(fEmail);
                    });

                    this.listOfCurrencies = fList;
                    
                    Swal.close();
                });
            },
            startGetData(){
                appFxs.showAjaxLoader();

                NetServices.requestGet('managt/settings/getlistcurrencies')
                .then(oRes => {
                    if(!oRes.bReturn){
                            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                            .then(xRes => {
                                //router.replace({path: '/app/myprofile', replace: true});
                                Swal.close();
                            });
                            return;
                        }

                    const fList: AppDevise[] = [];
                    oRes.listOfCurrencies!.forEach(oEmail => {
                        const fEmail = {...oEmail}
                        fList.push(fEmail);
                    });

                    this.listOfCurrencies = fList;
                    Swal.close();
                });
            },
            onCloseForm(evt: any){
                this.isDialogOpen = false;

                if(evt.returnType===0)return;

                const returnData = appFxs.getProxyData(evt.returnData);
                appFxs.showAjaxLoader();

                NetServices.requestPost('managt/settings/savecurrencyrecord/', {
                    currencySettRecord: returnData
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: AppDevise[] = [];
                    oRes.listOfCurrencies!.forEach(oEmail => {
                        const fEmail = {...oEmail}
                        fList.push(fEmail);
                    });

                    this.listOfCurrencies = fList;
                    
                    Swal.close();
                });
            },

            printListItems(){
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = language.list_of_currencies;
                dPrint.ReportToPrint = 1;

                this.printInitialValues = dPrint;


                this.isPrintvisible = true;
            },
            onPrintClose(evt: any){                
                this.isPrintvisible = false;
                if(evt.returnType===0)return;
            }
        },
        mounted(){
            document.title = `${this.language.settings}/${this.language.currencies} - ${this.language.appName}`;
            const fNew = {...emptyDevise};
            this.dialogRecordData = (fNew as AppDevise);

            const fListDev = appFxs.getLocalStorageByKey(appDeclarations.currencySymbols, '[]');
            const finalDevs = JSON.parse(fListDev) as CurrencyCode[];
            this.listOfNetCurrencies = finalDevs;

            this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));

            NetServices.requestGet('managt/settings/getlistcurrencies')
                .then(oRes => {
                    if(!oRes.bReturn){
                            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                            .then(xRes => {
                                router.replace({path: '/app/myprofile', replace: true});
                            });
                            return;
                        }

                    const fList: AppDevise[] = [];
                    oRes.listOfCurrencies!.forEach(oEmail => {
                        const fEmail = {...oEmail}
                        fList.push(fEmail);
                    });

                    this.listOfCurrencies = fList;
                    
                    this.isStillLoading = false;
                });
        }
    })
