
  import { useVuelidate } from '@vuelidate/core'
  import { required, email, helpers, minLength } from '@vuelidate/validators'
  import { defineComponent, ref } from "vue";
  import {RouterLink } from 'vue-router';
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';
  import { TelCode } from '@/tools/smarttypes';
  import { ManagtUser } from '@/models/managttypes';
import { NetReturn } from '@/models/netreturn';

  
const language =GetLanguage(),
    appFxs = GeneralFx();

export default defineComponent ({
  data(){
    return {
      v$: useVuelidate(),
      language,
      countries: [] as TelCode[],
      userRecord: {
        UserId: 0,
        Email: '',
        Password: '',
        FirstName: '',
        LastName: '',
        DisplayName: '',
        JobTitle: '',
        UserBio: '',
        MobilePhone: '',
        Sex: 0,
        DOB: new Date(),
        FacebookAccount: '',
        TwitterAccount: '',
        LinkedInAccount: '',
        KeyRequestInvite: '',
        TelephoneImei: '',
        SerialNumber: '',
        PhotoPath: '',
        Country: 'RW',
        City: '',
        UserActive: false,
        WaitForResp: false,
        ReceiveWiseEmail: false,
        IsMainAdmin: false,
        IsAdmin: false,
      } as ManagtUser,
      fileToUpload: null as File | any,
      filePath: require('@/assets/images/user-tempo.png'),
      isSavingRecord: false,
    }
  },
  computed: {
    isImageNetwork(): boolean{
      return this.userRecord.PhotoPath.length > 0;
    },
    imageFilePath(): string{
      const localPath = require('@/assets/images/user-tempo.png');
      return !this.userRecord.PhotoPath ? localPath : `${appDeclarations.apiUrl}cloud_data/managt_profiles/${this.userRecord.PhotoPath}`;
    }
  },
  methods: {
    onFileChange(evt: Event){
      const files = (evt.target as HTMLInputElement).files;
      const oneFile = files![0];

      if(oneFile && oneFile.type.includes('image')){
        const reader = new FileReader();
        reader.readAsDataURL(oneFile);

        reader.onloadend = (e) => {
          (this.$refs.imageSelector as HTMLImageElement).src = e.target?.result as string
        }

        this.fileToUpload = oneFile;
      }
      
    },
    selectFile(): void{
      (this.$refs.fileSelector as HTMLInputElement).click();
    },
    selectOnFocus(evt: Event){
      const oInput = evt.target as HTMLInputElement;
      oInput.select();
    },
    dateToYYYYMMDD(d: Date) {
    	return d && new Date(d.getTime()-(d.getTimezoneOffset()*60*1000)).toISOString().split('T')[0]
    },
    setDobChange(evt: Event){
      this.userRecord.DOB = (evt.target as HTMLInputElement).valueAsDate!;
    },
    saveMyProfileRecord(){
      this.isSavingRecord = true;
      let oRet: Promise<NetReturn>;

      if(!this.fileToUpload){
        oRet = NetServices.requestPost('managt/profile/savemyprofiledata',{
          managtPostedUser: this.userRecord
        });
      }else{
        const fDataToSend = {...this.userRecord, DOB: appFxs.dateToUtcStr(this.userRecord.DOB)};
        const fData = appFxs.getFormData(fDataToSend);        
        fData.append('profileImage', this.fileToUpload);
        oRet = NetServices.requestPostWithFile('managt/profile/saveprofiledatawithpic', fData);
      }

      oRet.then(oValues => {
        this.isSavingRecord = false;

        if(!oValues.bReturn){
          appFxs.showAlert(oValues.msgTitle, oValues.msgBody);
          return;
        }


        const fFinalUser = {...oValues.connectedUser!, DOB: new Date(oValues.connectedUser!.DOB)}
      

        appFxs.setLocalStorage(appDeclarations.connecteUserRecord, JSON.stringify(fFinalUser));        
        this.userRecord = fFinalUser;
      });
    }
  },
  validations() {
    return{
      userRecord: {
        FirstName: {
          required: helpers.withMessage(language.fldRequired, required), 
          $autoDirty: true,
        },
        LastName: {
          required: helpers.withMessage(language.fldRequired, required), 
          $autoDirty: true,
        },
        JobTitle: {
          required: helpers.withMessage(language.fldRequired, required), 
          $autoDirty: true,
        },
        MobilePhone: {
          required: helpers.withMessage(language.fldRequired, required),
          minLength: helpers.withMessage(language.passLengthMsg, minLength(12)),
          $autoDirty: true,
        },
      }
    }
  },
  mounted(){
    document.title = this.language.myProfile + ' - ' + this.language.appName;

    const oUser = appFxs.getLocalStorageByKey(appDeclarations.connecteUserRecord, '{}');
    const finalUser = JSON.parse(oUser,(key, value)=>{
      if(key==='DOB'){
        return new Date(value);
      }else{
        return value;
      }
    });
    this.userRecord = finalUser;

    const oCtries = appFxs.getLocalStorageByKey(appDeclarations.telephonesCodes, '[]');
    const countries = JSON.parse(oCtries);

    this.countries = countries;
  }
})
