
    import { defineComponent } from "vue";
    import NetServices from '@/tools/netservices';
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    
    
    export default defineComponent({
        name: 'PurchaseOValue',
        props: {
            purchaseKey: {
                type: String,
                default: '',
                required: true,
            },
            currencyCode: {
                type: String,
                default: 'RWF',
                required: false,
            },
        },
        data(){
            return {
                language,
                appFxs,
                isStillLoading: true,
                purchaseValueAmt: 0,
                purchaseTotalItems: 0,
            }
        },
        mounted(){
            NetServices.requestGet(`stockops/purchases/getpurchaseordervalue/${this.purchaseKey}`)
                .then(oRes => {
                    if(!oRes.bReturn){
                        this.purchaseValueAmt = 0;
                        this.isStillLoading = false;
                        return;
                    }
                    
                    this.isStillLoading = false;
                    this.purchaseValueAmt = oRes.purchaseValueAmt!;
                    this.purchaseTotalItems = oRes.purchaseTotalItems!;
                });
        }
    })
