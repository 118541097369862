import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "modal-dialog wise-modal" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { for: "MinQuant" }
const _hoisted_8 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_9 = { for: "MaxQuant" }
const _hoisted_10 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_11 = { for: "DiscValue" }
const _hoisted_12 = {
  key: 0,
  class: "my-1 error-style"
}
const _hoisted_13 = { class: "modal-footer" }
const _hoisted_14 = { class: "d-flex justify-content-end" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_numeric_fld_input = _resolveComponent("numeric-fld-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'd-none': !_ctx.visible }, "modal fade show"]),
    style: {"display":"block"}
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.windowTitle), 1),
          (!_ctx.isSavingRecord)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args))),
                type: "button",
                class: "btn-close"
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: _normalizeClass(["col-md-6 form-group", { 'has-error': _ctx.v$.dataRecord.MinQuant.$error }])
            }, [
              _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.language.min_qty), 1),
              _createVNode(_component_numeric_fld_input, {
                modelValue: _ctx.dataRecord.MinQuant,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataRecord.MinQuant) = $event)),
                disabled: _ctx.isSavingRecord || _ctx.dataRecord.IdDiscRule != 0,
                placeholder: '(' + _ctx.language.min_qty.toLowerCase() + ')'
              }, null, 8, ["modelValue", "disabled", "placeholder"]),
              (_ctx.v$.dataRecord.MinQuant.$error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.v$.dataRecord.MinQuant.$errors[0].$message), 1))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-md-6 form-group", { 'has-error': _ctx.v$.dataRecord.MaxQuant.$error }])
            }, [
              _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.language.max_qty), 1),
              _createVNode(_component_numeric_fld_input, {
                modelValue: _ctx.dataRecord.MaxQuant,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dataRecord.MaxQuant) = $event)),
                disabled: _ctx.isSavingRecord || _ctx.dataRecord.IdDiscRule != 0,
                placeholder: '(' + _ctx.language.max_qty.toLowerCase() + ')'
              }, null, 8, ["modelValue", "disabled", "placeholder"]),
              (_ctx.v$.dataRecord.MaxQuant.$error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.v$.dataRecord.MaxQuant.$errors[0].$message), 1))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-md-6 form-group", { 'has-error': _ctx.v$.dataRecord.DiscValue.$error }])
            }, [
              _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.language.disc_rate_per), 1),
              _createVNode(_component_numeric_fld_input, {
                modelValue: _ctx.dataRecord.DiscValue,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataRecord.DiscValue) = $event)),
                disabled: _ctx.isSavingRecord || _ctx.dataRecord.IdDiscRule != 0,
                placeholder: '(' + _ctx.language.disc_rate_per.toLowerCase() + ')',
                isFloatFormat: true,
                maxlength: 5
              }, null, 8, ["modelValue", "disabled", "placeholder"]),
              (_ctx.v$.dataRecord.DiscValue.$error)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.v$.dataRecord.DiscValue.$errors[0].$message), 1))
                : _createCommentVNode("", true)
            ], 2)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary me-2",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args))),
              disabled: _ctx.isSavingRecord
            }, _toDisplayString(_ctx.language.close), 9, _hoisted_15),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.saveRecordData && _ctx.saveRecordData(...args))),
              disabled: _ctx.v$.$invalid || !_ctx.isValidMaximum || _ctx.isSavingRecord
            }, [
              (_ctx.isSavingRecord)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    icon: ['fa', 'fa-spinner']
                  }))
                : _createCommentVNode("", true),
              (!_ctx.isSavingRecord)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 1,
                    icon: ['fa', 'fa-save']
                  }))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.language.save), 1)
            ], 8, _hoisted_16)
          ])
        ])
      ])
    ])
  ], 2))
}