
    import { defineComponent } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { SMSAccountSett } from "@/models/managttypes";

    import SmsSettingsModal from "@/components/modals/SmsSettingsModal.vue";

    import Swal from "sweetalert2";

    const language =GetLanguage(),
        appFxs = GeneralFx();
    
    const emptySmsSett = {
        SMSAcctId: 0,
        SmsAccount: '',
        Password: '',
        Country: 'RW',
        CallCode: '250',
        PhoneNumber: '',
        PhoneNumberFormat: '',
        StillActive: true,
        SMSDateCreated: new Date()
    }

    export default defineComponent({
        components: {
            SmsSettingsModal,
        },
        data(){
            return {
                language,
                appFxs,
                isStillLoading: true,
                listOfSmsAccts: [] as SMSAccountSett[],
                
                isDialogOpen: false,
                dialogRecordStatus: 0,
                dialogRecordData: (emptySmsSett as SMSAccountSett),
                eltSearchKey: '',

                
                isPrintvisible: false,
                printInitialValues: {...appFxs.getReportingData()} as any,
                printExtraData: {}
            }
        },
        computed: {
            filteredTaxes(): SMSAccountSett[] {
                let searchKey = this.eltSearchKey;

                if(searchKey.length === 0) return this.listOfSmsAccts;
                searchKey = searchKey.toLowerCase();
                
                const filterd = this.listOfSmsAccts.filter(oDev => {
                    return oDev.SmsAccount.toLowerCase().includes(searchKey);
                });
                return filterd;
            },

        },
        methods: {
            refreshData(){
                appFxs.showAjaxLoader();

                NetServices.requestGet('managt/settings/getlistsmssetts')
                    .then(oRes => {
                        if(!oRes.bReturn){
                                appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                                .then(xRes => {

                                    Swal.close();
                                });
                                return;
                            }

                        const fList: SMSAccountSett[] = [];
                        oRes.listOfSmsAccounts!.forEach(oEmail => {
                            const fEmail = {...oEmail}
                            fList.push(fEmail);
                        });

                        this.listOfSmsAccts = fList;
                        
                        Swal.close();
                    });
            },
            openMySMSSetts(iStatus: number, oData: SMSAccountSett | any){
                if(iStatus===0){
                    const fNew = {...emptySmsSett};
                    this.dialogRecordData = (fNew as SMSAccountSett);
                    this.dialogRecordStatus = 0;
                }else{
                    this.dialogRecordData = oData!;
                    this.dialogRecordStatus = 1;
                }
                
                this.isDialogOpen = true;
            },
            deleteOneSMSSett(data: SMSAccountSett){
                appFxs.showConfirm(language.delete, language.delete_sms_acct_qst)
                .then(oResp => {
                    if(!oResp.isConfirmed)return;
                    this.finalDeleteOneSmsAcct(data);
                });
            },
            finalDeleteOneSmsAcct(data: SMSAccountSett){
                appFxs.showAjaxLoader();

                NetServices.requestPost('managt/settings/saveonesmssettrecord', {
                    smsSettingRecord: data,
                    toDelete: true,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: SMSAccountSett[] = [];
                    oRes.listOfSmsAccounts!.forEach(oSmsAcct => {
                        const fEmail = {...oSmsAcct, SMSDateCreated: new Date(oSmsAcct.SMSDateCreated)}
                        fList.push(fEmail);
                    });

                    this.listOfSmsAccts = fList;
                    
                    Swal.close();
                });
            },
            onCloseForm(evt: any){
                this.isDialogOpen = false;

                if(evt.returnType===0)return;

                const returnData = appFxs.getProxyData(evt.returnData);
                appFxs.showAjaxLoader();

                NetServices.requestPost('managt/settings/saveonesmssettrecord', {
                    smsSettingRecord: returnData,
                    toDelete: false,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: SMSAccountSett[] = [];
                    oRes.listOfSmsAccounts!.forEach(oEmail => {
                        const fEmail = {...oEmail, SMSDateCreated: new Date(oEmail.SMSDateCreated)}
                        fList.push(fEmail);
                    });

                    this.listOfSmsAccts = fList;
                    
                    Swal.close();
                });
            },
            
            printListItems(){
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = language.list_of_sms_settings;
                dPrint.ReportToPrint = 4;

                this.printInitialValues = dPrint;


                this.isPrintvisible = true;
            },
            onPrintClose(evt: any){                
                this.isPrintvisible = false;
                if(evt.returnType===0)return;
            }
        },
        mounted(){
            document.title = `${this.language.settings}/${this.language.smsSettings} - ${this.language.appName}`;

            const fNew = {...emptySmsSett};
            this.dialogRecordData = (fNew as SMSAccountSett);
            
            NetServices.requestGet('managt/settings/getlistsmssetts')
                .then(oRes => {
                    if(!oRes.bReturn){
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                        .then(xRes => {
                            router.replace({path: '/app/myprofile', replace: true});
                        });
                        return;
                    }

                    const fList: SMSAccountSett[] = [];
                    oRes.listOfSmsAccounts!.forEach(oEmail => {
                        const fEmail = {...oEmail, CreatedDate: new Date(oEmail.SMSDateCreated)}
                        fList.push(fEmail);
                    });

                    this.listOfSmsAccts = fList;
                    
                    this.isStillLoading = false;
                });
        }
    })
