
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent, toRaw } from 'vue';
    import { EmailSenderSett } from '@/models/managttypes';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    const emptyEmailRec: EmailSenderSett = {
        IdEmail: 0,
        AccountKey: '',
        AccountEmail: '',
        AccountPassword: '',
        AccountServer: '',
        AccountPort: '456',
        EmailSentAs: '',
        CreatedDate: new Date(),
        IsDefaultNoReply: false,
        IsDefaultMarketing: false,
        AccountName: '',
        AccountType: 0,
    }
export default defineComponent({
    name: 'EmailSettModal',
    emits: ['closeForm'],
    props: ["visible", "initialValues", "recordStatus"],
    data(){
        return {
            v$: useVuelidate(),
            language,
            acctTypes: [
                { id: '0', label: language.general_sending },
                { id: '1', label: language.marketing },
            ],
            emailRecord: {...emptyEmailRec},
        }
    },
    methods: {
        closeFormRet(){
            this.$emit('closeForm', {returnType: 0, returnData: null});
        },
        selectOnFocus(evt: Event){
            const oInput = evt.target as HTMLInputElement;
            oInput.select();
        },
        saveRecordData(){
            this.$emit('closeForm', {returnType: 1, returnData: this.emailRecord});
        }
    },
    watch:{
        visible: {
            handler(newV, oldV){
                if((newV) && newV !== oldV ){
                    const oValues = appFxs.getProxyData(this.initialValues);
                    const fValues: EmailSenderSett = {...oValues, CreatedDate: new Date(oValues.CreatedDate)};

                    if(this.recordStatus == 0){
                        this.v$.$reset();
                    }

                    this.emailRecord = fValues;
                }
            }
        }
    },
    validations(){
        return {
            emailRecord: {                
                AccountName: {
                    required: helpers.withMessage(language.fldRequired, required),                 
                    $autoDirty: true,
                },
                AccountEmail: {
                    required: helpers.withMessage(language.fldRequired, required),
                    email: helpers.withMessage(language.emailFormat, email),                         
                    $autoDirty: true,
                },
                AccountPassword: {
                    required: helpers.withMessage(language.fldRequired, required),
                    $autoDirty: true,
                },
                AccountServer: {
                    required: helpers.withMessage(language.fldRequired, required),
                    $autoDirty: true,
                },
                AccountPort: {
                    required: helpers.withMessage(language.fldRequired, required),
                    minLength: helpers.withMessage(language.minLengthReqMsg, minLength(3)),
                    maxLength: helpers.withMessage(language.maxLengthReqMsg, maxLength(3)),
                    $autoDirty: true,
                },
                EmailSentAs: {
                    required: helpers.withMessage(language.fldRequired, required),
                    $autoDirty: true,
                },
            }
        }
    },
    computed: {        
        windowTitle():string {
            return this.recordStatus === 0 ? language.add_email_acct:
            language.edit_email_acct;
        }
    },
})
