    
    import { defineComponent } from 'vue';
    import paginate from 'jw-paginate';
    import appLanguage from '@/tools/language';
    import generalFxs from '@/tools/general_fx';

    const language = appLanguage();
    const appFxs = generalFxs();

    const defaultLabels = {
        first: language.first_p,
        last: language.last_p,
        previous: language.prev_p,
        next: language.next_p
    };

    const defaultStyles = {
        ul: {
            margin: 0,
            padding: 0,
            display: 'inline-block'
        },
        li: {
            listStyle: 'none',
            display: 'inline',
            textAlign: 'center'
        },
        a: {
            cursor: 'pointer',
            padding: '6px 12px',
            display: 'block',
            float: 'left'
        }
    };

    export default defineComponent({
        emits: ['changePage'],
        props: {
            items: {
                type: Array,
                required: true
            },
            initialPage: {
                type: Number,
                default: 1
            },
            pageSize: {
                type: Number,
                default: 10
            },
            maxPages: {
                type: Number,
                default: 10
            },
            labels: {
                type: Object,
                default: () => defaultLabels
            },
            styles: {
                type: Object
            },
            disableDefaultStyles: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                pager: {} as any,
                ulStyles: {},
                liStyles: {},
                aStyles: {}
            }
        },
        computed: {
            isCompentVis(): boolean{
                return this.pager.pages && this.pager.pages.length > 1
            }
        },
        created () {

            if (!this.disableDefaultStyles) {
                this.ulStyles = defaultStyles.ul;
                this.liStyles = defaultStyles.li;
                this.aStyles = defaultStyles.a;
            }

            if (this.styles) {
                this.ulStyles = { ...this.ulStyles, ...this.styles.ul };
                this.liStyles = { ...this.liStyles, ...this.styles.li };
                this.aStyles = { ...this.aStyles, ...this.styles.a };
            }

            if (this.items && this.items.length) {
                this.setPage(this.initialPage);
            }
        },
        methods: {
            setPage(page: number) {
                const { items, pageSize, maxPages } = this;

                // get new pager object for specified page
                const pager = paginate(items.length, page, pageSize, maxPages);

                // get new page of items from items array
                const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

                // update pager
                this.pager = pager;

                // emit change page event to parent component
                this.$emit('changePage', pageOfItems);
            }
        },
        watch:{
            items: {
                handler(newV, oldV){
                    if(newV){
                        const newArr = newV as any[];
                        const oldArr = oldV as any[];

                        if(!appFxs.arrayEquals(newArr, oldArr)){
                            this.setPage(this.initialPage);
                        }
                    }
                },
            },
            pageSize: {
                handler(newV, oldV){
                    if(newV){
                        if(newV !== oldV){
                            this.setPage(this.initialPage);
                        }
                    }
                }
            }
        }
    })
