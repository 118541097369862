
  import { defineComponent } from "vue";
  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';

  const language =GetLanguage(),
    appFxs = GeneralFx(); 

  export default defineComponent({
    data(){
        return {
            language,
        }
    },  
    mounted(){
        document.title = this.language.products + ' - ' + this.language.appName;

    }
})
