import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal fade show",
  style: {"display":"block"}
}
const _hoisted_2 = { class: "modal-dialog wise-modal" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = {
  name: "frmTaxSett",
  class: "dialog-form-data"
}
const _hoisted_8 = {
  for: "TaxName",
  class: "dlg-strong-title"
}
const _hoisted_9 = { class: "flex-fill" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = {
  key: 0,
  class: "my-1 error-style text-end"
}
const _hoisted_12 = {
  for: "TaxRate",
  class: "dlg-strong-title"
}
const _hoisted_13 = { class: "flex-fill" }
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = {
  key: 1,
  class: "my-1 error-style text-end"
}
const _hoisted_16 = { class: "d-flex mb-3 align-items-center" }
const _hoisted_17 = {
  for: "IsActive",
  class: "dlg-strong-title"
}
const _hoisted_18 = { class: "flex-fill form-check" }
const _hoisted_19 = { class: "modal-footer" }
const _hoisted_20 = { class: "d-flex justify-content-end" }
const _hoisted_21 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.windowTitle), 1),
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args))),
                type: "button",
                class: "btn-close"
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("form", _hoisted_7, [
                _createElementVNode("div", {
                  class: _normalizeClass(["d-flex mb-3", {'has-error': _ctx.v$.dataRecord.TaxName.$error}])
                }, [
                  _createElementVNode("label", _hoisted_8, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.language.tax_name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      id: "TaxName",
                      name: "TaxName",
                      class: "input-text-data selectallfocus",
                      maxlength: "15",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dataRecord.TaxName) = $event)),
                      placeholder: '(' + _ctx.language.tax_name.toLowerCase() + ')',
                      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectOnFocus($event)))
                    }, null, 40, _hoisted_10), [
                      [_vModelText, _ctx.dataRecord.TaxName]
                    ])
                  ])
                ], 2),
                (_ctx.v$.dataRecord.TaxName.$error)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.v$.dataRecord.TaxName.$errors[0].$message), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass(["d-flex mb-3", {'has-error': _ctx.v$.dataRecord.TaxRate.$error}])
                }, [
                  _createElementVNode("label", _hoisted_12, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.language.rate_per), 1)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      id: "TaxRate",
                      name: "TaxRate",
                      class: "input-text-data selectallfocus",
                      maxlength: "5",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataRecord.TaxRate) = $event)),
                      onKeypress: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.appFxs.floatNbr && _ctx.appFxs.floatNbr(...args))),
                      placeholder: '(' + _ctx.language.rate_per.toLowerCase() + ')',
                      onFocus: _cache[5] || (_cache[5] = ($event: any) => (_ctx.selectOnFocus($event)))
                    }, null, 40, _hoisted_14), [
                      [
                        _vModelText,
                        _ctx.dataRecord.TaxRate,
                        void 0,
                        { number: true }
                      ]
                    ])
                  ])
                ], 2),
                (_ctx.v$.dataRecord.TaxRate.$error)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.v$.dataRecord.TaxRate.$errors[0].$message), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("label", _hoisted_17, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.language.is_active), 1)
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      id: "IsActive",
                      name: "IsActive",
                      class: "form-check-input",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dataRecord.IsActive) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.dataRecord.IsActive]
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-secondary me-2",
                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.closeFormRet && _ctx.closeFormRet(...args)))
                }, _toDisplayString(_ctx.language.close), 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.saveRecordData && _ctx.saveRecordData(...args))),
                  disabled: _ctx.v$.$invalid
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fa', 'fa-save'] }),
                  _createTextVNode(" " + _toDisplayString(_ctx.language.save), 1)
                ], 8, _hoisted_21)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}