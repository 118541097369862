
    import { defineComponent } from 'vue';
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { ProdProduct, ProductVariant, ProductVariantDetail } from '@/models/producttypes';
    import NetServices from '@/tools/netservices';
    
    const language =GetLanguage(),
        appFxs = GeneralFx();
    const emptyVariantValue: ProductVariantDetail = {
        IdVariantDetail: 0,
        BarCode: '',
        ProductSku: '',
        PurchasePrice: 0,
        SellingPrice: 0,
        SKU: '',
        VariantCodes: '',
        VariantCodesObject: '',
        VariantValues: '',
    };
    export default defineComponent({
        name: 'ProdVariantDetModal',
        emits: ['closeForm'],
        props: ["visible", "initialValues", "recordStatus", "basicProduct", 'lstVariants',],
        data(){
            return {
                language,
                appFxs,
                dataRecord: {...emptyVariantValue} as ProductVariantDetail,
                isSavingRecord: false,
                variants: this.lstVariants as ProductVariant[],
                variantCodes: {},
                isBarCodeReady: false,
            }
        },
        computed: {
            windowTitle():string {
                return this.recordStatus === 0 ? language.new_variant_det:
                language.edit_variant_det;
            },
            fieldEditorData(): {key: string, val: any}[] {
                if(this.recordStatus == 0)return [];

                const oJson = JSON.parse(this.dataRecord.VariantCodesObject);
                const entries = Object.entries(oJson);
                const oRet = entries.map(o => {
                    return {
                        key: o[0],
                        val: o[1],
                    }
                });
                return oRet;
            },
            validationRules(){
                const ProductSkuRequired = this.dataRecord.ProductSku.length > 0;
                const ProductSkuLength = this.dataRecord.ProductSku.length == 12;
                const ProductSkuMsg = [] as string[];                
                if(!ProductSkuRequired) ProductSkuMsg.push(language.fldRequired);
                if(!ProductSkuLength) ProductSkuMsg.push(language.sku_min_err_msg);

                const VariantValuesValid = this.dataRecord.VariantValues.length > 0;
                const VariantValuesMsg = [] as string[];
                if(!VariantValuesValid) VariantValuesMsg.push(language.fldRequired);               

                const VariantCodesValid = this.dataRecord.VariantCodes.length > 0;
                const VariantCodesMsg = [] as string[];
                if(!VariantCodesValid) VariantCodesMsg.push(language.fldRequired);


                const VariantCodesObjectValid = this.dataRecord.VariantCodesObject.length > 0;
                const VariantCodesObjectMsg = [] as string[];
                if (!VariantCodesObjectValid) VariantCodesObjectMsg.push(language.fldRequired);
                
                const SKURequired = this.dataRecord.SKU.length > 0;
                const SKULength = this.dataRecord.SKU.length == 12;
                const SKUMsg = [] as string[];
                if(!SKURequired) SKUMsg.push(language.fldRequired);
                if(!SKULength) SKUMsg.push(language.sku_min_err_msg);
                
                const BarCodeRequired = this.dataRecord.BarCode.length > 0;
                const BarCodeLength = this.dataRecord.BarCode.length== 10;                
                const BarCodeMsg = [] as string[];
                if(!BarCodeRequired) BarCodeMsg.push(language.fldRequired);
                if(!BarCodeLength) BarCodeMsg.push(language.barcode_min_err_msg);
                




                const PurchasePriceValidLength = this.dataRecord.PurchasePrice.toString().length > 0;
                const PurchasePriceValidGreater = this.dataRecord.PurchasePrice > 0;
                const PurchasePriceMsg = [] as string[];
                if(!PurchasePriceValidLength) PurchasePriceMsg.push(language.fldRequired);
                if(!PurchasePriceValidGreater) PurchasePriceMsg.push(language.purchase_price_zero_err);
                
                
                const SellingPriceValidRequired = this.dataRecord.SellingPrice.toString().length > 0;
                const SellingPriceValidGreater = this.dataRecord.SellingPrice > 0;
                const SellingPriceMsg = [] as string[];
                if(!SellingPriceValidRequired) SellingPriceMsg.push(language.fldRequired);
                if(!SellingPriceValidGreater) SellingPriceMsg.push(language.sell_price_zero_err);
                




                const formInvalid = 
                    !ProductSkuRequired || !ProductSkuLength ||
                    !VariantValuesValid ||
                    !VariantCodesValid || 
                    !VariantCodesObjectValid || 
                    !SKURequired || !SKULength ||
                    !BarCodeRequired || !BarCodeLength || 
                    (!PurchasePriceValidLength || !PurchasePriceValidGreater) ||
                    (!SellingPriceValidRequired || !SellingPriceValidGreater);
                const $errors = [] as string[];
                $errors.push(...ProductSkuMsg);
                $errors.push(...VariantValuesMsg);
                $errors.push(...VariantCodesMsg);
                $errors.push(...VariantCodesObjectMsg);
                $errors.push(...SKUMsg);
                $errors.push(...BarCodeMsg);
                $errors.push(...PurchasePriceMsg);
                $errors.push(...SellingPriceMsg);

                return {
                    $invalid: formInvalid,
                    $errors,
                    fields: {
                        ProductSku: {
                            $error: !ProductSkuRequired || !ProductSkuLength,
                            $errors: ProductSkuMsg
                        },
                        VariantValues: {
                            $error: !VariantValuesValid,
                            $errors: VariantValuesMsg
                        },
                        VariantCodes: {
                            $error: !VariantCodesValid,
                            $errors: VariantCodesMsg
                        },
                        VariantCodesObject: {
                            $error: !VariantCodesObjectValid,
                            $errors: VariantCodesObjectMsg
                        },
                        SKU: {
                            $error: !SKURequired || !SKULength,
                            $errors: SKUMsg
                        },
                        BarCode: {
                            $error: !BarCodeRequired || !BarCodeLength,
                            $errors: BarCodeMsg
                        },
                        PurchasePrice: {
                            $error: !PurchasePriceValidLength || !PurchasePriceValidGreater,
                            $errors: PurchasePriceMsg
                        },
                        SellingPrice: {
                            $error: !SellingPriceValidRequired || !SellingPriceValidGreater,
                            $errors: SellingPriceMsg
                        },
                    }
                }
            },
        },
        methods: {
            buildCodesData(oVar: ProductVariant, evt: any){
                const varCode = oVar.VariantCode;
                const inputVAl = (evt.target as HTMLInputElement).value;
                const tempoObj = {...this.variantCodes};
                let tempoFinal = {};
                if(!inputVAl){
                    tempoFinal = appFxs.getObjWithoutPerop(tempoObj, varCode);
                }else{
                    tempoFinal = {...this.variantCodes, [varCode]: inputVAl};
                }

                this.variantCodes = {...tempoFinal};


                const keys = Object.keys(tempoFinal);
                const oKeys =  keys.join('-');

                const vals = Object.values(tempoFinal);
                const oVals = vals.join('-');

                const oObject = JSON.stringify(tempoFinal);

                this.dataRecord.VariantValues = vals.length > 0 ? oVals: '';
                this.dataRecord.VariantCodes = keys.length > 0 ? oKeys: '';
                this.dataRecord.VariantCodesObject = keys.length > 0 ? oObject: '';

            },
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                const fFinalData = appFxs.getProxyData(this.dataRecord);

                this.isSavingRecord = true;



                NetServices.requestPost(`products/variantdets/savevariantvaluerecord`, {
                    variantDetailRecord: fFinalData as ProductVariantDetail,
                    toDelete: false,
                    populateData: true,
                })
                .then(oRes => {
                    if(!oRes.bReturn){
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        this.isSavingRecord = false;
                        return;
                    }

                    this.$emit('closeForm', {returnType: 1, returnData: {
                        bReturn: true,
                        listOfItems: oRes.lstOfProdVariantValues!,
                    }});
                    this.isSavingRecord = false;
                });

            },
            generateBarCode(code: any){
                const binary = (code == null) ? appFxs.getRandomDigits(10) : code as string;
                this.dataRecord.BarCode = binary;
                this.isBarCodeReady = true;
            },
            onKeyUpRawBarcode(evt: any){
                const val = (evt.target as HTMLInputElement).value;
                const length = val ? val.length : 0;
                if(length==10){
                    this.generateBarCode(null);
                }else{
                    this.isBarCodeReady = false;
                }
            },
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: ProductVariantDetail = {...oValues};

                        this.dataRecord = fValues as ProductVariantDetail;

                        if(this.recordStatus==0){
                            this.dataRecord.SellingPrice = (this.basicProduct as ProdProduct).SellingPrice;
                            this.dataRecord.PurchasePrice = (this.basicProduct as ProdProduct).PurchasePrice;
                        }


                        this.variants = this.lstVariants as ProductVariant[];
                        appFxs.updateLoadedScripts();
                    }
                }
            }
        },
        mounted(){
            appFxs.updateLoadedScripts();
        }
    })
