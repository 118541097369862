
import { defineComponent } from 'vue';

export default defineComponent({
    emits: ["onClose"],
    props: {
        errorMessage: {
            type: String,
            required: false,
            default: ''
        }
    },
    data(){
        return {
        }
    },
    methods: {
        onCloseEvt(){
            this.$emit('onClose');
        }
    }
})
