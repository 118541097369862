    

    import { defineComponent, toRaw } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDecl from '@/tools/declarations';
    import { PrintRecType } from '@/tools/smarttypes';

    import NetServices from '@/tools/netservices';

    const language =GetLanguage(),
        appFxs = GeneralFx();

    const emptyValues: PrintRecType = {
        ReportTitle: '',
        ReportToPrint: 0,
        StylePrint: 4,
        LangPrint: 'en',
        EmailPrint: '',
        EmailMessage: '',
        EmailReceiver: '',
        EmailSubject: '',
        TypeSoft: 0,
    };
    export default defineComponent({
        name: 'CurrencySettModal',
        emits: ['closeForm'],
        props: ["visible", "printBaseData", "extraPrintData"],
        data(){
            return {
                language,
                dataRecord: {...emptyValues},
                extraData: {},
                isPrinting: false,
            }
        },
        computed: {
            formChecker(){
                let bForm = true;
                let bEmail = true;
                let bSubject = true;
                let bContent = true;

                const dRecord = appFxs.getProxyData(this.dataRecord);
                if(Number(dRecord.StylePrint) === 6){
                    if(dRecord.EmailReceiver.length == 0){
                        bEmail = false;
                    }else{
                        const isEmail = appFxs.isEmailValid(dRecord.EmailReceiver);
                        bEmail = isEmail;
                    }                    
                    bSubject = dRecord.EmailSubject.length > 0;
                    bContent = dRecord.EmailMessage.length > 0;
                }

                bForm = bEmail && bSubject && bContent;

                return {
                    isformValid: bForm,
                    isEmailValid: bEmail,
                    isSubjectValid: bSubject,
                    isContentValid: bContent,
                }
            }
        },
        methods: {
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            printRecordData(){
                const printData = appFxs.getProxyData(this.dataRecord);
                const extraData = appFxs.getProxyData(this.extraData);
                const formPrintData = appFxs.mergeObjects(printData, extraData);

                
                this.isPrinting = true;
                NetServices.requestPrinting(
                    formPrintData, 
                    (resp: any) => {
                        const oStyle = this.dataRecord.StylePrint;
                        const bReturn = resp.bReturn as boolean,
                            sMsgTitle = resp.msgTitle as string,
                            sMsgBody = resp.msgBody as string,
                            typeRet = resp.typeReturn as number;
                         
                            if(bReturn){
                                this.isPrinting = false;
                                if (oStyle === 0) {
                                    let xWin = window.open(sMsgTitle, '_blank');
                                    return;
                                }
                                if(oStyle==6){
                                    appFxs.showAlert(language.success, language.email_sent_success)
                                    .then(foRes =>{
                                        this.$emit('closeForm', {returnType: 0, returnData: null});
                                    });
                                }else{
                                    const reportTitle = appFxs.replaceByCharacter(this.dataRecord.ReportTitle, ' ', '_');
                                    const fileUrl = sMsgBody;
                                    const fileName = appFxs.extractFileNameFromUrl(fileUrl);

                                    const userEmail = appFxs.getLocalStorageByKey(
                                        appDecl.userEmail, '');
                                    const langOper = appFxs.getLocalStorageByKey(
                                        appDecl.currentLang, 'en');

                                    appFxs.downloadFromFileUrl(fileName, fileUrl);

                                    /* const fUserEmail = window.btoa(userEmail);
                                    const fLangOper = window.btoa(langOper);
                                    const fFileName = window.btoa(fileName);

                                    appFxs.openForDowloadPrint(fileName, userEmail, langOper); */
                                    
                                    this.$emit('closeForm', {returnType: 0, returnData: null});
                                }
                            }else{
                                this.isPrinting = false;
                                appFxs.showAlert(sMsgTitle, sMsgBody);
                            }
                    },
                    (err: any) => {
                        this.isPrinting = false;
                        appFxs.showAlert(language.networkError, language.networkErrorMsg);
                    }
                );

                //this.$emit('closeForm', {returnType: 1, returnData: this.dataRecord});
            },
        },
        watch: {
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.printBaseData);
                        const fValues = {...oValues};

                        const oExtraData = appFxs.getProxyData(this.extraPrintData);
                        const fExtraData = {...oExtraData};

                        this.dataRecord = fValues;
                        this.extraData = fExtraData;
                        this.isPrinting = false;
                    }
                }
            }
        }
    })
