import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "container login-area"
}
const _hoisted_2 = { class: "row vh-100 align-items-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullPageLoading = _resolveComponent("FullPageLoading")!
  const _component_JoinCompanyForm = _resolveComponent("JoinCompanyForm")!
  const _component_AccountImageArea = _resolveComponent("AccountImageArea")!

  return (_ctx.isLoadStarted)
    ? (_openBlock(), _createBlock(_component_FullPageLoading, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_JoinCompanyForm, {
            onOnCleanError: _ctx.closeErrorPanel,
            errorMessage: _ctx.errorMessage,
            isNetLoading: _ctx.isNetLoading,
            isErrorVisible: _ctx.isServerError,
            emailToJoinWith: _ctx.emailToJoinWith,
            onOnFormSubmit: _ctx.sendFormToServer
          }, null, 8, ["onOnCleanError", "errorMessage", "isNetLoading", "isErrorVisible", "emailToJoinWith", "onOnFormSubmit"]),
          _createVNode(_component_AccountImageArea)
        ])
      ]))
}