
  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';

  import Swal from "sweetalert2";
  import { PurchaseOperation, PurchasePayment } from "@/models/producttypes";
  import { Supplier } from "@/models/managttypes";

  import PurchaseOrderValueComp from '@/components/smalls/PurchaseOrderValueComp.vue';
  import { ShortData } from "@/tools/smarttypes";
  import PurchaseOperationModal from '@/components/modals/PurchaseOperationModal.vue';
  import PurchasePaytModal from '@/components/modals/PurchasePaytModal.vue';

  const language =GetLanguage(),
    appFxs = GeneralFx();

  
  const newDate = new Date(),
    month = newDate.getMonth() +1,
    year = newDate.getFullYear();

    const emptyPurchaseOps: PurchaseOperation = {
        IdPurchase: 0,
        PurchaseKey: '',
        SupplierKey: '',
        PurchaseOrderNbr: '',
        FinancialYear: 2000,
        OperationDate: new Date(),
        OperationStatus: 0,
        UserEmail: '',
    };
    const emptyPaytRecord: PurchasePayment = {
        IdPurchasePayment:0,
        PurchaseKey: '',
        PaytOpsKey: '',
        SupplierKey: '',
        PaytDate: new Date(),
        PaytAmt: 0,
        UserEmail: '',
        PaytDoctNbr: '',
        PaytType: 0,
    };

  export default defineComponent({
    components: {
      'purchase-order-val': PurchaseOrderValueComp,
      PurchaseOperationModal,
      PurchasePaytModal,
    },
    data(){
      return{            
        appFxs,
        language,
        isStillLoading: true,
        itemSearchKey: '',
        itemsPerPage: 5,
        listOfSuppliers: [] as Supplier[],    
        listOfMonthPurchases: [] as PurchaseOperation[],
        itemsPageElts: [] as PurchaseOperation[],
        currentYear: 2000,

        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 15,
          ReportTitle: language.list_of_purchase_ops
        } as any,
        printExtraData: {},

        currCode: 'RWF',
        selectedMonth: month,
        selectedYear: year,
        listOfMonths: [] as ShortData[],

        isPurchOpModalVis: false,
        purchaseInitVals: {...emptyPurchaseOps} as PurchaseOperation,
        purchRecStatus: 0,

        isPaytDlgVisible: false,
        paytInitialValues: {...emptyPaytRecord} as PurchasePayment,
        detailRecordStatus: 0,
        remainPaytAmt: 0,

        userEmail: '',
      }
    },
    computed: {
      selectedMonthLabel(): string{
        return this.listOfMonths[this.selectedMonth - 1].label;
      },
      filteredRecordItems(): PurchaseOperation[] {
        let searchKey = this.itemSearchKey;

        if (searchKey.length === 0) return this.listOfMonthPurchases;
        searchKey = searchKey.toLowerCase();

        const filterd = this.listOfMonthPurchases.filter(oDev => {
          return oDev.PurchaseOrderNbr.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
    },
    methods: {
      lockPurchaseOrder(oRecord: PurchaseOperation){
        appFxs.showConfirm(language.lock_purch_order, language.lock_purch_order_qst)
          .then(vRes => {
            if(vRes.isConfirmed){
              this.lockPurchaseOrderFinal(oRecord);
            }
          });
      },
      lockPurchaseOrderFinal(oRecord: PurchaseOperation){
        appFxs.showAjaxLoader();

        NetServices.requestPost('stockops/purchases/lockpaytforall', {
          purchOrderKey: oRecord.PurchaseKey,
          populateData: true,
        }).then(oRes => {
          if(!oRes.bReturn){
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }

          const fList: PurchaseOperation[] = [];
          oRes.lstOfPurchaseOps!.forEach(oEmail => {
            const fEmail = { ...oEmail, OperationDate: new Date(oEmail.OperationDate) }
            fList.push(fEmail);
          });

          
          Swal.close();
          this.listOfMonthPurchases = fList;
        });
      },
      confirmPOrderOps(oRecord: PurchaseOperation){
        appFxs.showConfirm(language.confirm, language.confirm_purch_order_quest)
          .then(vRes => {
            if(vRes.isConfirmed){
              this.confirmPOrderOpsFinal(oRecord);
            }
          });
      },
      confirmPOrderOpsFinal(oRecord: PurchaseOperation){
        appFxs.showAjaxLoader();

        NetServices.requestPost('stockops/purchases/confirmacceptpo', {
          purchOrderKey: oRecord.PurchaseKey,
          populateData: true,
        }).then(oRes => {
          if(!oRes.bReturn){
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }

          const fList: PurchaseOperation[] = [];
          oRes.lstOfPurchaseOps!.forEach(oEmail => {
            const fEmail = { ...oEmail, OperationDate: new Date(oEmail.OperationDate) }
            fList.push(fEmail);
          });

          
          Swal.close();
          this.listOfMonthPurchases = fList;
        });
      },
      getSupplier(supplKey: string){
        const oneSupplier = this.listOfSuppliers.filter(o => o.SupplierKey == supplKey);
        return oneSupplier.length>0?oneSupplier[0].SupplierName:'';
      },
      changeMonth(oMonth: number){
        this.selectedMonth = oMonth;
        this.refreshList();
      },
      getPOstatus(iStatus: number): string{
        return iStatus == 0 ? language.draft:
        iStatus == 1 ? language.confirmed:
        iStatus == 2 ? language.part_paid:
        iStatus == 3 ? language.fully_paid:
        iStatus == 4 ? language.locked:
        language.draft;
      },
      showAddPurchaseOps(iStatus: number, oRecord: PurchaseOperation | any){
        if(iStatus==0){
            this.purchRecStatus = 0;
            this.purchaseInitVals = {...emptyPurchaseOps} as PurchaseOperation;
            this.isPurchOpModalVis = true;
          }else{
            const fOper = oRecord as PurchaseOperation;
            this.purchRecStatus = 1;
            this.purchaseInitVals = {...fOper} as PurchaseOperation;
            this.isPurchOpModalVis = true;
          }
      },
      onPurchaseModalClose(evt: any){
        if(evt.returnType==0){
          this.isPurchOpModalVis = false;
          return;
        }

        if(!evt.returnData.bReturn) return;

        const purchaseKey = evt.returnData.purchaseKey;
        const oPath = `/app/products/purchases/${purchaseKey}/details/list`;
        router.replace({path: oPath, replace: true});
      },


      onPageChange(evt: any){
            this.itemsPageElts = appFxs.getProxyData(evt);
      },
      deletePurchaseOpRecord(data: PurchaseOperation){
        appFxs.showConfirm(language.delete, language.delete_quest_rec)
          .then(oResp => {
            if (!oResp.isConfirmed) return;
            this.finalDeletePurchaseOp(data);
          });
      },
      finalDeletePurchaseOp(data: PurchaseOperation){
        appFxs.showAjaxLoader();

        NetServices.requestPost('stockops/purchases/savepurchaseops', {
          purchaseOpsRecord: data,
          toDelete: true,
          populateData: true,
        }).then(oRes => {
            if(!oRes.bReturn){
                Swal.close();

                appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                return;
            }

            const fList: PurchaseOperation[] = [];
            oRes.lstOfPurchaseOps!.forEach(oEmail => {
                const fEmail = {...oEmail, OperationDate: new Date(oEmail.OperationDate) }
                fList.push(fEmail);
            });

            this.listOfMonthPurchases = fList;
            
            Swal.close();
        });
      },
      refreshList(){
          appFxs.showAjaxLoader();

          NetServices.requestGet(`stockops/purchases/listpurchaseperiodbyprod/${this.selectedMonth}`)
          .then(oRes => {
              if(!oRes.bReturn){
                      appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                      .then(xRes => {
                          //router.replace({path: '/app/myprofile', replace: true});
                          Swal.close();
                      });
                      return;
                  }

              const fList: PurchaseOperation[] = [];
              oRes.lstOfPurchaseOps!.forEach(oEmail => {
                  const fEmail = {...oEmail, OperationDate: new Date(oEmail.OperationDate)}
                  fList.push(fEmail);
              });

              this.listOfSuppliers = oRes.listOfSuppliers!
              this.listOfMonthPurchases = fList;

              Swal.close();
          });
      },
      printPurchaseOrder(oRecord: PurchaseOperation){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = `${language.purchase_order} - ${oRecord.PurchaseOrderNbr}`;
        dPrint.ReportToPrint = 16;

        this.printInitialValues = dPrint;

        const oPr = {
          PurchaseKey: oRecord.PurchaseKey,
        };
        this.printExtraData = oPr;

        this.isPrintvisible = true;
      },
      printListItems(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.list_of_purchase_ops;
        dPrint.ReportToPrint = 15;

        this.printInitialValues = dPrint;

        const oPr = {
          CurrentYear: this.selectedYear,
          CurrentMonth: this.selectedMonth,
        };
        this.printExtraData = oPr;

        this.isPrintvisible = true;
      },      
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },


      
      addPaytRecord(oRecord: PurchaseOperation) {
        appFxs.showAjaxLoader();
        NetServices.requestGet(`stockops/purchases/getpurchaseordersit/${oRecord.PurchaseKey}`)
          .then(oRes => {
            if(!oRes.bReturn){
              Swal.close();
              appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
              return;
            }

            const oSit = oRes.purchaseInfoSit!
            this.remainPaytAmt = oSit.RemAmt;
            Swal.close();
            this.addPaytRecordFinal(oRecord);
          });
      },
      addPaytRecordFinal(oRecord: PurchaseOperation) {
        const oRec = {...emptyPaytRecord} as PurchasePayment;

        const oSupplierF = this.listOfSuppliers.filter(o => o.SupplierKey == oRecord.SupplierKey);
        if(oSupplierF.length == 0){
          appFxs.showAlert(language.supplier, language.suppl_req_miss);
          return;
        }

        oRec.PurchaseKey = oRecord.PurchaseKey;
        oRec.SupplierKey = oSupplierF[0].SupplierKey;
        oRec.UserEmail = this.userEmail;

        this.detailRecordStatus = 0;
        this.paytInitialValues = oRec;

        this.isPaytDlgVisible = true;
      },
      onDetailClose(evt: any) {
        if (evt.returnType == 0) {
          this.isPaytDlgVisible = false;
          return;
        }
        if (!evt.returnData.bReturn) {
          this.isPaytDlgVisible = false;
          return;
        }


        this.isPaytDlgVisible = false;

        this.refreshList();
      },
    },
    mounted(){
      document.title = this.language.list_of_purchase_ops + ' - ' + this.language.appName;
      this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
      this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
      this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));
      this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
      this.listOfMonths = appFxs.getListMonthsByYear(this.selectedYear);

      NetServices.requestGet(`stockops/purchases/listpurchaseperiodbyprod/${this.selectedMonth}`)
          .then(oRes => {
              if(!oRes.bReturn){
                      appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                      .then(xRes => {
                          router.replace({path: '/app/myprofile', replace: true});
                      });
                      return;
                  }
  
              const fList: PurchaseOperation[] = [];
              oRes.lstOfPurchaseOps!.forEach(oEmail => {
                const fEmail = {...oEmail, OperationDate: new Date(oEmail.OperationDate)}
                    fList.push(fEmail);
              });

              this.listOfSuppliers = oRes.listOfSuppliers!;  
              this.listOfMonthPurchases = fList;
              
              this.isStillLoading = false;
          });

    }
  })
