
  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';
  import { Customer } from "@/models/managttypes";

  import Swal from "sweetalert2";
  import { TelCode } from "@/tools/smarttypes";



  const language =GetLanguage(),
  appFxs = GeneralFx(),
  newDate = new Date();

  export default defineComponent({
    data(){
      return {        
        appFxs,
        language,
        isStillLoading: true,
        listOfCustomers: [] as Customer[],
        customersPageElts: [] as Customer[],
        customerSearchKey: '',
        itemsPerPage: 5,
        countries: [] as TelCode[],
        currentYear: newDate.getFullYear(),


        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 6,
          ReportTitle: language.list_of_customers} as any,
        printExtraData: {},

        isSmsDlgVis: false,
        customerPhoneNbr: '',

        
        isEmailDlgVis: false,
        recipientEmails: [] as string[],
      }
    },
    computed: {
      filteredCustomers(): Customer[] {
        let searchKey = this.customerSearchKey;

        if (searchKey.length === 0) return this.listOfCustomers;
        searchKey = searchKey.toLowerCase();

        const filterd = this.listOfCustomers.filter(oDev => {
          return oDev.ClientName.toLowerCase().includes(searchKey) ||
            oDev.Phone.toLowerCase().includes(searchKey) ||
            oDev.Email.toLowerCase().includes(searchKey) ||
            oDev.City.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
    },
    methods: {
      getLangName(iLang: number): string{
        const langs = appFxs.listOfLangs();
        return langs[iLang]?.label!;
      },
      getSexText(iSex: number): string{
        const sexes = appFxs.listOfSexes();
        return sexes[iSex]?.label!;
      },
      onPageChange(evt: any){
          this.customersPageElts = appFxs.getProxyData(evt);
      },
      showAddCustomerAction(iStatus: number, oCustomer: Customer|any){
        if(iStatus==0){
          router.replace({path: '/app/customers/new', replace: true});
        }else{
          const fCustomer = oCustomer as Customer;
          const oPath = `/app/customers/editor/${fCustomer.ClientKey}`;
          router.replace({path: oPath, replace: true});
        }
      },
      deleteCustomerRecord(data: Customer){
        appFxs.showConfirm(language.delete, language.delete_quest_rec)
          .then(oResp => {
            if (!oResp.isConfirmed) return;
            this.finalDeleteCustomer(data);
          });
      },
      finalDeleteCustomer(data: Customer){
        appFxs.showAjaxLoader();

                NetServices.requestPost('managt/customers/saveonecustomer', {
                    customerRecord: data,
                    toDelete: true,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const fList: Customer[] = [];
                    oRes.listOfCustomers!.forEach(oEmail => {
                        const fEmail = {...oEmail, DateCreated: new Date(oEmail.DateCreated) }
                        fList.push(fEmail);
                    });

                    this.listOfCustomers = fList;
                    
                    Swal.close();
                });
      },
      refreshList(){
          appFxs.showAjaxLoader();

          NetServices.requestGet('managt/customers/getlistcustomers')
          .then(oRes => {
              if(!oRes.bReturn){
                      appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                      .then(xRes => {
                          //router.replace({path: '/app/myprofile', replace: true});
                          Swal.close();
                      });
                      return;
                  }

              const fList: Customer[] = [];
              oRes.listOfCustomers!.forEach(oEmail => {
                  const fEmail = {...oEmail, DateCreated: new Date(oEmail.DateCreated)}
                  fList.push(fEmail);
              });

              this.listOfCustomers = fList;
              Swal.close();
          });
      },
      printListItems(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.list_of_customers;
        dPrint.ReportToPrint = 6;

        this.printInitialValues = dPrint;


        this.isPrintvisible = true;
      },
      printItemsSituation(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = `${language.print_situation} - ${language.customers}`;
        dPrint.ReportToPrint = 35;

        this.printInitialValues = dPrint;
        this.printExtraData = {
          CurrentYear: this.currentYear,
          SituationTypeOper: 0,
        }


        this.isPrintvisible = true;
      },
      printElementSit(data: Customer){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = `${language.customer} - ${data.ClientName}`;
        dPrint.ReportToPrint = 34;

        this.printInitialValues = dPrint;
        this.printExtraData = {
          CurrentYear: this.currentYear,
          SituationSearchKey: data.ClientKey,
          SituationTypeOper: 0,
        }


        this.isPrintvisible = true;
      },
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },
      sendSms(oCustomer: Customer){
        const phoneNbr = oCustomer.Phone;
        const onlyReqPh = phoneNbr.substring(1);

        const oCountries = this.countries.filter(oCtry => oCtry.code == oCustomer.Country);
        const oCountry = oCountries.length > 0 ? appFxs.getProxyData(oCountries[0]): null;
        if(!oCountry){
          appFxs.showAlert(language.country, language.countr_miss_msg);
          return;
        }
        const dialCode = (oCountry as TelCode).dial_code.substring(1);
        const finalPhoneNbr = `${dialCode}${onlyReqPh}`;

        this.customerPhoneNbr = finalPhoneNbr;
        this.isSmsDlgVis = true;
      },
      makeCall(phoneNbr: string){
        appFxs.makeVoiceSinchaCall(phoneNbr);
      },
      sendEmail(oCustomer: Customer){
        this.recipientEmails = [oCustomer.Email]
        this.isEmailDlgVis = true;
      },

      onCloseSmsDialog(evt: any){
        this.isSmsDlgVis = false;
        const fFinal = appFxs.getProxyData(evt);
        if(fFinal.returnType === 1){
          const bRet = fFinal.returnData.bReturn as boolean;
          if(bRet){
            appFxs.showAlert(language.success, language.sms_success_sent);
          }else{
            appFxs.showAlert(language.failure, language.sms_failure_sent);
          }
        }
      },
      onCloseEmailDialog(evt: any){
        this.isEmailDlgVis = false;
        const fFinal = appFxs.getProxyData(evt);
        if(fFinal.returnType === 1){
          const bRet = fFinal.returnData.bReturn as boolean;
          if(bRet){
            appFxs.showAlert(language.email, language.email_sent_success);
          }else{
            appFxs.showAlert(language.failure, language.email_failure_sent);
          }
        }
      }
    },
    mounted(){
      document.title = this.language.list_of_customers + ' - ' + this.language.appName;
      this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
      this.currentYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, newDate.getFullYear().toString()));
      const countries = JSON.parse(appFxs.getLocalStorageByKey(
        appDeclarations.telephonesCodes, '[]')) as TelCode[];

      this.countries = countries;


      NetServices.requestGet('managt/customers/getlistcustomers')
        .then(oRes => {
            if(!oRes.bReturn){
                    appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                    .then(xRes => {
                        router.replace({path: '/app/myprofile', replace: true});
                    });
                    return;
                }

            const fList: Customer[] = [];
            oRes.listOfCustomers!.forEach(oEmail => {
              const fEmail = {...oEmail, DateCreated: new Date(oEmail.DateCreated)}
                  fList.push(fEmail);
            });

            this.listOfCustomers = fList;
            
            this.isStillLoading = false;
        });
    }
  })
