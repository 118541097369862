import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b07e5c1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert alert-danger position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: "javascript:;",
      class: "close-btn",
      title: "Close",
      "ng-click": "removeServerError()",
      "data-toggle": "tooltip",
      "data-placement": "top",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCloseEvt && _ctx.onCloseEvt(...args)))
    }, [
      _createVNode(_component_font_awesome_icon, {
        style: {"color":"black"},
        icon: ['fas', 'times-circle']
      })
    ]),
    _createTextVNode(" " + _toDisplayString(_ctx.errorMessage), 1)
  ]))
}