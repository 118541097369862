import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass([{'d-none': !_ctx.isCompentVis}, "pagination"]),
    style: _normalizeStyle(_ctx.ulStyles)
  }, [
    _createElementVNode("li", {
      class: _normalizeClass(["page-item first", {'disabled': _ctx.pager.currentPage === 1}]),
      style: _normalizeStyle(_ctx.liStyles)
    }, [
      _createElementVNode("a", {
        class: "page-link",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPage(1))),
        style: _normalizeStyle(_ctx.aStyles)
      }, _toDisplayString(_ctx.labels.first), 5)
    ], 6),
    _createElementVNode("li", {
      class: _normalizeClass(["page-item previous", {'disabled': _ctx.pager.currentPage === 1}]),
      style: _normalizeStyle(_ctx.liStyles)
    }, [
      _createElementVNode("a", {
        class: "page-link",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPage(_ctx.pager.currentPage - 1))),
        style: _normalizeStyle(_ctx.aStyles)
      }, _toDisplayString(_ctx.labels.previous), 5)
    ], 6),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pager.pages, (page) => {
      return (_openBlock(), _createElementBlock("li", {
        key: page,
        class: _normalizeClass(["page-item page-number", {'active': _ctx.pager.currentPage === page}]),
        style: _normalizeStyle(_ctx.liStyles)
      }, [
        _createElementVNode("a", {
          class: "page-link",
          onClick: ($event: any) => (_ctx.setPage(page)),
          style: _normalizeStyle(_ctx.aStyles)
        }, _toDisplayString(page), 13, _hoisted_1)
      ], 6))
    }), 128)),
    _createElementVNode("li", {
      class: _normalizeClass(["page-item next", {'disabled': _ctx.pager.currentPage === _ctx.pager.totalPages}]),
      style: _normalizeStyle(_ctx.liStyles)
    }, [
      _createElementVNode("a", {
        class: "page-link",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPage(_ctx.pager.currentPage + 1))),
        style: _normalizeStyle(_ctx.aStyles)
      }, _toDisplayString(_ctx.labels.next), 5)
    ], 6),
    _createElementVNode("li", {
      class: _normalizeClass(["page-item last", {'disabled': _ctx.pager.currentPage === _ctx.pager.totalPages}]),
      style: _normalizeStyle(_ctx.liStyles)
    }, [
      _createElementVNode("a", {
        class: "page-link",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setPage(_ctx.pager.totalPages))),
        style: _normalizeStyle(_ctx.aStyles)
      }, _toDisplayString(_ctx.labels.last), 5)
    ], 6)
  ], 6))
}