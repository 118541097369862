
  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';
  import DataFxs from '@/tools/data_functions';

  import Swal from "sweetalert2";
  import { ProdProduct, ProductVariantDetail } from "@/models/producttypes";
  import { Customer, TaxSett } from "@/models/managttypes";

  import { SalesQuote, SalesQuoteDetail } from "@/models/salestypes";
  import { DiscountWithRules, SalesQuoteInfo } from "@/models/notdb";

  import SalesQuoteDetailModal from '@/components/modals/SalesQuoteDetailModal.vue';



  const language =GetLanguage(),
    appFxs = GeneralFx(),
    dataFxs = DataFxs();
  const fNewDate = new Date(),
    year = fNewDate.getFullYear();

  const emptySalesQuote:SalesQuote = {
    IdQuote: 0,
    QuoteKey: '',
    QuoteNbr: '',
    ClientKey: '',
    OperDate: fNewDate,
    UserEmail: '',
    QuoteStatus: 0,
    DeviseISO: '',
    OfficeCode: '',
    QuoteNote: '',
    QuoteBarcode: '',
    QuoteQRCode: '',
    QuoteCreatedBy: '',
    FiscalYear: 0,
    TempoKey: '',
    GeneratedOrderKey: '',
  };
  const emptyQuoteDetail: SalesQuoteDetail = {
    IdQuoteDet: 0,
    QuoteKey: '',
    QuoteDetailKey: '',
    ProductSKU: '',
    ProductOrient: 0,
    VariantValues: '',
    Quantity: 1,
    UnitPrice: 1,
    SubTotal: 1,
    DiscountAmount: 0,
    TaxableAmount: 0,
    TaxKey: '',
    TaxRate: 0,
    TaxValue: 0,
    TotalAmt: 0,
    FiscalYear: year,
    TempoKey: '',
    DiscountKeys: '',
  };

  
  export default defineComponent({
    name: 'QuoteNewOps',
    components: {
      SalesQuoteDetailModal,
    },
    data(){
      return {
        appFxs,
        language,        
        isStillLoading: true,
        itemSearchKey: '',
        itemsPerPage: 5,
        selectedYear: year,
        currCode: 'RWF',
        userEmail: '',

        currentCustomer: null as Customer|any,
        currentQuoteOper: {...emptySalesQuote} as SalesQuote,
        listOfQuoteDetails: [] as SalesQuoteDetail[],
        itemsPageElts: [] as SalesQuoteDetail[],

        isSavingRecord: false,



        listOfProducts: [] as ProdProduct[],
        lstOfProdVariantValues: [] as ProductVariantDetail[],
        listOfDiscountWithRules: [] as DiscountWithRules[],
        listOfTaxSettings: [] as TaxSett[],

        savedSalesOperKey: '',
        salesQuoteInfo: null as SalesQuoteInfo|any,

        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 8,
          ReportTitle: language.list_of_brands
        } as any,
        printExtraData: {},


        isSalesDetailVis: false,
        salesDetInitVals: {...emptyQuoteDetail} as SalesQuoteDetail,
        salesDetRecStatus: 0,
      }
    },
    computed: {      
      filteredRecordItems(): SalesQuoteDetail[] {
        let searchKey = this.itemSearchKey;

        if (searchKey.length === 0) return this.listOfQuoteDetails;
        searchKey = searchKey.toLowerCase();

        const filterd = this.listOfQuoteDetails.filter(oDev => {
          return oDev.ProductSKU.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
      relatedPurchase(): { bReady: boolean, record: SalesQuote } {
        const bOReady = this.currentQuoteOper.ClientKey.length > 0;


        return {
          bReady: bOReady,
          record: this.currentQuoteOper,
        }
      },
      getCustomerName(): string {
        return this.currentCustomer == null ? '' : (this.currentCustomer as Customer).ClientName;
      },
      getTotalAmt(): number{
        const lst = this.listOfQuoteDetails;
        const total = lst.reduce((sum, curr) => sum + curr.TotalAmt, 0);
        return total;
      },
    },
    methods: {
      saveRecordData(){
        appFxs.showConfirm(language.save, language.save_quote_oper_qst_msg)
        .then(vRes => {
          if(vRes.isConfirmed){
            this.saveRecordDataFinal();
          }
        });
      },
      saveRecordDataFinal(){
        appFxs.showAjaxLoader();
        const oSales  = appFxs.getProxyData(this.currentQuoteOper) as SalesQuote;
        const oDetails  = appFxs.getProxyData(this.listOfQuoteDetails) as SalesQuoteDetail[];
        NetServices.requestPost('salesorders/quotes/savequotesoperationrec',{
          salesQuoteRecord: oSales,
          salesQuoteDetails: oDetails,
        }).then(oRes => {
          if(!oRes.bReturn){
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }
          const currentQuoteKey = oRes.currentQuoteKey!;
          this.savedSalesOperKey = currentQuoteKey;

          Swal.close();

          router.replace({path: '/app/sales/quotes/list', replace: true});
        });
      },
      getProductName(oDetail: SalesQuoteDetail): string{
        const prodType = oDetail.ProductOrient;
        const lstProds = this.listOfProducts;
        const lstVars = this.lstOfProdVariantValues;
        
        const oneProduct = lstProds.find(o => o.SKU == oDetail.ProductSKU);

        if(!oneProduct) {
          return '';
        }

        if(prodType==0) return oneProduct.Name;

        const oVar = lstVars.find(o => o.VariantValues == oDetail.VariantValues);
        if(!oVar)return oneProduct.Name;

        return `${oneProduct.Name} (${oVar.VariantValues})`;
      },
      addSalesQuoteDetails(iStatus: number, oRecord: SalesQuoteDetail|any){
        if(iStatus == 0){
          const fTempo = {...emptyQuoteDetail} as SalesQuoteDetail;
          fTempo.QuoteKey = this.currentQuoteOper.QuoteKey;
          fTempo.QuoteDetailKey = appFxs.generateUUID();
          fTempo.ProductOrient = 0;
          fTempo.FiscalYear = this.selectedYear;
          fTempo.TempoKey = this.currentQuoteOper.TempoKey;

          this.salesDetInitVals = fTempo;
        }else{
          this.salesDetInitVals = {...oRecord} as SalesQuoteDetail;
        }

        this.isSalesDetailVis = true;
      },
      onDetailClose(evt: any) {
        if (evt.returnType == 0) {
          this.isSalesDetailVis = false;
          return;
        }

        this.isSalesDetailVis = false;

        const finalRet = appFxs.getProxyData(evt.returnData);
        this.insertOrUpdateSalesDetail(finalRet as SalesQuoteDetail);
      },
      insertOrUpdateSalesDetail(oDetail: SalesQuoteDetail){
        const existings = [...this.listOfQuoteDetails];
        const oneFound = existings.find(o => 
          o.ProductSKU == oDetail.ProductSKU && 
          o.VariantValues == oDetail.VariantValues);
        let bExist = oneFound != undefined;
        

        if(!bExist){
          existings.push(oDetail);
          this.listOfQuoteDetails = existings;
        }else{
          const toUpdates = existings.filter(o => {
            const bChecker = o.ProductSKU == oDetail.ProductSKU && 
                            o.VariantValues == oDetail.VariantValues;
            return !bChecker;
          });
          const oSelectedProd = this.listOfProducts.find(o => 
            o.SKU == oDetail.ProductSKU);
          if(oSelectedProd == undefined)return;
          if(oneFound == undefined) return;
          const finalProduc = oSelectedProd as ProdProduct;
          const finalFound = oneFound as SalesQuoteDetail;
          const newUpdateRec = dataFxs.getNewQuoteDetailOper(
            this.listOfDiscountWithRules,
            this.listOfTaxSettings,
            finalProduc,
            this.currentQuoteOper,
            finalFound,
            oDetail
            );

            toUpdates.push(newUpdateRec);
            this.listOfQuoteDetails = toUpdates;
        }
      },

      deleteQuoteDetail(oRecord: SalesQuoteDetail){
        appFxs.showConfirm(language.delete, language.delete_quest_rec)
          .then(vRes => {
            if(vRes.isConfirmed){
              this.deleteQuoteDetailFinal(oRecord);
            }
          })
      },
      deleteQuoteDetailFinal(oRecord: SalesQuoteDetail){
        const fRemains = this.listOfQuoteDetails.filter(o => o.QuoteDetailKey != oRecord.QuoteDetailKey);
        this.listOfQuoteDetails = fRemains;
      },
      refreshList(){},
      onPageChange(evt: any){
            this.itemsPageElts = appFxs.getProxyData(evt);
      },    
      
      printListItems(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.list_of_brands;
        dPrint.ReportToPrint = 12;

        this.printInitialValues = dPrint;

        this.isPrintvisible = true;
      },      
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },
    },
    mounted(){   
      document.title = this.language.new_quote_oper + ' - ' + this.language.appName;
      this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
      this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
      this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
      this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));

      const operState = appFxs.getLocalStorageByKey(appDeclarations.salesNewOperationState, '0');

      if(operState == '0'){
        appFxs.showAlert(language.missing_settings, language.missing_settings_msg)
        .then(vRes => {
          router.push({
            name: 'appsalesquoteslist',
          });
        });
        return;
      }

      const tempoSalesRecord = JSON.parse(appFxs.getLocalStorageByKey(appDeclarations.tempoSalesRecord, '{}'));
      const tempoLstProducts = appFxs.getLocalStorageByKey(appDeclarations.tempoLstProducts, '[]');
      const tempoLstVariants = appFxs.getLocalStorageByKey(appDeclarations.tempoLstVariants, '[]');
      const tempoCustomerRec = appFxs.getLocalStorageByKey(appDeclarations.tempoCustomerRecord, '{}');
      
      const tempoLstTaxes = appFxs.getLocalStorageByKey(appDeclarations.tempoLstTaxes, '[]');
      const tempoLstDiscountsWRules = appFxs.getLocalStorageByKey(appDeclarations.tempoLstDiscountsWRules, '[]');

      const tempoSalesRecordF = {...tempoSalesRecord, OperDate: new Date(tempoSalesRecord.OperDate)};

      this.currentQuoteOper = tempoSalesRecordF as SalesQuote;
      this.listOfProducts = JSON.parse(tempoLstProducts) as ProdProduct[];
      this.lstOfProdVariantValues = JSON.parse(tempoLstVariants) as ProductVariantDetail[];
      
      this.listOfTaxSettings = JSON.parse(tempoLstTaxes) as TaxSett[];
      this.listOfDiscountWithRules = JSON.parse(tempoLstDiscountsWRules) as DiscountWithRules[];

      this.currentCustomer = JSON.parse(tempoCustomerRec) as Customer;

      appFxs.removeLocalStorageByKey(appDeclarations.tempoSalesRefund);
      appFxs.removeLocalStorageByKey(appDeclarations.tempoSalesRecord);
      appFxs.removeLocalStorageByKey(appDeclarations.tempoLstProducts);
      appFxs.removeLocalStorageByKey(appDeclarations.tempoLstVariants);
      appFxs.removeLocalStorageByKey(appDeclarations.tempoCustomerRecord);
      
      appFxs.setLocalStorage(appDeclarations.salesNewOperationState, '0');
      this.isStillLoading = false;

    }
  })
