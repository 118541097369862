
    import { useVuelidate } from '@vuelidate/core';
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent, toRaw } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { ShortData } from '@/tools/smarttypes';

    import appDeclarations from '@/tools/declarations';
    import { SMSAccountSett, SMSDatum } from '@/models/managttypes';

    import NetServices from '@/tools/netservices';

    const language =GetLanguage(),
        appFxs = GeneralFx();

    const emptySmsDatum: SMSDatum = {
        SMSId: 0,
        SMSKey: '',
        UserEmail: '',
        SmsAccount: '',
        Recipients: '',
        SMSBody: '',
        DateSent: new Date(),
        SMSSource: 0,
        SMSSourceKey: '', 
        StatusSMS: 0, 
        Important: false,
        IsDeleted: false,
        Label: '', 
    }
    export default defineComponent({
        name: 'SMSSenderDialog',
        emits: ['closeForm'],
        props: ["visible", "initialValues", "phoneNumber"],
        data(){
            return{
                v$: useVuelidate(),
                language,
                appFxs,
                dataRecord: {...emptySmsDatum},
                listOfSmsAccts: [] as SMSAccountSett[],
                isSending: false,
            }
        },
        methods: {
            sendRecordSms(){
                const smsData = appFxs.getProxyData(this.dataRecord);
                const editType = (smsData as SMSDatum).SMSId == 0 ? 0: 1;
                
                this.isSending = true;
                NetServices.requestPost('comm/sms/sendgeneralsmstobase', {
                    smsDatumModel: smsData,
                    editType: editType
                })
                .then(oRes => {
                    if(!oRes.bReturn){
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                        .then(vRes => {
                            this.isSending = false;
                        });

                        return;
                    };

                    this.isSending = false;
                    this.$emit('closeForm', {returnType: 1, returnData: {bReturn: oRes.smsStatus == 1}});
                });                
            },
            closeFormRet(){
                this.isSending = false;
                this.$emit('closeForm', {returnType: 0, returnData: {bReturn: false}});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            dateToYYYYMMDD(d: Date) {
                return d && new Date(d.getTime()-(d.getTimezoneOffset()*60*1000)).toISOString().split('T')[0]
            },
            setDateChange(evt: Event){
                this.dataRecord.DateSent = (evt.target as HTMLInputElement).valueAsDate!;
            },
        },
        validations(){
            return {
                dataRecord: {
                    SmsAccount: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    Recipients: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    SMSBody: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                }
            }
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if(newV != oldV){
                        this.dataRecord = {...emptySmsDatum};
                        const userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
                        this.dataRecord.UserEmail = userEmail;
                        this.dataRecord.Recipients = this.phoneNumber;
                    }
                }
            }
        },
        mounted(){
            const lstSmsAccts = JSON.parse(appFxs.getLocalStorageByKey(appDeclarations.listOfSmsAccounts, '[]'));
            this.listOfSmsAccts = lstSmsAccts as SMSAccountSett[];
            const userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
            this.dataRecord.UserEmail = userEmail;

            this.isSending = false;

        }
    });
