
    import { defineComponent, toRaw } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    
    import NetServices from '@/tools/netservices';
    import { ProdCategory, ProdSubCategory } from '@/models/producttypes';
    

    const language =GetLanguage(),
        appFxs = GeneralFx();
    
    const emptySubCategory: ProdSubCategory = {
        ProdCategoryId: 0,
        Code: '',
        SubCode: '',
        NameEn: '',
        NameFr: '',
        NameRw: '',
        Description: '',
    };
    export default defineComponent({
        name: 'ProdSubCategModal',
        emits: ['closeForm'],
        props: ["visible", "initialValues", "recordStatus", "listCategories"],
        data(){
            return {
                language,
                appFxs,
                dataRecord: { ...emptySubCategory } as ProdSubCategory,
                isSavingRecord: false,
                categories: [] as ProdCategory[],
            }
        },
        computed: {        
            windowTitle():string {
                return this.recordStatus === 0 ? language.new_sub_categ:
                language.edit_sub_categ;
            },
            validationRules(){                
                const codeErrorValid = !this.dataRecord.Code;
                const codeErrorMsg = [] as string[];
                if(codeErrorValid) codeErrorMsg.push(language.fldRequired);

                             
                const SubCodeErrorValid = !this.dataRecord.SubCode;
                const SubCodeErrorMsg = [] as string[];
                if(SubCodeErrorValid) SubCodeErrorMsg.push(language.fldRequired);

                
                const nameEnErrorValid = !this.dataRecord.NameEn;
                const nameEnErrorMsg = [] as string[];
                if(nameEnErrorValid) nameEnErrorMsg.push(language.fldRequired);

                const NameFrErrorValid = !this.dataRecord.NameFr;
                const NameFrErrorMsg = [] as string[];
                if (NameFrErrorValid) NameFrErrorMsg.push(language.fldRequired);

                const NameRwErrorValid = !this.dataRecord.NameRw;
                const NameRwErrorMsg = [] as string[];
                if (NameRwErrorValid) NameRwErrorMsg.push(language.fldRequired);


                const formInvalid = codeErrorValid || SubCodeErrorValid || NameFrErrorValid || nameEnErrorValid || NameFrErrorValid || NameRwErrorValid;

                return {
                    $invalid: formInvalid,
                    fields: {
                        Code: {
                            $error: codeErrorMsg,
                            $errors: codeErrorMsg
                        },
                        SubCode: {
                            $error: SubCodeErrorValid,
                            $errors: SubCodeErrorMsg
                        },
                        NameEn: {
                            $error: nameEnErrorValid,
                            $errors: nameEnErrorMsg
                        },
                        NameFr: {
                            $error: NameFrErrorValid,
                            $errors: NameFrErrorMsg
                        },
                        NameRw: {
                            $error: NameRwErrorValid,
                            $errors:NameRwErrorMsg
                        },
                    }
                }
            }
        },
        methods: {
            selectOnFocus(evt: Event) {
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveNewCategory() {
                this.isSavingRecord = true;
                const initRec = appFxs.getProxyData(this.dataRecord) as ProdSubCategory;
                const fRecord = { ...initRec, Code: initRec.Code.toUpperCase()  };
                
                NetServices.requestPost('products/categs/savesubcategoryrecord', {
                    prodSubCategoryRecord: fRecord,
                    toDelete: false,
                }).then(oRes => {
                    if (!oRes.bReturn) {
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        this.isSavingRecord = false;
                        return;
                    }

                    const fList: ProdSubCategory[] = [];
                    oRes.listOfProdSubCategories!.forEach(oEmail => {
                        const fEmail = { ...oEmail }
                        fList.push(fEmail);
                    });

                    this.isSavingRecord = false;
                    this.$emit('closeForm', {returnType: 1, returnData: {bReturn: true, listOfSubCategories: fList}});
                });
            },            
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            getCategoryName(oCateg: ProdCategory) {
                const oLang = appFxs.getLocalLanguage();
                return oLang == 'en' ? oCateg.NameEn :
                    oLang == 'fr' ? oCateg.NameFr :
                        oLang == 'rw' ? oCateg.NameRw :
                            oCateg.NameEn;
            },
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: ProdSubCategory = {...oValues};

                        const lstCategs = appFxs.getProxyData(this.listCategories);
                        const fLst: ProdCategory[] = [...lstCategs];

                        this.dataRecord = fValues;
                        this.categories = fLst;
                    }
                }
            }
        },
        
    });
