
    import { defineComponent } from "vue";
    import {RouterLink } from 'vue-router';
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { InvoiceSett } from "@/models/managttypes";
    import { CurrencyCode } from "@/tools/smarttypes";

    import Swal from "sweetalert2";
    import InvoiceOptionsSettingsModal from "@/components/modals/InvoiceOptionsSettingsModal.vue";


    const language =GetLanguage(),
    appFxs = GeneralFx();

    const emptyInvoiceSett: InvoiceSett = {
        InvoiceSettId: 0,
        Currency: '',
        InvoiceFooter: '',
        SendReminder: 0,
    }

    export default defineComponent({
        components: {
            InvoiceOptionsSettingsModal,
        },
        data(){
            return {
                language,
                isStillLoading: true,
                listOfCurrencies: [] as CurrencyCode[],
                currentInvoiceSetting: emptyInvoiceSett,
                isDialogOpen: false,
                dialogRecordStatus: 0,
                dialogRecordData: emptyInvoiceSett,
            }
        },
        computed: {
            getCurrencyName(): string{
                if(!this.currentInvoiceSetting.Currency)return '';
                const cCurrency = this.currentInvoiceSetting;

                const filtredCurr = this.listOfCurrencies.filter(o => o.code == cCurrency.Currency);
                if(filtredCurr.length == 0) return '';
                return filtredCurr[0].name;
            }
        },
        methods: {
            openInvoiceSettWindow(){
                this.dialogRecordData = {...this.currentInvoiceSetting};
                this.dialogRecordStatus = 1;
                
                this.isDialogOpen = true;
            },
            onCloseForm(evt: any){
                this.isDialogOpen = false;

                if(evt.returnType===0)return;

                const returnData = appFxs.getProxyData(evt.returnData);
                appFxs.showAjaxLoader();

                NetServices.requestPost('managt/settings/savecurrentinvsetts', {
                    invoiceSettingRecord: returnData,
                }).then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();

                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    this.currentInvoiceSetting = oRes.invoiceSetting!;
                    
                    Swal.close();
                });
            }
        },
        mounted(){
            document.title = `${this.language.settings}/${this.language.invoiceOptions} - ${this.language.appName}`;
            const lCurrencies = appFxs.getLocalStorageByKey(appDeclarations.currencySymbols, '[]');
            this.listOfCurrencies = JSON.parse(lCurrencies) as CurrencyCode[];

            NetServices.requestGet('managt/settings/getinvoicecurrencysett')
                .then(oRes => {
                    if(!oRes.bReturn){
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                        .then(xRes => {
                            router.replace({path: '/app/myprofile', replace: true});
                        });
                        return;
                    }

                    this.currentInvoiceSetting = oRes.invoiceSetting!;
                    this.isStillLoading = false;
                });
        }
    })
