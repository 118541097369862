
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent, toRaw } from 'vue';
    import { SMSAccountSett } from '@/models/managttypes';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { TelCode } from '@/tools/smarttypes';
    import appDeclarations from '@/tools/declarations';

    const language =GetLanguage(),
        appFxs = GeneralFx();

    const emptySms = {
        SMSAcctId: 0,
        SmsAccount: '',
        Password: '',
        Country: 'RW',
        CallCode: '250',
        PhoneNumber: '',
        PhoneNumberFormat: '',
        StillActive: true,
        SMSDateCreated: new Date()
    };
    const selectedCountry = {name: 'Rwanda', dial_code: '250', code: 'RW'} as TelCode;

    export default defineComponent({
        name: 'SmsSettModal',
        emits: ['closeForm'],
        props: ["visible", "initialValues", "recordStatus"],
        data(){
            return {
                v$: useVuelidate(),
                language,
                dataRecord: emptySms as SMSAccountSett,
                countries: [] as TelCode[],
                selectedCountry,
            }
        },
        methods: {
            onCountryChanged(evt: Event){
                const val = (evt.target as HTMLInputElement).value;
                const ctrs = this.countries.filter(o => o.code === val);
                const oneCountry = ctrs.length > 0 ? ctrs[0]: {name: 'Rwanda', dial_code: '250', code: 'RW'};
                this.selectedCountry = oneCountry;
                this.dataRecord.CallCode = oneCountry.dial_code.slice(1);
                this.getFormatedNbr();
            },
            getFormatedNbr() {
                const rec = this.dataRecord;
                if (rec.CallCode && rec.PhoneNumber) {
                    let _ph = rec.PhoneNumber.slice(1);
                    this.dataRecord.PhoneNumberFormat =
                        rec.CallCode + _ph;
                } else {
                    this.dataRecord.PhoneNumberFormat = '';
                }
            },
            onOtherFieldChanged() {
                this.getFormatedNbr();
            },
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.$emit('closeForm', {returnType: 1, returnData: this.dataRecord});
            }
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: SMSAccountSett = {...oValues, SMSDateCreated: new Date(oValues.SMSDateCreated)};

                        if(this.recordStatus == 0){
                            this.v$.$reset();
                        }

                        this.dataRecord = fValues;
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {                
                    SmsAccount: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },              
                    Password: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },              
                    PhoneNumber: {
                        required: helpers.withMessage(language.fldRequired, required),
                        minLength: helpers.withMessage(language.minLengthReqMsg, minLength(10)),
                        maxLength: helpers.withMessage(language.maxLengthReqMsg, maxLength(10)),
                        $autoDirty: true,
                    },
                }
            }
        },
        computed: {        
            windowTitle():string {
                return this.recordStatus === 0 ? language.add_sms_acct:
                language.edit_sms_acct;
            }
        },
        mounted(){
            const oCtries = appFxs.getLocalStorageByKey(appDeclarations.telephonesCodes, '[]');
            const countries = JSON.parse(oCtries);

            this.countries = countries;
        }
    })
