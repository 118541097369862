
    import { defineComponent } from 'vue';
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import AppDeclarations from '@/tools/declarations';
    import { DeliveryRemainingData } from '@/models/notdb';

    const language = GetLanguage(),
        appFxs = GeneralFx();

    const emptyData: DeliveryRemainingData = {
        OrderKey: '',
        OrderDetailKey: '',
        ProductSku: '',
        ProductOrient: 0,
        VariantValues: '',
        OrderQty: 0,
        DeliveredQty: 0,
        RemainingQty: 0,
        ToDeliverQty: 0,
        ProductName: '',
    }
    export default defineComponent({
        name: 'DeliveryInputData',
        emits: ['onUpdate'],
        props: [
            "remainingDataRecord"
        ],
        data(){
            return{
                language,
                appFxs,
                inputValue: this.remainingDataRecord as DeliveryRemainingData,
            };
        },
        computed: {
            isAddPossible(): boolean{
                return this.inputValue.ToDeliverQty < 
                this.inputValue.RemainingQty;
            },
            isSubPossible(): boolean{
                return this.inputValue.ToDeliverQty > 0;
            }
        },
        methods: {
            subOper(){
                this.inputValue.ToDeliverQty -= 1;
                this.$emit('onUpdate', this.inputValue);
            },
            addOper(){
                this.inputValue.ToDeliverQty += 1;
                this.$emit('onUpdate', this.inputValue);
            },
            changeManualValue(){
                const oVal = this.inputValue.ToDeliverQty;
                if(oVal > this.inputValue.RemainingQty)  {
                    this.inputValue.ToDeliverQty = this.inputValue.RemainingQty;
                }
            }
        },
        watch: {
            remainingDataRecord: {
                handler(newV, oldV) {
                    if (newV !== oldV) {
                        this.inputValue = newV as DeliveryRemainingData;
                    }
                },
            },
        },
    })
