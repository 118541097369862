
    import { defineComponent } from "vue";

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { ShortData } from "@/tools/smarttypes";

    import NetServices from '@/tools/netservices';

    const language =GetLanguage(),
            appFxs = GeneralFx(),
            fDate = new Date(); 

    export default defineComponent({
        data(){
            return {
                language,
                appFxs,
                isStillLoading: false,
                currentYear: fDate.getFullYear(),
                currentMonth: fDate.getMonth()+1,
                currencyLabel: 'RWF',

                isPrintvisible: false,
                printInitialValues: {
                ...appFxs.getReportingData(),
                ReportToPrint: 6,
                ReportTitle: language.list_of_customers} as any,
                printExtraData: {},
            }
        },
        computed: {
            getListMonths(): ShortData[] {
                return appFxs.getListMonthsByYear(this.currentYear);
            }
        },
        methods: {
            onPrintClose(evt: any) {
                this.isPrintvisible = false;
                if (evt.returnType === 0) return;
            },            
            printCusomrsList(){
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = language.list_of_customers;
                dPrint.ReportToPrint = 6;

                this.printInitialValues = dPrint;


                this.isPrintvisible = true;
            },
            printCusomrsSitList(){
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = `${language.print_situation} - ${language.customers}`;
                dPrint.ReportToPrint = 35;

                this.printInitialValues = dPrint;
                this.printExtraData = {
                    CurrentYear: this.currentYear,
                    SituationTypeOper: 0,
                }

                this.isPrintvisible = true;
            },
            printSuppliersList() {
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = language.list_of_suppliers;
                dPrint.ReportToPrint = 7;

                this.printInitialValues = dPrint;


                this.isPrintvisible = true;
            },
            printSuppliersSitList(){
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = `${language.print_situation} - ${language.suppliers}`;
                dPrint.ReportToPrint = 35;

                this.printInitialValues = dPrint;
                this.printExtraData = {
                CurrentYear: this.currentYear,
                    SituationTypeOper: 1,
                }


                this.isPrintvisible = true;
            },
        },
        mounted(){
            document.title = this.language.analyticals + ' - ' + this.language.appName; 
        }
    })
