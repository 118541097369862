import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-3" }
const _hoisted_3 = { class: "list-group" }
const _hoisted_4 = { class: "col-md-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_RouterLink, {
          to: "/app/suppliers/list",
          class: "list-group-item list-group-item-action"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.language.list), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_RouterLink, {
          to: "/app/suppliers/new",
          class: "list-group-item list-group-item-action"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.language.new), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_view)
    ])
  ]))
}