
  import { defineComponent } from "vue";
  import {RouterLink } from 'vue-router';
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';

  import AccountImageArea from '@/components/account/AccountImageArea.vue';
  import LoginForm from '@/components/account/LoginForm.vue';


  const language =GetLanguage(),
    appFxs = GeneralFx();

  export default defineComponent({
    components: {
      AccountImageArea,
      LoginForm,
    },
    data(){
      return {
        language,
        isFormInvalid: true,
        isNetLoading: false,
        isServerError: false,
        errorMessage: '',
      }
    },
    mounted() {
      document.title = this.language.login + ' - ' + this.language.appName;
    },
    methods: {
      closeErrorPanel(){
        this.isServerError = false;
      },
      sendFormToServer(oData: any){
        this.isNetLoading = true;
        this.isServerError = false;

        NetServices.requestPost('managt/account/login', {
          loginRecord: {
            email: window.btoa(oData.userEmail),
            password: window.btoa(oData.userPassword),
            remember: true,
          }
        }).then(oRes => {
          if(!oRes.bReturn){
            this.isServerError = true;
            this.errorMessage = oRes.msgBody;
            this.isNetLoading = false;
            return;
          }

          appFxs.setLocalStorage(appDeclarations.userAccessKey, oRes.accessKey!);
          appFxs.setLocalStorage(appDeclarations.connectionStatus, '1');

          router.replace({path: '/app', replace: true})
        }); 
      }
    }
  });
