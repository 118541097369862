
    import { defineComponent } from "vue";
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';

    import Swal from "sweetalert2";
    import { ProdProduct, ProdStockEntry, ProductVariantDetail, PurchaseOperation, PurchaseOperationDetail } from "@/models/producttypes";
    import { Supplier } from "@/models/managttypes";

    import StockSitutionForPODetail from '@/components/smalls/StockSitutionForPODetail.vue';
    import PurchaseOrderDetailModal from '@/components/modals/PurchaseOrderDetailModal.vue';
    import StockEntryModal from '@/components/modals/StockEntryModal.vue';
    import { OrderDetailInfo } from "@/models/notdb";

    const language =GetLanguage(),
      appFxs = GeneralFx();

    const emptyPurchaseOps: PurchaseOperation = {
        IdPurchase: 0,
        PurchaseKey: '',
        SupplierKey: '',
        PurchaseOrderNbr: '',
        FinancialYear: 2000,
        OperationDate: new Date(),
        OperationStatus: 0,
        UserEmail: '',
    };
    const emptyProd: ProdProduct = {
        ProdProductId: 0,
        SKU: '',
        BarCode: '',
        Name: '',
        ProductType: 0,
        Categories: '',
        SubCategories: '',
        BrandCode: 'BR0001',
        IsKeepStock: true,
        IsTaxable: true,
        AllowPurchaseOutStock: false,
        PurchasePrice: 0,
        SellingPrice: 0,
        ReorderQuantity: 0,
        MinOrderQuantity: 0,
        Tags: '',
        Description: '',
        DefaultSupplier: '',
        DefaultTax: '',
        UnitOfMeasuremt: '',
        DefaultImage: '',
        ProdImage1: '',
        ProdImage2: '',
        ProdImage3: '',
        ProdImage4: '',
        ProdImage5: '',
        YoutubeVideoLink: '',
        PcePerUnit: 1,
    };
    const emptyVariantDetail: ProductVariantDetail = {
        IdVariantDetail: 0,
        ProductSku: '',
        VariantValues: '',
        VariantCodes: '',
        VariantCodesObject: '',
        SKU: '',
        BarCode: '',
        PurchasePrice: 0,
        SellingPrice: 0,
    }
    const emptyPurchaseDetailOper:PurchaseOperationDetail = {
      IdPurchaseDetail: 0,
      PurchaseKey: '',
      PurchaseDetKey: '',
      ProductSku: '',
      ProductType: 0,
      ProductBarcode: '',
      VariantSku: '',
      VariantBarcode: '',
      Qty: 0,
      UnitPrice: 0,
      SousTotal: 0,
      Tax: 0,
      TotalPrice: 0,
      StockTransferStatus: 0,
    }
    const emptyStockEntry: ProdStockEntry = {
        IdStockEntry: 0,
        EntryKey: '',
        ProductSku: '',
        ProductVariantKey: '',
        EntryType: 0,
        PurchaseOrderKey: '',
        PurchaseOrderDetKey: '',
        UserEmail: '',
        SupplierKey: '',
        UnitType: '',
        PcesPerUnit: 0,
        UnitQty: 0,
        Quantity: 0,
        BuyingPrice: 0,
        CostPrice: 0,
        SellingPrice: 0,
        EntryDate: new Date(),
        Description: '',
        FiscalYear: 0,
    }
    const newDate = new Date();
    
    export default defineComponent({
        components: {
          'stock-sit-po-detail': StockSitutionForPODetail,
          PurchaseOrderDetailModal,
          StockEntryModal,
        },
        data(){
            return{
                language,
                appFxs,
                isStillLoading: true,
                itemSearchKey: '',
                itemsPerPage: 5,

                lstOfPurchaseOrderDetails: [] as PurchaseOperationDetail[],
                itemsPageElts: [] as PurchaseOperationDetail[],

                onePurchaseOper: {...emptyPurchaseOps} as PurchaseOperation,
                oneSupplierRec: null as Supplier | any,
                listOfProducts: [] as ProdProduct[],
                lstOfProdVariantValues: [] as ProductVariantDetail[],

                purchaseKey: '',
                itemsSearchKey: '',

                userEmail: '',
                currentYear: newDate.getFullYear(),


                isPrintvisible: false,
                printInitialValues: {
                    ...appFxs.getReportingData(),
                    ReportToPrint: 8,
                    ReportTitle: language.list_of_brands
                } as any,
                printExtraData: {},

                currCode: 'RWF',


                isPODetailVis: false,
                detailInitialValue: {...emptyPurchaseDetailOper} as PurchaseOperationDetail,
                detailRecordStatus: 0,

                isStockVis: false,
                stockEntryRec: {...emptyStockEntry} as ProdStockEntry,
                stockEntryStatus: 0,
                purchDetailInfo: null as OrderDetailInfo | any,
                purchaseDetailRecord: null as PurchaseOperationDetail | any,

                productRecord: null as ProdProduct | any,
                variantDetailRecord: {...emptyVariantDetail} as ProductVariantDetail,
                productType: 0,
            }
        },
        computed: {
            filteredOperationDet(): PurchaseOperationDetail[] {
                let searchKey = this.itemsSearchKey;
                if (searchKey.length === 0) return this.lstOfPurchaseOrderDetails;
                searchKey = searchKey.toLowerCase();

                const filterd = this.lstOfPurchaseOrderDetails.filter(oDev => {
                    return oDev.ProductSku.toLowerCase().includes(searchKey) ||
                        oDev.ProductBarcode.toLowerCase().includes(searchKey) ||
                        oDev.VariantSku.toLowerCase().includes(searchKey) ||
                        oDev.VariantBarcode.toLowerCase().includes(searchKey);
                });
                return filterd;
            },
            relatedPurchase(): {bReady: boolean, record: PurchaseOperation}{
                const bOReady = this.onePurchaseOper.IdPurchase != 0;
                

                return {
                    bReady: bOReady, 
                    record: this.onePurchaseOper,
                }
            },
            getSupplierName():string{
              return this.oneSupplierRec == null ? '': (this.oneSupplierRec as Supplier).SupplierName;
            }
        },
        methods: {
            showAddEditDetailAction(iStatus: number, oRecord: PurchaseOperationDetail | any) {
                if (iStatus == 0) {
                  const oRec = {...emptyPurchaseDetailOper} as PurchaseOperationDetail;
                  oRec.PurchaseKey = this.onePurchaseOper.PurchaseKey;

                  this.detailRecordStatus = 0;
                  this.detailInitialValue = oRec;
                } else {
                  this.detailRecordStatus = 1;
                  this.detailInitialValue = {...oRecord} as PurchaseOperationDetail;
                }

                this.isPODetailVis = true;
            },
            onDetailClose(evt: any){
              if(evt.returnType==0){
                this.isPODetailVis = false;
                return;
              }
              if(!evt.returnData.bReturn){
                this.isPODetailVis = false;
                return;
              }

              const listItems = evt.returnData.listItems;
              this.lstOfPurchaseOrderDetails = listItems as PurchaseOperationDetail[];              
              this.isPODetailVis = false;
            },
            deleteDetailAction(oRecord: PurchaseOperationDetail){
              appFxs.showConfirm(language.delete, language.delete_quest_rec)
                .then(vRes => {
                  if(vRes.isConfirmed)this.deleteDetailActionfinal(oRecord);
                });
            },
            deleteDetailActionfinal(oRecord: PurchaseOperationDetail){
              appFxs.showAjaxLoader();
              NetServices.requestPost('stockops/purchases/savepurchasedetailops', {
                    purchaseOpsDetailRecord: oRecord,
                    toDelete: true,
                }).then(oRes => {
                    if (!oRes.bReturn) {
                        Swal.close();
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    this.$emit('closeForm', {returnType: 1, returnData: {
                        bReturn: true,
                        listItems: oRes.lstOfPurchaseOrderDetails!
                    }});

                    this.lstOfPurchaseOrderDetails = oRes.lstOfPurchaseOrderDetails!;
                    Swal.close();
                });
            },



            onPageChange(evt: any) {
                this.itemsPageElts = appFxs.getProxyData(evt);
            },
            refreshList() {
                appFxs.showAjaxLoader();

                NetServices.requestGet(`stockops/purchases/detailsbypurchaseorder/${this.purchaseKey}`)
                    .then(oRes => {
                        if (!oRes.bReturn) {
                            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                                .then(xRes => {
                                    //router.replace({path: '/app/myprofile', replace: true});
                                    Swal.close();
                                });
                            return;
                        }

                        this.onePurchaseOper = oRes.onePurchaseOper!;
                        this.oneSupplierRec = oRes.oneSupplierRec!;
                        this.listOfProducts = oRes.listOfProducts!;
                        this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;
                        this.lstOfPurchaseOrderDetails = oRes.lstOfPurchaseOrderDetails!;


                        Swal.close();
                    });
            },

            getSku(oRecord: PurchaseOperationDetail): string {
              const oProdCheck = this.listOfProducts.filter(o => o.SKU == 
                oRecord.ProductSku);
              const realProd = oProdCheck.length >0 ? oProdCheck[0]: {...emptyProd} as ProdProduct;

              if(oRecord.ProductType == 0){
                return realProd.SKU;
              }else{
                const oVarCheck = this.lstOfProdVariantValues.filter(o => 
                  o.ProductSku == oRecord.ProductSku && 
                  o.VariantValues == oRecord.VariantSku);
                const oVar = oVarCheck.length >0 ? oVarCheck[0]: {...emptyVariantDetail} as ProductVariantDetail;
                return oVar.SKU;
              }

            },
            getType(oRecord: PurchaseOperationDetail): string {
              return oRecord.ProductType == 0 ? 
                language.product:
                language.variant;
            },
            getProd(oRecord: PurchaseOperationDetail): string {
              const oProdCheck = this.listOfProducts.filter(o => o.SKU == 
                oRecord.ProductSku);
              const realProd = oProdCheck.length >0 ? oProdCheck[0]: {...emptyProd} as ProdProduct;

              if(oRecord.ProductType == 0){
                return realProd.Name;
              }else{
                const oVarCheck = this.lstOfProdVariantValues.filter(o => 
                  o.ProductSku == oRecord.ProductSku && 
                  o.VariantValues == oRecord.VariantSku);
                const oVar = oVarCheck.length >0 ? oVarCheck[0]: {...emptyVariantDetail} as ProductVariantDetail;
                return `${realProd.Name} (${oVar.VariantValues})`;
              }
            },


            entryStockOperation(oRecord: PurchaseOperationDetail){
              appFxs.showAjaxLoader();
              NetServices.requestGet(`stockops/purchases/stockop/getpurchasedetailinfo/${oRecord.PurchaseKey}/${oRecord.PurchaseDetKey}`)
                .then(oRes => {
                  if(!oRes.bReturn){
                    Swal.close();
                    appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                  };
                  const oInfo = oRes.orderDetailInfo!;


                  if(oInfo.RemainQty == 0){
                    appFxs.showAlert(language.rem_purch_miss, language.rem_purch_miss_msg);
                    return;
                  }

                  
                  


                  this.purchDetailInfo = oInfo;
                  this.purchaseDetailRecord = oRecord;
                  this.stockEntryStatus = 0;

                  const fSuppl = this.oneSupplierRec as Supplier;
                  const fProd = this.listOfProducts.filter(o => o.SKU == oRecord.ProductSku);
                  if(fProd.length == 0){
                    Swal.close();
                    appFxs.showAlert(language.prod_miss, language.prod_miss_msg);
                    return;
                  }

                  const finalProd  = fProd[0];

                  this.productType = oRecord.ProductType;
                  this.productRecord = finalProd;

                  const finalRecord = {...emptyStockEntry} as ProdStockEntry;
                  finalRecord.ProductSku = oRecord.ProductSku;
                  finalRecord.ProductVariantKey = oRecord.VariantSku;
                  finalRecord.EntryType = 0;
                  finalRecord.PurchaseOrderKey = oRecord.PurchaseKey;
                  finalRecord.PurchaseOrderDetKey = oRecord.PurchaseDetKey;
                  finalRecord.UserEmail = this.userEmail;
                  finalRecord.SupplierKey = fSuppl.SupplierKey;
                  finalRecord.UnitType = finalProd.UnitOfMeasuremt;
                  finalRecord.UnitQty = finalProd.PcePerUnit;


                  const nowQty = oInfo.RemainQty;
                  let unitPrice = oRecord.UnitPrice;
                  let totalPrice = nowQty * unitPrice;
                  let sellPrice = 0;

                  if(oRecord.ProductType==0){
                    sellPrice = finalProd.SellingPrice;
                  }else{
                    var varRec = this.lstOfProdVariantValues.filter(o => o.ProductSku == oRecord.ProductSku && o.VariantValues == oRecord.VariantSku);
                    if(varRec.length > 0){
                      sellPrice = varRec[0].SellingPrice;
                      this.variantDetailRecord = varRec[0];
                    }else{
                      sellPrice = finalProd.SellingPrice;
                    }
                  }

                  finalRecord.Quantity = nowQty;                 

                  finalRecord.BuyingPrice = oRecord.UnitPrice;
                  finalRecord.CostPrice = oRecord.UnitPrice;



                  finalRecord.SellingPrice = sellPrice;

                  finalRecord.EntryDate = new Date();

                  finalRecord.FiscalYear = this.currentYear;
                  finalRecord.Description = '';

                  this.stockEntryRec = {...finalRecord} as ProdStockEntry;







                  Swal.close();

                  this.isStockVis = true;
                });
            },
            onStockDlgClose(evt: any){
              if(evt.returnType==0){
                this.isStockVis = false;
                return;
              }
              if(!evt.returnData.bReturn){
                this.isStockVis = false;
                return;
              }

              const listItems = evt.returnData.listItems as PurchaseOperationDetail[];


              this.lstOfPurchaseOrderDetails = [];

              const fRecs = [] as PurchaseOperationDetail[];
              listItems.forEach(oDet => {
                var _f = {...oDet} as PurchaseOperationDetail;
                fRecs.push(_f);
              });
              
              this.setupFinalItemAfterStockchange(fRecs);
              this.isStockVis = false;
            },
            setupFinalItemAfterStockchange(fRecs: PurchaseOperationDetail[]){
              setTimeout(() => {

                this.lstOfPurchaseOrderDetails = fRecs;  
              }, 500);
            },

            printListItems() {
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = language.list_of_brands;
                dPrint.ReportToPrint = 11;

                this.printInitialValues = dPrint;


                this.isPrintvisible = true;
            },
            onPrintClose(evt: any) {
                this.isPrintvisible = false;
                if (evt.returnType === 0) return;
            },            
            confirmPOrderOps(purchaseKey: string){
              appFxs.showConfirm(language.confirm, language.confirm_purch_order_quest)
                .then(vRes => {
                  if(vRes.isConfirmed){
                    this.confirmPOrderOpsFinal(purchaseKey);
                  }
                });
            },
            confirmPOrderOpsFinal(purchaseKey: string){
              appFxs.showAjaxLoader();

              NetServices.requestGet(`stockops/purchases/confirmacceptpobypokey/${purchaseKey}`)
                .then(oRes => {
                  if(!oRes.bReturn){
                    Swal.close();
                    appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                    return;
                  }

                  const onePO = oRes.onePurchaseOper!;

                  this.onePurchaseOper = onePO;
                  this.oneSupplierRec = oRes.oneSupplierRec!;
                  this.listOfProducts = oRes.listOfProducts!;
                  this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;
                  this.lstOfPurchaseOrderDetails = oRes.lstOfPurchaseOrderDetails!;

                  
                  Swal.close();
                });
            },

            printPurchaseOrder() {
              const dPrint = appFxs.getReportingData();
              dPrint.ReportTitle = `${language.purchase_order} - ${this.relatedPurchase.record.PurchaseOrderNbr}`;
              dPrint.ReportToPrint = 16;

              this.printInitialValues = dPrint;

              const oPr = {
                PurchaseKey: this.relatedPurchase.record.PurchaseKey,
              };
              this.printExtraData = oPr;

              this.isPrintvisible = true;
            },
            
        },
        mounted(){
            document.title = this.language.loading_purchase_details + '... - ' + this.language.appName;
            this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
            this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
            this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
            this.currentYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook,
              newDate.getFullYear().toString()));


            const purchaseKey = this.$route.params.purchaseKey;
            if(!purchaseKey){
                appFxs.showAlert(language.missing_settings, language.missing_settings_msg)
                .then(vRes => {
                    router.replace({path: '/app/products/purchases/list', replace: true});
                });

                return;
            }
            this.purchaseKey = purchaseKey as string;

            NetServices.requestGet(`stockops/purchases/detailsbypurchaseorder/${this.purchaseKey}`)
                .then(oRes => {
                    if(!oRes.bReturn){
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                        .then(vRes => {
                            router.replace({path: '/app/products/purchases/list', replace: true});
                        })
                        return;
                    }

                    const onePO =  oRes.onePurchaseOper!;

                    this.onePurchaseOper = onePO;
                    this.oneSupplierRec = oRes.oneSupplierRec!;
                    this.listOfProducts = oRes.listOfProducts!;
                    this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!;
                    this.lstOfPurchaseOrderDetails = oRes.lstOfPurchaseOrderDetails!;

                    document.title = `${language.po}: ${onePO.PurchaseOrderNbr} | ${language.details}` + ' - ' + this.language.appName;


                    this.isStillLoading = false;
                });
        }
    })
