
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, helpers, minLength, maxLength } from '@vuelidate/validators'

    import { defineComponent } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { PurchaseOperation } from '@/models/producttypes';
    import { Supplier } from '@/models/managttypes';
    import AppDeclarations from '@/tools/declarations';
    import NetServices from '@/tools/netservices';

    const language =GetLanguage(),
        appFxs = GeneralFx();

    const emptyPurchaseOps: PurchaseOperation = {
        IdPurchase: 0,
        PurchaseKey: '',
        SupplierKey: '',
        PurchaseOrderNbr: '',
        FinancialYear: 2000,
        OperationDate: new Date(),
        OperationStatus: 0,
        UserEmail: '',
    };
    const newDate = new Date(),
        year = newDate.getFullYear();

    export default defineComponent({        
        name: 'PurchOpsModal',
        emits: ['closeForm'],
        props: ["visible", "initialValues", "recordStatus", "listSuppliers", "selectedMonth"],
        data(){
            return {
                v$: useVuelidate(),
                appFxs,
                language,
                dataRecord: {...emptyPurchaseOps} as PurchaseOperation,
                suppliers: this.listSuppliers as Supplier[],
                isSavingRecord: false,

                firstDate: newDate,
                lastDate: newDate,
                selectedMonthData: this.selectedMonth as number,
                selectedYear: year,
            }
        },
        computed: {
            windowTitle(){
                return this.recordStatus == 0 ? language.new_purch_op:
                language.edit_purch_op;
            },
            isDateCorrect(): boolean{
                return this.isBetweenTwoDates(this.dataRecord.OperationDate);
            }
        },
        methods: {
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.isSavingRecord = true;
                const opsData = {...this.dataRecord, PurchaseOrderNbr: this.dataRecord.PurchaseOrderNbr.toUpperCase() } as PurchaseOperation;
                NetServices.requestPost('stockops/purchases/savepurchaseops', {
                    purchaseOpsRecord: opsData,
                    toDelete: false,
                    populateData: false,
                }).then(oRes => {
                    if (!oRes.bReturn) {
                        this.isSavingRecord = false;
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }


                    this.$emit('closeForm', {returnType: 1, returnData: {
                        bReturn: true,
                        purchaseKey: oRes.purchaseKey
                    }});
                });
            },
            isBetweenTwoDates(val: Date): boolean{
                return appFxs.isBetweenTwoDates(val, this.firstDate, this.lastDate);
            },
            generatePurchaseNbr(){
                var oTime = new Date();
                var oMins = oTime.getMinutes().toString();
                var oSecs = oTime.getSeconds().toString();

                var finalMins = oMins.length == 1 ? '0' + oMins: oMins;
                var finalSecs = oSecs.length == 1 ? '0' + oSecs: oSecs;

                var gen = appFxs.getRandomDigits(4);
                const f = `PO${gen}${finalMins}${finalSecs}`;
                this.dataRecord.PurchaseOrderNbr = f;
            }
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: PurchaseOperation = {...oValues, OperationDate: new Date(oValues.OperationDate)};

                        const suppl = this.listSuppliers as Supplier[];
                        this.suppliers = suppl;
                        this.selectedMonthData = this.selectedMonth as number

                        this.selectedYear = Number(appFxs.getLocalStorageByKey(AppDeclarations.currentYearbook, year.toString()));
                        const userEmail = appFxs.getLocalStorageByKey(AppDeclarations.userEmail, '');


                        this.firstDate = appFxs.getFirstDate(this.selectedYear, this.selectedMonthData);
                        this.lastDate = appFxs.getLastDate(this.selectedYear, this.selectedMonthData);

                        if(this.recordStatus == 0){
                            fValues.UserEmail = userEmail;
                            this.v$.$reset();
                        }

                        this.dataRecord = fValues;                        
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {
                    SupplierKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    PurchaseOrderNbr: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                }
            }
        },
        mounted(){
            appFxs.updateLoadedScripts();
        }
    })
