
      import { defineComponent } from 'vue';
  
      
  
      
  
      export default defineComponent({
          name: "AnimatedHotCup",
          data(){
              return{
                  timeOut: 0,
              }
          },
          methods: {
          },
          mounted(){
          },
          unmounted(){
          }
      })
  