
      import { defineComponent } from "vue";  
      import GetLanguage from '@/tools/language';
      import GeneralFx from '@/tools/general_fx';
  
      const language = GetLanguage(),
          appFxs = GeneralFx();
      
      export default defineComponent({
          data(){
            return {
              language,
              appFxs,
            }
          }
      })
  