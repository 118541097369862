    
    import { defineComponent } from "vue";
    import router from '@/router/index';

    import NetServices from '@/tools/netservices';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';

    import Swal from "sweetalert2";
    import { PurchaseOperation, PurchasePayment } from "@/models/producttypes";
    import { Supplier } from "@/models/managttypes";
    import PurchasePaytModal from '@/components/modals/PurchasePaytModal.vue';
    import { OrderValueInfo } from "@/models/notdb";

    const language =GetLanguage(),
        appFxs = GeneralFx();

  
    const newDate = new Date(),
        month = newDate.getMonth() +1,
        year = newDate.getFullYear();

    const emptyPaytRecord: PurchasePayment = {
        IdPurchasePayment:0,
        PurchaseKey: '',
        PaytOpsKey: '',
        SupplierKey: '',
        PaytDate: new Date(),
        PaytAmt: 0,
        UserEmail: '',
        PaytDoctNbr: '',
        PaytType: 0,
    };
    const emptyPurchOps: PurchaseOperation = {
        IdPurchase: 0,
        PurchaseKey: '',
        SupplierKey: '',
        PurchaseOrderNbr: '',
        FinancialYear: 0,
        OperationDate: new Date(),
        UserEmail: '',
        OperationStatus: 0,
    };
    const emptySupplier:Supplier = {
      IdSupplier: 0,
      SupplierKey: '',
      SupplierOrigin: 1,
      SupplierType: 0,
      SupplierName: '',
      Phone: '',
      Email: '',
      AdressLine1: '',
      AdressLine2: '',
      City: '',
      Country: 'RW',
      Region: '',
      AcceptMarketing: true,
      TaxExempted: false,
      Note: '',
      Tags: '',
      DateCreated: new Date(),
      SupplierLang: 0,
      Sex: 0,
    };
    const emptyOrderValue: OrderValueInfo = {
        OrderValue: 0,
        TotalItemQty: 0,
        AmtPaid: 0,
        RemAmt: 0,
    }


    export default defineComponent({
        components: {
            PurchasePaytModal,
        },
        data(){
            return{           
                appFxs,
                language,
                isStillLoading: true,
                itemSearchKey: '',
                itemsPerPage: 5,
                currCode: 'RWF',
                purchaseKey: '',
                userEmail: '',

                onePurchaseOper: {...emptyPurchOps} as PurchaseOperation,
                oneSupplierRec: {...emptySupplier} as Supplier,
                lstPaymentsForPurchaseOrder: [] as PurchasePayment[],
                itemsPageElts: [] as PurchasePayment[],
                purchaseInfoSit: {...emptyOrderValue} as OrderValueInfo,

                isPrintvisible: false,
                printInitialValues: {
                    ...appFxs.getReportingData(),
                    ReportToPrint: 8,
                    ReportTitle: language.list_of_brands
                } as any,
                printExtraData: {},

                isPaytDlgVisible: false,
                paytInitialValues: {...emptyPaytRecord} as PurchasePayment,
                detailRecordStatus: 0,
            }
        },
        computed: {
            filteredDataItems(): PurchasePayment[] {
                let searchKey = this.itemSearchKey;
                if (searchKey.length === 0) return this.lstPaymentsForPurchaseOrder;
                searchKey = searchKey.toLowerCase();

                const filterd = this.lstPaymentsForPurchaseOrder.filter(oDev => {
                    return oDev.PurchaseKey.toLowerCase().includes(searchKey) ||
                        oDev.PaytOpsKey.toLowerCase().includes(searchKey) ||
                        oDev.SupplierKey.toLowerCase().includes(searchKey);
                });
                return filterd;
            },            
            relatedPurchase(): {bReady: boolean, record: PurchaseOperation}{
                const bOReady = this.onePurchaseOper.IdPurchase != 0;
                

                return {
                    bReady: bOReady, 
                    record: this.onePurchaseOper,
                }
            },
            getSupplierName():string{
              return this.oneSupplierRec == null ? '': (this.oneSupplierRec as Supplier).SupplierName;
            }
        },
        methods: {
            showAddEditPaytOper(iStatus: number, oRecord: PurchasePayment|any){
                if (iStatus == 0) {
                  const oRec = {...emptyPaytRecord} as PurchasePayment;

                  console.log('purchaseInfoSit', this.purchaseInfoSit);

                  oRec.PurchaseKey = this.onePurchaseOper.PurchaseKey;
                  oRec.SupplierKey = this.oneSupplierRec.SupplierKey;
                  oRec.UserEmail = this.userEmail;

                  this.detailRecordStatus = 0;
                  this.paytInitialValues = oRec;
                } else {
                  this.detailRecordStatus = 1;
                  this.paytInitialValues = {...oRecord} as PurchasePayment;
                }

                this.isPaytDlgVisible = true;
            },
            onDetailClose(evt: any){
              if(evt.returnType==0){
                this.isPaytDlgVisible = false;
                return;
              }
              if(!evt.returnData.bReturn){
                this.isPaytDlgVisible = false;
                return;
              }

              const listItems = evt.returnData.listItems;
              const purchInfos = evt.returnData.purchaseInfoSit;
              this.lstPaymentsForPurchaseOrder = listItems as PurchasePayment[]; 
              this.purchaseInfoSit = purchInfos as OrderValueInfo; 
              
              
              this.isPaytDlgVisible = false;
            },
            deleteRecordAction(oRecord: PurchasePayment){
                appFxs.showConfirm(language.delete, language.delete_quest_rec)
                .then(vRes => {
                    if(!vRes.isConfirmed)return;

                    this.deleteRecordActionFinal(oRecord);
                });
            },
            deleteRecordActionFinal(oRecord: PurchasePayment){
                appFxs.showAjaxLoader();
                NetServices.requestPost('stockops/purchases/deletepurchasepaytoper',{
                    purchaseOrderKey: oRecord.PurchaseKey,
                    purchasePaytKey: oRecord.PaytOpsKey,
                })
                .then(oRes => {
                    if(!oRes.bReturn){
                        Swal.close();
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                        return;
                    }

                    const listItems = oRes.lstPaymentsForPurchaseOrder!;
                    const purchInfos = oRes.purchaseInfoSit!;
                    this.lstPaymentsForPurchaseOrder = listItems as PurchasePayment[]; 
                    this.purchaseInfoSit = purchInfos as OrderValueInfo;
                    
                    Swal.close();
                });
            },
            printDocumentRecord(oRecord: PurchasePayment){

            },
            onPageChange(evt: any) {
                this.itemsPageElts = appFxs.getProxyData(evt);
            },
            refreshList() {
                appFxs.showAjaxLoader();

                NetServices.requestGet(`stockops/purchases/getlistofpaymentsbypurchaseorder/${this.purchaseKey}`)
                    .then(oRes => {
                        if (!oRes.bReturn) {
                            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                                .then(xRes => {
                                    //router.replace({path: '/app/myprofile', replace: true});
                                    Swal.close();
                                });
                            return;
                        }

                        const fList: PurchasePayment[] = [];
                        oRes.lstPaymentsForPurchaseOrder!.forEach(oEmail => {
                            const fEmail = { ...oEmail, PaytDate: new Date(oEmail.PaytDate) }
                            fList.push(fEmail);
                        });

                        this.onePurchaseOper = oRes.onePurchaseOper!;
                        this.oneSupplierRec = oRes.oneSupplierRec!;
                        this.lstPaymentsForPurchaseOrder = fList;
                        this.purchaseInfoSit = oRes.purchaseInfoSit!;

                        Swal.close();
                    });
            },







            printListItems() {
                const dPrint = appFxs.getReportingData();
                dPrint.ReportTitle = language.list_of_brands;
                dPrint.ReportToPrint = 12;

                this.printInitialValues = dPrint;

                this.isPrintvisible = true;
            },
            onPrintClose(evt: any) {
                this.isPrintvisible = false;
                if (evt.returnType === 0) return;
            },
        },
        mounted(){
            document.title = this.language.loading + '... - ' + this.language.appName;
            this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
            this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
            this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');

            const purchaseKey = this.$route.params.purchaseKey;
            if(!purchaseKey){
                appFxs.showAlert(language.missing_settings, language.missing_settings_msg)
                    .then(vRes => {
                        router.replace({path: '/app/myprofile', replace: true});
                    });
                return;
            }
            this.purchaseKey = purchaseKey as string;

            NetServices.requestGet(`stockops/purchases/getlistofpaymentsbypurchaseorder/${this.purchaseKey}`)
                .then(oRes => {
                    if (!oRes.bReturn) {
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                            .then(xRes => {
                                router.replace({ path: '/app/myprofile', replace: true });
                            });
                        return;
                    }

                    const fList: PurchasePayment[] = [];
                    oRes.lstPaymentsForPurchaseOrder!.forEach(oEmail => {
                        const fEmail = { ...oEmail, PaytDate: new Date(oEmail.PaytDate) }
                        fList.push(fEmail);
                    });

                    this.onePurchaseOper = oRes.onePurchaseOper!;
                    this.oneSupplierRec = oRes.oneSupplierRec!;
                    this.lstPaymentsForPurchaseOrder = fList;
                    this.purchaseInfoSit = oRes.purchaseInfoSit!;

                    document.title = language.po + ': ' + this.onePurchaseOper.PurchaseOrderNbr + '|' +   this.language.payments + '... - ' + this.language.appName;

                    this.isStillLoading = false;
                });
        }
    })
