
    import { defineComponent } from "vue";
    import router from '@/router/index';
  
    import NetServices from '@/tools/netservices';
  
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import appDeclarations from '@/tools/declarations';
    import { Supplier } from "@/models/managttypes";
  
    import Swal from "sweetalert2";
  import { TelCode } from "@/tools/smarttypes";
  
    const language =GetLanguage(),
    appFxs = GeneralFx(),
    newDate = new Date();
  
    export default defineComponent({
      data(){
        return {        
          appFxs,
          language,
          isStillLoading: true,
          listOfSuppliers: [] as Supplier[],
          suppliersPageElts: [] as Supplier[],
          supplierSearchKey: '',
          itemsPerPage: 5,
          countries: [] as TelCode[],
          currentYear: newDate.getFullYear(),
  
  
          isPrintvisible: false,
          printInitialValues: {
            ...appFxs.getReportingData(),
            ReportToPrint: 7,
            ReportTitle: language.list_of_suppliers} as any,
          printExtraData: {},
  
          isSmsDlgVis: false,
          supplierPhoneNbr: '',
  
          
          isEmailDlgVis: false,
          recipientEmails: [] as string[],
        }
      },
      computed: {
        filteredSuppliers(): Supplier[] {
          let searchKey = this.supplierSearchKey;
  
          if (searchKey.length === 0) return this.listOfSuppliers;
          searchKey = searchKey.toLowerCase();
  
          const filterd = this.listOfSuppliers.filter(oDev => {
            return oDev.SupplierName.toLowerCase().includes(searchKey) ||
              oDev.Phone.toLowerCase().includes(searchKey) ||
              oDev.Email.toLowerCase().includes(searchKey) ||
              oDev.City.toLowerCase().includes(searchKey);
          });
          return filterd;
        },
      },
      methods: {
        getLangName(iLang: number): string{
          const langs = appFxs.listOfLangs();
          return langs[iLang]?.label!;
        },
        getSexText(iSex: number): string{
          const sexes = appFxs.listOfSexes();
          return sexes[iSex]?.label!;
        },
        onPageChange(evt: any){
            this.suppliersPageElts = appFxs.getProxyData(evt);
        },
        showAddSupplierAction(iStatus: number, oSupplier: Supplier|any){
          if(iStatus==0){
            router.replace({path: '/app/suppliers/new', replace: true});
          }else{
            const fSupplier = oSupplier as Supplier;
            const oPath = `/app/suppliers/editor/${fSupplier.SupplierKey}`;
            router.replace({path: oPath, replace: true});
          }
        },
        deleteSupplierRecord(data: Supplier){
          appFxs.showConfirm(language.delete, language.delete_quest_rec)
            .then(oResp => {
              if (!oResp.isConfirmed) return;
              this.finalDeleteSupplier(data);
            });
        },
        finalDeleteSupplier(data: Supplier){
          appFxs.showAjaxLoader();
  
                  NetServices.requestPost('managt/suppliers/saveonesupplier', {
                    supplierRecord: data,
                      toDelete: true,
                  }).then(oRes => {
                      if(!oRes.bReturn){
                          Swal.close();
  
                          appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
                          return;
                      }
  
                      const fList: Supplier[] = [];
                      oRes.listOfSuppliers!.forEach(oEmail => {
                          const fEmail = {...oEmail, DateCreated: new Date(oEmail.DateCreated) }
                          fList.push(fEmail);
                      });
  
                      this.listOfSuppliers = fList;
                      
                      Swal.close();
                  });
        },
        refreshList(){
            appFxs.showAjaxLoader();
  
            NetServices.requestGet('managt/suppliers/getlistsuppliers')
            .then(oRes => {
                if(!oRes.bReturn){
                        appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                        .then(xRes => {
                            //router.replace({path: '/app/myprofile', replace: true});
                            Swal.close();
                        });
                        return;
                    }
  
                const fList: Supplier[] = [];
                oRes.listOfSuppliers!.forEach(oEmail => {
                    const fEmail = {...oEmail, DateCreated: new Date(oEmail.DateCreated)}
                    fList.push(fEmail);
                });
  
                this.listOfSuppliers = fList;
                Swal.close();
            });
        },
        printListItems(){
          const dPrint = appFxs.getReportingData();
          dPrint.ReportTitle = language.list_of_suppliers;
          dPrint.ReportToPrint = 7;
  
          this.printInitialValues = dPrint;
  
  
          this.isPrintvisible = true;
        },        
        printElementSit(data: Supplier){
          const dPrint = appFxs.getReportingData();
          dPrint.ReportTitle = `${language.supplier} - ${data.SupplierName}`;
          dPrint.ReportToPrint = 34;

          this.printInitialValues = dPrint;
          this.printExtraData = {
            CurrentYear: this.currentYear,
            SituationSearchKey: data.SupplierKey,
            SituationTypeOper: 1,
          }


          this.isPrintvisible = true;
        },
      printItemsSituation(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = `${language.print_situation} - ${language.suppliers}`;
        dPrint.ReportToPrint = 35;

        this.printInitialValues = dPrint;
        this.printExtraData = {
          CurrentYear: this.currentYear,
          SituationTypeOper: 1,
        }


        this.isPrintvisible = true;
      },
        onPrintClose(evt: any) {
          this.isPrintvisible = false;
          if (evt.returnType === 0) return;
        },
        sendSms(oSupplier: Supplier){
          const phoneNbr = oSupplier.Phone;
          const onlyReqPh = phoneNbr.substring(1);
  
          const oCountries = this.countries.filter(oCtry => oCtry.code == oSupplier.Country);
          const oCountry = oCountries.length > 0 ? appFxs.getProxyData(oCountries[0]): null;
          if(!oCountry){
            appFxs.showAlert(language.country, language.countr_miss_msg);
            return;
          }
          const dialCode = (oCountry as TelCode).dial_code.substring(1);
          const finalPhoneNbr = `${dialCode}${onlyReqPh}`;
  
          this.supplierPhoneNbr = finalPhoneNbr;
          this.isSmsDlgVis = true;
        },
        makeCall(phoneNbr: string){
          appFxs.makeVoiceSinchaCall(phoneNbr);
        },
        sendEmail(oSupplier: Supplier){
          this.recipientEmails = [oSupplier.Email]
          this.isEmailDlgVis = true;
        },
  
        onCloseSmsDialog(evt: any){
          this.isSmsDlgVis = false;
          const fFinal = appFxs.getProxyData(evt);
          if(fFinal.returnType === 1){
            const bRet = fFinal.returnData.bReturn as boolean;
            if(bRet){
              appFxs.showAlert(language.success, language.sms_success_sent);
            }else{
              appFxs.showAlert(language.failure, language.sms_failure_sent);
            }
          }
        },
        onCloseEmailDialog(evt: any){
          this.isEmailDlgVis = false;
          const fFinal = appFxs.getProxyData(evt);
          if(fFinal.returnType === 1){
            const bRet = fFinal.returnData.bReturn as boolean;
            if(bRet){
              appFxs.showAlert(language.email, language.email_sent_success);
            }else{
              appFxs.showAlert(language.failure, language.email_failure_sent);
            }
          }
        }
      },
      mounted(){
        document.title = this.language.list_of_suppliers + ' - ' + this.language.appName;
        this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
        this.currentYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, newDate.getFullYear().toString()));
        const countries = JSON.parse(appFxs.getLocalStorageByKey(
          appDeclarations.telephonesCodes, '[]')) as TelCode[];
  
        this.countries = countries;
  
  
        NetServices.requestGet('managt/suppliers/getlistsuppliers')
          .then(oRes => {
              if(!oRes.bReturn){
                      appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
                      .then(xRes => {
                          router.replace({path: '/app/myprofile', replace: true});
                      });
                      return;
                  }
  
              const fList: Supplier[] = [];
              oRes.listOfSuppliers!.forEach(oEmail => {
                const fEmail = {...oEmail, DateCreated: new Date(oEmail.DateCreated)}
                    fList.push(fEmail);
              });
  
              this.listOfSuppliers = fList;
              
              this.isStillLoading = false;
          });
      }
    })
  