  
  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';
  import DataFxs from '@/tools/data_functions';

  import Swal from "sweetalert2";
  import { ProdProduct, ProductVariantDetail } from "@/models/producttypes";
  import {ManagtUser } from "@/models/managttypes";

  import { SalesOrderDelivery } from "@/models/salestypes";
  import { DeliveryRemainingData, DeliveryWithInfo, OrderDeliveryStatus } from "@/models/notdb";

  import DeliveryOperModal from '@/components/modals/DeliveryOperModal.vue';
  import DeliverOperDetailsModal from '@/components/modals/DeliverOperDetailsModal.vue';


  const language = GetLanguage(),
    appFxs = GeneralFx(),
    dataFxs = DataFxs();
  const fNewDate = new Date(),
    year = fNewDate.getFullYear();
  const emptyDeliverOper: SalesOrderDelivery = {
      IdDelivery: 0,
      DeliveryKey: '',
      DeliveryNbr: '',
      DeliveryStatus: 0,
      OrderNbr: '',
      OrderKey: '',
      DeliveryDate: fNewDate,
      UserEmail: '',
      Barcode: '',
      QrCode: '',
      ReceiverName: '',
      ReceiverTel: '',
      CreatedBy: '',
  };

  export default defineComponent({    
    name: 'SalesDelivsByOder',
    components: {
      DeliveryOperModal,
      DeliverOperDetailsModal,
    },
    data(){
      return {
        appFxs,
        language,        
        isStillLoading: true,
        itemSearchKey: '',
        itemsPerPage: 5,
        selectedYear: year,
        currCode: 'RWF',
        userEmail: '',

        selectedOrderKey: '',

        lstDeliveriesInfoByOrder: [] as DeliveryWithInfo[],
        itemsPageElts: [] as DeliveryWithInfo[],

        listOfUsers: [] as ManagtUser[],
        listOfProducts: [] as ProdProduct[],
        lstOfProdVariantValues: [] as ProductVariantDetail[],
        orderDeliveryStatus: null as OrderDeliveryStatus | any,

        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 8,
          ReportTitle: language.list_of_brands
        } as any,
        printExtraData: {},

        isDeliverModVis: false,
        deliverOpsRecord: {...emptyDeliverOper} as SalesOrderDelivery,
        deliverOpsStatus: 0,

        createdDeliveryOp: {...emptyDeliverOper} as SalesOrderDelivery,

        serverLstOfRemainings: [] as DeliveryRemainingData[],
        toSaveLstOfRemainings: [] as DeliveryRemainingData[],

        isDelivDetailVisisble: false,
      };
    },
    computed: {
      filteredRecordItems(): DeliveryWithInfo[] {
        let searchKey = this.itemSearchKey;

        if (searchKey.length === 0) return this.lstDeliveriesInfoByOrder;
        searchKey = searchKey.toLowerCase();

        const filterd = this.lstDeliveriesInfoByOrder.filter(oDev => {
          return oDev.DeliveryOper.OrderNbr.toLowerCase().includes(searchKey) || 
          oDev.DeliveryOper.Barcode.toLowerCase().includes(searchKey) || 
          oDev.DeliveryOper.QrCode.toLowerCase().includes(searchKey) || 
          oDev.DeliveryOper.OrderNbr.toLowerCase().includes(searchKey) || 
          oDev.DeliveryOper.ReceiverName.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
      orderDelivStatus():
        {
          orderNbr: string;
          orderQty: number;
          delivQty: number;
          remQty: number;
          customerName: string;
          staffName: string;
        }{

          if(this.orderDeliveryStatus == null){
            return {
              orderNbr: '',
              orderQty: 0,
              delivQty: 0,
              remQty: 0,
              customerName: '',
              staffName: '',
            }
          }
          const final = this.orderDeliveryStatus as OrderDeliveryStatus;
          return {
            orderNbr: final.SalesOrder.OrderNbr,
            orderQty: final.OrderQty,
            delivQty: final.DeliveredQty,
            remQty: final.RemainingQty,
            customerName: final.Customer.ClientName,
            staffName: `${final.Staff.FirstName + ' ' + final.Staff.LastName}`,
          }
      },
    },
    methods: {
      getDate(oStr: string): Date{
        return new Date(oStr);
      },
      addDeliveryOper(iStatus: number, oRecord: any){
        if(iStatus == 0){
          console.log('this.selectedOrderKey', this.selectedOrderKey);
          const fNew = {...emptyDeliverOper} as SalesOrderDelivery;
          fNew.Barcode = appFxs.getRandomDigits(12).toUpperCase();
          fNew.QrCode = appFxs.generateTextNbr(15).toUpperCase();
          fNew.DeliveryKey = appFxs.getRandomDigits(35),
          fNew.UserEmail = this.userEmail;
          fNew.CreatedBy = this.userEmail;
          fNew.DeliveryDate = new Date();
          fNew.OrderKey = this.selectedOrderKey;
          fNew.OrderNbr = this.orderDelivStatus.orderNbr;

          this.deliverOpsRecord = fNew;
          this.deliverOpsStatus = 0
        }else{
          console.log('oRecord', oRecord);
          const fEdit = {...oRecord} as DeliveryWithInfo;
          this.deliverOpsRecord = {...fEdit.DeliveryOper} as SalesOrderDelivery;
          this.deliverOpsStatus = 1
        }
        this.isDeliverModVis = true;
      },
      onCloseDelivery(evt: any){
        if (evt.returnType == 0) {
          this.isDeliverModVis = false;
          return;
        }

        this.createdDeliveryOp = evt.returnData as SalesOrderDelivery;
        
        this.isDeliverModVis = false;
        this.getDataForDeliveryOper(this.selectedOrderKey)
      },
      getDataForDeliveryOper(orderKey: string){
        appFxs.showAjaxLoader();
        NetServices.requestGet(`salesorders/delivery/getremainingdetail/${orderKey}`)
        .then(oRes => {
          if (!oRes.bReturn) {
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);              
            return;
          }

          this.serverLstOfRemainings = oRes.lstOfOrderDeliveryDetails!;
          Swal.close();

          console.log('this.serverLstOfRemainings', appFxs.getProxyData(this.serverLstOfRemainings));
          this.openDetailforSaving();
        });
      },
      openDetailforSaving(){
        this.isDelivDetailVisisble = true;
      },
      onClodeDelivDetails(evt: any){
        if (evt.returnType == 0) {
          this.isDelivDetailVisisble = false;
          return;
        }

        const finalList = appFxs.getProxyData(evt.returnData) as DeliveryRemainingData[];
        const toFinal = finalList.filter(o => o.ToDeliverQty > 0);
        console.log('toFinal', toFinal);
        this.toSaveLstOfRemainings = toFinal;
        

        if(toFinal.length>0) this.saveFinalsRecords();
      },
      saveFinalsRecords(){
        appFxs.showConfirm(language.save_deliv_ops, language.save_deliv_ops_msg)
          .then(vRes => {
            if(!vRes.isConfirmed)return;
            this.saveFinalsRecordsFinal();
          });
      },
      saveFinalsRecordsFinal(){
        const oneDelivery = appFxs.getProxyData(this.createdDeliveryOp) as SalesOrderDelivery;
        const detailsRemainings = appFxs.getProxyData(this.toSaveLstOfRemainings) as DeliveryRemainingData[];

        appFxs.showAjaxLoader();
        NetServices.requestPost('salesorders/delivery/savedeliveryoperation',{
          deliverOperation: oneDelivery,
          lstOfSalesDelivRemains: detailsRemainings,
        })
        .then(oRes=>{
          if(!oRes.bReturn){
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }

          const finalList: DeliveryWithInfo[] = [];
          const fList = oRes.lstDeliveriesInfoByOrder!;
          fList.forEach(cList => {
            const final = {...cList} as DeliveryWithInfo;
            finalList.push(final);
          });

          this.lstDeliveriesInfoByOrder = finalList;   
          this.listOfProducts = oRes.listOfProducts!
          this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!
          this.orderDeliveryStatus = oRes.orderDeliveryStatus!;
          this.listOfUsers = oRes.listOfUsers!;

          this.isDelivDetailVisisble = false;
          Swal.close();
        });
      },



      refresListItems(){
        appFxs.showAjaxLoader();
        NetServices.requestGet(`salesorders/delivery/getlistofdeliverbyorder/${this.selectedOrderKey}`)
        .then(oRes => {
          if(!oRes.bReturn){
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }

          const finalList: DeliveryWithInfo[] = [];
          const fList = oRes.lstDeliveriesInfoByOrder!;
          fList.forEach(cList => {
            const final = {...cList} as DeliveryWithInfo;
            finalList.push(final);
          });

          this.lstDeliveriesInfoByOrder = finalList;
          this.listOfProducts = oRes.listOfProducts!
          this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!
          this.orderDeliveryStatus = oRes.orderDeliveryStatus!;
          this.listOfUsers = oRes.listOfUsers!;

          Swal.close();
        });
    
      },
      onPageChange(evt: any){
            this.itemsPageElts = appFxs.getProxyData(evt);
      },
      printListItems(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.delivs_by_order;
        dPrint.ReportToPrint = 22;

        this.printInitialValues = dPrint;
        this.printExtraData = {
          SalesOrderKey: this.selectedOrderKey
        }

        this.isPrintvisible = true;
      },      
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },


    },
    mounted(){   
      document.title = this.language.delivs_by_order + ' - ' + this.language.appName;
      this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
      this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
      this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
      this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));

      const orderKey = this.$route.params.orderKey;

      if(orderKey == null){
        router.push({
          name: 'appsalesdelivlist',
        });
        return;
      }
      const _key = orderKey as string;
      this.selectedOrderKey = _key;

      NetServices.requestGet(`salesorders/delivery/getlistofdeliverbyorder/${_key}`)
        .then(oRes => {
          if(!oRes.bReturn){
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody)
            .then(vRes => {
              router.push({
                name: 'appsalesdelivlist',
              });
            });            
            return;
          }

          const finalList: DeliveryWithInfo[] = [];
          const fList = oRes.lstDeliveriesInfoByOrder!;
          fList.forEach(cList => {
            const final = {...cList} as DeliveryWithInfo;
            finalList.push(final);
          });

          this.lstDeliveriesInfoByOrder = finalList;   
          this.listOfProducts = oRes.listOfProducts!
          this.lstOfProdVariantValues = oRes.lstOfProdVariantValues!
          this.orderDeliveryStatus = oRes.orderDeliveryStatus!;
          this.listOfUsers = oRes.listOfUsers!;

          this.isStillLoading = false;
        });
    }
  })
