
    import { useVuelidate } from '@vuelidate/core'
    import { required, helpers } from '@vuelidate/validators'

    import { defineComponent } from 'vue';

    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { DiscountRec, SalesQuote, SalesQuoteDetail } from '@/models/salestypes';
    import { TaxSett } from '@/models/managttypes';
    import AppDeclarations from '@/tools/declarations';
    import { ProdProduct, ProductVariantDetail } from '@/models/producttypes';
    import { DiscountWithRules } from '@/models/notdb';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    const greaterThanZero = (val: number) => val > 0;
    const fNewDate = new Date(),
        year = fNewDate.getFullYear();

    const emptyQuoteDetail: SalesQuoteDetail = {
        IdQuoteDet: 0,
        QuoteKey: '',
        QuoteDetailKey: '',
        ProductSKU: '',
        ProductOrient: 0,
        VariantValues: '',
        Quantity: 1,
        UnitPrice: 1,
        SubTotal: 1,
        DiscountAmount: 0,
        TaxableAmount: 0,
        TaxKey: '',
        TaxRate: 0,
        TaxValue: 0,
        TotalAmt: 0,
        FiscalYear: year,
        TempoKey: '',
        DiscountKeys: '',
    };
    const emptySalesQuote: SalesQuote = {
        ClientKey: '',
        DeviseISO: 'RWF',
        FiscalYear: 2000,
        IdQuote: 0,
        OfficeCode: 'HQ',
        OperDate: new Date(),
        QuoteBarcode: '',
        QuoteCreatedBy: '',
        QuoteKey: '',
        QuoteNbr: '',
        QuoteNote: '',
        QuoteQRCode: '',
        QuoteStatus: 0,
        TempoKey: '',
        UserEmail: '',
        GeneratedOrderKey: '',
    };
    
    export default defineComponent({
        name: 'SalesQuoteDetailModal',
        emits: ['closeForm'],
        props: [
            "visible",
            "initialValues", 
            "recordStatus",
            "listOfProducts",
            "listOfVariants",
            "listOfDiscounts",
            "listOfTaxes",
            "orderRecord",
        ],
        data(){
            return {
                v$: useVuelidate(),
                appFxs,
                language,
                dataRecord: {...emptyQuoteDetail} as SalesQuoteDetail,
                isSavingRecord: false,
                currCode: 'RWF',
                userEmail: '',
                selectedYear: year,

                theListProds: [] as ProdProduct[],
                theListVars: [] as ProductVariantDetail[],
                theListDiscs: [] as DiscountWithRules[],
                theListTaxes: [] as TaxSett[],
                theOrderRecord: {...emptySalesQuote} as SalesQuote,

                specificVariants: [] as ProductVariantDetail[],

                listOfDiscountWithRules: [] as DiscountWithRules[],
                listOfTaxSettings: [] as TaxSett[],

                selectedProduct: null as ProdProduct|any,
                selectedVariant: null as ProductVariantDetail|any,
                selectedTax: null as TaxSett|any,
                productOrient: 0,
            }
        },
        computed: {
            windowTitle() {
                return this.recordStatus == 0 ? language.add_operation :
                    language.edit_operation;
            },            
            isVariantSet(): boolean{
                return this.dataRecord.ProductOrient == 0 ? true:
                this.dataRecord.VariantValues.length > 0;
            },
            getTaxValue(): number {
                const lst = this.theListTaxes as TaxSett[];

                if(this.selectedProduct==null) return 0;
                const finalProd = this.selectedProduct as ProdProduct;
                if(!finalProd.IsTaxable) return 0;

                const taxKey = finalProd.DefaultTax;
                const oTax = lst.find(o => o.TaxKey == taxKey);
                if(oTax==undefined) return 0;

                
                const oValue = this.dataRecord.TaxableAmount * (oTax.TaxRate / 100);
                return oValue;
            },
            getDiscountValue(): number {
                const salesOper = appFxs.getProxyData(this.theOrderRecord);
                const finalData = {...salesOper, OperDate: new Date(salesOper.OperDate)} as SalesQuote;


                const flst = appFxs.getProxyData(this.theListDiscs) as any[];
                const lst: DiscountWithRules[] = [];
                flst.forEach(o => {
                    const dsc = {
                        ...o.discount, 
                        StartDate: new Date(o.discount.StartDate), 
                        EndDate: new Date(o.discount.EndDate)
                    } as DiscountRec;
                    const dl = o.listOfRules;

                    lst.push({
                        discount: dsc,
                        listOfRules: dl,
                    })
                });


                let oValue = 0;
                const dOperDate = finalData.OperDate;
                const oQty = this.dataRecord.Quantity;
                const oUnitPr = this.dataRecord.UnitPrice;
                const subTotal = oQty * oUnitPr;

                const customerKey = finalData.ClientKey;
                const productSku = this.dataRecord.ProductSKU;
                const appliedDiscKeys: string[] = [];

                lst.forEach(oDisc => {
                    const oneDisc = oDisc.discount;

                    const bHasEndDate = oneDisc.HasEndDate;
                    const dStartDate = oneDisc.StartDate;
                    const dEndDate = oneDisc.EndDate;
                    const bHasRules = oneDisc.HasRules;
                    const bIsStillActive = oneDisc.IsStillActive;

                    const bAcceptCust = oneDisc.ApplytoAllClients || oneDisc.Clients.includes(customerKey);
                    const bAcceptProd = oneDisc.ApplytoAllProducts || oneDisc.Products.includes(productSku);

                    const isValidDate = !bHasEndDate || appFxs.isBetweenTwoDates(dOperDate, dStartDate, dEndDate);


                    const bToApply = bIsStillActive && isValidDate && bAcceptCust && bAcceptProd;
                    if(bToApply){
                        if (bHasRules) {
                            const rules = oDisc.listOfRules;
                            const oQty = this.dataRecord.Quantity;
                            const ruleToApply = rules.find(fx => oQty >= fx.MinQuant && oQty <= fx.MaxQuant);
                            if(ruleToApply != undefined){
                                oValue += (subTotal * (ruleToApply.DiscValue / 100));
                            }
                        }else{
                            oValue += (subTotal * (oDisc.discount.DiscValue / 100));
                        }

                        appliedDiscKeys.push(oneDisc.DiscountCode);
                    }
                    
                });

                this.dataRecord.DiscountKeys = 
                    appliedDiscKeys.length > 0 ?
                    appliedDiscKeys.join(';'):
                    '';
                return oValue;
            },
        },
        methods: {
            changeSousTotalRecs(){
                const subTotal = this.dataRecord.Quantity * this.dataRecord.UnitPrice;
                this.dataRecord.SubTotal = subTotal;

                const oDisc = this.getDiscountValue;
                this.dataRecord.DiscountAmount = oDisc;
                const taxableAmt = subTotal - oDisc;
                this.dataRecord.TaxableAmount = taxableAmt;

                const oTaxVal = this.getTaxValue;
                this.dataRecord.TaxValue = oTaxVal;

                const totalAmt = taxableAmt + oTaxVal;
                
                this.dataRecord.TotalAmt = totalAmt;
            },
            onProductChange(){
                const prodSku = this.dataRecord.ProductSKU;

                const finalAllProds = appFxs.getProxyData(this.theListProds) as ProdProduct[];
                const finalAllVar = appFxs.getProxyData(this.theListVars) as ProductVariantDetail[];

                if(!prodSku){
                    this.dataRecord.ProductOrient = 0;
                    this.dataRecord.VariantValues = '';
                    this.dataRecord.UnitPrice = 0;

                    this.dataRecord.TaxKey = '';
                    this.dataRecord.TaxRate = 0;
                    this.dataRecord.TaxValue = 0;

                    this.selectedProduct = null;

                    this.productOrient = 0;

                    this.changeSousTotalRecs();
                    return;
                }
                const realProdChecker = finalAllProds.find(o => o.SKU == prodSku);

                if(realProdChecker == null){
                    this.dataRecord.ProductOrient = 0;
                    this.dataRecord.VariantValues = '';
                    this.dataRecord.UnitPrice = 0;

                    this.dataRecord.TaxKey = '';
                    this.dataRecord.TaxRate = 0;
                    this.dataRecord.TaxValue = 0;

                    this.selectedProduct = null;
                    
                    this.productOrient = 0;

                    this.changeSousTotalRecs();
                    return;
                }

                this.selectedProduct = realProdChecker as ProdProduct;
                const oTaxRec = this.theListTaxes.find(o => o.TaxKey == realProdChecker.DefaultTax);
                this.dataRecord.TaxKey = (!oTaxRec) ? '': oTaxRec.TaxKey;
                this.dataRecord.TaxRate = (!oTaxRec) ? 0: oTaxRec.TaxRate;
                

                const specVariants = finalAllVar.filter(o => o.ProductSku == prodSku);                
                const hasVariants = specVariants.length > 0;

                this.dataRecord.VariantValues = '';
                this.specificVariants = specVariants;
                
                this.dataRecord.ProductOrient = hasVariants ? 1 : 0;
                if(!hasVariants){
                    this.dataRecord.UnitPrice = realProdChecker.SellingPrice;
                }else{
                    this.dataRecord.UnitPrice = 0;
                }

                this.productOrient = hasVariants ? 1 : 0;
                
                this.changeSousTotalRecs();
            },
            onVariantChange(){
                const varSku = this.dataRecord.VariantValues;
                if(!varSku){
                    this.dataRecord.UnitPrice = 0;
                    this.selectedVariant = null;


                    this.changeSousTotalRecs();
                    return;
                }
                const foundVars = this.specificVariants.find(o => o.VariantValues == varSku);
                if(!foundVars){
                    this.dataRecord.UnitPrice = 0;
                    this.selectedVariant = null;

                    this.changeSousTotalRecs();
                    return;
                }
                
                this.selectedVariant = foundVars as ProductVariantDetail;

                this.dataRecord.UnitPrice = foundVars!.SellingPrice;
                this.changeSousTotalRecs();
            },
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            selectOnFocus(evt: Event){
                const oInput = evt.target as HTMLInputElement;
                oInput.select();
            },
            saveRecordData(){
                this.$emit('closeForm', {returnType: 1, returnData: this.dataRecord});
            },
            updateFieldOnChange(oSales: SalesQuoteDetail){
                if(!oSales.ProductSKU) return;
                const prodSku = oSales.ProductSKU;
                const finalAllProds = appFxs.getProxyData(this.theListProds) as ProdProduct[];
                const finalAllVar = appFxs.getProxyData(this.theListVars) as ProductVariantDetail[];
                const realProdChecker = finalAllProds.find(o => o.SKU == prodSku);
                if(!realProdChecker) return;
                this.selectedProduct = realProdChecker as ProdProduct;

                const specVariants = finalAllVar.filter(o => o.ProductSku == prodSku);                
                const hasVariants = specVariants.length > 0;
                this.specificVariants = specVariants;
                this.productOrient = hasVariants ? 1 : 0;
            },
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.initialValues);
                        const fValues: SalesQuoteDetail = {...oValues} as SalesQuoteDetail;


                        this.selectedYear = Number(appFxs.getLocalStorageByKey(AppDeclarations.currentYearbook, year.toString()));
                        const userEmail = appFxs.getLocalStorageByKey(AppDeclarations.userEmail, '');
                        this.userEmail = userEmail;

                        this.theListProds = appFxs.getProxyData(this.listOfProducts) as ProdProduct[];
                        this.theListVars = appFxs.getProxyData(this.listOfVariants) as ProductVariantDetail[];
                        this.theListDiscs = appFxs.getProxyData(this.listOfDiscounts) as DiscountWithRules[];
                        this.theListTaxes = appFxs.getProxyData(this.listOfTaxes) as TaxSett[];
                        this.theOrderRecord = appFxs.getProxyData(this.orderRecord) as SalesQuote;

                        if(this.recordStatus == 0){
                            this.v$.$reset();
                        }else{
                            this.v$.$reset();
                        }
                        this.updateFieldOnChange(fValues);
                        this.dataRecord = fValues;
                    }
                }
            }
        },
        validations(){
            return {
                dataRecord: {
                    QuoteKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    QuoteDetailKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    ProductSKU: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    Quantity: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),                 
                        $autoDirty: true,
                    },
                    UnitPrice: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),                 
                        $autoDirty: true,
                    },
                    SubTotal: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),                 
                        $autoDirty: true,
                    },
                    TaxableAmount: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),                 
                        $autoDirty: true,
                    },
                    TotalAmt: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        greaterThanZero: helpers.withMessage(language.greaterThanZeroMSg, greaterThanZero),                 
                        $autoDirty: true,
                    },
                    FiscalYear: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                    TempoKey: {
                        required: helpers.withMessage(language.fldRequired, required),                 
                        $autoDirty: true,
                    },
                }
            }
        },
        mounted(){
            appFxs.updateLoadedScripts();
        }
    });
