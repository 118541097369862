
  import { defineComponent } from "vue";
  import router from '@/router/index';

  import NetServices from '@/tools/netservices';

  import GetLanguage from '@/tools/language';
  import GeneralFx from '@/tools/general_fx';
  import appDeclarations from '@/tools/declarations';
  import DataFxs from '@/tools/data_functions';

  import Swal from "sweetalert2";
  import { SalesOrderPayment, SalesRefundPayment } from "@/models/salestypes";
  import { SalesRefundInfo } from "@/models/notdb";

  import RefundOperPaytModal from '@/components/modals/RefundOperPaytModal.vue';


  const language =GetLanguage(),
    appFxs = GeneralFx(),
    dataFxs = DataFxs();
  const fNewDate = new Date(),
    year = fNewDate.getFullYear();

  
  const emptyRefundPayt: SalesRefundPayment = {
    IdRefundPayt: 0,
    RefundKey : '',
    RefundPaytKey: '',
    ReceiptNbr: '',
    AmtPaid: 0,
    CashierEmail: '',
    PaytType: 0,
    PaytDate: new Date(),
    PaytBarcode: appFxs.getRandomDigits(12),
    PaytQRCode: appFxs.getRandomDigits(15),
  }

  export default defineComponent({
    name: 'ListRefundByRefundOper',
    components: {
      RefundOperPaytModal,
    },
    data(){
      return {
        appFxs,
        language,        
        isStillLoading: true,
        itemSearchKey: '',
        itemsPerPage: 5,
        selectedYear: year,
        currCode: 'RWF',
        userEmail: '',

        selectedRefundKey: '',

        lstRefundPayments: [] as SalesRefundPayment[],
        itemsPageElts: [] as SalesRefundPayment[],
        salesRefundInfo: null as SalesRefundInfo|any,
        paytTypes: appFxs.getPaytTypes(),

        savedSalesOperKey: '',

        isPrintvisible: false,
        printInitialValues: {
          ...appFxs.getReportingData(),
          ReportToPrint: 8,
          ReportTitle: language.list_of_brands
        } as any,
        printExtraData: {},

        isSalesPaytVis: false,
        salesPaytInitVals: {...emptyRefundPayt} as SalesRefundPayment,
        salesPaytRecStatus: 0,
        salesRemAmt: 0,
      }
    },
    computed: {
      filteredRecordItems(): SalesRefundPayment[] {
        let searchKey = this.itemSearchKey;

        if (searchKey.length === 0) return this.lstRefundPayments;
        searchKey = searchKey.toLowerCase();

        const filterd = this.lstRefundPayments.filter(oDev => {
          return oDev.ReceiptNbr.toLowerCase().includes(searchKey);
        });
        return filterd;
      },
      getRefundInfo():{
        nRefund:string;
        cltName: string;
        refundDate: Date;
        orderAmt: number;
        paidAmt: number;
        balanace: number;
      }{

        if(this.salesRefundInfo==null){
          return {
            nRefund: '',
            cltName: '',
            refundDate: new Date(),
            orderAmt: 0,
            paidAmt: 0,
            balanace: 0,
          }
        }

        const fFinal = this.salesRefundInfo as SalesRefundInfo;

        return {
            nRefund: fFinal.SalesRefundRecord.RefundNbr,
            cltName: fFinal.CustomerRecord.ClientName,
            refundDate: fFinal.SalesOrderRecord.OperDate,
            orderAmt: fFinal.TotalAmt,
            paidAmt: fFinal.PaidAmount,
            balanace: fFinal.RemainingToPay,
          }
      }
    },
    methods: {
      deletePaytRecord(oRecord: SalesRefundPayment){
        appFxs.showConfirm(language.delete, language.delete_quest_rec)
          .then(vRes => {
            if(!vRes.isConfirmed)return;
            this.deletePaytRecordFinal(oRecord);
          });
      },
      deletePaytRecordFinal(oRecord: SalesRefundPayment){
        appFxs.showAjaxLoader();

        NetServices.requestPost('salesorders/refund/payts/deleterefundoperrecord',{
          populateData: true,
          refundPaytOperKey: oRecord.RefundPaytKey,
        })
        .then(oRes => {
          if(!oRes.bReturn){
            Swal.close();
            appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
            return;
          }

          const finalList: SalesRefundPayment[] = [];
          const fList = oRes.lstRefundPayments!;
          fList.forEach(cList => {
            const final = { ...cList, PaytDate: new Date(cList.PaytDate) } as SalesRefundPayment;
            finalList.push(final);
          });

          this.lstRefundPayments = finalList;
          const fD = oRes.salesRefundInfo!;
          const finalFd = {
            ...fD,
            SalesOrderRecord: { ...fD.SalesOrderRecord, OperDate: new Date(fD.SalesOrderRecord.OperDate) },
            SalesRefundRecord: { ...fD.SalesRefundRecord, OperDate: new Date(fD.SalesRefundRecord.OperDate) }
          } as SalesRefundInfo;

          this.salesRefundInfo = finalFd;
          this.salesRemAmt = fD.RemainingToPay;

          Swal.close();
        });
      },
      addPaytRecord(iStatus: number, oRecord: SalesOrderPayment | any){
          const oSalesOrder = this.salesRefundInfo as SalesRefundInfo;

        if(iStatus==0){
          const fValues = {
            ...emptyRefundPayt,
            RefundKey: oSalesOrder.SalesRefundRecord.RefundKey,
            OrderKey: oSalesOrder.SalesOrderRecord.OrderKey,
            ReceiptNbr: ('RCPT' + appFxs.generateTextNbr(7)).toUpperCase(),
            AmtPaid: oSalesOrder.RemainingToPay,
            CashierEmail: this.userEmail,
            PaytType: 0,
            PaytDate: oSalesOrder.SalesOrderRecord.OperDate,
            PaytBarcode: appFxs.getRandomDigits(12),
            PaytQRCode: appFxs.getRandomDigits(15),
          } as SalesRefundPayment;
          this.salesPaytInitVals = fValues;
          this.salesPaytRecStatus = 0;

          this.salesRemAmt = oSalesOrder.RemainingToPay;
        }else{
          const finalVal = {...oRecord} as SalesRefundPayment;
          this.salesPaytInitVals = finalVal;
          this.salesPaytRecStatus = 0;
          this.salesRemAmt = oSalesOrder.RemainingToPay;
        }
        this.isSalesPaytVis = true;
      },
      refreshList(){
        appFxs.showAjaxLoader();
        NetServices.requestGet(`salesorders/refund/payts/getlistpaymentsbyrefund/${this.selectedRefundKey}`)
          .then(oRes => {
            if (!oRes.bReturn) {
              Swal.close();
              appFxs.showAlert(oRes.msgTitle, oRes.msgBody);
              return;
            }

            const finalList: SalesRefundPayment[] = [];
            const fList = oRes.lstRefundPayments!;
            fList.forEach(cList => {
              const final = { ...cList, PaytDate: new Date(cList.PaytDate) } as SalesRefundPayment;
              finalList.push(final);
            });

            this.lstRefundPayments = finalList;
            const fD = oRes.salesRefundInfo! as SalesRefundInfo;
            this.salesRefundInfo = fD;  
            this.salesRemAmt = fD.RemainingToPay;

            Swal.close();
          });
      },
      onPageChange(evt: any){
            this.itemsPageElts = appFxs.getProxyData(evt);
      },
      onClosePaytModal(evt: any){
        if (evt.returnType == 0) {
          this.isSalesPaytVis = false;
          return;
        }

        const oData = evt.returnData;
        const salesRefundInfo = oData.salesRefundInfo as SalesRefundInfo;
        const lstRefundPayments = oData.lstRefundPayments as SalesRefundPayment[];

        const finalList: SalesRefundPayment[] = [];
          const fList = lstRefundPayments;
          fList.forEach(cList => {
            const final = {...cList, PaytDate: new Date(cList.PaytDate)} as SalesRefundPayment;
            finalList.push(final);
          });

          this.lstRefundPayments = finalList;
          const fD = salesRefundInfo;
          const finalFd = {
            ...fD,
            SalesOrderRecord: {...fD.SalesOrderRecord, OperDate: new Date(fD.SalesOrderRecord.OperDate)},
            SalesRefundRecord: {...fD.SalesRefundRecord, OperDate: new Date(fD.SalesRefundRecord.OperDate)}
          } as SalesRefundInfo;

          this.salesRefundInfo = finalFd;
          this.isSalesPaytVis = false;
      },
      printListItems(){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = language.refund_payts;
        dPrint.ReportToPrint = 29;

        this.printInitialValues = dPrint;
        this.printExtraData = {
          SalesRefundKey: this.selectedRefundKey
        }

        this.isPrintvisible = true;
      },
      printReceipt(oRecord: SalesRefundPayment){
        const dPrint = appFxs.getReportingData();
        dPrint.ReportTitle = `${language.print_receipt}-${oRecord.ReceiptNbr}`;
        dPrint.ReportToPrint = 30;

        this.printInitialValues = dPrint;
        this.printExtraData = {
          SalesRefundPaytKey: oRecord.RefundPaytKey
        }

        this.isPrintvisible = true;
      },
      onPrintClose(evt: any) {
        this.isPrintvisible = false;
        if (evt.returnType === 0) return;
      },
    },
    mounted(){   
      document.title = this.language.list_of_refund_payts + ' - ' + this.language.appName;
      this.itemsPerPage = Number(appFxs.getLocalStorageByKey(appDeclarations.itemsPerPage, '5'));
      this.currCode = appFxs.getLocalStorageByKey(appDeclarations.currentDevise, 'RWF');
      this.userEmail = appFxs.getLocalStorageByKey(appDeclarations.userEmail, '');
      this.selectedYear = Number(appFxs.getLocalStorageByKey(appDeclarations.currentYearbook, year.toString()));

      const refundKey = this.$route.params.refundKey;
      if(refundKey == null){
        router.push({
          name: 'appsalesrefundlist',
        });
        return;
      }
      const _key = refundKey as string;
      this.selectedRefundKey = _key;
      this.paytTypes = appFxs.getPaytTypes();

      NetServices.requestGet(`salesorders/refund/payts/getlistpaymentsbyrefund/${_key}`)
        .then(oRes => {
          if(!oRes.bReturn){
            router.push({
              name: 'appsalesrefundlist',
            });
            return;
          }

          const finalList: SalesRefundPayment[] = [];
            const fList = oRes.lstRefundPayments!;
            fList.forEach(cList => {
              const final = { ...cList, PaytDate: new Date(cList.PaytDate) } as SalesRefundPayment;
              finalList.push(final);
            });

            this.lstRefundPayments = finalList;
            const fD = oRes.salesRefundInfo! as SalesRefundInfo;
            
            this.salesRefundInfo = fD;
            this.salesRemAmt = fD.RemainingToPay;

          this.isStillLoading = false;
        });



      
      

    }
  })
