
    import { defineComponent } from 'vue';
    import GetLanguage from '@/tools/language';
    import GeneralFx from '@/tools/general_fx';
    import { DeliveryRemainingData, RefundEntryRemainingData } from '@/models/notdb';

    import DeliveryInput from '@/components/smalls/DeliveryInputData.vue';

    const language =GetLanguage(),
        appFxs = GeneralFx();
    const fNewDate = new Date(),
        year = fNewDate.getFullYear();

    export default defineComponent({
        name: 'RefundEntryStockDetailModal',
        components: {
            DeliveryInput,
        },
        emits: ['closeForm'],
        props: [
            "visible",
            "listOfDetails",
        ],
        data(){
            return {                
                appFxs,
                language,
                isSavingRecord: false,

                theListOfdetails: [] as RefundEntryRemainingData[],
                theListOfSavedDetails: [] as RefundEntryRemainingData[],
            };
        },
        computed: {
            windowTitle() {
                return language.delivery_details;
            },
            isAnySelected():boolean{
                const oSum = this.theListOfSavedDetails.reduce((acc: number, obj:RefundEntryRemainingData) => {
                    return acc + obj.ToDeliverQty;
                }, 0);

                return oSum > 0;
            }
        },
        methods: {
            onEltUpdate(evt: any){
                const oChanged = appFxs.getProxyData(evt) as RefundEntryRemainingData;
                const lst =  appFxs.getProxyData(this.theListOfSavedDetails) as RefundEntryRemainingData[];
                const filt = lst.filter(f => f.OrderDetailKey !== oChanged.OrderDetailKey);
                filt.push(oChanged);
                this.theListOfSavedDetails = filt;
            },
            closeFormRet(){
                this.$emit('closeForm', {returnType: 0, returnData: null});
            },
            saveRecordData(){
                this.$emit('closeForm', {returnType: 1, returnData: this.theListOfdetails});
            },
            isBtnDisabled(): boolean{
                return false;
            },
        },
        watch:{
            visible: {
                handler(newV, oldV){
                    if((newV) && newV !== oldV ){
                        const oValues = appFxs.getProxyData(this.listOfDetails) as RefundEntryRemainingData[];
                        const fValues = oValues;                        

                        this.theListOfdetails = fValues;
                        this.theListOfSavedDetails = fValues;
                    }
                }
            }
        },
    })
